import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { formatPrice } from 'features/game/lib/prices/format'
import { $activeTransactionType, chooseReaction, chooseReactionFetching } from 'features/game/model'
import { handleActionTypeToVariant } from 'lib/handle-fields'
import arrowDown from 'static/arrow-down-red.svg'
import arrowUp from 'static/arrow-up-green.svg'
import { theme } from 'theme'
import { ActionButton } from 'ui/atoms/button/action-button'
import { Typography } from 'ui/atoms/typography'
import { NoData } from 'ui/molecules/no-data'

import { Divider } from '../../atoms/divider'
import { TabInnerHeading } from '../../atoms/tab-inner-heading'
import { TabInnerSection } from '../../atoms/tab-inner-section'
import { ButtonsSection, Icon, Instrument, Name, Price } from '../../organisms/buy-sell-tab/molecules'

export const EventsActionsTab = ({ selectedInstrument }) => {
  const reactionError = useStore(chooseReactionFetching.error)
  const activeTransactionType = useStore($activeTransactionType)
  if (!selectedInstrument) return <NoData />
  const { type, actions } = selectedInstrument
  window.log(actions, 'selected event actions')
  return (
    <div>
      <TabInnerSection>
        <TabInnerHeading>Actions</TabInnerHeading>
        <Instrument>
          <Name hasImg={false} color={theme.color.black} weight={600}>
            {selectedInstrument.name}
          </Name>
        </Instrument>
        {type !== 'other' && selectedInstrument.sum !== 0 && (
          <Instrument>
            <Icon small src={type === 'income' ? arrowUp : arrowDown} />
            <Price status={type}>{formatPrice(selectedInstrument.sum)}</Price>
          </Instrument>
        )}
      </TabInnerSection>
      <Divider />
      {reactionError && (
        <TabInnerSection>
          <Typography color={theme.color.red} size={theme.font.size.extrasmall}>
            {reactionError.toString()}
          </Typography>
        </TabInnerSection>
      )}
      {actions.length > 0 && (
        <TabInnerSection>
          <StyledButtonsSection>
            {actions.map(({ payload, type, name }, idx) => (
              <StyledActionButton
                onClick={() => {
                  chooseReaction({ payload })
                }}
                loading={activeTransactionType === payload.reaction_id}
                flex
                key={idx}
                disabled={activeTransactionType && activeTransactionType !== payload.reaction_id}
                variant={handleActionTypeToVariant(type)}
              >
                {name}
              </StyledActionButton>
            ))}
          </StyledButtonsSection>
        </TabInnerSection>
      )}
    </div>
  )
}

const StyledActionButton = styled(ActionButton)`
  &:last-of-type:not(:first-of-type) {
    margin-left: 0;
  }
  &:not(:first-of-type) {
    margin-left: 0 !important;
    margin-top: 10px;
  }
`

const StyledButtonsSection = styled(ButtonsSection)`
  flex-direction: column;
`
