/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import Scrollbar from 'react-scrollbars-custom'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

export const Scrollbars = ({
  children,
  offset = 6,
  trackOffset = 0,
  trackHeight = '100%',
  top = 0,
  ...rest
}) => {
  const scrollRef = useRef(null)
  const [contentOffset, setContentOffset] = useState(0)
  const scrollbarOpts = {
    noScrollX: true,
    ref: scrollRef,
    removeTracksWhenNotUsed: true,
    contentProps: {
      renderer: (props) => {
        const { elementRef, ...rest } = props
        return <Content offset={contentOffset} ref={elementRef} {...rest} />
      },
    },
    wrapperProps: {
      renderer: (props) => {
        const { elementRef, ...rest } = props
        return <Wrapper offset={contentOffset} ref={elementRef} {...rest} />
      },
    },
    scrollerProps: {
      renderer: (props) => {
        const { elementRef, ...rest } = props
        return <Scroller ref={elementRef} {...rest} />
      },
    },
    trackYProps: {
      renderer: (props) => {
        const { elementRef, ...rest } = props
        return <Track top={top} trackHeight={trackHeight} offset={trackOffset} ref={elementRef} {...rest} />
      },
    },
    thumbYProps: {
      renderer: (props) => {
        const { elementRef, ...rest } = props
        return <Thumb ref={elementRef} {...rest} />
      },
    },
    ...rest,
  }
  useEffect(() => {
    if (scrollRef.current) {
      const needOffset = scrollRef.current.scrollHeight > scrollRef.current.clientHeight
      setContentOffset(needOffset ? offset : 0)
    }
  }, [scrollRef, children])
  return <Scrollbar {...scrollbarOpts}>{children}</Scrollbar>
}

const Wrapper = styled.div`
  right: ${({ offset }) => (offset === 0 ? 0 : 4)}px !important;
`

const Scroller = styled.div``

const Thumb = styled.div`
  background: ${hexToRGBA(theme.color.black, 0.6)} !important;
`

const Track = styled.div`
  background: #4c4f56 !important;
  height: ${prop('trackHeight')} !important;
  right: ${prop('offset')}px !important;
  top: ${prop('top')} !important;
  width: 4px !important;
`

const Content = styled.div`
  height: 100%;
  padding-right: ${prop('offset')}px !important;
  transition: all 0.2s ease-in-out;
`
