import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import backIcon from 'static/back-green.svg'
import closeIcon from 'static/cross-green.svg'
import { theme } from 'theme'

type IconProps = {
  size?: number
}

type CircleButtonProps = {
  type: 'close' | 'back'
  className?: string
  onClick?: () => {}
} & IconProps

export const CircleButton = ({ type = 'close', size = 40, className, onClick }: CircleButtonProps) => {
  return (
    <Container {...{ size, className, onClick }}>
      <Icon {...{ size }} src={type === 'close' ? closeIcon : backIcon} />
    </Container>
  )
}

const Icon = styled.img<IconProps>`
  width: ${({ size }) => Math.floor(size * 0.3)}px;
  height: ${({ size }) => Math.floor(size * 0.3)}px;
  display: block;
`

const Container = styled.div`
  width: ${prop('size')}px;
  height: ${prop('size')}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${hexToRGBA(theme.color.green, 0.3)};
  background: ${hexToRGBA(theme.color.green, 0.1)};
`
