import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { ErrorStripes } from 'features/error'
import { handleCenterStyle } from 'lib/handle-fields'
import noDataBG from 'static/no-data-bg.png'
import { theme } from 'theme'
import { Scrollbars } from 'ui/molecules/scrollbars'

import { $selectedSubTabData } from '../model'
import { $selectedSubTab, $selectedTab } from '../navigation/model'
import { isStockMarketRelated } from '../types'

export const GameFieldLayout = React.memo(
  ({ listHeader = 'Tools', listBody, centerHeader, centerBody, actionsHeader, actionsBody }) => {
    const selectedRootTab = useStore($selectedTab)
    const selectedRootSubTab = useStore($selectedSubTab)

    const centerTabs = centerBody.map(({ props }) => props)
    const actionsTabs = actionsBody.map(({ props }) => props)

    const selectedInstrument = useStore(useStore($selectedSubTabData).selected)
    const isSelectedInstrRelatedStocks = isStockMarketRelated(selectedInstrument?.instrumentType)

    const INITIAL_STATE_SELECTED_CENTER_TAB_IDX =
      centerTabs.length > 0
        ? isSelectedInstrRelatedStocks && centerTabs[1]
          ? centerTabs[1].id
          : centerTabs[0].id
        : null
    const INITIAL_STATE_SELECTED_ACTIONS_TAB_IDX = actionsTabs.length > 0 ? actionsTabs[0].id : null

    const [selectedCenterTabIdx, setSelectedCenterTabIdx] = useState(INITIAL_STATE_SELECTED_CENTER_TAB_IDX)
    const [selectedActionsTabIdx, setSelectedActionsTabIdx] = useState(INITIAL_STATE_SELECTED_ACTIONS_TAB_IDX)

    const activeCenterTab = centerBody.filter(({ props: { id } }) => id === selectedCenterTabIdx)
    const activeActionsTab = actionsBody.filter(({ props: { id } }) => id === selectedActionsTabIdx)

    const [centerStyle, setCenterStyle] = useState(handleCenterStyle(selectedRootSubTab))

    useEffect(() => {
      setSelectedCenterTabIdx(INITIAL_STATE_SELECTED_CENTER_TAB_IDX)
      setSelectedActionsTabIdx(INITIAL_STATE_SELECTED_ACTIONS_TAB_IDX)
      setCenterStyle(handleCenterStyle(selectedRootSubTab))
    }, [
      selectedRootTab,
      selectedRootSubTab,
      selectedInstrument,
      INITIAL_STATE_SELECTED_CENTER_TAB_IDX,
      INITIAL_STATE_SELECTED_ACTIONS_TAB_IDX,
    ])
    return (
      <ErrorStripes>
        <Container>
          <ListColumn>
            <Header>{listHeader}</Header>
            <Body>
              <ErrorStripes>{listBody}</ErrorStripes>
              <BG src={noDataBG} />
            </Body>
          </ListColumn>
          {centerBody.length > 0 && (
            <CenterColumn {...centerStyle}>
              <TabsHeader>
                <Tabs>
                  {centerTabs.length > 0 &&
                    centerTabs.map(({ heading, id }) => (
                      <TabHeader
                        key={id}
                        onClick={() => setSelectedCenterTabIdx(id)}
                        active={id === selectedCenterTabIdx}
                      >
                        {heading}
                      </TabHeader>
                    ))}
                </Tabs>
                {centerHeader}
              </TabsHeader>
              <Body>
                <Scrollbars>
                  <ErrorStripes>{activeCenterTab}</ErrorStripes>
                </Scrollbars>
                <BG src={noDataBG} />
              </Body>
            </CenterColumn>
          )}
          {(actionsBody?.length > 0 || actionsHeader) && (
            <ActionsColumn>
              <TabsHeader>
                <Tabs>
                  {actionsTabs.length > 0 &&
                    actionsTabs.map(({ heading, id }) => (
                      <TabHeader
                        key={id}
                        onClick={() => setSelectedActionsTabIdx(id)}
                        active={id === selectedActionsTabIdx}
                      >
                        {heading}
                      </TabHeader>
                    ))}
                </Tabs>
                {actionsHeader}
              </TabsHeader>
              <Body actions>
                <Scrollbars>
                  <ErrorStripes>{activeActionsTab}</ErrorStripes>
                </Scrollbars>
                <BG src={noDataBG} />
              </Body>
            </ActionsColumn>
          )}
        </Container>
      </ErrorStripes>
    )
  }
)

const BG = styled.img`
  bottom: 0;
  height: auto;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: 100%;
`

const Header = styled.div`
  align-items: center;
  color: ${theme.color.black};
  display: flex;
  font-size: ${theme.font.size.main}px;
  font-weight: bold;
  justify-content: flex-start;
  margin-bottom: 7px;
  min-height: 22px;
`

const Tabs = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`

const TabsHeader = styled(Header)`
  justify-content: space-between;
`

const TabHeader = styled.h3`
  color: ${theme.color.black};
  cursor: pointer;
  font-size: ${theme.font.size.main}px;
  font-weight: bold;
  transition: all 0.15s ease-in-out;
  user-select: none;
  &:not(:last-child) {
    margin-right: 20px;
  }
  ${ifProp(
    'active',
    'opacity: 1;',
    `
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  `
  )}
`

const Body = styled.div`
  background: ${theme.color.secondary};
  border-radius: ${theme.radius.button.small}px;
  box-shadow: ${theme.shadow.loginForm};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const ListColumn = styled(Column)`
  flex: 46 46 510px;
  // max-width: 580px;
  height: 100%;
  ${theme.media.large} {
    max-width: unset;
  }
`
const CenterColumn = styled(Column)`
  height: 100%;
  margin-left: 10px;
  ${({ flex }) => `
    flex-grow: ${flex || 29};
    flex-shrink: ${flex || 29};
  `}
  ${({ width }) => `
    max-width: ${width + 'px' || 'unset'};
    flex-basis: ${width || 300}px;
  `}
  ${theme.media.large} {
    ${({ largeFlex }) => `
    flex-grow: ${largeFlex || 29};
    flex-shrink: ${largeFlex || 29};
  `}
    ${({ largeWidth }) => `
    flex-basis: ${largeWidth || 360}px;
  `}
    max-width: unset;
  }
`

const ActionsColumn = styled(Column)`
  flex: 23 23 250px;
  height: 100%;
  ${theme.media.large} {
    max-width: 360px;
  }
  margin-left: 10px;
  max-width: 250px;
`
