import { createBrowserHistory } from 'history'

export const browserHistory = createBrowserHistory()
window.browserHistory = browserHistory

export const areWeLocatedHere = (url) => url === browserHistory.location.pathname

export const areWeLocatedHereRegex = (url) => browserHistory.location.pathname.includes(url)

export const navigate = (url) => {
  if (!areWeLocatedHere(url)) browserHistory.replace(url)
}
