/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { RoomLeaderboard } from 'features/rooms/leaderboard'
import { getRoomLeaderboardFetching } from 'features/rooms/model'

export const LeaderboardPage = () => {
  const { id } = useParams()
  useEffect(() => {
    getRoomLeaderboardFetching.lazy(id)
  }, [])
  return <RoomLeaderboard roomIDFromURL={id} />
}
