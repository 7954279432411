import { theme } from 'theme'

import { getRandomPrice } from './state'

export const DETAILED_SESSION = {
  id: 'dfggdgdf',
  name: 'The greatest session in the world',
  startDate: new Date(),
  finishDate: new Date(+new Date() + 1000 * 60 * 60 * 12),
  turnQty: 60,
  type: 'challenge', // 'challenge' || 'training',
  description:
    "<b>Oh yeah, its big brain's time</b><i>italic lol</i><br/><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</p>",
  characters: [
    {
      id: 'sdfsdffsd',
      age: 23,
      retirementAge: 45,
      name: 'Pretty Duck',
      image: {
        big:
          'https://fm.cnbc.com/applications/cnbc.com/resources/img/editorial/2017/05/12/104466932-PE_Color.530x298.jpg?v=1494613853',
        small:
          'https://fm.cnbc.com/applications/cnbc.com/resources/img/editorial/2017/05/12/104466932-PE_Color.530x298.jpg?v=1494613853',
      },
      intro: 'just few words about donald',
      description:
        "<b>Oh yeah, its big brain's time</b><i>italic lol</i><br/><b>Oh yeah, its big brain's time</b><i>italic lol</i><br/><b>Oh yeah, its big brain's time</b><i>italic lol</i><b>Oh yeah, its big brain's time</b><i>italic lol</i><br/><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</p>",
      balance: 10000,
      revenueInYear: 3000,
      expensesInYear: 2340,
      property: [
        {
          id: 'house',
          type: 'realEstate', // 'realEstate' || 'vehicle' || 'service' || 'landPlot'
          image: 'https://upload.wikimedia.org/wikipedia/commons/a/af/WhiteHouseSouthFacade.JPG',
          name: 'White House',
          qty: 1,
          currentMarketVal: 4340400,
          costPerYear: 700000,
          flowBonus: 0.1,
        },
        {
          id: 'ferrari',
          type: 'vehicle', // 'realEstate' || 'vehicle' || 'service' || 'landPlot'
          image: 'https://cdn.motor1.com/images/mgl/0x6J3/s1/ferrari-sf90-stradale.jpg',
          name: 'Ferrari',
          qty: 1,
          currentMarketVal: 45000,
          costPerYear: 1000,
          flowBonus: 0.3,
        },
      ],
      goals: [
        {
          name: 'Goal 1',
          endBonus: 1.1,
        },
        {
          name:
            'Get advanced training in 10 years. $ 1000 The endpoint to points is 0.1. Further training allows you to increase your income level',
          endBonus: 2.3,
        },
      ],
      initialSettings: {
        savingsLevel: 0.25,
        spendingsLevel: {
          vacation: 0.2,
          medicine: 0.4,
          entertainment: 0.2,
          fitness: 0.2,
        },
      },
    },
    {
      id: 'psycho',
      age: 23,
      retirementAge: 45,
      name: 'Not a Psycho, Just Сreative',
      image: {
        big:
          'https://fm.cnbc.com/applications/cnbc.com/resources/img/editorial/2017/05/12/104466932-PE_Color.530x298.jpg?v=1494613853',
        small:
          'https://fm.cnbc.com/applications/cnbc.com/resources/img/editorial/2017/05/12/104466932-PE_Color.530x298.jpg?v=1494613853',
      },
      intro: 'just few words about donald',
      description:
        "<b>Oh yeah, its big brain's time</b><i>italic lol</i><br/><b>Oh yeah, its big brain's time</b><i>italic lol</i><br/><b>Oh yeah, its big brain's time</b><i>italic lol</i><b>Oh yeah, its big brain's time</b><i>italic lol</i><br/><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</p>",
      balance: 10000,
      revenueInYear: 3000,
      expensesInYear: 2340,
      property: [
        {
          id: 'house',
          type: 'realEstate', // 'realEstate' || 'vehicle' || 'service' || 'landPlot'
          image: 'https://upload.wikimedia.org/wikipedia/commons/a/af/WhiteHouseSouthFacade.JPG',
          name: 'White House',
          qty: 1,
          currentMarketVal: 4340400,
          costPerYear: 700000,
          flowBonus: 0.1,
        },
        {
          id: 'ferrari',
          type: 'vehicle', // 'realEstate' || 'vehicle' || 'service' || 'landPlot'
          image: 'https://cdn.motor1.com/images/mgl/0x6J3/s1/ferrari-sf90-stradale.jpg',
          name: 'Ferrari',
          qty: 1,
          currentMarketVal: 45000,
          costPerYear: 1000,
          flowBonus: 0.3,
        },
      ],
      goals: [
        {
          name: 'Goal 1',
          endBonus: 1.1,
        },
        {
          name: 'Goal 2',
          endBonus: 2.3,
        },
      ],
      initialSettings: {
        savingsLevel: 0.25,
        spendingsLevel: {
          vacation: 0.2,
          medicine: 0.1,
          entertainment: 0.2,
          fitness: 0.1,
        },
      },
    },
  ],
}

export const SESSIONS_LIST = [
  {
    // первая сессия
    id: 'sdfsfdfsdf',
    is_available: true, // некоторые сессии могут быть видимые но (пока?) недоступные
    name: 'Are you ready to be rich?',
    image: null,
    type: 'training', // 'challenge' || 'training'
    startDate: '2020-03-17T13:09:52.929Z',
    finishDate: '2020-04-17T13:09:52.929Z',
    turnQty: 24,
    scenarios: [
      {
        id: '435543',
        name: 'Scenario 1',
        turnQty: 34, // количество эпох в сценарии
        characters: [
          {
            id: 'sfsdfsfsd',
            name: 'Bomzhara',
            intro: 'dfdfgdgdgdfgd', // краткое описание персонажа
          },
        ],
      },
    ],
  },
  {
    // вторая сессия
    id: 'fgdfgdf',
    is_available: true, // некоторые сессии могут быть видимые но (пока?) недоступные
    name: 'Are you ready to be poor?',
    image: null,
    type: 'challenge', // 'challenge' || 'training'
    startDate: '2020-04-20T13:09:52.929Z',
    finishDate: '2020-05-14T13:10:52.929Z',
    turnQty: 24,
    scenarios: [
      {
        id: '435543',
        name: 'Scenario 1',
        turnQty: 34, // количество эпох в сценарии
        characters: [
          {
            id: 'sfsdfsfsd',
            name: 'Bomzhara',
            intro: 'dfdfgdgdgdfgd', // краткое описание персонажа
          },
        ],
      },
    ],
  },
]

export const LEADERBOARD = {
  leaderboard: [
    {
      position: 1,
      name: 'Alexander The First',
      characterName: 'Parmenid',
      turnsQty: 20,
      passedTime: '2020-04-17T13:09:52.929Z',
      points: 23425435433,
      squad: 'Admin squad',
    },
    {
      position: 2,
      name: 'Helga',
      characterName: 'Swanky Tunes',
      turnsQty: 18,
      passedTime: '2020-05-17T13:09:52.929Z',
      points: 322000,
      squad: null,
    },
  ],
}

export const ROOM_HISTORY = {
  id: 'sdfsfdfsdf',
  name: 'Are you ready to be rich?',
  startDate: '2020-03-17T13:09:52.929Z',
  finishDate: '2020-04-17T13:09:52.929Z',
  description: '<b>very small description</b>',
  turnQty: 24,
  bestResult: {
    name: 'Alexander Zhitkovskiy',
    points: 4604200,
  },
  type: 'training', // 'challenge' || 'training',
  myTurns: [
    {
      id: 1,
      position: 1,
      payments: [
        {
          // пассивные доходы/расходы, например, плата лендлорду за снимаемую квартиру
          operationName: "Tenant's fee",
          subjectName: null, // если есть субъект продажи/оплаты
          subjectID: null, // если есть субъект продажи/оплаты
          type: 'income', // 'sell' || 'buy' || ‘getCredit’ || ’payOffDebt’ || ‘putLockbox’ || ‘getOffLockbox’
          sum: 750,
          points: 2300,
          flowBonus: 3,
          qty: 1,
        },
        {
          // пассивные доходы/расходы, например, плата лендлорду за снимаемую квартиру
          operationName: "Sberbank's service charges",
          subjectName: 'stocks', // если есть субъект продажи/оплаты
          subjectID: 'aa', // если есть субъект продажи/оплаты
          type: 'expense', // 'sell' || 'buy' || ‘getCredit’ || ’payOffDebt’ || ‘putLockbox’ || ‘getOffLockbox’
          sum: 4,
          points: 4,
          flowBonus: 0,
          qty: 2,
        },
      ],
      actions: [
        {
          // активные действия игрока, приводящие к движению денег на счетах
          operationName: null,
          subjectName: 'bonds', // если есть субъект продажи/оплаты
          subjectID: 'rosneft1', // если есть субъект продажи/оплаты
          type: 'sell', // 'sell' || 'buy' || ‘getCredit’ || ’payOffDebt’ || ‘putLockbox’ || ‘getOffLockbox’
          sum: 500,
          points: 1000,
          flowBonus: 2,
          endBonus: 0,
          qty: 5,
        },
        {
          // активные действия игрока, приводящие к движению денег на счетах
          operationName: null,
          subjectName: 'indicies', // если есть субъект продажи/оплаты
          subjectID: 'sp500', // если есть субъект продажи/оплаты
          type: 'buy', // 'sell' || 'buy' || ‘getCredit’ || ’payOffDebt’ || ‘putLockbox’ || ‘getOffLockbox’
          sum: 1200,
          points: 1200,
          flowBonus: 0,
          endBonus: 0,
          qty: 1,
        },
      ],
      news: [
        {
          name: 'News 1',
          description: 'desc news1',
          image: null,
        },
      ],
      goals: [
        {
          name: 'goal 1',
          sum: 3453,
          points: 234243,
          flowBonus: 1.2,
          tasks: [
            {
              id: 0,
              name: 'task 1',
              isReady: false,
              sum: 34234,
            },
          ],
          status: 'processing', // 'appointed' || 'processing' || 'finished' || 'aborted'
        },
      ],
      dreams: [
        {
          id: 0,
          name: 'VW Polo Sedan 2017 Trendline',
          sum: 12000,
          endBonus: 0.12,
          points: 15000,
          tasks: [
            {
              id: 0,
              name: 'MF 1',
              isReady: false,
              sum: 35766,
            },
            {
              id: 1,
              name: 'Bonds Apple',
              isReady: true,
              sum: 3452,
            },
          ],
        },
      ],
      events: [
        {
          name: 'Event 1',
          description: '<i>sdfsdfsaf</i>',
          image: null,
          type: 'sell', // 'sell' || 'buy'
          sum: 500,
          points: 23245,
          flowBonus: 0,
          endBonus: 1.2,
          status: 'finished', // 'appointed' || 'processing' || 'finished' || 'aborted'
        },
      ],
      total: {
        income: 23444,
        expense: getRandomPrice(20000),
        flowBonus: getRandomPrice(20000),
        endBonus: getRandomPrice(20000),
        capital: 104030.32,
        points: 34502407,
        profit: getRandomPrice(20000),
      },
    },
  ],
  ...LEADERBOARD,
}

const DATE2019 = '2019-03-17T13:09:52.929Z'
const DATE2020 = '2020-03-17T13:09:52.929Z'

export const ROOM_STATISTICS = {
  minTurn: {
    current: 4,
    average: 9,
  },
  maxTurn: {
    current: 27,
    average: 20,
  },
  readyDreamsCount: {
    current: 1,
    average: 1,
  },
  readyGoalsCount: {
    current: 6,
    average: 10,
  },
  capital: {
    current: [
      { date: DATE2019, turn: 1, value: 434000 },
      { date: DATE2020, turn: 2, value: 350000 },
    ],
    average: [
      { date: DATE2019, turn: 1, value: 234000 },
      { date: DATE2020, turn: 2, value: 700000 },
    ],
  },
  cushion: {
    current: [
      { date: DATE2019, turn: 1, value: 4340 },
      { date: DATE2020, turn: 2, value: 35 },
    ],
    average: [
      { date: DATE2019, turn: 1, value: 234 },
      { date: DATE2020, turn: 2, value: 700 },
    ],
  },
  totalPoints: {
    current: [
      { date: DATE2019, turn: 1, value: 434000 },
      { date: DATE2020, turn: 2, value: 350000 },
    ],
    average: [
      { date: DATE2019, turn: 1, value: 234000 },
      { date: DATE2020, turn: 2, value: 700000 },
    ],
  },
  flowPoints: {
    current: [
      { date: DATE2019, turn: 1, value: 434000 },
      { date: DATE2020, turn: 2, value: 350000 },
    ],
    average: [
      { date: DATE2019, turn: 1, value: 234000 },
      { date: DATE2020, turn: 2, value: 700000 },
    ],
  },
  endPoints: {
    current: [
      { date: DATE2019, turn: 1, value: 434000 },
      { date: DATE2020, turn: 2, value: 350000 },
    ],
    average: [
      { date: DATE2019, turn: 1, value: 234000 },
      { date: DATE2020, turn: 2, value: 700000 },
    ],
  },
  income: {
    current: [
      { date: DATE2019, turn: 1, value: 434000 },
      { date: DATE2020, turn: 2, value: 350000 },
    ],
    average: [
      { date: DATE2019, turn: 1, value: 234000 },
      { date: DATE2020, turn: 2, value: 700000 },
    ],
  },
  expenses: {
    current: [
      { date: DATE2019, turn: 1, value: 434000 },
      { date: DATE2020, turn: 2, value: 350000 },
    ],
    average: [
      { date: DATE2019, turn: 1, value: 234000 },
      { date: DATE2020, turn: 2, value: 700000 },
    ],
  },
  debts: {
    // все-все задолженности в денежном выражении
    current: [
      { date: DATE2019, turn: 1, value: 434000 },
      { date: DATE2020, turn: 2, value: 350000 },
    ],
    average: [
      { date: DATE2019, turn: 1, value: 234000 },
      { date: DATE2020, turn: 2, value: 700000 },
    ],
  },
  aggression: {
    current: [
      {
        date: DATE2019,
        turn: 1,
        values: [
          {
            label: 'mid',
            value: 0.2,
            color: theme.color.cyan,
          },
          {
            label: 'high',
            value: 0.6,
            color: theme.color.red,
          },
          {
            label: 'low',
            value: 0.2,
            color: theme.color.green,
          },
        ],
      },
      {
        date: DATE2020,
        turn: 2,
        values: [
          {
            label: 'mid',
            value: 0.1,
            color: theme.color.cyan,
          },
          {
            label: 'high',
            value: 0.2,
            color: theme.color.red,
          },
          {
            label: 'low',
            value: 0.7,
            color: theme.color.green,
          },
        ],
      },
    ],
    average: [
      {
        date: DATE2019,
        turn: 1,
        values: [
          {
            label: 'mid',
            value: 0.2,
            color: theme.color.cyan,
          },
          {
            label: 'high',
            value: 0.3,
            color: theme.color.red,
          },
          {
            label: 'low',
            value: 0.5,
            color: theme.color.green,
          },
        ],
      },
      {
        date: DATE2020,
        turn: 2,
        values: [
          {
            label: 'mid',
            value: 0.7,
            color: theme.color.cyan,
          },
          {
            label: 'high',
            value: 0.1,
            color: theme.color.red,
          },
          {
            label: 'low',
            value: 0.2,
            color: theme.color.green,
          },
        ],
      },
    ],
  },
}
