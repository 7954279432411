import React from 'react'
import styled from 'styled-components'

import { formatPrice } from 'features/game/lib/prices/format'

export const FlippingNumbers = ({
  children,
  notCurrency = false,
  noDecimals = false,
  postfix = '',
  prefix = '',
}) => {
  let formatArgs = { disableCurrency: notCurrency, noDecimals }
  let formattedChildren = formatPrice(children, formatArgs) + postfix
  return <Span>{formattedChildren}</Span>
}

const Span = styled.p`
  display: inline-block;
  font-size: inherit;
  color: inherit;
  // width: 100%;
  text-overflow: inherit;
  overflow: inherit;
  font-weight: inherit;
`
