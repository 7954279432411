import char from 'static/character-img-placeholder.svg'
import def from 'static/img-placeholder.svg'
import session from 'static/session-img-placeholder.svg'

type imgType =
  | 'character-small'
  | 'character-big'
  | 'instrument'
  | 'session'
  | 'dream-wide'
  | 'dream-thumbnail'
  | 'goal'
type imgObject = {
  big: string | null
  small: string | null
  thumbnail: string | null
  wide: string | null
  ver?: string | null
}

export const imgSrc = (src: string | null | undefined | imgObject, type?: imgType): string => {
  let placeholder
  let img
  if (typeof src === 'object') {
    switch (type) {
      case 'dream-wide':
        img = src?.wide || src?.thumbnail || src?.ver
        break
      case 'character-small':
        img = src?.small || src?.big
        break
      case 'character-big':
        img = src?.big || src?.small
        break
      case 'dream-thumbnail':
        img = src?.thumbnail || src?.wide || src?.ver
        break
      default:
        img = src?.big || src?.small || src?.thumbnail || src?.wide
        break
    }
  } else {
    img = src
  }
  switch (type) {
    case 'character-small':
      placeholder = char
      break
    case 'character-big':
      placeholder = char
      break
    case 'session':
      placeholder = session
      break
    // case 'dream':
    //   break;
    // case 'goal':
    //   break;
    default:
      placeholder = def
      break
  }
  return img || placeholder
}
