type FormatPriceParams = {
  disableCurrency: boolean
  noDecimals: boolean
  forceDecimals: boolean
  simplify: boolean
}

const defaultParams = {
  disableCurrency: false,
  noDecimals: false,
  forceDecimals: false,
  simplify: false,
}

export const formatPrice = (price: number | string, params: FormatPriceParams = defaultParams) => {
  const { disableCurrency, noDecimals, forceDecimals, simplify } = params
  const isNearZeroAndNegative = Math.round(Number(price)) === 0 && String(price)[0] === '-'
  const modifiedPrice = isNearZeroAndNegative ? -0 : Number(price)
  const absPrice = Math.abs(modifiedPrice)
  let str = null
  if (String(modifiedPrice).length > 0) {
    str =
      (disableCurrency ? '' : '$ ') +
      (isNearZeroAndNegative ? '-' : '') +
      Number.parseFloat(modifiedPrice.toFixed(noDecimals ? 0 : 2))
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  } else {
    return str
  }
  let dec = str.split('.')[1]
  if (!dec) str += '.00'
  if (dec?.length === 1) str += '0'
  if ((str.slice(-2) === '00' || modifiedPrice >= 1000) && !forceDecimals) str = str.slice(0, -3)
  if (simplify) {
    let prevDigit // решающая цифра предыдущего разряда, по которой округляем
    let futureLastDigit // последняя цифра округлённого числа, которую возможно будем менять
    let changingDigit // предыдущее состояние той последней цифры округляемого числа
    if (absPrice >= 1000 && absPrice < 1000000) {
      str = str.slice(0, -2)
      prevDigit = Number(str.slice(-1))
      changingDigit = Number(str[str.length - 3])
      futureLastDigit = prevDigit >= 5 ? changingDigit + 1 : changingDigit
      str = `${str.slice(0, -3)}${futureLastDigit}k`
    } else if (absPrice > 1000000) {
      str = str.slice(0, -6)
      prevDigit = Number(str.slice(-1))
      changingDigit = Number(str[str.length - 3])
      futureLastDigit = prevDigit >= 5 ? changingDigit + 1 : changingDigit
      str = `${str.slice(0, -3)}${futureLastDigit}m`
    }
  }
  return str
}
