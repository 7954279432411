import React from 'react'
import { useParams } from 'react-router-dom'

import { LoginByCodeForm } from 'features/auth'
import { FullContainer } from 'ui/atoms/full-centered-container'

export const LoginByCodePage = () => {
  const code = useParams().code
  return (
    <FullContainer>
      <LoginByCodeForm initialCode={code} />
    </FullContainer>
  )
}
