import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { CharacterDetails } from 'features/game/character/details'
import {
  resetStartPlayingEffect,
  startPlaying,
  startPlayingFetching,
} from 'features/game/navigation/model/common'
import { browserHistory } from 'lib/history'
import backRoundIcon from 'static/back-round.svg'
import { theme } from 'theme'
import { Container } from 'ui/atoms/container'
import { Typography } from 'ui/atoms/typography'
import { LoadingOverflow } from 'ui/molecules/loading-overflow'

import { $room, $selectedCharacter, resetRoomStatusInterval, setSelectedCharacter } from '../model'
import { RoomCharacters } from './stepper/characters'
import { Stepper } from './stepper'
import { RoomRules } from './stepper/rules'
import { RoomSettings } from './stepper/settings'

export const RoomDetails = () => {
  const [step, setStep] = useState(0)
  const [detailsCharacter, setDetailsCharacter] = useState(null)
  const [settings, setSettings] = useState({})

  const session = useStore($room)
  const chosenCharacter = useStore($selectedCharacter)
  const startPlayingStatus = useStore(startPlayingFetching.status)
  const onBackButton = () => {
    switch (step) {
      case 1:
        detailsCharacter ? setDetailsCharacter(null) : setStep(0)
        break
      case 2:
        setStep(1)
        break
      case 0:
        browserHistory.goBack()
        break
      default:
        console.error('what step it is?')
        break
    }
  }
  const onNext = () => {
    if (step < 2 && step > -1) {
      setStep(step + 1)
    } else if (step === 2) {
      resetRoomStatusInterval()
      startPlaying({
        characterId: chosenCharacter.id,
        scenarioId: 0,
        sessionId: session.id,
        characterSettings: settings,
      })
    } else {
      setStep(0)
    }
  }
  const onDetails = (char) => {
    setDetailsCharacter(char)
  }
  const onChoose = (char) => {
    setSelectedCharacter(char)
    setStep(2)
  }
  const foundCharIdx =
    detailsCharacter && session?.characters.findIndex((char) => char.id === detailsCharacter.id)
  const canGoPrevChar = foundCharIdx > 0
  const canGoNextChar = foundCharIdx !== -1 && foundCharIdx !== session?.characters.length - 1
  const onPrevChar = () => {
    setDetailsCharacter(session.characters[foundCharIdx - 1])
  }
  const onNextChar = () => {
    setDetailsCharacter(session.characters[foundCharIdx + 1])
  }
  const isPlayingStatusDone = startPlayingStatus === 'done'
  useEffect(() => {
    switch (step) {
      case 0:
        setDetailsCharacter(null)
        setSelectedCharacter(null)
        break
      default:
        break
    }
  }, [step])
  useEffect(() => {
    if (isPlayingStatusDone) {
      setStep(0)
      resetStartPlayingEffect()
    }
  }, [isPlayingStatusDone])
  return (
    <StyledContainer small>
      {session ? (
        <>
          {' '}
          <Header>
            <BackButton src={backRoundIcon} onClick={onBackButton} />
            <SessionName weight="600" size={theme.font.size.large}>
              {session.name}
            </SessionName>
            <Filler />
          </Header>
          <StyledStepper step={step} onChange={setStep}>
            <Step id="rules" title="Game rules and settings">
              <RoomRules onNext={onNext} />
            </Step>
            <Step disabled={step === 0} id="character" title="Choose a character">
              {detailsCharacter ? (
                <CharacterDetails
                  haveControls
                  haveAdditionalInfo
                  onPrev={canGoPrevChar ? onPrevChar : null}
                  onNext={canGoNextChar ? onNextChar : null}
                  onChoose={() => onChoose(detailsCharacter)}
                  character={detailsCharacter}
                />
              ) : (
                <RoomCharacters characters={session.characters} onDetails={onDetails} onChoose={onChoose} />
              )}
            </Step>
            <Step disabled={!chosenCharacter} id="settings" title="Character settings">
              {chosenCharacter?.id && (
                <RoomSettings
                  loading={startPlayingStatus === 'loading'}
                  onSettingsChange={setSettings}
                  character={chosenCharacter}
                  onCancel={onBackButton}
                  onNext={onNext}
                />
              )}
            </Step>
          </StyledStepper>
        </>
      ) : (
        <LoadingOverflow withLoader loading={true} />
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

const StyledStepper = styled(Stepper)`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Step = styled.div`
  flex: 1;
  height: 100%;
`

const SessionName = styled(Typography)``

const Filler = styled.div``

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
`

const BackButton = styled.img`
  ${ifProp(
    'disabled',
    `
    opacity: 0.5;
`,
    `
cursor: pointer;
&:hover {
    opacity: 0.5;
}
`
  )}
`
