import React from 'react'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'

export const TextTable = ({
  rows,
  headerRow,
  className,
  marginCols = 8,
  marginRows = 5,
  labelFontSize = theme.font.size.small,
  labelTextAlign = 'right',
  valueTextAlign = 'left',
  valueFontSize = theme.font.size.small,
  valueFlex,
  valueMonoFont,
}) => {
  const labels = rows.map((row) => row[0])
  const values = rows.map((row) => row[1])
  return (
    <Table className={className}>
      <LabelColumn marginRows={marginRows} labelTextAlign={labelTextAlign}>
        {headerRow && (
          <LabelHeader weight="bold" size={18}>
            {headerRow[0]}
          </LabelHeader>
        )}
        {labels.map((label, idx) => (
          <Typography key={idx} color={theme.color.gray400} weight="600" size={labelFontSize}>
            {label}
          </Typography>
        ))}
      </LabelColumn>
      <ValueColumn
        valueMonoFont={valueMonoFont}
        valueFlex={valueFlex}
        marginRows={marginRows}
        valueTextAlign={valueTextAlign}
        marginCols={marginCols}
      >
        {headerRow && (
          <LabelHeader weight="bold" size={18}>
            {headerRow[1]}
          </LabelHeader>
        )}
        {values.map((value, idx) => (
          <Typography key={idx} weight="500" size={valueFontSize}>
            {value}
          </Typography>
        ))}
      </ValueColumn>
    </Table>
  )
}

export const LabelHeader = styled(Typography)`
  margin-bottom: 25px;
  min-height: 25px;
`

const Table = styled.div`
  display: flex;
`

export const ValueColumn = styled.div`
  margin-left: ${prop('marginCols')}px;
  text-align: ${prop('valueTextAlign')};
  ${ifProp('valueFlex', 'flex: 1;')}
  overflow: hidden;
  & > * {
    margin: ${prop('marginRows')}px 0;
    text-overflow: ellipsis;
    // overflow: hidden;
    white-space: nowrap;
    ${ifProp(
      'valueMonoFont',
      `
    font-family: 'Roboto Mono';
    letter-spacing: -0.7px;
    `
    )}
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const LabelColumn = styled.div`
  text-align: ${prop('labelTextAlign')};
  & > * {
    margin: ${prop('marginRows')}px 0;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
