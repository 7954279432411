import { useStore } from 'effector-react'
import React from 'react'

import { Divider } from 'features/game/field/atoms/divider'
import { NewsContent } from 'features/game/field/atoms/news-content'
import { TabInnerHeading } from 'features/game/field/atoms/tab-inner-heading'
import { TabInnerSection } from 'features/game/field/atoms/tab-inner-section'
import { $selectedSubTabData } from 'features/game/model'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

export const StockMarketAbout = ({ alone }) => {
  const selectedInstrument = useStore(useStore($selectedSubTabData).selected)
  window.log(selectedInstrument, 'stockmarketabout')
  const description = selectedInstrument?.about?.description || selectedInstrument?.description
  return description ? (
    <>
      <TabInnerSection noPt={!alone}>
        <TabInnerHeading weight="bold" color={hexToRGBA(theme.color.black, 0.7)} size={theme.font.size.main}>
          {alone ? selectedInstrument.name : null}
        </TabInnerHeading>
        {selectedInstrument.about && <NewsContent html={description} />}
      </TabInnerSection>
      <Divider />
    </>
  ) : null
}
