import { combine, createEffect, createEvent, createStore, sample } from 'effector-root'
import { difference as arrayDiff } from 'lodash'

import { authAPI } from 'api/auth'
import { createFetching } from 'lib/fetching'
import { browserHistory, navigate } from 'lib/history'

const NETWORK_ERROR_MESSAGE = 'Failed to fetch'
const ERROR_THRESHOLD_RATIO = 0.1

const setReqQty = createEvent()
const setSuccessedReqQty = createEvent()
const resetCalculations = createEvent()
const setTimerStatus = createEvent()
const setTimer = createEvent()
const addFailedReqs = createEvent()
const resetFailedReqs = createEvent()

const $reqQty = createStore(0).on(setReqQty, (_, p) => p)
const $successedReqQty = createStore(0)
  .on(setSuccessedReqQty, (_, p) => p)
  .reset(resetCalculations)
const timerStatus = createStore('pending').on(setTimerStatus, (_, p) => p)
const timer = createStore(null).on(setTimer, (_, p) => p)
const failedReqs = createStore(0)
  .on(addFailedReqs, (state) => state + 1)
  .reset(resetFailedReqs)
export const $loadingPercent = combine($reqQty, $successedReqQty, (reqQty, successed) =>
  Math.round((successed * 100) / reqQty)
).reset(resetCalculations)

const preloadResource = createEffect()
preloadResource.use(({ url, cache }) => cache.add(url))
sample({
  clock: preloadResource.finally,
  source: $successedReqQty,
  fn: (source) => source + 1,
  target: setSuccessedReqQty,
})
sample({
  clock: preloadResource.fail,
  source: [failedReqs, $reqQty, timerStatus],
}).watch(([failedQty, reqQty, status]) => {
  if (failedQty / reqQty >= ERROR_THRESHOLD_RATIO && status !== 'done') {
    setTimerStatus('done')
  }
})
preloadResource.watch(() => {
  const t = setTimeout(() => {
    setTimerStatus('done')
  }, 1000 * 60)
  setTimer(t)
})
preloadResource.fail.watch(({ error }) => {
  if (error.message === NETWORK_ERROR_MESSAGE) addFailedReqs()
})

export const preloadResources = createEffect({
  handler: async () => {
    try {
      const images = await authAPI.preloadResources('all').then((res) => res.data.images)
      const cache = await caches.open('FLG')
      const keys = await cache.keys()
      const reqQty = arrayDiff(images, keys).length
      setReqQty(reqQty)
      const promisesArr = images.map((url) => preloadResource({ url, cache }))
      return Promise.allSettled(promisesArr)
    } catch (error) {
      throw error
    }
  },
})
preloadResources.finally.watch(() => {
  setTimerStatus('done')
})

sample({
  clock: timerStatus,
  source: timer,
  fn: (source, clock) => ({ timer: source, status: clock }),
}).watch(({ status, timer }) => {
  if (status === 'done') {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    resetCalculations()
    resetFailedReqs()
    if (browserHistory.location.pathname === '/preload') navigate('/rooms')
  }
})

$reqQty.watch((qty) => {
  window.log(qty, 'reqQty')
})
$successedReqQty.watch((qty) => {
  window.log(qty, 'successedReqQty')
})
export const preloadResourcesFetching = createFetching(preloadResources)
