import styled from 'styled-components'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

export const Divider = styled.hr`
  background: ${hexToRGBA(theme.color.black, 0.1)};
  border: 0;
  height: 1px;
  margin-top: 5px;
  width: 100%;
`
