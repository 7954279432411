import { notify } from 'lib/toast'
type APIErrorCases = {
  [statusCode: number]: string
  [errorType: string]: string
  default?: string
}

export const handleAPIError = (
  cases: APIErrorCases,
  notShowingCases: Array<string | number> = [],
  cleanDetails?: Boolean
) => (error) => {
  if (error.code) {
    const foundMsgByErrType = cases[error.errType]
    const foundMsgByCode = cases[error.code]
    if (foundMsgByErrType) {
      error.message = foundMsgByErrType
    } else if (foundMsgByCode) {
      error.message = foundMsgByCode
    } else if (cases.default) {
      error.message = cases.default
    }
    if (cleanDetails) error.details = ''
    let haveToShow = true
    const errTypeNSCases = notShowingCases.filter((el) => typeof el === 'string')
    const codeNSCases = notShowingCases.filter((el) => typeof el === 'number')
    if (error.errType && errTypeNSCases.includes(error.errType)) haveToShow = false
    if (codeNSCases.includes(error.code)) haveToShow = false
    if (haveToShow) {
      notify(error.message, error.details, 'error')
    }
  }
  throw error
}
