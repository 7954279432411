/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { getRoomStatisticsFetching } from 'features/rooms/model'
import { RoomStatistics } from 'features/rooms/statistics'

export const StatisticsPage = React.memo(() => {
  const urlParams = useParams()
  useEffect(() => {
    getRoomStatisticsFetching.lazy(urlParams)
  }, [])
  return <RoomStatistics roomIDFromURL={urlParams.roomID} />
})
