export const getListBySubTab = (subtabName, subtabData) => {
  if (!subtabData.hasOwnProperty('selected')) {
    const firstKey = Object.keys(subtabData)[0]
    return subtabData[firstKey].list
  }
  if (
    [
      'stocks',
      'bonds',
      'etf',
      'commodities',
      'indicies',
      'crypto',
      'goals',
      'realEstate',
      'goods',
      'services',
      'incomeAndExpenses',
      'credit',
      'deposits',
      'leverage',
      'dreamsMarket',
    ].includes(subtabName)
  ) {
    return subtabData.list
  }
  if (['dreams', 'assetsAndLiabilities'].includes(subtabName)) {
    return subtabData.list
  }
  return subtabData.list
}
