import React from 'react'
import styled from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import CheckIcon from 'static/done-check.svg'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'
import { Scrollbars } from 'ui/molecules/scrollbars'

export const Stepper = ({
  children,
  className,
  onChange = (id) => console.log(id),
  alignment = 'center',
  step,
}) => {
  const headers = React.Children.map(children, (child) => ({
    title: child.props.title,
    id: child.props.id,
    disabled: child.props.disabled,
    done: child.props.done,
  }))
  return (
    <Container className={className}>
      <Nav>
        <Typography size={theme.font.size.huge} weight="600">
          {headers.find((header, idx) => idx === step).title}
        </Typography>
        <Delimiter />
        <Steps>
          {headers.map((header, idx) => (
            <Step
              key={idx}
              disabled={header.disabled}
              onClick={header.disabled ? void 0 : () => onChange(idx)}
              active={!header.disabled && step >= idx}
            >
              {idx < step ? <Check src={CheckIcon} /> : idx + 1}
            </Step>
          ))}
        </Steps>
      </Nav>
      <Content>
        <Scrollbars>{React.Children.toArray(children)[step]}</Scrollbars>
      </Content>
    </Container>
  )
}

const Check = styled.img``

const Nav = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 30px;
`

const Delimiter = styled.div`
  background: white;
  flex: 1;
  height: 1px;
  margin: 0 20px;
  opacity: 0.2;
`

const Steps = styled.ul`
  align-items: center;
  display: flex;
  justify-content: ${switchProp('alignment', {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  })};
`

const Step = styled.li`
  align-items: center;
  background: ${hexToRGBA(theme.color.black, 0.1)};
  border: 1px solid ${hexToRGBA(theme.color.black, 0.3)};
  border-radius: 50%;
  color: ${theme.color.black};
  display: flex;
  font-size: ${theme.font.size.large}px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  margin-left: 20px;
  transition: all 0.15s ease-in-out;
  width: 50px;
  ${ifProp(
    'disabled',
    `
    opacity: 0.5;
    cursor: not-allowed;
  `,
    `
  cursor: pointer;
  &:hover {
    background: ${hexToRGBA(theme.color.black, 0.3)};
  }
  `
  )}
  ${ifProp(
    'active',
    `
    background: ${hexToRGBA(theme.color.green, 0.7)};
    border-color: ${hexToRGBA(theme.color.green, 0.7)};
    &:hover {
      background: ${hexToRGBA(theme.color.green, 0.7)};
      color: ${theme.color.black};
    }
  `,
    `
  `
  )}
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1120px;
  width: 100%;
`

const Content = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;
`
