import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import checkIcon from 'static/check-green.svg'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'

export const Checkbox = ({
  className,
  children,
  checked,
  onChange = () => {},
  color = theme.color.black,
  fontColor = theme.color.black,
}) => {
  const onClick = () => {
    onChange({ target: { checked: !checked } })
  }
  return (
    <Container className={className} onClick={onClick}>
      <UICheckbox color={color} readOnly type="checkbox" defaultChecked={checked} />
      {children && (
        <StyledTypography weight="500" color={fontColor} size={theme.font.size.small}>
          {children}
        </StyledTypography>
      )}
    </Container>
  )
}

const UICheckbox = styled.input`
  appearance: none;
  background: transparent;
  border: 2px solid ${({ color, defaultChecked }) =>
    defaultChecked ? theme.color.green : hexToRGBA(color, 0.4)};
  border-radius: ${theme.radius.button.small}px;
  cursor: pointer;
  height: 16px;
  position: relative;
  width: 16px;
  &::before {
    background-image: url('${checkIcon}');
    background-size: cover;
    content: ' ';
    height: 60%;
    left: 10%;
    opacity: 0;
    position: absolute;
    top: 20%;
    transition: opacity 0.15s ease-in-out;
    width: 80%;
    will-change: opacity;
  }
  ${ifProp(
    'defaultChecked',
    `&::before {
      opacity: 1;
    }`
  )}
`
const StyledTypography = styled(Typography)`
  margin-left: 10px;
  margin-top: 1px;
  user-select: none;
`
const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`
