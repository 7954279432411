import React from 'react'
import { format, parseISO } from 'date-fns'
import { desaturate, lighten } from 'polished'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { getTimer } from 'features/rooms/lib/get-timer'
import { handleRoomStatusHeading } from 'lib/handle-fields'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Error } from 'ui/atoms/form'
import { Typography } from 'ui/atoms/typography'

export const RoomTimer = ({
  status,
  difference,
  finishDate,
  onStart,
  canStart,
  onCancel,
  loading,
  error,
}) => {
  const timer = Object.entries(getTimer(difference)).map((entry, idx) => {
    let key = entry[0]
    let val = entry[1]
    let value = String(val)
    if (value.length < 2) value = `0${value}`
    return (
      <React.Fragment key={key}>
        {idx !== 0 && (
          <Measure>
            <MeasureValue doubledot>:</MeasureValue>
          </Measure>
        )}
        <Measure>
          <MeasureValue>{value}</MeasureValue>
          <MeasureLabel weight="600">{key}</MeasureLabel>
        </Measure>
      </React.Fragment>
    )
  })
  return (
    <TimeContainer>
      <Heading size={theme.font.size.large} weight="800">
        {handleRoomStatusHeading(status)}
      </Heading>
      <Timebox>{timer}</Timebox>
      {status === 'playing' && (
        <Note weight="600">
          The game session will be available until {format(parseISO(finishDate), 'dd.MM.yyyy hh:mm aaa')} UTC
        </Note>
      )}
      {onStart && (
        <StartButton {...{ loading }} fullWidth disabled={!canStart} onClick={onStart}>
          Play
        </StartButton>
      )}
      {onCancel && (
        <CancelButton fullWidth variant="outlinedLight" onClick={onCancel}>
          Change character
        </CancelButton>
      )}
      {error && <Error>{error.toString()}</Error>}
    </TimeContainer>
  )
}

const StartButton = styled(Button)`
  margin-top: 30px;
`

const CancelButton = styled(Button)`
  margin-top: 10px;
`

const Note = styled(Typography)`
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
`

const Heading = styled(Typography)`
  text-align: center;
`

const Timebox = styled.div`
  align-items: center;
  background: ${desaturate(0.05, lighten(0.05, theme.color.secondary))};
  border-radius: ${theme.radius.button.small * 2}px;
  box-shadow: ${theme.shadow.loginForm};
  display: flex;
  justify-content: center;
  margin: 30px 0;
  padding: 30px 20px;
`

const TimeContainer = styled.div`
  background: ${theme.color.secondary};
  border-radius: ${theme.radius.button.small * 2}px;
  box-shadow: ${theme.shadow.loginForm};
  min-width: 410px;
  padding: 40px;
`
const Measure = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MeasureLabel = styled(Typography)`
  opacity: 0.5;
`

const MeasureValue = styled.p`
  color: ${theme.color.black};
  font-size: 78px;
  font-weight: 300;
  line-height: 1;
  ${ifProp(
    'doubledot',
    `
    margin-bottom: 40px;
    line-height: 1;
  `
  )}
`
