import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { Typography } from 'ui/atoms/typography'

type notifyType = 'default' | 'error' | 'success' | 'warn'

export const notify = (msg: string, details: string, type: notifyType) => {
  let toastType
  switch (type) {
    case 'error':
      toastType = toast.TYPE.ERROR
      break
    case 'success':
      toastType = toast.TYPE.SUCCESS
      break
    case 'warn':
      toastType = toast.TYPE.WARNING
      break
    default:
      toastType = toast.TYPE.DARK
      break
  }
  toast(
    <>
      {/* @ts-ignore */}
      <Typography weight="600">{msg}</Typography>
      {/* @ts-ignore */}
      {details && <Desc size={14}>{details}</Desc>}
    </>,
    { type: toastType }
  )
}

const Desc = styled(Typography)`
  margin-top: 7px;
`
