import { darken } from 'polished'
import styled from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

import { Button } from '..'

type ActionButtonProps = {
  flex?: boolean
  type?: 'buy' | 'sell' | 'secondary'
  disabled?: boolean
}

export const ActionButton = styled(Button)<ActionButtonProps>`
  border-radius: ${theme.radius.button.medium}px;
  color: ${theme.color.black};
  cursor: pointer;
  font-size: ${theme.font.size.main}px;
  font-weight: 600;
  padding: ${theme.padding.button.small};
  ${ifProp('flex', 'flex: 1;')}
  ${switchProp('type', {
    buy: `
      background: ${theme.color.green};
      border: none;
      &:hover,&:active {
        background: ${darken(0.1, theme.color.green)};
      }
    `,
    sell: `
      background: ${theme.color.primary};
      border: none;
      &:hover,&:active {
         background: ${darken(0.1, theme.color.primary)};
      }
    `,
    secondary: `
      background: ${theme.color.gray400};
      border: none;
      &:hover,&:active {
        background: ${darken(0.1, theme.color.gray400)};
     }
    `,
  })}
  ${ifProp(
    'disabled',
    `
    background: ${hexToRGBA(theme.color.black, 0.3)};
    color: ${hexToRGBA(theme.color.black, 0.5)};
    &:hover,&:active {
      background: ${hexToRGBA(theme.color.black, 0.3)};
      color: ${hexToRGBA(theme.color.black, 0.5)};
      cursor: default;
    }
  `
  )}
`
