import styled from 'styled-components'
import { Input } from 'ui/atoms/input'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

export const ActionInput = styled(Input)`
  border-color: ${hexToRGBA(theme.color.black, 0.6)};
  font-weight: 600;
  line-height: 1.4;
  padding: 0;
  text-align: center;
  width: 120px;
  &:focus {
    background: ${hexToRGBA(theme.color.black, 0.1)};
    border-color: ${theme.color.green};
  }
`
