import React from 'react'
import styled from 'styled-components'

import { TabInnerHeading } from 'features/game/field/atoms/tab-inner-heading'
import { theme } from 'theme'

export const GoodsServicesDescCard = ({ children, className, heading, onClick }) => {
  return (
    <Container {...{ className, onClick }}>
      <TabInnerHeading>{heading}</TabInnerHeading>
      {children}
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.color.darkgray};
  border-radius: ${theme.radius.button.small}px;
  padding: 12px;
`
