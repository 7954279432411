import MockAdapter from 'axios-mock-adapter'

import { GAME_HTTP_ROUTES } from 'api/game'
import { USER, USER_WRITER } from 'mocks/auth'
import { DETAILED_SESSION, SESSIONS_LIST, LEADERBOARD } from 'mocks/rooms'
import { MARKET, PORTFOLIO, PROFILE_STATE } from 'mocks/state'
import { TRANSACTION_RESP, STEP_RESP, VISUAL_SETTINGS } from 'mocks/game'

import { http } from '.'

let mock = new MockAdapter(http, { delayResponse: process.env.REACT_APP_MOCK_DELAY || 1000 })

if (process.env.REACT_APP_MOCK_BACKEND === 'true') {
  mock.onPost('login').reply(200, USER_WRITER)
  mock.onGet('user').reply(200, USER_WRITER)
  mock.onPost('rooms/start').reply(200)
  mock.onPost('actions/transaction').reply(200, TRANSACTION_RESP)
  mock.onPost('actions/step').reply(200, STEP_RESP)
  mock.onPost('actions/reset-step').reply(200)
  mock.onPost(/login-by-code\/*/).reply(200, USER)
  mock.onGet('rooms').reply(200, SESSIONS_LIST)
  mock.onGet(/rooms\/*/).reply(200, DETAILED_SESSION)
  mock.onGet(/rooms\/leaderboard\/*/).reply(200, LEADERBOARD)
  mock.onGet(/state\/market\/*/).reply(200, MARKET)
  mock.onGet(/state\/portfolio\/*/).reply(200, PORTFOLIO)
  mock.onGet(/state\/profile\/*/).reply(200, PROFILE_STATE)
  mock.onPost(/admin\/go-to-step\/*/).reply(200, TRANSACTION_RESP)
  mock.onPut(/admin\/change-character\/*/).reply(200, TRANSACTION_RESP)
  mock.onGet(GAME_HTTP_ROUTES.getVisualSettings).reply(200, VISUAL_SETTINGS)
}

mock.onAny().passThrough()
