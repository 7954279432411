import { resultTheme } from './initial'
import { $theme } from './model'

export const getTheme = () => $theme.getState()

export const theme = new Proxy(resultTheme, {
  get(target, prop) {
    const dynamicTheme = getTheme()
    return prop in dynamicTheme ? dynamicTheme[prop] : target[prop]
  },
})
