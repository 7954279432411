import React from 'react'
import styled from 'styled-components'

import logoImg from 'static/logo.svg'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'

import { MarketTicker } from './market-ticker'
import { NewsRolletButton } from './news-rollet/button'
import { NewsTicker } from './news-ticker'

export const BottomBar = () => {
  return (
    <Container>
      <Left>
        <Typography weight="bold" color={theme.color.gray400} size={theme.font.size.small}>
          News:
        </Typography>
        <TickerContainer>
          <NewsTicker />
        </TickerContainer>
        <NewsRolletButton />
      </Left>
      <Right>
        <Typography weight="bold" color={theme.color.gray400} size={theme.font.size.small}>
          Market:
        </Typography>
        <TickerContainer>
          <MarketTicker />
        </TickerContainer>
        <Logo src={logoImg} />
      </Right>
    </Container>
  )
}

const TickerContainer = styled.div`
  height: 20px;
  margin: 0 5px;
  width: 100%;
`

const Logo = styled.img`
  border-left: 1px solid rgba(136, 155, 166, 0.5);
  height: 19px;
  opacity: 0.4;
  padding: 0 10px;
`

const Container = styled.footer`
  align-items: center;
  background: ${theme.color.secondary};
  box-shadow: ${theme.shadow.bottomBar};
  display: flex;
  z-index: 6;
`
const Left = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  padding: 5px 0;
  padding-left: ${theme.padding.large}px;
  position: relative;
`
const Right = styled.div`
  align-items: center;
  background: ${theme.color.darkgray};
  border-radius: 10px 0px 0px 10px;
  display: flex;
  flex: 1;
  padding: 5px 0;
  padding-left: 10px;
`
