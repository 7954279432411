import { enhanceAPIValidationError } from 'lib/http/error'

export const validateForSuccess = (resp) => {
  const {
    data: { success },
  } = resp
  window.log(success, 'API check for success field')
  if (success) return resp
  let error: any = new Error('Server cannot validate this request.')
  error.key = 'success'
  throw enhanceAPIValidationError(error)
}
