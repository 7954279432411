import { attach, combine, createEffect, createEvent, createStore, merge, sample } from 'effector-root'

import { gameAPI } from 'api/game'
import { roomsAPI } from 'api/rooms'
import { getSubjectInstrument } from 'features/game/lib/get-subject-instrument'
import { createEntityListModel, createRootTabsModel } from 'features/game/lib/instrument-model-factory'
import { $selectedSubTab, $selectedTab, setSelectedSubTab } from 'features/game/navigation/model'
import { createEffectWithFetching, createFetching } from 'lib/fetching'
import { navigate } from 'lib/history'
import { sortByTurns, sortDreams } from 'lib/sort/custom-sorts'
import { notify } from 'lib/toast'

import { mergeMarketPortfolio, transformTabs } from '../lib/merge-market-portfolio'
import { normalizeMarket } from '../lib/normalize-market'
import { FINANCIAL_SETTINGS_ID } from '../lib/normalize-market/add-income-expenses'
import { normalizeProfile } from '../lib/normalize-profile'
import { goToLeaderboardPage, resetIsNowPlaying } from '../navigation/model/common'
import { ROOT_TABS_STRUCTURE } from '../navigation/model/tabs-structure'
import { InstrumentTypes, isStockMarketRelated } from '../types'
import { _setSelectedInstrument, $state, setSelectedInstrument } from './state'

const RENT_ACTIONS = new Set(['rent', 'rent_out', 'rent_drop', 'rent_out_drop'])

const PROFILE_INITIAL_STATE = {
  finishTime: new Date(),
  currentTurn: null,
  turnQty: null,
  character: null,
  offerBankruptcy: false,
  profile: {
    id: null,
    age: null,
    residence: null,
    eventsHistory: [],
    pointsHistory: [],
    balance: {
      cash: {
        previous: 0,
        current: 0,
      },
      capital: {
        previous: 0,
        current: 0,
      },
      points: {
        previous: 0,
        current: 0,
      },
      profit: {
        previous: 0,
        current: 0,
      },
      flowBonus: {
        previous: 0,
        current: 0,
      },
      endBonus: {
        previous: 0,
        current: 0,
      },
      revenue: {
        previous: 0,
        current: 0,
      },
      expenses: {
        previous: 0,
        current: 0,
      },
      cushion: 0.5,
      aggression: [],
    },
    news: [],
    events: [],
    financialSettings: {
      savingsLevel: null,
      spendingsLevel: {
        vacation: null,
        medicine: null,
        entertainment: null,
        fitness: null,
      },
    },
  },
}

const MARKET_INITIAL_STATE = {
  otherMarkets: {
    realEstate: [],
    goods: [],
    services: [],
  },
  financialMarkets: {
    stocks: [],
    bonds: [],
    etf: [],
    commodities: [],
    indicies: [],
    mf: [],
    crypto: [],
    banking: [],
  },
  personal: {
    dreams: [],
    goals: [],
    assetsAndLiabilities: [],
    incomeAndExpenses: [],
  },
}

export const toggleLeverage = createEvent()
export const toggleMortgage = createEvent()
export const resetLeverage = createEvent()
export const resetMortgage = createEvent()
export const toggleAutobuy = createEvent()
export const setGameState = createEvent()
export const _setGameState = createEvent()

export const $profile = createStore(PROFILE_INITIAL_STATE)
export const $activeTransactionType = createStore(null)
export const $actions = $profile.map((profile) => {
  const actions = profile?.turnActions || []
  const currentTurn = profile?.currentTurn
  if (currentTurn === 1) {
    return actions.filter((action) => action.order !== 0 && action.type !== 'settings')
  }
  return actions
})
export const $news = $profile.map((state) =>
  [...state.profile.news.map((el) => ({ name: el.name, description: el.description, turn: el.turn }))].sort(
    sortByTurns
  )
)
export const $actionsHistory = $profile.map((profile) => profile.profile.eventsHistory)
export const actionsHistoryEnhanced = createEntityListModel($actionsHistory, [], 'turn')
export const $pointsHistory = $profile.map((profile) => profile.profile.pointsHistory)
export const pointsHistoryEnhanced = createEntityListModel($pointsHistory, [], 'turn')
export const $dreams = $state.map((state) => state.personal.dreams.sort(sortDreams))
export const $events = $profile.map((state) => state?.profile?.events || [])
export const $profilePresented = $profile.map((state) => state !== PROFILE_INITIAL_STATE)
export const $statePresented = $state.map((state) => state !== MARKET_INITIAL_STATE)
export const $mortgageOn = createStore(false)
  .on(toggleMortgage, (state) => !state)
  .reset(resetMortgage)
export const $autobuyOn = createStore(false).on(toggleAutobuy, (state) => !state)
export const $leverageOn = createStore(false)
  .on(toggleLeverage, (prevSt) => !prevSt)
  .reset(resetLeverage)

export const _makeTransaction = createEffect()
export const makeTransaction = attach({
  effect: _makeTransaction,
  source: $leverageOn,
  mapParams: (params, leverage) => {
    const { id, actionType, value } = params
    let normalizedParams = {
      transaction: {
        instrumentId: id,
        value: Number(actionType === 'sell' ? -value : value),
        leverage,
      },
    }
    if (RENT_ACTIONS.has(actionType)) normalizedParams.transaction.rentTransaction = actionType
    if ([InstrumentTypes.Credit, InstrumentTypes.Deposit].includes(params.instrumentType)) {
      delete normalizedParams.transaction.leverage
      normalizedParams.transaction.term = params.term
    }
    if (params.portfolioId) normalizedParams.transaction.portfolio_id = params.portfolioId
    if (params.downPayment) {
      normalizedParams.transaction.downPayment = params.downPayment
      normalizedParams.transaction.term = params.term
    }
    return normalizedParams
  },
})
export const _endTurn = createEffect()
export const endTurn = attach({
  effect: _endTurn,
  source: $autobuyOn,
  mapParams: (params, autobuyOn) => ({
    autobuyOn,
  }),
})

export const makeTransactionFetching = createFetching(makeTransaction)
export const endTurnFetching = createFetching(endTurn)
export const [getGameState, getGameStateFetching] = createEffectWithFetching()
export const [resetTurn, resetTurnFetching] = createEffectWithFetching()
export const [finishGame, finishGameFetching] = createEffectWithFetching()
export const [chooseReaction, chooseReactionFetching] = createEffectWithFetching()
export const [relocate, relocateFetching] = createEffectWithFetching()
export const [chooseDream, chooseDreamFetching] = createEffectWithFetching()
export const [changeFinancialSettings, changeFinancialSettingsFetching] = createEffectWithFetching()
export const [goBankrupt, goBankruptFetching] = createEffectWithFetching()

export const $stateLoading = getGameStateFetching.isLoading

getGameState.use(() => gameAPI.getState())
_makeTransaction.use((params) => gameAPI.makeTransaction(params))
_endTurn.use((params) => gameAPI.endTurn(params))
resetTurn.use(() => gameAPI.resetTurn())
finishGame.use(() => roomsAPI.finishRoom())
chooseReaction.use((params) => gameAPI.chooseReaction(params))
relocate.use(({ id }) => gameAPI.relocate(id))
changeFinancialSettings.use((settings) => gameAPI.changeFinancialSettings(settings))
chooseDream.use((params) => gameAPI.chooseDream(params))
goBankrupt.use(() => gameAPI.goBankrupt())

$state
  .on(getGameState.done, (_, { result: { data: { market, portfolio } } }) =>
    transformTabs(normalizeMarket(mergeMarketPortfolio({ market, portfolio })))
  )
  .on(setGameState, (_, { market, portfolio }) =>
    transformTabs(normalizeMarket(mergeMarketPortfolio({ market, portfolio })))
  )
  .on(_setGameState, (_, state) => state)

$profile
  .on(getGameState.done, (_, { result: { data: { profile, market, portfolio } } }) =>
    normalizeProfile(profile, transformTabs(normalizeMarket(mergeMarketPortfolio({ market, portfolio }))))
  )
  .on(setGameState, (_, { market, portfolio, profile }) =>
    normalizeProfile(profile, transformTabs(normalizeMarket(mergeMarketPortfolio({ market, portfolio }))))
  )

$activeTransactionType
  .on(makeTransaction, (_, { actionType }) => actionType)
  .on(chooseReaction, (_, { payload: { reaction_id } }) => reaction_id)
  .reset([chooseReaction.finally, makeTransaction.finally])
$actions.reset([resetTurn.done, endTurn.done])
$actions.watch((p) => {
  window.log(p, 'store', 'actions')
})

endTurn.done.watch(
  ({
    result: {
      data: { end_of_game, state },
    },
  }) => {
    window.log(end_of_game, 'event', 'endTurn.done is end of game')
    if (end_of_game) {
      resetIsNowPlaying()
      goToLeaderboardPage()
    } else {
      setGameState(state)
    }
  }
)

merge([makeTransaction.done, resetTurn.done, relocate.done, chooseDream.done, chooseReaction.done]).watch(
  ({
    result: {
      data: { state },
    },
  }) => {
    window.log('event', 'merged makeTransaction + reset turn + relocate + choose-dream')
    setGameState(state)
  }
)
chooseDream.done.watch(() => {
  notify('Action with dream completed!', null, 'success')
})
makeTransaction.done.watch(() => {
  notify('Transaction completed!', null, 'success')
})
changeFinancialSettings.done.watch(() => {
  notify('Changes saved!', null, 'success')
})
relocate.done.watch(() => {
  notify('Relocated successfully!', null, 'success')
})
merge([
  getGameState.fail,
  makeTransaction.fail,
  goBankrupt.fail,
  endTurn.fail,
  resetTurn.fail,
  chooseDream.fail,
  chooseReaction.fail,
  changeFinancialSettings.fail,
  relocate.fail,
]).watch(({ error: { code } }) => {
  if (code === 403) {
    resetIsNowPlaying()
    navigate('/login')
  } else if (code === 410) {
    resetIsNowPlaying()
    navigate('/rooms')
  }
})

$leverageOn.watch((st) => {
  window.log(st, 'leverageOn', 'store')
})

merge([finishGame.done, goBankrupt.done]).watch(() => {
  resetIsNowPlaying()
  goToLeaderboardPage()
})

export const dataRootTabs = createRootTabsModel(ROOT_TABS_STRUCTURE, {
  main: $state,
  turnActions: $actions,
  events: $events,
})

window.log(dataRootTabs, 'dataRootTabs')
$events.watch((p) => {
  window.log(p, '$events', 'store')
})

const getNormalizedSelectedInstrument = (selected, gameState, events) => {
  if (selected?.isTurnAction) {
    let foundInstr = null
    if (['buy', 'sell'].includes(selected.type)) {
      foundInstr = getSubjectInstrument(
        selected.payload.instrumentId,
        selected.payload.instrumentType,
        selected.payload.portfolio_id,
        gameState
      )
    } else if (selected.type === 'reaction') {
      foundInstr = events.find((el) => el.id === selected.payload.event_id)
    } else if (selected.type === 'settings') {
      foundInstr = getSubjectInstrument(FINANCIAL_SETTINGS_ID, 'incomeAndExpenses')
    }
    return foundInstr
  } else {
    return selected
  }
}

export const $selectedTabData = $selectedTab.map((tab) => dataRootTabs[tab])
export const $selectedSubTabData = combine(
  $selectedSubTab,
  $selectedTabData,
  (subtab, tabData) => tabData[subtab]
)
export const $selectedInstrument = createStore(null, { name: 'selectedInstrument' }).on(
  _setSelectedInstrument,
  (_, p) => p
)
sample({
  source: [$state, $events],
  clock: setSelectedInstrument,
  fn: ([state, events], selected) => getNormalizedSelectedInstrument(selected, state, events),
  target: _setSelectedInstrument,
})
$selectedSubTabData.watch((state) => {
  window.log(state, 'selectedSubTabData')
  state.selected.watch(() => {
    resetMortgage()
  })
})

$selectedInstrument.watch((p) => {
  window.log(p, 'store', '$selectedInstrument')
})

$state.watch((p) => {
  window.log(p, 'store', 'gameState')
})

sample({
  clock: _setSelectedInstrument,
  source: $selectedSubTabData,
  fn: (source, clock) => ({ instrument: clock, subTab: source }),
}).watch(({ instrument, subTab }) => {
  subTab.setSelected(instrument)
})

$actions.watch((p) => {
  window.log(p, 'store', '$actions')
})
getGameState.done.watch(() => {
  dataRootTabs.events.events.resetSelected()
})
merge([
  getGameState.done,
  resetTurn.done,
  endTurn.done,
  makeTransaction.done,
  chooseReaction.done,
  finishGame.done,
  goBankrupt.done,
  setSelectedInstrument,
]).watch(() => {
  getGameStateFetching.resetError()
  resetTurnFetching.resetError()
  endTurnFetching.resetError()
  makeTransactionFetching.resetError()
  chooseReactionFetching.resetError()
  finishGameFetching.resetError()
  goBankruptFetching.resetError()
})

merge([makeTransaction.done, setSelectedSubTab]).watch(() => {
  resetMortgage()
})

$selectedSubTab.watch((subtab) => {
  if (!isStockMarketRelated(subtab)) resetLeverage()
})

export const $selectedTabLayout = $selectedTab.map((tab) => ROOT_TABS_STRUCTURE.find((el) => el.key === tab))
export const $selectedSubTabLayout = combine($selectedSubTab, $selectedTabLayout, (subtab, tabLayout) =>
  tabLayout.subtabs.find((el) => el.key === subtab)
)
$selectedSubTabLayout.watch((v) => {
  window.log(v, 'store', 'selectedSubTabLayout')
})
export const $isLivingInSelected = combine($profile, $selectedInstrument, (profile, selected) => {
  if (profile?.profile?.residence && selected?.id && selected?.instrumentType === InstrumentTypes.RealEstate)
    return profile.profile.residence === selected.id
  return false
})
