/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { applySort } from 'lib/sort/apply-sort'

export const useTableListWithLocalSort = (list, defaultOrderBy = 'name', defaultOrder = 'asc') => {
  const [sort, setSort] = useState({ orderBy: defaultOrderBy, order: defaultOrder })
  const [sortedList, setSortedList] = useState(applySort(sort, list))
  useEffect(() => {
    setSortedList(applySort(sort, sortedList))
  }, [sort])
  return [sort, setSort, sortedList]
}
