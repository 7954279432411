/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { useRAF } from 'lib/hooks/use-raf'
import { useWindowSize } from 'lib/hooks/use-window-size'

export const Ticker = ({
  children,
  speed = 1,
  delay = 0,
  bgColor = 'transparent',
  startMode = 'begin',
  gradientWidth = 30,
  showGradient = true,
  innerPadding = 4,
}) => {
  const windowSize = useWindowSize()
  const innerRef1 = useRef(null)
  const innerRef2 = useRef(null)
  const containerRef = useRef()
  const [x1, setX1] = useState(0)
  const [x2, setX2] = useState(0)
  const fps = 30 * speed
  useEffect(() => {
    if (innerRef1.current && innerRef2.current && containerRef.current) {
      const inner1 = innerRef1.current
      const container = containerRef.current
      const containerWidth = container.clientWidth
      startMode === 'blank' ? setX1(containerWidth) : void 0 // начальное положение первого тикера - спрятались справа
      startMode === 'blank' ? setX2(containerWidth + inner1.clientWidth) : setX2(inner1.clientWidth)
    }
  }, [innerRef1.current, containerRef.current, innerRef2.current, children, windowSize])
  useRAF((deltaTime) => {
    if (innerRef1.current && innerRef2.current && containerRef.current) {
      const inner1 = innerRef1.current
      const inner2 = innerRef2.current
      if (inner1.offsetLeft < -inner1.clientWidth) {
        setX1(inner2.offsetLeft + inner2.clientWidth)
      }
      if (inner2.offsetLeft < -inner2.clientWidth) {
        setX2(inner1.offsetLeft + inner1.clientWidth)
      }
      setX1((prev) => prev - deltaTime / fps)
      setX2((prev) => prev - deltaTime / fps)
    }
  })
  useEffect(() => {
    if (innerRef1.current) {
      // innerRef1.current.style.transform = `translate3d(${x1}px, 0, 0)`
      innerRef1.current.style.left = x1 + 'px'
    }
  }, [x1])
  useEffect(() => {
    if (innerRef2.current) {
      // innerRef2.current.style.transform = `translate3d(${x2}px, 0, 0)`
      innerRef2.current.style.left = x2 + 'px'
    }
  }, [x2])
  return (
    <TickerContainer {...{ showGradient }} gradientWidth={gradientWidth} bgColor={bgColor} ref={containerRef}>
      <TickerInner padding={innerPadding} key={1} ref={innerRef1}>
        {children}
      </TickerInner>
      <TickerInner padding={innerPadding} key={2} ref={innerRef2}>
        {children}
      </TickerInner>
    </TickerContainer>
  )
}

const TickerInner = styled.div`
  display: flex;
  height: 100%;
  padding: 0 ${prop('padding')}px;
  position: absolute;
`

const TickerContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 100%;
  & > * {
    user-select: none;
  }
  &::before {
    background: linear-gradient(
      90deg,
      ${({ bgColor }) => bgColor || 'transparent'} 0%,
      ${({ bgColor }) => hexToRGBA(bgColor, 0)} 100%
    );
    content: ' ';
    height: 100%;
    left: -1px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: all 0.2s ease-in-out;
    ${ifProp('showGradient', 'opacity: 1;')}
    width: ${prop('gradientWidth')}px;
    z-index: 1;
  }
  &::after {
    background: linear-gradient(
      -90deg,
      ${({ bgColor }) => bgColor || 'transparent'} 0%,
      ${({ bgColor }) => hexToRGBA(bgColor, 0)} 100%
    );
    content: ' ';
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: -1px;
    top: 0;
    transition: all 0.2s ease-in-out;
    ${ifProp('showGradient', 'opacity: 1;')}
    width: ${prop('gradientWidth')}px;
    z-index: 1;
  }
`
