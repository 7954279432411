/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import { useNonLinearSliders } from 'features/game/lib/hooks/use-non-linear-sliders'
import { formatPrice } from 'features/game/lib/prices/format'
import { $room, $roomStatus } from 'features/rooms/model'
import { startPlayingFetching } from 'features/game/navigation/model/common'
import { browserHistory } from 'lib/history'
import { MiniCard } from 'ui/atoms/mini-card'
import { Slider } from 'ui/atoms/slider'
import { Typography } from 'ui/atoms/typography'
import { HeadingWithStripe } from 'ui/molecules/heading-with-stripe'
import { theme } from 'theme'

import { RoomTimer } from '../../timer'
import {
  SettingsCardRow as CardRow,
  SettingsLabel as Label,
  SettingsValue as Value,
} from './molecules/financial-settings'

export const RoomSettings = ({
  onNext,
  onCancel,
  loading,
  onSettingsChange,
  character: { balance, revenueInYear, expensesInYear, dormitoryExpense, initialSettings },
}) => {
  const roomStatus = useStore($roomStatus)
  const session = useStore($room)
  const enterError = useStore(startPlayingFetching.error)
  const { changeSliderLevel, slidersValues, slidersLevels } = useNonLinearSliders({ initialSettings })
  useEffect(() => {
    if (enterError?.details === 'User already has an active game session') {
      //@TODO: человеческая проверка на ошибку
      browserHistory.replace('/game')
    }
  }, [enterError])
  useEffect(() => {
    onSettingsChange(slidersValues)
  }, [slidersValues])
  return (
    <Columns>
      <InfoContainer>
        <HeadingWithStripe>Initial settings:</HeadingWithStripe>
        <CardsGroup>
          <MiniCard>
            <CardRow>
              <Label>
                <Typography weight="600" size={theme.font.size.small}>
                  Cash on hands:
                </Typography>
              </Label>
              <Value>
                <Typography size={theme.font.size.small}>{formatPrice(balance)}</Typography>
              </Value>
            </CardRow>
            <CardRow>
              <Label>
                <Typography weight="600" size={theme.font.size.small}>
                  Annual income:
                </Typography>
              </Label>
              <Value>
                <Typography size={theme.font.size.small}>{formatPrice(revenueInYear)}</Typography>
              </Value>
            </CardRow>
            <CardRow>
              <Label>
                <Typography weight="600" color={theme.color.gray400} size={theme.font.size.small}>
                  Savings Level:
                </Typography>
              </Label>
              <Value>
                <Slider
                  value={slidersLevels.savingsLevel}
                  digitValue={slidersValues.savingsLevel}
                  onChange={(e) => changeSliderLevel(e, 'savingsLevel')}
                />
              </Value>
            </CardRow>
          </MiniCard>
          <MiniCard>
            <CardRow>
              <Typography weight="600" size={theme.font.size.small}>
                Expenses:
              </Typography>
            </CardRow>
            {dormitoryExpense && (
              <CardRow>
                <Typography weight="600" size={theme.font.size.small}>
                  Rent cost: {formatPrice(dormitoryExpense)}
                </Typography>
              </CardRow>
            )}
            <CardRow>
              <Typography weight="600" size={theme.font.size.small}>
                Savings: {formatPrice(revenueInYear * slidersValues.savingsLevel)}
              </Typography>
            </CardRow>
            {(Number(dormitoryExpense) || expensesInYear) + slidersValues.savingsLevel * revenueInYear >
              revenueInYear && (
              <CardRow>
                <Typography weight="600" color={theme.color.primary} size={theme.font.size.small}>
                  You have more expenses than income, which means you'll have to take out a loan (at least{' '}
                  {formatPrice(
                    (Number(dormitoryExpense) || expensesInYear) +
                      slidersValues.savingsLevel * revenueInYear -
                      revenueInYear
                  )}
                  ).
                </Typography>
              </CardRow>
            )}
            <CardRow>
              <Label>
                <Typography color={theme.color.gray400} size={theme.font.size.small}>
                  Vacation:
                </Typography>
              </Label>
              <Value>
                <Slider
                  value={slidersLevels.spendingsLevel.vacation}
                  digitValue={slidersValues.spendingsLevel.vacation}
                  onChange={(e) => changeSliderLevel(e, 'vacation')}
                />
              </Value>
            </CardRow>
            <CardRow>
              <Label>
                <Typography color={theme.color.gray400} size={theme.font.size.small}>
                  Medicine:
                </Typography>
              </Label>
              <Value>
                <Slider
                  value={slidersLevels.spendingsLevel.medicine}
                  digitValue={slidersValues.spendingsLevel.medicine}
                  onChange={(e) => changeSliderLevel(e, 'medicine')}
                />
              </Value>
            </CardRow>
            <CardRow>
              <Label>
                <Typography color={theme.color.gray400} size={theme.font.size.small}>
                  Entertainment:
                </Typography>
              </Label>
              <Value>
                <Slider
                  value={slidersLevels.spendingsLevel.entertainment}
                  digitValue={slidersValues.spendingsLevel.entertainment}
                  onChange={(e) => changeSliderLevel(e, 'entertainment')}
                />
              </Value>
            </CardRow>
            <CardRow>
              <Label>
                <Typography color={theme.color.gray400} size={theme.font.size.small}>
                  Fitness:
                </Typography>
              </Label>
              <Value>
                <Slider
                  value={slidersLevels.spendingsLevel.fitness}
                  digitValue={slidersValues.spendingsLevel.fitness}
                  onChange={(e) => changeSliderLevel(e, 'fitness')}
                />
              </Value>
            </CardRow>
          </MiniCard>
          <Typography size={theme.font.size.large} weight="600">
            Annual net revenue:{' '}
            <Accent>
              {formatPrice(
                Math.max(
                  revenueInYear * (1 - slidersValues.savingsLevel) -
                    expensesInYear -
                    (Number(dormitoryExpense) || expensesInYear),
                  0
                )
              )}
            </Accent>
          </Typography>
        </CardsGroup>
      </InfoContainer>
      {roomStatus && (
        <RoomTimer
          {...{ loading }}
          status={roomStatus.status}
          difference={roomStatus.difference}
          finishDate={session.finishDate}
          onStart={onNext}
          onCancel={onCancel}
          error={enterError}
          canStart={roomStatus.status === 'playing'}
        />
      )}
    </Columns>
  )
}

const Accent = styled.span`
  color: ${theme.color.primary};
`

const CardsGroup = styled.div`
  margin-top: 35px;
  width: 420px;
  & > * {
    margin: 10px 0;
  }
`

const Columns = styled.div`
  display: flex;
`
const InfoContainer = styled.ul`
  flex: 1;
  margin-right: 150px;
  padding-left: 30px;
`
