import * as Sentry from '@sentry/browser'

import { $fingerprint, $tokens, $user } from 'features/auth'
import { $actions, $profile } from 'features/game/model'
import { $selectedSubTab, $selectedTab } from 'features/game/navigation/model'
import { $gameID, $roomID } from 'features/game/navigation/model/common'
import { $selectedCharacter } from 'features/rooms/model'
import { Storage } from 'lib/storage'

const optimizeMsgSize = (extras) => {
  const largeKeys = [
    'currentCharacterState',
    'LSLogs',
    'gameProfileState',
    'gameActionsState',
    'selectedInstrumentState',
  ]
  let counter = 0
  const recursiveDelete = (data) => {
    const extraLength = Buffer.from(JSON.stringify(data)).length / 1000 + 3
    window.log(extraLength, 'extras kilobytes')
    counter++
    if (extraLength > 200) {
      const key = largeKeys[counter]
      if (key) {
        delete data[largeKeys[counter]]
        if (key === 'LSLogs') {
          Storage.flushErrorLogs()
        }
        return recursiveDelete(data)
      } else {
        return data
      }
    } else {
      return data
    }
  }
  return recursiveDelete(extras)
}

export const sendToSentry = (error, level = 'error', errorInfo) =>
  new Promise((resolve, reject) => {
    Sentry.withScope((scope) => {
      let userStore,
        gameProfileState,
        gameActionsState,
        currentCharacterState,
        selectedInstrumentState,
        currentTabState,
        currentSubTabState,
        roomIDState,
        gameIDState,
        tokensState,
        fingerPrintState
      try {
        userStore = JSON.stringify($user.getState())
        gameProfileState = JSON.stringify($profile.getState())
        gameActionsState = JSON.stringify($actions.getState())
        currentCharacterState = JSON.stringify($selectedCharacter.getState())
        currentTabState = JSON.stringify($selectedTab.getState())
        currentSubTabState = JSON.stringify($selectedSubTab.getState())
        roomIDState = JSON.stringify($roomID.getState())
        gameIDState = JSON.stringify($gameID.getState())
        tokensState = JSON.stringify($tokens.getState())
        fingerPrintState = JSON.stringify($fingerprint.getState())
      } catch (error) {
        console.error(error)
      }
      let extras = {
        userStore,
        gameProfileState,
        gameActionsState,
        currentCharacterState,
        currentTabState,
        currentSubTabState,
        roomIDState,
        gameIDState,
        selectedInstrumentState,
        LSLogs: JSON.stringify(Storage.getErrorLogs()),
      }
      if (errorInfo) extras = { ...extras, ...errorInfo }
      scope.setExtras(optimizeMsgSize(extras))
      scope.setLevel(level as any)
      scope.setUser({ id: String(userStore?.uid), name: userStore?.name })
      scope.setTag('token', tokensState?.access)
      scope.setTag('fingerprint', fingerPrintState)
      resolve(Sentry.captureException(error.error))
    })
  })
