import React from 'react'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { ReactComponent as RolletArrow } from 'static/roll-down.svg'
import { theme } from 'theme'

export const RolletButton = ({ rolletPos, toggleRollet, className, size = 28 }) => {
  const iconSize = (size * 0.4).toFixed(2)
  return (
    <Button size={size} {...{ className }} onClick={toggleRollet} rolletDown={rolletPos === 'down'}>
      <RolletIcon color={theme.color.primary} size={iconSize}>
      <RolletArrow />
        </RolletIcon>
    </Button>
  )
}

const RolletIcon = styled.div`
  & > svg {
    color: ${prop('color')};
    height: ${prop('size')}px;
    transition: all 0.2s ease-in-out;
    width: ${prop('size')}px;
  }
`

const Button = styled.div`
  align-items: center;
  border-radius: 50%;
  box-shadow: ${theme.shadow.rolletButton};
  cursor: pointer;
  display: flex;
  height: ${prop('size')}px;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  width: ${prop('size')}px;
  z-index: 4;
  ${ifProp(
    'rolletDown',
    `
    background: ${theme.color.primary};
    & > * {
        transform: rotate(-180deg);
        filter: brightness(0.3);
    }
    &:hover {
        background: ${theme.color.darkgray};
        & > * {
            filter: brightness(1);
        }
    }
  `,
    `
    background: ${theme.color.darkgray};
    &:hover {
        background: ${theme.color.primary};
        & > * {
            filter: brightness(0.3);
        }
    }
  `
  )}
`
