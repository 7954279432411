import { Logger } from 'features/error'
import { $state } from 'features/game/model/state'
import { InstrumentTypes } from 'features/game/types'

const enhanceFieldError = (err) => {
  err.name = 'FieldError'
  return err
}

export const getSubjectArrToFind = (type, state = $state.getState()) => {
  let arrToFind = []
  switch (type) {
    case InstrumentTypes.Stock:
      arrToFind = state.financialMarkets.stocks
      break
    case InstrumentTypes.Bond:
      arrToFind = state.financialMarkets.bonds
      break
    case InstrumentTypes.ETF:
      arrToFind = state.financialMarkets.etf
      break
    case 'indicies':
      arrToFind = state.financialMarkets.indicies
      break
    case InstrumentTypes.Commodity:
      arrToFind = state.financialMarkets.commodities
      break
    case InstrumentTypes.MF:
      arrToFind = state.financialMarkets.mf
      break
    case InstrumentTypes.Crypto:
      arrToFind = state.financialMarkets.crypto
      break
    case InstrumentTypes.Goal:
      arrToFind = state.personal.goals
      break
    case InstrumentTypes.Dream:
      arrToFind = state.personal.dreams
      break
    case InstrumentTypes.Good:
      arrToFind = state.otherMarkets.goods
      break
    case InstrumentTypes.Service:
      arrToFind = state.otherMarkets.services
      break
    case InstrumentTypes.RealEstate:
      arrToFind = state.otherMarkets.realEstate
      break
    case InstrumentTypes.Deposit:
      arrToFind = state.financialMarkets.banking
      break
    case InstrumentTypes.IncomeAndExpenses:
      arrToFind = state.personal.incomeAndExpenses
      break
    case InstrumentTypes.Credit:
      arrToFind = state.financialMarkets.banking
      break
    case InstrumentTypes.Leverage:
      arrToFind = state.financialMarkets.banking
      break
    // case 'businesses':
    // arrToFind = state.REBusiness.businesses
    // break
    default:
      const error = enhanceFieldError(new Error('not found subject type of instrument: ' + type))
      Logger.warn({ error })
  }
  return arrToFind
}

export const getSubjectInstrument = (id, type, portfolioId, gameState) => {
  const arrToFind = getSubjectArrToFind(type, gameState)
  let foundInstr = arrToFind.find((el) => {
    if (portfolioId) return el.id === id && portfolioId === el.portfolio_id
    return el.id === id
  })
  if (!foundInstr && portfolioId) {
    foundInstr = arrToFind.find((el) => {
      return el.id === id
    })
  }
  if (foundInstr) return foundInstr
  const error = enhanceFieldError(new Error('not found subject id of instrument: ' + type + ' id: ' + id))
  Logger.warn({ error })
  return null
}
