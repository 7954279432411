import { http } from 'lib/http'
import { handleAPIError } from 'lib/http/error/handle-error'

import { viewGameArgs } from './types'

export const ADMIN_HTTP_ROUTES = {
  goToStep: 'admin/go-to-step',
  viewGame: 'admin/view-game',
  changeCharacter: 'admin/change-character',
  refreshScenario: 'admin/refresh-scenario',
}

const goToStep = (idx: Number) =>
  http.post(`${ADMIN_HTTP_ROUTES.goToStep}/${idx}`).catch(handleAPIError({ default: 'Error changing step.' }))
const viewGame = ({ userID, gameID, sessionID }: viewGameArgs) =>
  http
    .post(ADMIN_HTTP_ROUTES.viewGame, { user_id: userID, game_id: gameID, sessionId: sessionID })
    .catch(handleAPIError({ default: 'Cannot view this game.' }))
const changeCharacter = (id: String) =>
  http
    .put(`${ADMIN_HTTP_ROUTES.changeCharacter}/${id}`)
    .catch(handleAPIError({ default: 'Error changing character.' }))
const refreshScenario = () =>
  http
    .post(ADMIN_HTTP_ROUTES.refreshScenario)
    .catch(handleAPIError({ default: 'Error refreshing scenario.' }))

export const adminAPI = {
  goToStep,
  viewGame,
  changeCharacter,
  refreshScenario,
}
