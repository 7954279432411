import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import { useKeypress } from 'lib/hooks/use-keypress'
import { Button } from 'ui/atoms/button'
import { Typography } from 'ui/atoms/typography'
import { theme } from 'theme'

import { Modal } from '../modal'
import { $modal, resetModal } from '../modal/model'

export const Alert = ({
  id,
  withCloseButton,
  noBackdropClose,
  circleTop,
  circleRight,
  children,
  buttonTitle = 'Confirm',
}) => {
  const modal = useStore($modal)
  const onClick = () => {
    if (modal) {
      modal.onSuccess()
      resetModal()
    }
  }
  useKeypress('KeyE', onClick)
  useKeypress('KeyQ', onClick)
  useKeypress('Enter', onClick)
  useKeypress('Escape', modal.onCancel ? modal.onCancel : resetModal)
  if (!modal) return null
  const preparedChild =
    typeof children === 'string' ? (
      <Heading color={theme.color.black} size={theme.font.size.large + 4} weight="600" {...{ onClick }}>
        {children}
      </Heading>
    ) : (
      children
    )
  return (
    <Modal onCancel={modal.onCancel} {...{ id, withCloseButton, noBackdropClose, circleTop, circleRight }}>
      <Container>
        {preparedChild}
        <Button {...{ onClick }}>{buttonTitle}</Button>
      </Container>
    </Modal>
  )
}

const Heading = styled(Typography)`
  margin-bottom: 20px;
  text-align: center;
`

const Container = styled.div`
  background: ${theme.color.darksecondary};
  border-radius: ${theme.radius.button.medium}px;
  box-shadow: ${theme.shadow.loginForm};
  display: flex;
  flex-direction: column;
  max-width: 500px;
  overflow: hidden;
  padding: ${theme.padding.medium}px;
  position: relative;
`
