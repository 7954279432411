export enum USER_ROLES {
  TEAMLEAD = 'teamlead',
  WRITER = 'scenarist',
  ADMIN = 'admin',
  DEMIURGE = 'demiurge',
  GOD = 'god',
}

export const isTeamlead = (user) => user?.role === USER_ROLES.TEAMLEAD

export const isAdmin = (user) => [USER_ROLES.ADMIN, USER_ROLES.DEMIURGE, USER_ROLES.GOD].includes(user?.role)

export const canUseTimeline = (user) =>
  [USER_ROLES.WRITER, USER_ROLES.ADMIN, USER_ROLES.DEMIURGE, USER_ROLES.GOD].includes(user?.role)
