import React from 'react'
import RCSlider from 'react-input-slider'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import { theme } from 'theme'

import { Typography } from '../typography'

const sliderStyles = {
  track: {
    background: theme.gradients.sliderBg,
    width: 'auto',
    minWidth: 110,
  },
  active: {
    background: 'transparent',
  },
  thumb: {
    width: 22,
    height: 22,
    boxShadow: theme.shadow.sliderKnob,
    border: `2px solid ${theme.color.green}`,
    backgroundColor: theme.color.secondary,
  },
}

export const Slider = ({
  onChange,
  value,
  digitValue,
  step = 0.01,
  max = 100,
  min = 0,
  additionalText,
  className,
  textMarginType = 'normal',
}) => {
  return (
    <Container className={className}>
      <StyledRCSlider
        styles={sliderStyles}
        axis="x"
        x={Math.round(value / step)}
        onChange={({ x }) => onChange(x * step)}
        xmin={min}
        xmax={max}
        step={1}
      />
      <Text size={theme.font.size.small} textMarginType={textMarginType} color={theme.color.gray400}>
        {Math.round((digitValue || value) / step)}% {additionalText}
      </Text>
    </Container>
  )
}

const Text = styled(Typography)`
  margin-left: ${prop('textMarginType') === 'normal' ? '15px' : '12px'};
  margin-left: 15px;
  text-align: right;
  width: 25px;
`

const Container = styled.div`
  align-items: center;
  display: flex;
`
const StyledRCSlider = styled(RCSlider)`
  flex: 1;
  width: 100%;
`
