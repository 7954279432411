import { hexToRGBA } from 'lib/hex-to-rgba'
import styled from 'styled-components'
import { theme } from 'theme'

export const Delimeter = styled.div`
  background: ${hexToRGBA(theme.color.black, 0.5)};
  display: block;
  height: 20px;
  margin-right: 8px;
  opacity: 0.5;
  width: 1px;
`
export const TickerInner = styled.div`
  display: flex;
  height: 20px;
  margin: 0 4px;
  white-space: nowrap;
`
