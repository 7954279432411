import styled from 'styled-components'

import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'

export const Form = styled.form`
  background: ${theme.color.secondary};
  border-radius: ${theme.radius.button.small * 2}px;
  box-shadow: ${theme.shadow.loginForm};
  padding: 40px;
  position: relative;
  width: 560px;
`

export const Subheading = styled(Typography)`
  color: ${theme.color.gray400};
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 15px;
`

export const ButtonsGroup = styled.div`
  margin: 15px 0;
  & > * {
    margin: 8px 0;
  }
`
export const Error = styled(Typography)`
  color: ${theme.color.red};
  font-size: ${theme.font.size.extrasmall}px;
  margin: 10px 0;
`

export const Heading = styled(Typography)`
  margin-bottom: 35px;
`

export const Horizontal = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`
