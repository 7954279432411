import { useStore } from 'effector-react'
import React from 'react'

import { $selectedSubTabData } from 'features/game/model'
import { InstrumentTypes, isStockMarketRelated } from 'features/game/types'
import { NoData } from 'ui/molecules/no-data'

import { GoodsAndServicesDescription } from '../../goods-services/tabs/description'
import { IncomeExpensesDescription } from '../../income-expenses/tabs/description'
import { StockMarketAbout } from '../../stock-market/tabs/about'

export const DescriptionTab = () => {
  const GOODS_AND_SERVICES_SUBTABS = [
    InstrumentTypes.Good,
    InstrumentTypes.Service,
    InstrumentTypes.Event,
    InstrumentTypes.Deposit,
    InstrumentTypes.Credit,
    InstrumentTypes.Leverage,
    InstrumentTypes.RealEstate,
  ]
  const selectedInstrument = useStore(useStore($selectedSubTabData).selected)
  if (
    !selectedInstrument ||
    (selectedInstrument &&
      [InstrumentTypes.Goal, InstrumentTypes.Dream].includes(selectedInstrument.instrumentType)) ||
    selectedInstrument?.isTurnAction
  )
    return <NoData />
  if (GOODS_AND_SERVICES_SUBTABS.includes(selectedInstrument.instrumentType))
    return <GoodsAndServicesDescription />
  if (selectedInstrument.instrumentType === InstrumentTypes.IncomeAndExpenses)
    return <IncomeExpensesDescription />
  if (isStockMarketRelated(selectedInstrument.instrumentType)) return <StockMarketAbout alone />
  return <NoData />
}
