import React from 'react'
import styled from 'styled-components'

import { Divider } from 'features/game/field/atoms/divider'
import { formatPrice } from 'features/game/lib/prices/format'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'
import { TextTable } from 'ui/organisms/text-table'
import { GoodsServicesDescCard } from '..'

export const MatchCard = ({ match, className, type, onClick }) => {
  let rows = [['Endbonus:', `x ${formatPrice(match.endBonus, { disableCurrency: true })}`]]
  if (type === 'achievement') {
    rows = []
    if (match.flowPoints) {
      rows = [...rows, ['Additional points:', formatPrice(match.flowPoints, { disableCurrency: true })]]
    }
    if (match.flowBonusTemporary) {
      rows = [
        ...rows,
        ['Flowbonus:', match.flowBonusTemporary],
        ['Flowbonus lasting:', `${match.flowBonusDuration} turn(s)`],
      ]
    }
    if (match.flowBonusForever) {
      rows = [...rows, ['Flowbonus forever:', match.flowBonusForever]]
    }
  }
  return (
    <GoodsServicesDescCard heading={`Matches the ${type}:`} {...{ className, onClick }}>
      <MatchName weight="600" color={theme.color.gray400} size={15}>
        {match.name}
      </MatchName>
      <Divider />
      <StyledTextTable labelTextAlign="left" valueTextAlign="right" {...{ rows }} />
    </GoodsServicesDescCard>
  )
}

const StyledTextTable = styled(TextTable)`
  justify-content: space-between;
`

const MatchName = styled(Typography)`
  margin-top: -5px;
`
