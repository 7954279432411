import { ThemeVariant, Theme, ObjContainerSize, ContainerSize } from './types'
const applyVariant = (variant: ThemeVariant, initial: Theme): Theme => {
  switch (variant) {
    case 'dark':
      return {
        ...initial,
        color: {
          ...initial.color,
          primary: '#F35531',
          secondary: '#1D222B',
          green: '#20CE98',
          red: '#DE0D02',
          white: '#000000',
          black: '#ffffff',
          thirdly: '#10151F',
          gray: '#3C414A',
          darkgray: '#292D35',
        },
        radius: {
          ...initial.radius,
          button: {
            ...initial.radius.button,
            medium: 8,
            large: 8,
          },
        },
      }
    case 'test':
      return {
        ...initial,
        color: {
          ...initial.color,
          primary: '#4b76a8',
          secondary: '#1D222B',
          green: '#72e486',
          red: '#fa636c',
          white: '#000000',
          black: '#ffffff',
          thirdly: '#10151F',
          gray: '#3C414A',
          darkgray: '#292D35',
        },
        radius: {
          ...initial.radius,
          button: {
            ...initial.radius.button,
            medium: 8,
            large: 8,
          },
        },
      }
    default:
      return initial
  }
}

const container: ObjContainerSize = {
  medium: {
    medium: 1200,
    large: 1120,
  },
  large: {
    medium: 1360,
    large: 1280,
  },
}
const breakpoints: ContainerSize = {
  large: 1440,
}

const initialTheme: Theme = {
  container,
  breakpoints,
  padding: {
    medium: 30,
    large: 40,
    button: {
      task: '2px 4px',
      extrasmall: '5px 8px',
      small: '9px 15px',
      medium: '15px 20px',
      large: '18px 20px',
      chooseDream: '7px 10px',
    },
    table: {
      stocks: '11px 8px',
    },
    form: {
      action: '12px',
    },
    input: {
      action: '3px 10px',
    },
  },
  media: {
    large: `@media (min-width: ${breakpoints.large}px)`,
  },
  shadow: {
    loginForm: '0px 6px 18px rgba(0, 0, 0, 0.22)',
    sliderKnob: '0px 3px 4px rgba(0, 0, 0, 0.18)',
    addDreamButton: '0px 8px 12px rgba(0, 0, 0, 0.18)',
    bottomBar: '0px -2px 4px rgba(0, 0, 0, 0.04)',
    topBar: '0px 3px 5px rgba(26, 26, 26, 0.12)',
    rolletButton: '0px 0px 8px rgba(26, 26, 26, 0.72), 0px 3px 5px rgba(26, 26, 26, 0.12)',
  },
  color: {
    primary: '#889BA6',
    secondary: '#EAF1F5',
    green: '#14b855',
    dark: '#333333',
    cyan: '#0089ff',
    blue: '#0052CC',
    red: '#FF5630',
    violet: '#6554C0',
    yellow: '#EF8F00',
    darkgray: '#525252',
    mediumgray: '#e8e8e8',
    lightgray: '#f9fafb',
    gray400: '#818181',
    gray200: '#2b2f36',
    thirdly: '#ffffff',
    white: '#ffffff',
    black: '#000000',
    darksecondary: '#20242D',
  },
  radius: {
    button: {
      large: 40,
      medium: 30,
      small: 4,
    },
    form: {
      action: 10,
    },
  },
  gradients: {
    primary: 'linear-gradient(123deg, #484848 0%, #1E1E1E 100%)',
    sliderBg:
      'linear-gradient(90deg, rgba(41,45,53, 0.3) 0%, rgba(32,206,152, 0.3) 50%, rgba(41,45,53, 0.3) 100%)',
  },
  font: {
    size: {
      main: 16,
      large: 20,
      small: 14,
      mediumsmall: 13,
      extrasmall: 12,
      huge: 30,
    },
  },
}
export const resultTheme = applyVariant('dark', initialTheme)
