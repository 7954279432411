export const debounce = (callback, wait, immediate = false) => {
  let timeout = null

  return function() {
    const callNow = immediate && !timeout
    const next = () => Reflect.apply(callback, this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(next, wait)

    if (callNow) {
      next()
    }
  }
}
