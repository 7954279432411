/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

export const useControlForReactSelect = ({ register, registerProps = { name: 'reactSelect' }, setValue }) => {
  const [value, setSelectValue] = useState(null)
  const handleMultiChange = (selectedOption) => {
    setValue('reactSelect', selectedOption)
    setSelectValue(selectedOption)
  }
  useEffect(() => {
    register(registerProps)
  }, [])
  return { handleMultiChange, value }
}
