import Fingerprint2 from 'fingerprintjs2'

export const getFingerprint = () =>
  new Promise((resolve, reject) => {
    const options = {
      excludes: {
        // fonts: true,
        adBlock: true,
        // audio: true,
        enumerateDevices: true,
        // localStorage: true,
        screenResolution: true,
        availableScreenResolution: true,
      },
    }
    const resolveFingerprint = () => {
      Fingerprint2.get(options, function (components) {
        var values = components.map(function (component) {
          return component.value
        })
        let fingerprint = Fingerprint2.x64hash128(values.join(''), 31)
        resolve(fingerprint)
      })
    }
    if (window.requestIdleCallback) {
      requestIdleCallback(resolveFingerprint)
    } else {
      setTimeout(resolveFingerprint, 200)
    }
  })
window.getFingerprint = getFingerprint
