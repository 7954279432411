import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { uuid } from 'uuidv4'

import { setModal } from 'features/common/organisms/modal/model'
import { useQtyInput } from 'features/game/lib/hooks/use-qty-input'
import { formatPrice } from 'features/game/lib/prices/format'
import { getStatusDelta } from 'features/game/lib/prices/get-status-and-delta'
import {
  $activeTransactionType,
  $isLivingInSelected,
  $leverageOn,
  $mortgageOn,
  makeTransaction,
  makeTransactionFetching,
  relocate,
  relocateFetching,
  toggleMortgage,
} from 'features/game/model'
import { InstrumentTypes, isBankingRelated, isStockMarketRelated } from 'features/game/types'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { useKeypress } from 'lib/hooks/use-keypress'
import arrowDown from 'static/arrow-down-red.svg'
import arrowUp from 'static/arrow-up-green.svg'
import { theme } from 'theme'
import { ActionButton } from 'ui/atoms/button/action-button'
import { Checkbox } from 'ui/atoms/checkbox'
import { Typography } from 'ui/atoms/typography'
import { FlippingNumbers } from 'ui/molecules/flipping-numbers'

import { Divider } from '../../atoms/divider'
import { TabInnerHeading } from '../../atoms/tab-inner-heading'
import { TabInnerSection } from '../../atoms/tab-inner-section'
import { ActionInput } from '../../molecules/action-input'
import { Label, Row, Value } from '../../molecules/row-elements'
import { ButtonsSection, Icon, Instrument, Name, Price } from './molecules'

export const BuySellTab = ({ selectedInstrument, profile }) => {
  const activeTransactionType = useStore($activeTransactionType)
  const actionError = useStore(makeTransactionFetching.error)
  const transactionIsDone = useStore(makeTransactionFetching.isDone)
  const relocateError = useStore(relocateFetching.error)
  const relocateLoading = useStore(relocateFetching.isLoading)
  const isLivingHere = useStore($isLivingInSelected)
  const isLeverageOn = useStore($leverageOn)
  const isMortgageOn = useStore($mortgageOn)
  const [onActionInputChange, actionQtyInput, actionQty, inputRef] = useQtyInput(selectedInstrument, [
    transactionIsDone,
  ])
  const [onDownInputChange, downInput, down, downInputRef] = useQtyInput(
    selectedInstrument,
    [transactionIsDone],
    true,
    Math.round(selectedInstrument?.creditDetails?.minDownPayment) || 1
  )
  const [onYearsInputChange, yearsInput, years, inputRefYears] = useQtyInput(
    selectedInstrument,
    [transactionIsDone],
    true,
    selectedInstrument?.creditDetails?.maxYear || 1
  )
  const onRelocate = () => {
    setModal({
      id: 'relocate-confirm',
      onSuccess: () => {
        relocate({ uid: uuid(), actionType: 'relocate', id: selectedInstrument.id })
      },
    })
  }
  const onBuy = () => {
    let params = {
      id: selectedInstrument.id,
      value: actionQty,
      actionType: 'buy',
      instrumentType: instrumentType,
      uid: uuid(),
    }
    if (isMortgageOn) {
      params.term = years
      params.downPayment = down
    }
    if (selectedInstrument.portfolio_id) params.portfolioId = selectedInstrument.portfolio_id
    makeTransaction(params)
  }
  const onSell = () => {
    let params = {
      id: selectedInstrument.id,
      value: actionQty,
      actionType: 'sell',
      instrumentType: instrumentType,
      uid: uuid(),
    }
    if (selectedInstrument.portfolio_id) params.portfolioId = selectedInstrument.portfolio_id
    if (selectedInstrument.instrumentType === InstrumentTypes.RealEstate) {
      setModal({ id: 're-sell-confirm', onSuccess: () => makeTransaction(params) })
    } else {
      makeTransaction(params)
    }
  }
  const isOwning = selectedInstrument.qty > 0
  const qty = selectedInstrument.qty || 0
  const { instrumentType } = selectedInstrument
  const checkIfCanSell = () => {
    if (selectedInstrument?.restrictedAccess?.includes('sell')) return false
    if (isBankingRelated(instrumentType)) return true
    if (isLeverageOn && isStockMarketRelated(instrumentType)) return true
    return (
      isOwning &&
      actionQty <= Math.round(selectedInstrument.qty) &&
      actionQty !== 0 &&
      activeTransactionType !== 'buy'
    )
  }
  const checkIfCanBuy = () => {
    const sum = Math.round(actionQty * selectedInstrument.price?.current)

    if (selectedInstrument?.restrictedAccess?.includes('buy')) return false
    if (isBankingRelated(instrumentType)) return true
    if (isLeverageOn && isStockMarketRelated(instrumentType)) return true
    if (
      isMortgageOn &&
      instrumentType === InstrumentTypes.RealEstate &&
      down <= profile?.profile?.balance.cash.current
    ) {
      return true
    }
    if (selectedInstrument.maxPurchaseCount && selectedInstrument.maxPurchaseCount < actionQty) return false
    // основное условие: есть деньги - покупаем

    return sum <= profile.profile.balance.cash.current && actionQty !== 0 && activeTransactionType !== 'sell'
  }
  const checkIfCanRelocate = () => {
    if (selectedInstrument?.restrictedAccess?.includes('relocate')) return false
    return !isLivingHere
  }
  const canBuy = checkIfCanBuy()
  const canSell = checkIfCanSell()
  const canRelocate = checkIfCanRelocate()
  const hasThirdButton = instrumentType === InstrumentTypes.RealEstate
  const { status, delta, deltaAbs } = getStatusDelta(
    selectedInstrument.price.current,
    selectedInstrument.price.previous
  )
  const avgProfit = selectedInstrument.averageGain?.sum.relative
  const onPressQ = () => {
    if (canBuy) onBuy()
  }
  const onPressE = () => {
    if (canSell) onSell()
  }
  useKeypress('KeyQ', onPressQ)
  useKeypress('Enter', onPressQ)
  useKeypress('KeyE', onPressE)
  const renderActionButtons = () => {
    switch (instrumentType) {
      case 'realEstate':
        return (
          <>
            <ActionButton
              flex
              onClick={onBuy}
              loading={activeTransactionType === 'buy'}
              disabled={!canBuy}
              type="buy"
              data-testid="buy-sell-buy-button"
            >
              Buy
            </ActionButton>
            <ActionButton
              onClick={onSell}
              loading={activeTransactionType === 'sell'}
              flex
              type="sell"
              disabled={!canSell}
              data-testid="buy-sell-sell-button"
            >
              Sell
            </ActionButton>
            <ActionButton
              disabled={!canRelocate}
              onClick={onRelocate}
              loading={relocateLoading}
              fullWidth
              variant="greenOutlined"
              data-testid="buy-sell-relocate-button"
            >
              Relocate
            </ActionButton>
          </>
        )

      default:
        return (
          <>
            <ActionButton
              onClick={onBuy}
              loading={activeTransactionType === 'buy'}
              flex
              disabled={!canBuy}
              type="buy"
              data-testid="buy-sell-buy-button"
            >
              Buy
            </ActionButton>
            {instrumentType !== 'services' && (
              <ActionButton
                onClick={onSell}
                loading={activeTransactionType === 'sell'}
                flex
                type="sell"
                disabled={!canSell}
                data-testid="buy-sell-sell-button"
              >
                Sell
              </ActionButton>
            )}
          </>
        )
    }
  }
  return (
    <div style={{ color: theme.color.secondary }}>
      <TabInnerSection>
        <ButtonsSection {...{ hasThirdButton }}>{renderActionButtons()}</ButtonsSection>
      </TabInnerSection>
      <TabInnerSection>
        <ActionForm>
          <ActionColumn>
            <ActionLabel>Quantity</ActionLabel>
            <ActionValue>
              <ActionInput
                fullWidth
                type="number"
                step="1"
                maxWidth
                min="0"
                autoSize
                radius={theme.radius.button.small}
                padding={theme.padding.input.action}
                value={actionQtyInput}
                ref={inputRef}
                onChange={onActionInputChange}
                data-testid="buy-sell-qty-input"
              />
            </ActionValue>
          </ActionColumn>
          <ActionColumn>
            <ActionLabel>Price</ActionLabel>
            <ActionValue>{formatPrice(selectedInstrument.price.current * actionQty)}</ActionValue>
          </ActionColumn>
        </ActionForm>
      </TabInnerSection>
      {instrumentType === InstrumentTypes.RealEstate &&
        Boolean(selectedInstrument?.creditDetails) &&
        !selectedInstrument.portfolio_id && (
          <>
            <Divider />
            <TabInnerSection noPt>
              <BonusRow>
                <TabInnerHeading>Mortgage</TabInnerHeading>
                <Checkbox onChange={toggleMortgage} checked={isMortgageOn} />
              </BonusRow>
              <MortgageContainer disabled={!isMortgageOn}>
                <Row>
                  <Label>Down payment:</Label>
                  <Value>
                    <ActionInput
                      fullWidth
                      type="number"
                      step="1"
                      min={selectedInstrument?.minDownPayment || 1}
                      maxWidth
                      radius={theme.radius.button.small}
                      padding={0}
                      value={downInput}
                      ref={downInputRef}
                      onChange={onDownInputChange}
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>Years:</Label>
                  <ActionInput
                    fullWidth
                    type="number"
                    step="1"
                    min={selectedInstrument?.minYears || 1}
                    max={selectedInstrument?.maxYears || 50}
                    maxWidth
                    radius={theme.radius.button.small}
                    padding={0}
                    value={yearsInput}
                    ref={inputRefYears}
                    onChange={onYearsInputChange}
                  />
                </Row>
                <Row>
                  <Label>Min down payment:</Label>
                  <Value>{formatPrice(selectedInstrument.creditDetails?.minDownPayment)}</Value>
                </Row>
                <Row>
                  <Label>Max years:</Label>
                  <Value>{selectedInstrument.creditDetails?.maxYear}</Value>
                </Row>
                <Row>
                  <Label>Rate:</Label>
                  <Value>
                    {formatPrice(selectedInstrument.creditDetails?.rate * 100, { disableCurrency: true })}%
                  </Value>
                </Row>
              </MortgageContainer>
            </TabInnerSection>
          </>
        )}
      <Divider />
      <TabInnerSection noPt>
        <TabInnerHeading>On hand</TabInnerHeading>
        <OnHandTable>
          <OnHandBlock>
            <OnHandLabel>Quantity</OnHandLabel>
            <OnHandValue>
              <FlippingNumbers noDecimals notCurrency>
                {qty}
              </FlippingNumbers>
            </OnHandValue>
          </OnHandBlock>
          <OnHandBlock>
            <OnHandLabel>Price</OnHandLabel>
            <OnHandValue>
              <FlippingNumbers notCurrency>{selectedInstrument.price.current}</FlippingNumbers>
            </OnHandValue>
          </OnHandBlock>
          <OnHandBlock>
            <OnHandLabel>Total</OnHandLabel>
            <OnHandValue>
              <FlippingNumbers>{qty * selectedInstrument.price.current}</FlippingNumbers>
            </OnHandValue>
          </OnHandBlock>
        </OnHandTable>
      </TabInnerSection>
      <Divider />
      {Boolean(selectedInstrument.dividends) && (
        <>
          <TabInnerSection noPt>
            <TabInnerHeading>
              {isStockMarketRelated(instrumentType) ? 'Dividends' : 'Money flow'}
            </TabInnerHeading>
            <Typography color={theme.color.black}>
              <FlippingNumbers>{selectedInstrument.dividends}</FlippingNumbers>
            </Typography>
          </TabInnerSection>
          <Divider />
        </>
      )}
      <TabInnerSection noPt>
        <Instrument>
          {selectedInstrument.image?.length > 0 && instrumentType === InstrumentTypes.Stock && (
            <Icon src={selectedInstrument.image} />
          )}
          <Name hasImg={instrumentType === InstrumentTypes.Stock} color={theme.color.black} weight={600}>
            {selectedInstrument.name}
          </Name>
        </Instrument>
        <Instrument>
          {status !== 'neutral' && selectedInstrument.price.current && (
            <Icon small src={status === 'up' ? arrowUp : arrowDown} />
          )}
          <Price status={status}>
            <FlippingNumbers>{selectedInstrument.price.current}</FlippingNumbers>{' '}
            <span>
              {deltaAbs} ({delta})
            </span>
          </Price>
        </Instrument>
      </TabInnerSection>
      {Boolean(selectedInstrument.sellingPrice) && (
        <>
          <Divider />
          <TabInnerSection noPt>
            <TabInnerHeading>Selling price</TabInnerHeading>
            <Typography color={theme.color.black}>
              <FlippingNumbers>{selectedInstrument.sellingPrice}</FlippingNumbers>
            </Typography>
          </TabInnerSection>
        </>
      )}
      {Boolean(selectedInstrument.rate) && (
        <>
          <Divider />
          <TabInnerSection noPt>
            <TabInnerHeading>Rate</TabInnerHeading>
            <Typography color={theme.color.black}>
              <FlippingNumbers postfix="%">{selectedInstrument.rate * 100}</FlippingNumbers>
            </Typography>
          </TabInnerSection>
        </>
      )}
      {Boolean(avgProfit) && (
        <>
          <Divider />
          <TabInnerSection noPt>
            <TabInnerHeading>Average gain</TabInnerHeading>
            <Price noMl status={avgProfit > 0 ? 'up' : 'down'}>
              <FlippingNumbers notCurrency postfix="%">
                {avgProfit}
              </FlippingNumbers>
            </Price>
          </TabInnerSection>
        </>
      )}
      {instrumentType === 'realEstate' && Boolean(selectedInstrument?.creditDetails) && (
        <>
          <Divider />
          <TabInnerSection>
            <BonusRow>
              <TabInnerHeading>Mortgage</TabInnerHeading>
              <Checkbox onChange={toggleMortgage} checked={isMortgageOn} />
            </BonusRow>
            <MortgageContainer disabled={!isMortgageOn}>
              <Row>
                <Label>Down payment:</Label>
                <Value>
                  <ActionInput
                    fullWidth
                    type="number"
                    step="1"
                    min={selectedInstrument?.minDownPayment || 1}
                    maxWidth
                    radius={theme.radius.button.small}
                    padding={0}
                    value={downInput}
                    ref={downInputRef}
                    onChange={onDownInputChange}
                  />
                </Value>
              </Row>
              <Row>
                <Label>Years:</Label>
                <ActionInput
                  fullWidth
                  type="number"
                  step="1"
                  min={selectedInstrument?.minYears || 1}
                  max={selectedInstrument?.maxYears || 50}
                  maxWidth
                  radius={theme.radius.button.small}
                  padding={0}
                  value={yearsInput}
                  ref={inputRefYears}
                  onChange={onYearsInputChange}
                />
              </Row>
              <Row>
                <Label>Min down payment:</Label>
                <Value>{formatPrice(selectedInstrument.creditDetails?.minDownPayment)}</Value>
              </Row>
              <Row>
                <Label>Max years:</Label>
                <Value>{selectedInstrument.creditDetails?.maxYear}</Value>
              </Row>
              <Row>
                <Label>Rate:</Label>
                <Value>{formatPrice(selectedInstrument.creditDetails?.rate * 100, true)}%</Value>
              </Row>
            </MortgageContainer>
          </TabInnerSection>
        </>
      )}
      {['goods', 'services'].includes(instrumentType) && !isOwning && (
        <TabInnerSection noPt>
          <TabInnerHeading>Purchase Bonuses</TabInnerHeading>
          {Boolean(selectedInstrument.bonuses.purchaseDetails.capitalGain) && (
            <BonusRow>
              <BonusLabel>Capital gain:</BonusLabel>
              <BonusValue>
                <Price status="up">
                  <FlippingNumbers noDecimals>
                    {selectedInstrument.bonuses.purchaseDetails.capitalGain * actionQty}
                  </FlippingNumbers>
                </Price>
              </BonusValue>
            </BonusRow>
          )}
          {Boolean(selectedInstrument.bonuses.purchaseDetails.expiresIn) && (
            <BonusRow>
              <BonusLabel>Expires in:</BonusLabel>
              <BonusValue>
                <Price status="neutral">
                  <FlippingNumbers notCurrency noDecimals>
                    {selectedInstrument.bonuses.purchaseDetails.expiresIn}
                  </FlippingNumbers>
                </Price>
              </BonusValue>
            </BonusRow>
          )}
          {Boolean(selectedInstrument.bonuses.purchaseDetails.flowBonusGain) && (
            <BonusRow>
              <BonusLabel>Flowbonus gain:</BonusLabel>
              <BonusValue>
                <Price status="up">
                  <FlippingNumbers notCurrency noDecimals>
                    {selectedInstrument.bonuses.purchaseDetails.flowBonusGain * actionQty}
                  </FlippingNumbers>
                </Price>
              </BonusValue>
            </BonusRow>
          )}
          {Boolean(selectedInstrument.bonuses.purchaseDetails.flowBonusGainLasting) && (
            <BonusRow>
              <BonusLabel>Flowbonus gain lasting:</BonusLabel>
              <BonusValue>
                <Price status="up">
                  <FlippingNumbers notCurrency noDecimals>
                    {selectedInstrument.bonuses.purchaseDetails.flowBonusGainLasting * actionQty}
                  </FlippingNumbers>
                </Price>
              </BonusValue>
            </BonusRow>
          )}
          {Boolean(selectedInstrument.bonuses.purchaseDetails.endBonusGain) && (
            <BonusRow>
              <BonusLabel>Endbonus gain:</BonusLabel>
              <BonusValue>
                <Price status="up">
                  <FlippingNumbers notCurrency noDecimals>
                    {selectedInstrument.bonuses.purchaseDetails.endBonusGain * actionQty}
                  </FlippingNumbers>
                </Price>
              </BonusValue>
            </BonusRow>
          )}
          <BonusRow>
            <BonusLabel>Points total gain:</BonusLabel>
            <BonusValue>
              <Price status="up">
                <FlippingNumbers notCurrency noDecimals>
                  {selectedInstrument.bonuses.purchaseDetails.pointsGainTotal * actionQty}
                </FlippingNumbers>
              </Price>
            </BonusValue>
          </BonusRow>
        </TabInnerSection>
      )}
      {Boolean(actionError) && (
        <TabInnerSection>
          <Typography color={theme.color.red} size={theme.font.size.extrasmall}>
            {actionError.toString()}
          </Typography>
        </TabInnerSection>
      )}
      {Boolean(relocateError) && (
        <TabInnerSection>
          <Typography color={theme.color.red} size={theme.font.size.extrasmall}>
            {relocateError.toString()}
          </Typography>
        </TabInnerSection>
      )}
    </div>
  )
}

const MortgageContainer = styled.div`
  ${ifProp('disabled', 'opacity: 0.3;')}
`

const BonusLabel = styled.div`
  color: ${theme.color.gray400};
  font-size: ${theme.font.size.small}px;
  font-weight: 600;
  margin-right: 10px;
  min-width: 73px;
`

const BonusValue = styled(BonusLabel)`
  margin-right: 0;
  min-width: unset;
`

const BonusRow = styled.div`
  //align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 7px 0;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`

const ActionColumn = styled.div`
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-right: 15px;
    flex-basis: 35px;
  }
  &:not(:first-of-type) {
    flex: 1;
  }
`
const ActionValue = styled.div`
  align-items: center;
  color: ${theme.color.black};
  display: flex;
  flex: 1;
  font-size: ${theme.font.size.main}px;
  margin-top: 10px;
`
const ActionLabel = ({ children }) => (
  <Typography color={hexToRGBA(theme.color.black, 0.7)} weight="600" size={theme.font.size.extrasmall}>
    {children}
  </Typography>
)

const ActionForm = styled.div`
  background: ${theme.color.darkgray};
  border-radius: ${theme.radius.form.action}px;
  display: flex;
  padding: ${theme.padding.form.action};
`

const OnHandBlock = styled.div`
  flex: 1;
  width: calc(100% / 3 - 5px);
`

const OnHandTable = styled.div`
  display: flex;
  justify-content: space-between;
  ${OnHandBlock}:first-child {
    flex: 0;
    flex-basis: 50px;
  }
  ${OnHandBlock}:nth-child(2n) {
    flex: 2;
    flex-basis: 28px;
    margin: 0 5px;
  }
  ${OnHandBlock}:nth-child(3n) {
    flex: 2;
    flex-basis: 44px;
  }
`

const OnHandLabel = styled.p`
  color: ${hexToRGBA(theme.color.black, 0.4)};
  font-size: ${theme.font.size.extrasmall}px;
  font-weight: 600;
`

const OnHandValue = styled.p`
  color: ${theme.color.black};
  font-size: ${theme.font.size.main}px;
`
