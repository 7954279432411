import { gameAPI } from 'api/game'
import { createEffect, restore } from 'effector-root'
import { createFetching } from 'lib/fetching'
import defaultBgImg from 'static/bg-login.png'
import { VisualSettings } from 'api/game/types'
import { resultTheme } from './initial'

const initialVisualSettings: VisualSettings = {
  images: {
    mainBg: defaultBgImg,
  },
  theme: resultTheme,
}

export const getVisualSettings = createEffect<undefined, VisualSettings>()
export const getVisualSettingsFetching = createFetching(getVisualSettings)

getVisualSettings.use(() => gameAPI.getVisualSettings().then((res) => res.data))

export const $visualSettings = restore(getVisualSettings, initialVisualSettings)

export const $bgImgSrc = $visualSettings.map((store) => store.images.mainBg)
export const $theme = $visualSettings.map((store) => store.theme)
