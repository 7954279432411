import { notify } from 'lib/toast'

export const showNotifications = ({ error, level }) => {
  let title
  let subtitle
  if (!['notify', 'log', 'warn'].includes(level)) {
    if (!error || !error.name || !error.toString) {
      title = 'Unexpected error'
      subtitle = 'Something is wrong'
      notify(title, subtitle, level)
    } else if (error) {
      switch (error.name) {
        case 'HTTPError':
          title = error.message
          subtitle = error.details
          notify(title, subtitle, level)
          break
        case 'FieldError':
          title = error.toString()
          notify(title, subtitle, level)
          break
        default:
          title = error.toString()
          subtitle = error.details
          notify(title, subtitle, level)
          break
      }
    }
  }
}
