import React from 'react'
import styled from 'styled-components'
import { Typography } from 'ui/atoms/typography'
import { theme } from 'theme'
import { hexToRGBA } from 'lib/hex-to-rgba'

export const NewsContent = ({ html }) => {
  return (
    <NewsBody
      size={theme.font.size.extrasmall}
      color={hexToRGBA(theme.color.black, 0.7)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

const NewsBody = styled(Typography)`
  margin-top: 3px;
  & hr {
    background: ${hexToRGBA(theme.color.black, 0.1)};
    border: 0;
    height: 1px;
    width: 100%;
  }
`
