import React, { useEffect } from 'react'
import { logoutUser } from 'features/auth'
import { navigate } from 'lib/history'

export const LogoutPage = () => {
  useEffect(() => {
    logoutUser()
    navigate('/login')
  }, [])
  return <div>Logging out...</div>
}
