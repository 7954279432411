import { get } from 'lodash'

export const defaultSort = ({ order, orderBy }) => (a, b) => {
  const valueA = get(a, orderBy)
  const valueB = get(b, orderBy)
  let dateA = Date.parse(valueA)
  let dateB = Date.parse(valueB)
  const isAsc = order === 'asc'
  if (!Number.isNaN(dateA) && !Number.isNaN(dateB)) {
    return isAsc ? dateA - dateB : dateB - dateA
  }
  if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
    if (valueA && !valueB) return isAsc ? 1 : -1
    if (valueA === valueB) return 0
    if (!valueA && valueB) return isAsc ? -1 : 1
  }
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return isAsc ? valueA.localeCompare(valueB) : -valueA.localeCompare(valueB)
  }
  if (valueA < valueB) {
    return isAsc ? -1 : 1
  }
  if (valueA > valueB) {
    return isAsc ? 1 : -1
  }
  return 0
}
