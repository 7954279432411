/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'

import { useEffectWithoutInit } from 'lib/hooks/use-effect-without-init'
import { usePrevious } from 'lib/hooks/use-previous'

import { formatPrice } from '../../prices/format'

export const useQtyInput = (selectedInstrument, otherDeps = [], noNeedToFocus = false, customInitialQty) => {
  const inputRef = useRef(null)
  const initialQty = Math.round(
    customInitialQty ? customInitialQty : selectedInstrument?.qty > 0 ? selectedInstrument.qty : 1
  )
  const [actionQty, setActionQty] = useState(
    Number(formatPrice(initialQty, { disableCurrency: true }).replace(' ', ''))
  )
  const [actionQtyInput, setActionQtyInput] = useState(actionQty)
  const onActionInputChange = ({ target: { value } }) => {
    setActionQtyInput(value.replace(/^0+/, '').replace(/[^\d(),.|]/g, ''))
    let strArr = value.split('')
    if (!Number.isNaN(value) && strArr[strArr.length - 1] !== '.' && Math.round(value) >= 0) {
      setActionQty(Math.round(value))
    }
  }
  useEffect(() => {
    if (actionQty === 0) {
      setActionQtyInput('')
    } else {
      setActionQtyInput(actionQty)
    }
  }, [actionQty])
  //@TODO: showing prev value, when loading
  // eslint-disable-next-line no-unused-vars
  const prev = usePrevious([selectedInstrument, otherDeps[0]])
  useEffectWithoutInit(() => {
    // deep instrument compare
    // const isSameObject = Object.is(prev[0], selectedInstrument)
    // const isSameTransStatus = prev[1] === otherDeps[0]
    // if (!isSameObject || !isSameTransStatus)
    setActionQty(initialQty) // когда меняем инструмент, сбрасываем кол-во покупаемого до дефолтного (1)
  }, [selectedInstrument, ...otherDeps])
  useEffect(() => {
    if (selectedInstrument && inputRef.current && !noNeedToFocus) {
      inputRef.current.focus()
    }
  }, [selectedInstrument])
  return [onActionInputChange, actionQtyInput, actionQty, inputRef, initialQty]
}
