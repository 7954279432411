import { useStore } from 'effector-react'
import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { setModal } from 'features/common/organisms/modal/model'
import {
  $profile,
  finishGame,
  finishGameFetching,
  goBankrupt,
  goBankruptFetching,
  resetTurn,
  resetTurnFetching,
} from 'features/game/model'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'

const onGoBankrupt = () => {
  setModal({ id: 'go-bankrupt-confirm', onSuccess: goBankrupt })
}
const onEndGame = () => {
  setModal({ id: 'end-game-confirm', onSuccess: finishGame })
}

export const TurnActionsButtons = () => {
  const finishGameLoading = useStore(finishGameFetching.isLoading)
  const resetTurnLoading = useStore(resetTurnFetching.isLoading)
  const goBankruptLoading = useStore(goBankruptFetching.isLoading)
  const canGoBankrupt = useStore($profile).offerBankruptcy
  return (
    <Container>
      <OtherActionsContainer>
        <EndButton loading={resetTurnLoading} onClick={resetTurn} block variant="noBorderGray">
          Reset action
        </EndButton>
        <EndButton
          data-test="end-game-button"
          loading={finishGameLoading}
          onClick={onEndGame}
          block
          color={theme.color.red}
          variant="noBorderGray"
        >
          End game
        </EndButton>
        {canGoBankrupt && (
          <EndButton
            data-test="go-bankrupt-button"
            loading={goBankruptLoading}
            onClick={onGoBankrupt}
            block
            color={theme.color.primary}
            variant="noBorderGray"
          >
            Go bankrupt
          </EndButton>
        )}
      </OtherActionsContainer>
    </Container>
  )
}

const OtherActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 10%;
`

const EndButton = styled(Button)`
  margin-top: 15px;
  min-width: 250px;
  ${ifProp(
    'color',
    css`
      color: ${prop('color')};
    `
  )}
`
