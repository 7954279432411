/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from 'effector-react'
import React, { useEffect } from 'react'

import { $isNowPlaying } from 'features/game/navigation/model/common'
import { navigate } from 'lib/history'
import { LoadingOverflow } from 'ui/molecules/loading-overflow'

/* Логика страницы: если нет токенов - страничка rooms редиректнет на логин.  */
/**
 * Если есть активная игра - checkActiveGame сам редиректнет на игру.
 */
export const InitPage = () => {
  const hasActiveGame = useStore($isNowPlaying)
  useEffect(() => {
    if (!hasActiveGame) {
      navigate('/rooms')
    }
  }, [])
  return <LoadingOverflow withLoader loading={true} />
}
