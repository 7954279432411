import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { Tooltip } from 'ui/molecules/tooltip'
import { Typography } from 'ui/atoms/typography'
import { theme } from 'theme'

import { generateCushionProps, generateStructureProps } from './lib/generate-colors'

export const CushionLevel = React.forwardRef(({ level }, ref) => (
  <Container {...{ ref }}>
    <Level {...generateCushionProps(level)} />
  </Container>
))

export const PortfolioStructure = React.forwardRef(({ level, className }, ref) => {
  const structureProps = generateStructureProps(level)
  return (
    <Container {...{ className, ref }}>
      <StructureLevel {...structureProps}>
        {structureProps.levels.map(({ width, color }, idx) => (
          <Filler key={idx} {...{ width, color }} />
        ))}
      </StructureLevel>
    </Container>
  )
})

export const TopBarLevels = ({ aggression, cushion, className }) => {
  return (
    <Container {...{ className }}>
      <Tooltip
        interactive={false}
        content={
          <>
            <Typography size={theme.font.size.small} weight="600">
              This is your safety cushion level.
            </Typography>
            <Typography size={theme.font.size.extrasmall}>
              Expand header by clicking on orange arrow to see more details.
            </Typography>
          </>
        }
      >
        <CushionLevel level={cushion} />
      </Tooltip>
      <Tooltip
        interactive={false}
        content={
          <>
            <Typography size={theme.font.size.small} weight="600">
              This is your portfolio structure.
            </Typography>
            <Typography size={theme.font.size.extrasmall}>
              Expand header by clicking on orange arrow to see more details.
            </Typography>
          </>
        }
      >
        <StyledPortfolioStructure mt={7} level={aggression} />
      </Tooltip>
    </Container>
  )
}

const Filler = styled.div`
  background: ${prop('color')};
  height: 100%;
  width: ${prop('width')}%;
`

const StyledPortfolioStructure = styled(PortfolioStructure)`
  margin-top: ${prop('mt', 0)}px;
`

const Level = styled.div`
  background: ${theme.color.gray200};
  border-radius: 4px;
  height: 7px;
  position: relative;
  &::before {
    background: ${prop('bg')};
    border-radius: 4px;
    bottom: 0;
    box-shadow: 0 0 4px ${({ shadow }) => hexToRGBA(shadow, 0.7)};
    content: ' ';
    left: 0;
    position: absolute;
    top: 0;
    width: ${prop('level')}%;
  }
`

const StructureLevel = styled(Level)`
  box-shadow: 0 0 4px ${({ shadow }) => hexToRGBA(shadow, 0.7)};
  display: flex;
  overflow: hidden;
  &::before {
    content: none;
  }
`

const Container = styled.div`
  flex: 1;
`
