import { createEffect, createEvent, createStore, Effect, Event, guard, sample } from 'effector-root'
import { isEqual as isEqualObject } from 'lodash'

interface fetchingParams {
  reset?: Event<any>
  result?: any
  error?: any
}

export function createFetching(effect, params: fetchingParams = {}) {
  const initialStatus = 'initial'
  const customReset = params.reset || createEvent()
  const resetError = createEvent()
  const resetLazy = createEvent()
  const lazy = createEvent()
  const setParamsEqual = createEvent()

  const result = createStore(params.result || null)
    .reset(effect)
    .reset(effect.fail)
    .reset(customReset)
    .on(effect.done, (_, { result: value }) => value)

  const error = createStore(params.error || null)
    .reset(effect)
    .reset(effect.done)
    .reset(customReset)
    .reset(resetError)
    .on(effect.fail, (_, { error: value }) => value)

  const status = createStore(initialStatus)
    .on(effect, () => 'loading')
    .on(effect.done, () => 'done')
    .on(effect.fail, () => 'fail')
    .on(customReset, () => 'initial')

  const prevParams = createStore(null).on(effect.done, (_, { params }) => params)

  const paramsNotEqual = createStore(false)
    .on(setParamsEqual, (_, p) => p)
    .reset(resetLazy)

  sample({
    source: prevParams,
    target: setParamsEqual,
    clock: lazy,
    fn: (prev, cur) => !isEqualObject(prev, cur),
  })

  guard({
    source: lazy,
    filter: paramsNotEqual,
    target: effect,
  })

  const isDone = status.map((state) => state === 'done')
  const isFailed = status.map((state) => state === 'fail')
  const isLoading = status.map((state) => state === 'loading')

  return {
    result,
    error,
    status,
    isDone,
    isFailed,
    isLoading,
    lazy,
    resetError,
    resetLazy,
  }
}

export const createEffectWithFetching = (
  params: fetchingParams = {}
): [Effect<unknown, unknown, Error>, ReturnType<typeof createFetching>] => {
  const effect = createEffect()
  return [effect, createFetching(effect, params)]
}
