import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { theme } from 'theme'

type TypographyProps = {
  children: React.ReactNode
  centered?: boolean
  onClick?: any
  size?: number
  weight?: string
  color?: string
  className?: string
  dangerouslySetInnerHTML?: any
  heading?: boolean
  overflowHidden?: boolean
  oneLine?: boolean
  fontMono?: boolean
  lineClamp?: number
  role?: string
}

export const Typography = React.forwardRef(
  (
    {
      children = null,
      centered = false,
      onClick = () => {},
      size = theme.font.size.main,
      weight = '400',
      color = theme.color.black,
      className,
      dangerouslySetInnerHTML,
      heading = false,
      overflowHidden = false,
      oneLine = false,
      fontMono = false,
      lineClamp,
      role,
    }: TypographyProps,
    ref
  ) => {
    return (
      <StyledText
        fsize={size}
        fcolor={color}
        //@ts-ignore
        ref={ref}
        {...{
          role,
          overflowHidden,
          oneLine,
          lineClamp,
          heading,
          weight,
          className,
          onClick,
          fontMono,
          centered,
          dangerouslySetInnerHTML,
        }}
      >
        {children}
      </StyledText>
    )
  }
)

const StyledText = styled.div`
  color: ${prop('fcolor')};
  font-size: ${prop('fsize')}px;
  font-weight: ${prop('weight')};
  line-height: 1.4;
  ${ifProp(
    'lineClamp',
    css`
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${prop('lineClamp')};
      display: -webkit-box;
      overflow: hidden;
    `
  )}
  ${ifProp(
    'overflowHidden',
    `
  overflow: hidden;
  text-overflow: ellipsis;
  `
  )}
  ${ifProp(
    'fontMono',
    `
  font-family: 'Roboto Mono';
  letter-spacing: -0.8px;
  `
  )}
  ${ifProp('oneLine', 'white-space: nowrap;')}
  ${ifProp('heading', `font-family: "Gilroy";`)}
  ${ifProp('centered', `text-align: center;`)}
`
