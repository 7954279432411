/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'

import { theme } from 'theme'
import { Highlight } from 'ui/atoms/highlight'
import { Typography } from 'ui/atoms/typography'

export const Tabs = ({
  children,
  className,
  onChange = (id) => console.log(id),
  alignment = 'center',
  defaultActiveId,
  hasFlexBase,
}) => {
  const headers = React.Children.map(children, (child) => ({ title: child.props.title, id: child.props.id }))
  const [activeId, setActiveId] = useState(defaultActiveId ? defaultActiveId : headers[0].id)
  useEffect(() => {
    onChange(activeId)
  }, [activeId])
  return (
    <Container {...{ hasFlexBase }} className={className}>
      <Nav>
        <Headers alignment={alignment}>
          {headers.map((header) => (
            <Header
              data-test={`tab-${header.id}`}
              key={header.id}
              onClick={() => setActiveId(header.id)}
              active={activeId === header.id}
            >
              <StyledHighlight />
              <Typography weight="600">{header.title}</Typography>
            </Header>
          ))}
        </Headers>
      </Nav>
      <Content {...{ hasFlexBase }}>
        {React.Children.toArray(children).filter((child) => child.props.id === activeId)}
      </Content>
    </Container>
  )
}

const Nav = styled.nav``

const Headers = styled.ul`
  align-items: center;
  display: flex;
  justify-content: ${switchProp('alignment', {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
    'space-between': 'space-between',
  })};
  margin-bottom: 30px;
`
const StyledHighlight = styled(Highlight)``

const Header = styled.li`
  cursor: pointer;
  font-size: 18px;
  position: relative;
  transition: all 0.2s ease-in-out;
  &:not(:first-child) {
    margin-left: 30px;
  }
  &::before {
    background: ${theme.color.primary};
    border-radius: 1px;
    bottom: -10px;
    content: ' ';
    height: 0;
    opacity: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
  ${ifProp(
    'active',
    `
  &::before {
    opacity: 1;
    height: 3px;
  }
  ${StyledHighlight} {
    opacity: 1;
  }
  `,
    `
  &:hover {
    &::before {
      opacity: 0.5;
      height: 3px;
    }
    ${StyledHighlight} {
      opacity: 0.5;
    }
  }
  `
  )}
`

const Container = styled.div`
  ${ifProp(
    'hasFlexBase',
    css`
      display: flex;
      flex-direction: column;
      height: 100%;
    `
  )}
`

const Content = styled.div`
  height: 100%;
  width: 100%;
  ${ifProp('hasFlexBase', 'flex: 1;')}
`
