import { lighten, mix } from 'polished'

import { theme } from 'theme'

export const generateCushionProps = (cushionLevel) => {
  let lvl = (cushionLevel.current.absolute / cushionLevel.target.absolute).toFixed(2)
  let shadow = theme.color.red
  let startColor = theme.color.red
  let endColor = mix(0.3, theme.color.red, theme.color.yellow)
  if (lvl > 0.7) {
    shadow = theme.color.green
    startColor = mix(0.7, theme.color.yellow, theme.color.green)
    endColor = theme.color.green
  } else if (lvl > 0.3) {
    shadow = theme.color.yellow
    startColor = mix(0.2, theme.color.red, theme.color.yellow)
    endColor = mix(0.9, theme.color.yellow, theme.color.green)
  }
  if (lvl > 1) {
    lvl = 1
    shadow = theme.color.cyan
    startColor = theme.color.cyan
    endColor = lighten(0.2, theme.color.cyan)
  }
  const gradient = `linear-gradient(90deg, ${startColor} 0%, ${endColor} 100%)`
  let level = lvl * 100
  return {
    level,
    shadow,
    bg: gradient,
    startColor,
    endColor,
  }
}

export const generateStructureProps = (aggression) => {
  const colors = [
    theme.color.red,
    theme.color.green,
    theme.color.violet,
    theme.color.yellow,
    theme.color.blue,
  ]

  const levels = [...aggression]
    .sort((a, b) => Number(b.current.relative) - Number(a.current.relative))
    .map((part, idx, arr) => {
      let newPart = { ...part }
      if (!part.color) newPart.color = colors[idx]
      // let pos = arr[idx - 1]?.pos ? arr[idx - 1].pos : 0
      newPart.width = (part.current.relative * 100).toFixed(2)
      return newPart
    })
  let shadow = levels[0].color
  return {
    level: 100,
    shadow,
    levels,
  }
}
