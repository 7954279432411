import { lighten } from 'polished'
import React from 'react'
import ReactSelect from 'react-select'
import { components } from 'react-select'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import { Scrollbars } from 'ui/molecules/scrollbars'

export const Select = React.forwardRef(
  (
    {
      options = [],
      value,
      initialValue,
      onChange,
      onRawChange,
      color = theme.color.black,
      radius = theme.radius.button.large,
      className,
      disabled = false,
      searchable = true,
      placeholder = '',
      placeholderColor = hexToRGBA(theme.color.black, 0.5),
      borderColor = hexToRGBA(theme.color.black, 0.2),
      bgColor = 'transparent',
      padding = theme.padding.button.medium,
      optionBgColor = theme.color.darkgray,
      menuListHeight = 200,
      ...rest
    },
    ref
  ) => {
    const onSelect = (ev) => {
      if (onRawChange) {
        onRawChange(ev)
      } else {
        onChange(ev.value)
      }
    }
    const customStyles = {
      control: (provided, { isFocused }) => ({
        ...provided,
        borderRadius: radius,
        borderColor: !isFocused ? borderColor : color,
        '&:hover': {
          borderColor: !isFocused ? hexToRGBA(borderColor, 0.7) : hexToRGBA(color, 0.7),
        },
        background: bgColor,
        boxShadow: 'none',
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: padding,
        paddingRight: 0,
        color: color,
      }),
      placeholder: (provided) => ({
        ...provided,
        color: placeholderColor,
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0,
      }),
      indicatorsContainer: (provided) => {
        return {
          ...provided,
          padding,
          paddingLeft: 10,
        }
      },
      dropdownIndicator: (provided, { selectProps: { menuIsOpen } }) => ({
        ...provided,
        color: color,
        padding: 0,
        opacity: menuIsOpen ? 1 : 0.4,
        cursor: 'pointer',
        '&:hover': {
          color: color,
          opacity: menuIsOpen ? 0.8 : 0.6,
        },
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: radius,
        backgroundColor: optionBgColor,
      }),
      menuList: (provided) => ({ ...provided, padding: 0, borderRadius: radius }),
      singleValue: (provided, state) => ({ ...provided, color }),
      input: (provided, state) => ({ ...provided, color }),
      option: (provided, { isSelected }) => ({
        ...provided,
        padding: '10px 20px',
        color,
        opacity: 1,
        backgroundColor: isSelected ? theme.color.primary : optionBgColor,
        cursor: 'pointer',
        transition: 'all .15s ease-in-out',
        '&:hover': { color: theme.color.primary, background: lighten(0.03, optionBgColor) },
      }),
    }
    const MenuList = (props) => {
      return (
        <components.MenuList {...props}>
          <ScrollbarsContainer {...{ menuListHeight }}>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={menuListHeight}>
              {props.children}
            </Scrollbars>
          </ScrollbarsContainer>
        </components.MenuList>
      )
    }
    return (
      <StyledReactSelect
        styles={customStyles}
        value={value}
        initialValue={initialValue}
        options={options}
        onChange={onSelect}
        className={className}
        isDisabled={disabled}
        isSearchable={searchable}
        placeholder={placeholder}
        captureMenuScroll={false}
        maxMenuHeight={menuListHeight}
        noOptionsMessage={() => 'Nothing was found.'}
        components={{ MenuList, NoOptionsMessage }}
        {...{ ref }}
        {...rest}
      />
    )
  }
)

const NoOptionsMessage = (props) => {
  return (
    <CenteredContainer>
      <components.NoOptionsMessage {...props} />
    </CenteredContainer>
  )
}

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const ScrollbarsContainer = styled.div`
  height: ${prop('menuListHeight')}px;
`

const StyledReactSelect = styled(ReactSelect)``
