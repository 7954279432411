import React from 'react'
import styled, { css } from 'styled-components'
import { prop, switchProp } from 'styled-tools'

import { ArrowIcon, Price, PriceWithArrow } from 'features/game/field/molecules/price'
import { RiskCircle } from 'features/game/field/molecules/risk-circle'
import { formatPrice } from 'features/game/lib/prices/format'
import { getStatusDelta } from 'features/game/lib/prices/get-status-and-delta'
import {
  handleBankingType,
  handleIncomeAndExpensesGroup,
  handleInstrumentType,
  handleReactionType,
  handleTurnActionType,
} from 'lib/handle-fields'
import { hexToRGBA } from 'lib/hex-to-rgba'
import {
  // sortByDeltaAbsPriceValue,
  sortByDeltaPriceValue,
  sortByEffectOnSliders,
  sortByFlowBonusDuration,
  sortByPriceValue,
  sortByQty,
  sortByRisk,
  sortBySum,
} from 'lib/sort/custom-sorts'
import eye from 'static/eye.svg'
import { ReactComponent as SalaryIcon } from 'static/salary.svg'
import { theme } from 'theme'
import { Checkbox } from 'ui/atoms/checkbox'
import { Typography } from 'ui/atoms/typography'
import { FlippingNumbers } from 'ui/molecules/flipping-numbers'
import { Tooltip } from 'ui/molecules/tooltip'
import { CellText } from 'ui/organisms/table/cell-text'

const renderCell = (data, color) => {
  return (
    <CellText
      weight="500"
      size={theme.font.size.mediumsmall}
      color={color ? color : hexToRGBA(theme.color.black, 0.6)}
    >
      {data}
    </CellText>
  )
}

const renderCellGoodsServices = (data, row) => {
  let color = hexToRGBA(theme.color.black, 0.6)
  if (row.relatedDreamID) color = theme.color.primary
  if (row.relatedGoalID) color = theme.color.blue
  return renderCell(data, color)
}

const NameContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`

const CenteringContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

const TasksContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`

const EffectIcon = styled.img`
  object-fit: contain;
  width: 17px;
`

const EffectIconContainer = styled.div`
  & > svg {
    height: auto;
    width: 17px;
  }
  color: ${prop('color')};
`

const EffectTextIcon = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  ${switchProp('type', {
    flowBonus: css`
      color: ${theme.color.primary};
    `,
    endBonus: css`
      color: ${theme.color.pink};
    `,
  })}
`

const FONT_MONO = 'Roboto Mono'

const COL_RISK_TYPE = {
  key: 'risk',
  label: 'Risk',
  align: 'center',
  width: '35px',
  maxWidth: '35px',
  isSortable: true,
  sortFn: sortByRisk,
  Aggregated: () => {
    return (
      <CenteringContainer>
        <RiskCircle
          color={`linear-gradient(90deg, ${theme.color.green} 0%, ${theme.color.cyan} 50%, ${theme.color.red} 100%)`}
        />
      </CenteringContainer>
    )
  },
  render: (data) => (
    <CenteringContainer>
      <Tooltip content={<Typography size={theme.font.size.small}>{data.description}</Typography>}>
        <RiskCircle color={data.color} />
      </Tooltip>
    </CenteringContainer>
  ),
}

const COL_PRICE_WITH_ARROW = {
  key: 'price.current',
  label: 'Price',
  isSortable: true,
  font: FONT_MONO,
  minWidth: '100px',
  maxWidth: '130px',
  align: 'right',
  aggregate: 'median',
  Aggregated: ({ value }) => {
    return <PriceWithArrow noDecimals status="neutral" current={value} />
  },
  render: (_, { price: { current, previous } }) => {
    const { status } = getStatusDelta(current, previous)
    return <PriceWithArrow noDecimals {...{ status, current }} />
  },
}

const COL_EFFECT_ON_SLIDERS = {
  key: 'bonuses.effects.effectOnSliders',
  label: <EffectTextIcon>S</EffectTextIcon>,
  labelTooltip: 'Affects life expenses',
  width: '20px',
  align: 'center',
  isSortable: true,
  effectColumn: true,
  sortFn: sortByEffectOnSliders,
  render: (data) => (
    <>
      {data.hasEffect && (
        <Tooltip content={<Typography size={theme.font.size.small}>{data.description}</Typography>}>
          <EffectTextIcon>{data.slider.toUpperCase()}</EffectTextIcon>
        </Tooltip>
      )}
    </>
  ),
}

const COL_EFFECT_FLOWBONUS = {
  key: 'bonuses.effects.flowbonus.duration',
  label: <EffectTextIcon>F</EffectTextIcon>,
  labelTooltip: 'Affects flowbonus (duration in game steps)',
  width: '20px',
  align: 'center',
  isSortable: true,
  effectColumn: true,
  sortFn: sortByFlowBonusDuration,
  render: (data, row) => (
    <>
      {row.bonuses.effects.flowbonus.hasBonus && (
        <Tooltip
          content={
            <Typography size={theme.font.size.small}>{row.bonuses.effects.flowbonus.description}</Typography>
          }
        >
          <EffectTextIcon>{data ? data : 'F'}</EffectTextIcon>
        </Tooltip>
      )}
    </>
  ),
}

const COL_EFFECT_ENDBONUS = {
  key: 'bonuses.effects.endbonus.hasBonus',
  label: <EffectTextIcon>E</EffectTextIcon>,
  labelTooltip: 'Affects endbonus',
  width: '20px',
  align: 'center',
  isSortable: true,
  effectColumn: true,
  render: (data, row) => (
    <>
      {data && (
        <Tooltip
          content={
            <Typography size={theme.font.size.small}>{row.bonuses.effects.endbonus.description}</Typography>
          }
        >
          <EffectTextIcon>E</EffectTextIcon>
        </Tooltip>
      )}
    </>
  ),
}

const COL_EFFECT_DREAM = {
  key: 'bonuses.effects.dream.isDream',
  label: <EffectIcon src={eye} />,
  labelTooltip: 'Can be chosen as a dream',
  width: '20px',
  align: 'center',
  isSortable: true,
  effectColumn: true,
  render: (data) => (
    <>
      {data && (
        <Tooltip content={<Typography size={theme.font.size.small}>Can be chosen as a dream</Typography>}>
          <EffectIcon src={eye} />
        </Tooltip>
      )}
    </>
  ),
}

const COL_EFFECT_SALARY = {
  key: 'bonuses.effects.effectOnSalary.hasEffect',
  label: (
    <EffectIconContainer color={theme.color.green}>
      <SalaryIcon />
    </EffectIconContainer>
  ),
  labelTooltip: 'Affects salary/income/money flow',
  width: '20px',
  maxWidth: '20px',
  align: 'center',
  isSortable: true,
  effectColumn: true,
  render: (data, row) => {
    const color = row.bonuses.effects.effectOnSalary.type === 'positive' ? theme.color.green : theme.color.red
    return (
      <>
        {data && (
          <Tooltip
            content={
              <Typography size={theme.font.size.small}>
                {row.bonuses.effects.effectOnSalary.description}
              </Typography>
            }
          >
            <EffectIconContainer {...{ color }}>
              <SalaryIcon />
            </EffectIconContainer>
          </Tooltip>
        )}
      </>
    )
  },
}

const COL_DELTA = {
  key: 'delta',
  label: 'Change %',
  font: FONT_MONO,
  align: 'right',
  minWidth: '60px',
  width: '80px',
  maxWidth: '110px',
  isSortable: true,
  sortFn: sortByDeltaPriceValue,
  render: (_, { price: { current, previous } }) => {
    const { deltaNumber, deltaSign } = getStatusDelta(current, previous)
    return (
      <Typography
        weight="500"
        size={theme.font.size.mediumsmall}
        color={
          deltaSign
            ? (deltaSign === '+'
              ? theme.color.green
              : theme.color.red)
            : hexToRGBA(theme.color.black, 0.6)
        }
      >
        <FlippingNumbers notCurrency prefix={deltaSign} postfix="%">
          {deltaNumber}
        </FlippingNumbers>
      </Typography>
    )
  },
}

const COL_DIVIDENDS = {
  key: 'dividends',
  label: 'Divs',
  labelTooltip: 'Dividends',
  font: FONT_MONO,
  isSortable: true,
  align: 'right',
  minWidth: '52px',
  width: '70px',
  maxWidth: '110px',
  render: (data) => {
    return renderCell(<FlippingNumbers>{data}</FlippingNumbers>)
  },
}

const COL_QTY = {
  key: 'qty',
  label: 'Owned',
  font: FONT_MONO,
  minWidth: '50px',
  maxWidth: '80px',
  aggregate: 'sum',
  width: '60px',
  isSortable: true,
  sortFn: sortByQty,
  align: 'right',
  render: (data) => {
    return data
      ? renderCell(
          <FlippingNumbers noDecimals notCurrency>
            {data}
          </FlippingNumbers>
        )
      : null
  },
}

const COL_QTY_PRICE = {
  key: 'qtyPrice',
  label: 'Cost',
  font: FONT_MONO,
  minWidth: '85px',
  width: '90px',
  maxWidth: '110px',
  align: 'right',
  isSortable: true,
  sortFn: sortBySum,
  render: (_, row) => {
    return row.qty
      ? renderCell(<FlippingNumbers noDecimals>{row.qty * row.price.current}</FlippingNumbers>)
      : null
  },
}

const COL_MRKT_NAME = {
  key: 'name',
  label: 'Name',
  isSortable: true,
  minWidth: '140px',
  width: '150px',
  maxWidth: '700px',
  largeMinWidth: '33%',
  largeMaxWidth: '700px',
  render: (data, row) => {
    return (
      <CellText color={hexToRGBA(theme.color.black, 1)} weight="600" size={theme.font.size.mediumsmall}>
        {data}
      </CellText>
    )
  },
}

const COL_INTEREST = {
  key: 'rate',
  label: 'Interest',
  isSortable: true,
  align: 'right',
  font: FONT_MONO,
  aggregate: 'median',
  width: '62px',
  render: (data) => {
    return renderCell(
      <FlippingNumbers postfix="%" notCurrency>
        {data * 100}
      </FlippingNumbers>
    )
  },
}

const COL_EXPIRATION_TURN = {
  key: 'minYears',
  label: 'Mat.',
  labelTooltip: 'Maturity date',
  align: 'center',
  minWidth: '50px',
  width: '60px',
  maxWidth: '65px',
  font: FONT_MONO,
  isSortable: true,
  render: (data) =>
    renderCell(
      <FlippingNumbers noDecimals notCurrency>
        {data}
      </FlippingNumbers>
    ),
}

// const COL_DELTA_ABS = {
//   key: 'deltaAbs',
//   label: 'Delta Abs',
//   font: FONT_MONO,
//   isSortable: true,
//   align: 'right',
//   sortFn: sortByDeltaAbsPriceValue,
//   render: (_, { price: { current, previous } }) => {
//     const { deltaAbsNumber, deltaSign, status } = getStatusDelta(current, previous)
//     return (
//       <Price status={status}>
//         <FlippingNumbers prefix={deltaSign} notCurrency>
//           {deltaAbsNumber}
//         </FlippingNumbers>
//       </Price>
//     )
//   },
// }

export const COLUMNS = {
  stockMarket: {
    stocks: [
      COL_RISK_TYPE,
      COL_MRKT_NAME,
      { ...COL_PRICE_WITH_ARROW, width: '80px', maxWidth: '110px' },
      COL_DELTA,
      COL_DIVIDENDS,
      COL_QTY,
      COL_QTY_PRICE,
      // COL_POINTS,
    ],
    bonds: [
      COL_RISK_TYPE,
      COL_MRKT_NAME,
      { ...COL_PRICE_WITH_ARROW, minWidth: '80px' },
      COL_DELTA,
      COL_INTEREST,
      COL_EXPIRATION_TURN,
      COL_QTY,
      COL_QTY_PRICE,
      // COL_POINTS,
    ],
    onHandBonds: [
      COL_RISK_TYPE,
      COL_MRKT_NAME,
      { ...COL_PRICE_WITH_ARROW, minWidth: '80px' },
      COL_INTEREST,
      COL_EXPIRATION_TURN,
      COL_QTY,
      COL_QTY_PRICE,
    ],
    indicies: [
      COL_RISK_TYPE,
      { ...COL_MRKT_NAME, minWidth: '160px', maxWidth: '1000px', width: '180px' },
      COL_PRICE_WITH_ARROW,
      COL_DELTA,
      COL_QTY,
      COL_QTY_PRICE,
      // COL_POINTS,
    ],
    commodities: [
      COL_RISK_TYPE,
      COL_MRKT_NAME,
      COL_PRICE_WITH_ARROW,
      COL_DELTA,
      COL_QTY,
      COL_QTY_PRICE,
      // COL_POINTS,
    ],
    etf: [
      COL_RISK_TYPE,
      COL_MRKT_NAME,
      COL_PRICE_WITH_ARROW,
      COL_DELTA,
      COL_DIVIDENDS,
      COL_QTY,
      COL_QTY_PRICE,
      // COL_POINTS,
    ],
    crypto: [
      COL_RISK_TYPE,
      COL_MRKT_NAME,
      { ...COL_PRICE_WITH_ARROW, width: '80px', maxWidth: '110px' },
      COL_DELTA,
      COL_QTY,
      COL_QTY_PRICE,
    ],
  },
  dreamsAndGoals: {
    goals: [
      {
        key: 'name',
        label: 'Name',
        isSortable: true,
        minWidth: '120px',
        maxWidth: '1000px',
        render: (data, row) =>
          renderCell(data, row.status === 'ready' ? theme.color.green : hexToRGBA(theme.color.black, 0.6)),
      },
      {
        key: 'tasks',
        label: 'Tasks',
        isSortable: true,
        minWidth: '70px',
        maxWidth: '120px',
        render: (data, row) => {
          return (
            <TasksContainer>
              {data.map(({ status }, idx) => (
                <Checkbox key={idx} checked={status === 'ready'} />
              ))}
            </TasksContainer>
          )
        },
      },
      {
        key: 'sum',
        label: 'Sum',
        isSortable: true,
        minWidth: '40px',
        maxWidth: '90px',
        align: 'right',
        font: FONT_MONO,
        render: (data) => renderCell(formatPrice(data)),
      },
      {
        key: 'flowPoints',
        label: 'Flowpoints',
        width: '70px',
        font: FONT_MONO,
        align: 'right',
        isSortable: true,
        render: (data) =>
          renderCell(
            <FlippingNumbers noDecimals notCurrency>
              {data}
            </FlippingNumbers>
          ),
      },
    ],
  },
  goodsAndServices: {
    goods: [
      {
        key: 'name',
        label: 'Name',
        minWidth: '200px',
        maxWidth: '900px',
        isSortable: true,
        render: renderCellGoodsServices,
      },
      COL_EFFECT_SALARY,
      COL_EFFECT_DREAM,
      COL_EFFECT_FLOWBONUS,
      COL_EFFECT_ENDBONUS,
      COL_EFFECT_ON_SLIDERS,
      COL_PRICE_WITH_ARROW,
      // {
      //   key: 'cashFlow',
      //   label: 'Cash Flow',
      //   minWidth: '70px',
      //   maxWidth: '130px',
      //   font: FONT_MONO,
      //   align: 'right',
      //   render: () => renderCell(),
      // },
      // COL_DELTA_ABS,
      // COL_DELTA,
    ],
    services: [
      {
        key: 'name',
        label: 'Name',
        minWidth: '200px',
        maxWidth: '900px',
        isSortable: true,
        render: renderCellGoodsServices,
      },
      COL_EFFECT_SALARY,
      COL_EFFECT_DREAM,
      COL_EFFECT_FLOWBONUS,
      COL_EFFECT_ENDBONUS,
      COL_EFFECT_ON_SLIDERS,
      { ...COL_PRICE_WITH_ARROW, minWidth: '90px', width: '100px', maxWidth: '130px' },
      // {
      //   key: 'cashFlow',
      //   label: 'Cash Flow',
      //   minWidth: '60px',
      //   width: '80px',
      //   maxWidth: '120px',
      //   font: FONT_MONO,
      //   align: 'right',
      //   render: () => renderCell(),
      // },
    ],
  },
  actionsHistory: [
    {
      key: 'turn',
      label: '',
      width: '40px',
      isSortable: false,
      render: () => null,
    },
    {
      ...COL_MRKT_NAME,
      minWidth: '150px',
      Aggregated: ({
        row: {
          values: { turn },
        },
      }) => `Turn ${turn}`,
    },
    {
      key: 'reaction',
      label: 'Reaction',
      minWidth: '60px',
      render: (data, row) => renderCell(data, handleReactionType(row?.reactionType)),
    },
    {
      key: 'consequence',
      label: 'Consequence',
      minWidth: '150px',
      render: (data) => renderCell(data),
    },
  ],
  pointsHistory: [
    {
      key: 'turn',
      label: '',
      width: '40px',
      isSortable: false,
      render: () => null,
    },
    {
      ...COL_MRKT_NAME,
      minWidth: '200px',
      width: '220px',
      maxWidth: '400px',
      render: (data, row) => renderCell(data, handleReactionType(row.type)),
      Aggregated: ({
        row: {
          values: { turn },
        },
      }) => `Turn ${turn}`,
    },
    {
      key: 'flowPoints',
      label: 'Flowpoints',
      align: 'right',
      minWidth: '90px',
      maxWidth: '120px',
      aggregate: 'sum',
      font: FONT_MONO,
      render: (data) => renderCell(formatPrice(data, { disableCurrency: true })),
    },
    {
      key: 'totalPoints',
      label: 'Total points',
      align: 'right',
      minWidth: '90px',
      maxWidth: '120px',
      aggregate: 'max',
      font: FONT_MONO,
      Aggregated: ({ value }) => renderCell(formatPrice(value, { disableCurrency: true })),
      render: () => null,
    },
  ],
  turnActions: [
    {
      key: 'order',
      label: 'Order',
      width: '60px',
      align: 'center',
      isSortable: true,
      render: (data) => renderCell(data + 1),
    },
    {
      key: 'name',
      label: 'Name',
      minWidth: '160px',
      maxWidth: '900px',
      isSortable: true,
      render: (data) => {
        return renderCell(data)
      },
    },
    {
      key: 'qty',
      label: 'Owned',
      minWidth: '60px',
      maxWidth: '90px',
      font: FONT_MONO,
      isSortable: true,
      align: 'center',
      render: (data, row) => {
        return data
          ? renderCell(
              <FlippingNumbers noDecimals notCurrency>
                {data}
              </FlippingNumbers>
            )
          : null
      },
    },
    {
      key: 'type',
      label: 'Type',
      isSortable: true,
      width: '70px',
      align: 'center',
      render: (data, row) => {
        return data ? renderCell(handleTurnActionType(data)) : null
      },
    },
    {
      key: 'sum',
      label: 'Sum',
      isSortable: true,
      font: FONT_MONO,
      align: 'right',
      minWidth: '100px',
      maxWidth: '500px',
      render: (data, { type }) => {
        return renderCell(
          <FlippingNumbers prefix={`${type === 'buy' ? '+' : '-'} $ `}>{data}</FlippingNumbers>
        )
      },
    },
  ],
  events: [
    {
      key: 'name',
      label: 'Name',
      // isSortable: true,
      render: (data) => {
        return renderCell(data)
      },
    },
  ],
  incomeAndExpenses: [
    {
      key: 'group',
      label: '',
      isSortable: false,
      width: '30px',
      render: () => null,
    },
    {
      key: 'name',
      label: 'Name',
      isSortable: true,
      minWidth: '140px',
      width: '300px',
      maxWidth: '1000px',
      Aggregated: ({ row }) => handleIncomeAndExpensesGroup(row.values.group),
      render: (data) => renderCell(data),
    },
    {
      key: 'sum',
      label: 'Amount',
      minWidth: '115px',
      maxWidth: '205px',
      font: FONT_MONO,
      isSortable: true,
      // sortFn: sortWithAbs,
      align: 'right',
      aggregate: 'sum',
      render: (data) => {
        return renderCell(
          <FlippingNumbers noDecimals>{data}</FlippingNumbers>,
          data > 0 ? theme.color.green : theme.color.red
        )
      },
    },
  ],
  banking: {
    all: [
      COL_RISK_TYPE,
      {
        key: 'name',
        label: 'Name',
        isSortable: true,
        minWidth: '140px',
        maxWidth: '1000px',
        render: (data) => renderCell(data),
      },
      {
        key: 'rate',
        label: 'Rate',
        width: '40px',
        font: FONT_MONO,
        isSortable: true,
        align: 'right',
        render: (data) => (
          <FlippingNumbers notCurrency postfix="%">
            {data * 100}
          </FlippingNumbers>
        ),
      },
    ],
    deposits: [
      COL_RISK_TYPE,
      {
        key: 'name',
        label: 'Name',
        isSortable: true,
        minWidth: '140px',
        maxWidth: '1000px',
        render: (data) => renderCell(data),
      },
      {
        key: 'rate',
        label: 'Rate',
        font: FONT_MONO,
        width: '40px',
        align: 'right',
        isSortable: true,
        render: (data) => (
          <FlippingNumbers notCurrency postfix="%">
            {data * 100}
          </FlippingNumbers>
        ),
      },
    ],
  },
  assetsAndLiabilities: {
    assets: [
      {
        key: 'subType',
        label: 'Type',
        isSortable: true,
        width: '70px',
        maxWidth: '140px',
        render: (data, row) => renderCell(handleInstrumentType(data)),
      },
      COL_RISK_TYPE,
      {
        key: 'name',
        label: 'Name',
        minWidth: '100px',
        maxWidth: '900px',
        isSortable: true,
        Aggregated: () => 'Expand to see',
        render: (data) => renderCell(data),
      },
      {
        key: 'years',
        label: 'Expr.',
        labelTooltip: 'Expires in',
        isSortable: true,
        align: 'center',
        width: '50px',
        aggregate: 'median',
        render: (data) =>
          data && data !== 0
            ? renderCell(
                <FlippingNumbers noDecimals notCurrency>
                  {data}
                </FlippingNumbers>
              )
            : null,
      },
      {
        key: 'leftTurns',
        label: 'Left',
        width: '45px',
        align: 'center',
        aggregate: 'median',
        isSortable: true,
        render: (data) =>
          renderCell(
            data && data !== 0 ? (
              <FlippingNumbers noDecimals notCurrency>
                {data}
              </FlippingNumbers>
            ) : null
          ),
      },
      { ...COL_PRICE_WITH_ARROW, minWidth: '80px', width: '100px', maxWidth: '130px' },
      // {
      //   key: 'averageGain.dividends.relative',
      //   isVisible: false,
      //   label: '',
      //   width: '0px',
      //   render: (data) => null,
      // },
      // {
      //   key: 'averageGain.capital.relative',
      //   isVisible: false,
      //   label: '',
      //   width: '0px',
      //   render: (data) => null,
      // },
      {
        key: 'averageGain.sum.relative',
        label: 'Gain',
        labelTooltip: 'Total gain/loss for the whole time of owning the instrument',
        minWidth: '50px',
        width: '60px',
        maxWidth: '80px',
        align: 'right',
        isSortable: true,
        aggregate: 'median',
        render: (data, row) => {
          return renderCell(
            <Price status={data === 0 ? 'neutral' : (data > 0 ? 'up' : 'down')}>
              {/* {data > 10000 ? (
                 'A lot of'
               ) : ( */}
              <FlippingNumbers noDecimals notCurrency postfix="%">
                {data * 100}
              </FlippingNumbers>
              {/* )} */}
            </Price>
          )
        },
      },
      COL_QTY,
      {
        key: 'totalSum',
        label: 'Total sum',
        minWidth: '85px',
        width: '100px',
        maxWidth: '150px',
        font: FONT_MONO,
        aggregate: 'sum',
        isSortable: true,
        align: 'right',
        render: (data) => {
          return renderCell(<FlippingNumbers noDecimals>{data}</FlippingNumbers>)
        },
      },
    ],
    liabilities: [
      {
        key: 'instrumentType',
        label: 'Type',
        width: '70px',
        maxWidth: '140px',
        isSortable: true,
        render: (data) => renderCell(handleBankingType(data)),
      },
      {
        key: 'name',
        label: 'Name',
        minWidth: '130px',
        maxWidth: '1000px',
        Aggregated: () => 'Expand to see',
        isSortable: true,
        render: (data) => renderCell(data),
      },
      COL_INTEREST,
      {
        key: 'leftTurns',
        label: 'Turns left',
        isSortable: true,
        width: '50px',
        maxWidth: '75px',
        aggregate: 'median',
        align: 'center',
        render: (data) => renderCell(data),
      },
      {
        key: 'totalSum',
        label: 'Sum',
        font: FONT_MONO,
        minWidth: '90px',
        maxWidth: '150px',
        isSortable: true,
        aggregate: 'sum',
        align: 'right',
        render: (_, row) => {
          return renderCell(<FlippingNumbers>{row.price.current * row.qty}</FlippingNumbers>)
        },
      },
    ],
  },
  REBusiness: {
    realEstate: [
      {
        key: 'name',
        label: 'Name',
        isSortable: true,
        minWidth: '200px',
        maxWidth: '1000px',
        render: (data, { isLivingHere }) => (
          <NameContainer>
            <CellText
              color={isLivingHere ? theme.color.primary : hexToRGBA(theme.color.black, 1)}
              weight="600"
              size={theme.font.size.mediumsmall}
            >
              {data}
            </CellText>
            {/* {isLivingHere && <StockImg src={houseIcon} />} */}
          </NameContainer>
        ),
      },
      {
        key: 'price',
        label: 'Market price',
        font: FONT_MONO,
        isSortable: true,
        sortFn: sortByPriceValue,
        align: 'right',
        render: ({ current, previous }) => {
          const { status } = getStatusDelta(current, previous)
          return (
            <Price status={status}>
              {status !== 'neutral' && <ArrowIcon isUp={status === 'up'} />}
              <FlippingNumbers noDecimals>{current}</FlippingNumbers>
              {/* {delta}
              {deltaAbs && `(${deltaAbs})`} */}
            </Price>
          )
        },
      },
      {
        key: 'rentPrice',
        label: 'Rent price',
        isSortable: true,
        font: FONT_MONO,
        sortFn: sortByPriceValue,
        align: 'right',
        render: ({ current, previous }) => {
          const { status } = getStatusDelta(current, previous)
          return (
            <Price status={status}>
              {status !== 'neutral' && <ArrowIcon isUp={status === 'up'} />}
              <FlippingNumbers noDecimals>{current}</FlippingNumbers>
              {/* {delta}
              {deltaAbs && `(${deltaAbs})`} */}
            </Price>
          )
        },
      },
    ],
  },
}
