import 'features/error/lib/logger/console'
import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App.tsx'
import * as serviceWorker from './serviceWorker'

import './index.css'

if (process.env.REACT_APP_DEV !== 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    beforeBreadcrumb(breadcrumb, hint) {
      const { data, category } = breadcrumb
      if (category === 'console' && data.logger === 'console' && data.arguments.length === 4) return null
      return breadcrumb
    },
  })
}

ReactDOM.render(<App />, document.querySelector('#root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
