import { useStore } from 'effector-react'
import humanizeDuration from 'humanize-duration'
import React from 'react'
import styled from 'styled-components'

import { $room, $roomStatus } from 'features/rooms/model'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Typography } from 'ui/atoms/typography'

import { RoomTimer } from '../../timer'

export const RoomRules = ({ onNext }) => {
  const session = useStore($room)
  const roomStatus = useStore($roomStatus)
  const { startDate, finishDate, turnQty, description } = session
  return (
    <>
      <Columns>
        <InfoContainer>
          {true && (
            <Item>
              <Label weight="600">Session is available during:</Label>
              <Value>
                {humanizeDuration(finishDate - startDate, {
                  round: true,
                  units: ['h', 'm', 'mo', 'd'],
                  conjunction: ' and ',
                })}
              </Value>
            </Item>
          )}
          {turnQty && (
            <Item>
              <Label weight="600">Number of game years (available turns):</Label>
              <Value>{turnQty}</Value>
            </Item>
          )}
          {!!(description?.length > 0) && (
            <Item>
              <Label weight="600">Description:</Label>
              <Value dangerouslySetInnerHTML={{ __html: description }} />
            </Item>
          )}
        </InfoContainer>
        {roomStatus && (
          <RoomTimer status={roomStatus.status} difference={roomStatus.difference} finishDate={finishDate} />
        )}
      </Columns>
      <Delimiter />
      <ButtonContainer>
        <RegisterButton disabled={roomStatus?.status === 'finished'} onClick={onNext}>
          Registration
        </RegisterButton>
      </ButtonContainer>
    </>
  )
}

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

const RegisterButton = styled(Button)`
  min-width: 400px;
`

const Delimiter = styled.hr`
  background: ${theme.color.secondary};
  border: none;
  box-sizing: border-box;
  height: 1px;
  margin: 40px 0px;
  width: 100%;
`

const Item = styled.li`
  margin: 15px 0;
`

const Value = styled(Typography)`
  margin-top: 5px;
`

const Label = styled(Typography)``

const Columns = styled.div`
  display: flex;
`
const InfoContainer = styled.ul`
  flex: 1;
  margin-right: 150px;
`
