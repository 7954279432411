import { getSubjectInstrument } from 'features/game/lib/get-subject-instrument'
import { getStatusDelta } from 'features/game/lib/prices/get-status-and-delta'

import { defaultSort } from '../default-sort'

export const sortWithAbs = ({ a, b, order }) => {
  return order === 'asc' ? Math.abs(a) - Math.abs(b) : Math.abs(b) - Math.abs(a)
}

export const sortGoodsByFlowPoints = ({ a, b, order }) => {
  const flowBonusA = a.qty > 0 ? a.bonuses.selling.flowBonus : a.bonuses.buying.flowBonus
  const flowBonusB = b.qty > 0 ? b.bonuses.selling.flowBonus : b.bonuses.buying.flowBonus
  const priceA = a.price.value
  const priceB = b.price.value
  return order === 'asc'
    ? flowBonusA * priceA - flowBonusB * priceB
    : flowBonusB * priceB - flowBonusA * priceA
}

export const sortGoodsByFlowbonus = ({ a, b, order }) => {
  const flowBonusA = a.qty > 0 ? a.bonuses.selling.flowBonus : a.bonuses.buying.flowBonus
  const flowBonusB = b.qty > 0 ? b.bonuses.selling.flowBonus : b.bonuses.buying.flowBonus
  return order === 'asc' ? flowBonusA - flowBonusB : flowBonusB - flowBonusA
}

export const sortByPriceValue = ({ a, b, order, orderBy }) =>
  order === 'asc' ? a[orderBy].current - b[orderBy].current : b[orderBy].current - a[orderBy].current

export const sortByQty = ({ a, b, order, orderBy }) => {
  if (!a[orderBy]) return order === 'asc' ? -1 : 1
  if (!b[orderBy]) return order === 'asc' ? 1 : -1
  return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]
}

export const sortBySum = ({ a, b, order, orderBy }) => {
  const sumA = a.price.current * a.qty
  const sumB = b.price.current * b.qty
  return order === 'asc' ? sumA - sumB : sumB - sumA
}

export const sortByEffectOnSliders = ({ a, b, order, orderBy }) => {
  const sliderA = a.bonuses.effects.effectOnSliders.hasEffect ? a.bonuses.effects.effectOnSliders.slider : ''
  const sliderB = b.bonuses.effects.effectOnSliders.hasEffect ? b.bonuses.effects.effectOnSliders.slider : ''
  return order === 'asc' ? sliderA.localeCompare(sliderB) : -sliderA.localeCompare(sliderB)
}

export const sortByFlowBonusDuration = ({ a, b, order, orderBy }) => {
  const durationA = a.bonuses.effects.flowbonus.duration || 0
  const durationB = b.bonuses.effects.flowbonus.duration || 0
  return order === 'asc' ? durationA - durationB : durationB - durationA
}

export const sortBySumWithRelatedInstrument = ({ a, b, order, orderBy }) => {
  const relatedInstrumentA = getSubjectInstrument(a.id, a.instrumentType)
  const relatedInstrumentB = getSubjectInstrument(b.id, b.instrumentType)
  const qtyA = a.value
  const qtyB = b.value
  if (relatedInstrumentA && relatedInstrumentB) {
    const priceA = relatedInstrumentA.price.current * (a.actionType === 'buy' ? 1 : -1)
    const priceB = relatedInstrumentA.price.current * (b.actionType === 'buy' ? 1 : -1)
    const sumA = priceA * qtyA
    const sumB = priceB * qtyB
    return order === 'asc' ? sumA - sumB : sumB - sumA
  } else {
    return 0
  }
}

/**
 * Функция сортирует по полю, указанному в orderBy ИНСТРУМЕНТА, найденного по айди и типу, находящегося внутри сущности
 * @param {Function} sortFn - кастомная функция сортировки
 */

export const sortByRelatedInstrumentField = (sortFn) => ({ a, b, order, orderBy }) => {
  const relatedInstrumentA = getSubjectInstrument(a.id, a.instrumentType)
  const relatedInstrumentB = getSubjectInstrument(b.id, b.instrumentType)
  if (relatedInstrumentA && relatedInstrumentB) {
    return sortFn
      ? sortFn({ order, orderBy, a: relatedInstrumentA, b: relatedInstrumentB })
      : defaultSort({ order, orderBy })(relatedInstrumentA, relatedInstrumentB)
  } else {
    return 0
  }
}

export const sortByTurns = (a, b) => {
  return b.turn - a.turn
}

export const sortByPoints = ({ a, b, order }) => (order === 'asc' ? a.points - b.points : b.points - a.points)

export const sortByReady = (a, b) => {
  if (a.status === 'ready' && b.status !== 'ready') return -1
  if (a.status !== 'ready' && b.status === 'ready') return 1
  return 0
}

const getNumByRisk = (riskType) => {
  switch (riskType) {
    case 'high':
      return 3
    case 'mid':
      return 2
    case 'low':
      return 1
    default:
      return 2
  }
}

export const sortByRisk = ({ a, b, order, orderBy }) => {
  const ARisk = getNumByRisk(a[orderBy].type)
  const BRisk = getNumByRisk(b[orderBy].type)
  if (ARisk === BRisk) {
    return b.price?.current - a.price?.current
  }
  return order === 'asc' ? ARisk - BRisk : BRisk - ARisk
}

export const sortDreams = (a, b) => {
  const AMotivation = a.motivation
  const BMotivation = b.motivation
  const APrice = a.sum
  const BPrice = b.sum
  if (AMotivation === BMotivation) {
    return BPrice - APrice
  }
  return BMotivation - AMotivation
}

export const sortByDeltaPriceValue = ({ a, b, order }) => {
  const ADelta = Number(getStatusDelta(a.price.current, a.price.previous).delta.slice(0, -1))
  const BDelta = Number(getStatusDelta(b.price.current, b.price.previous).delta.slice(0, -1))
  return order === 'asc' ? ADelta - BDelta : BDelta - ADelta
}

export const sortByDeltaAbsPriceValue = ({ a, b, order }) => {
  const ADelta = Number(
    getStatusDelta(a.price.current, a.price.previous).deltaAbs.slice(1).replace(/ /gi, '')
  )
  const BDelta = Number(
    getStatusDelta(b.price.current, b.price.previous).deltaAbs.slice(1).replace(/ /gi, '')
  )
  return order === 'asc' ? ADelta - BDelta : BDelta - ADelta
}
