import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { Divider } from 'features/game/field/atoms/divider'
import { formatPrice } from 'features/game/lib/prices/format'
import { theme } from 'theme'
import { GaugeChart } from 'ui/atoms/chart/gauge'
import { PieChart } from 'ui/atoms/chart/pie'
import { Typography } from 'ui/atoms/typography'
import { FlippingNumbers } from 'ui/molecules/flipping-numbers'
import { TextTable } from 'ui/organisms/text-table'
import { OverflowTooltip } from 'ui/molecules/overflow-tooltip'
import { RatioTable } from './ratio-table'
import { isFirefox } from 'react-device-detect'

export const TopBarDetails = React.memo(({ isDown, bonuses, balance }) => {
  const chartData = {
    data: balance.aggression.map((el) => el.current.absolute),
    labels: balance.aggression.map((el) => el.description),
    colors: balance.aggression.map((el) => el.color || theme.color.primary),
    targetRelativeValues: balance.aggression.map((el) => el.target.relative * 100),
  }
  const percentage = (balance.cushion.current.absolute / balance.cushion.target.absolute) * 100
  const percentageOfCushion =
    percentage > 100 ? "It's OK" : `${formatPrice(percentage, { disableCurrency: true })}%`
  return (
    <Container {...{ isDown }}>
      <Divider />
      <Inner>
        <Cell>
          <StyledTextTable headerRow={['Portfolio structure', '']} rows={[]} />
          <StyledPieChart {...chartData} height={160} />
        </Cell>
        <Cell>
          <StyledTextTable headerRow={['Safety cushion', '']} rows={[]} />
          {!isFirefox && <StyledGaugeChart level={balance.cushion} height={300} />}
          <TextContainer topAlignment={isFirefox}>
            <Percentage size={theme.font.size.large} weight="600">
              {percentageOfCushion}
            </Percentage>
            <TextTable
              labelTextAlign="left"
              valueTextAlign="right"
              valueFlex
              valueMonoFont
              rows={[
                [
                  'Current:',
                  <OverflowTooltip>
                    <FlippingNumbers>{balance.cushion.current.absolute}</FlippingNumbers>
                  </OverflowTooltip>,
                ],
                [
                  'Target:',
                  <OverflowTooltip>
                    <FlippingNumbers>{balance.cushion.target.absolute}</FlippingNumbers>
                  </OverflowTooltip>,
                ],
              ]}
            />
          </TextContainer>
        </Cell>
        <Cell maxWidth="185px" flex>
          <StyledTextTable
            marginRows={7}
            labelTextAlign="left"
            valueTextAlign="right"
            headerRow={['Points', '']}
            marginCols={20}
            rows={[
              [
                'Flowpoints:',
                <OverflowTooltip maxWidth={100}>
                  <FlippingNumbers notCurrency>{balance.flowPoints.current}</FlippingNumbers>
                </OverflowTooltip>,
              ],
              [
                'Endpoints:',
                <OverflowTooltip maxWidth={100}>
                  <FlippingNumbers notCurrency>{balance.endPoints.current}</FlippingNumbers>
                </OverflowTooltip>,
              ],
              [
                'Total points:',
                <OverflowTooltip maxWidth={100}>
                  <FlippingNumbers notCurrency>{balance.points.current}</FlippingNumbers>
                </OverflowTooltip>,
              ],
            ]}
          />
        </Cell>
        <Cell flex>
          <RatioTable data={bonuses.flowBonus} type="flowBonus" />
        </Cell>
        <Cell flex>
          <RatioTable data={bonuses.endBonus} type="endBonus" />
        </Cell>
      </Inner>
    </Container>
  )
})

const Percentage = styled(Typography)`
  margin-bottom: 10px;
`

const TextContainer = styled.div`
  bottom: -2px;
  ${ifProp(
    'topAlignment',
    `
    bottom: 10px;
  `
  )}
  left: 10%;
  position: absolute;
  text-align: center;
  width: 70%;
`

const StyledGaugeChart = styled(GaugeChart)`
  flex: 1;
  margin-right: -75px;
`

const StyledPieChart = styled(PieChart)`
  flex: 1;
  margin-right: -20px;
`

const Cell = styled.div`
  ${ifProp('flex', 'flex: 1;')}
  display: flex;
  flex-direction: column;
  margin: 0 14px;
  max-width: ${prop('maxWidth', 'unset')};
  min-width: 180px;
  position: relative;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:nth-of-type(2) {
    margin: 0;
  }
`

const Inner = styled.div`
  display: flex;
  height: 180px;
  overflow: hidden;
  width: 100%;
`

const Container = styled.div`
  transition: all 0.3s ease-in-out;
  width: 100%;
  ${ifProp(
    'isDown',
    css`
      height: 200px;
      opacity: 1;
      padding-bottom: ${theme.padding.medium / 2}px;
    `,
    css`
      height: 0px;
      opacity: 0;
      padding: 0;
    `
  )}
`

const StyledTextTable = styled(TextTable)`
  & ${Typography} {
    width: 100%;
  }
  & > *:last-of-type {
    flex: 1;
    font-family: 'Roboto Mono';
    font-size: 13px;
    letter-spacing: -0.8px;
    margin-left: 10px;
  }
`
