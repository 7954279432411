import React from 'react'

import { LoginForm } from 'features/auth'
import { FullContainer } from 'ui/atoms/full-centered-container'

export const LoginPage = () => {
  return (
    <FullContainer>
      <LoginForm />
    </FullContainer>
  )
}
