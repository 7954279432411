import styled from 'styled-components'
import { ifProp } from 'styled-tools'

export const SettingsLabel = styled.div`
  flex: 1;
  ${ifProp('noFlex', 'flex: 0;')}
`

export const SettingsValue = styled.div`
  flex: 2;
  margin-left: 20px;
`

export const SettingsCardRow = styled.div`
  align-items: center;
  display: flex;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`
