import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { Divider } from 'features/game/field/atoms/divider'
import { TabInnerHeading } from 'features/game/field/atoms/tab-inner-heading'
import { TabInnerSection } from 'features/game/field/atoms/tab-inner-section'
import { useNonLinearSliders } from 'features/game/lib/hooks/use-non-linear-sliders'
import { formatPrice } from 'features/game/lib/prices/format'
import { $profile, changeFinancialSettings, changeFinancialSettingsFetching } from 'features/game/model'
import {
  SettingsCardRow,
  SettingsLabel,
  SettingsValue,
} from 'features/rooms/details/stepper/settings/molecules/financial-settings'
import { theme } from 'theme'
import { ActionButton } from 'ui/atoms/button/action-button'
import { Error } from 'ui/atoms/form'
import { Slider } from 'ui/atoms/slider'
import { Typography } from 'ui/atoms/typography'
import { TextTable } from 'ui/organisms/text-table'
import { BalanceBar } from '../balance-bar'

export const FinancialSettings = () => {
  const profile = useStore($profile)
  const profileProfit = profile?.profile?.balance?.profit.current
  const loadingSaving = useStore(changeFinancialSettingsFetching.isLoading)
  const loadingError = useStore(changeFinancialSettingsFetching.error)
  const { changeSliderLevel, slidersValues, slidersLevels } = useNonLinearSliders({
    initialSettings: profile?.profile?.financialSettings,
  })
  const onSave = () => {
    changeFinancialSettings({
      characterSettings: slidersValues,
    })
  }
  return (
    <>
      <TabInnerSection small>
        <TabInnerHeading noMb>Financial settings</TabInnerHeading>
      </TabInnerSection>
      <Divider />
      {profile && (
        <>
          <TabInnerSection small>
            <Profit
              valueTextAlign="right"
              labelTextAlign="left"
              rows={[['Annual profit:', formatPrice(profileProfit)]]}
            />
            <StyledSettingsCardRow>
              <SettingsLabel noFlex>
                <Typography size={theme.font.size.small} weight="bold">
                  Savings:
                </Typography>
              </SettingsLabel>
              <SettingsValue>
                <Slider
                  textMarginType="small"
                  value={slidersLevels.savingsLevel}
                  digitValue={slidersValues.savingsLevel}
                  onChange={(e) => changeSliderLevel(e, 'savingsLevel')}
                />
              </SettingsValue>
            </StyledSettingsCardRow>
            <Profit
              valueTextAlign="right"
              rows={[
                [
                  'Rest:',
                  formatPrice(Math.max(0, profileProfit - slidersValues.savingsLevel * profileProfit)),
                ],
              ]}
            />
          </TabInnerSection>
          <Divider />
          {Object.keys(slidersValues.spendingsLevel).map((key) => {
            return key === 'property' ? null : (
              <React.Fragment key={key}>
                <TabInnerSection>
                  <BalanceBar level={profile.profile.satisfactionLevels[key]} type={key} />
                  <SpendingSlider
                    value={slidersLevels.spendingsLevel[key]}
                    digitValue={slidersValues.spendingsLevel[key]}
                    onChange={(e) => changeSliderLevel(e, key)}
                  />
                </TabInnerSection>
                <Divider />
              </React.Fragment>
            )
          })}
          <TabInnerSection>
            <ActionButton fullWidth onClick={onSave} loading={loadingSaving} type="sell">
              Save changes
            </ActionButton>
          </TabInnerSection>
          {loadingError && <Error>{loadingError}</Error>}
        </>
      )}
    </>
  )
}
const SpendingSlider = styled(Slider)`
  margin-left: auto;
  margin-top: 8px;
  width: calc(100% - 10px);
`

const StyledSettingsCardRow = styled(SettingsCardRow)`
  margin: 10px 0;
  & > *:last-of-type {
    flex: 1;
    text-align: right;
  }
`

const Profit = styled(TextTable)`
  & > * > * {
    font-weight: bold;
    color: ${theme.color.black};
  }
  & > *:last-of-type {
    flex: 1;
  }
`
