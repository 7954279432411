import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Container } from 'ui/atoms/container'

const Desaturate = styled.div`
  flex: 1;
  height: 100%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  ${ifProp(
    'working',
    `
  filter: saturate(0.3);
`
  )}
`

const MainContainer = styled(Container)`
  flex: 1;
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 20px 0;
`
const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`

export const S = {
  Desaturate,
  MainContainer,
  InnerContainer,
  OuterContainer,
}
