import React from 'react'

import { formatPrice } from 'features/game/lib/prices/format'
import { Typography } from 'ui/atoms/typography'
import { theme } from 'theme'

const bonusesSum = (bonuses) => bonuses.reduce((prev, { value }) => prev + value, 0)

export const prepareRatioColumns = (cols, bonuses, key) =>
  cols.map((col) => {
    if (col.key === 'value') {
      const total = bonusesSum(bonuses)
      return {
        ...col,
        label: () => (
          <Typography size={theme.font.size.large - 2} weight="bold">
            x {formatPrice(total, { disableCurrency: true })}
          </Typography>
        ),
      }
    }
    if (col.key === 'description') {
      return {
        ...col,
        label: () => (
          <Typography size={theme.font.size.large - 2} weight="bold">
            {key === 'endBonus' ? 'Endbonus' : 'Flowbonus'} Ratio:
          </Typography>
        ),
      }
    }
    return col
  })
