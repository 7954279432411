import { browserHistory } from 'lib/history'
import { Storage } from 'lib/storage'

import { AUTH_ERRORS } from './dictionary'

export const handleHTTPError = (error) => {
  let title = ''
  let subtitle = ''
  let status
  if (error?.response?.status) {
    title = error.response.status
    status = error.response.status
    switch (error.response.status) {
      case 500:
        title = 'Internal server error.'
        subtitle = 'If this error repeats - please, contact with your admin.'
        break
      case 404:
        title = 'Not found.'
        break
      case 403:
        title = 'Access forbidden.'
        subtitle = 'If you think you have access - please, try to log out and log in.'
        break
      case 401:
        title = 'You should log in.'
        break
      case 400:
        title = 'Invalid data format.'
        break
      default:
        break
    }
    if (error.response.data?.error?.length > 0) subtitle = error.response.data.error
    if (error.response.data?.message?.length > 0) subtitle = error.response.data.message
    if (error.response.data?.heading?.length > 0) title = error.response.data.heading
  } else {
    status = 503
    title = 'Connection error.'
    subtitle = 'There is a problem with your internet connection or our server is down.'
  }
  return Promise.reject(enhanceHTTPError(error, status, title, subtitle))
}

export const enhanceAPIValidationError = (error) => {
  const errName = 'APIValidationError'
  error.name = ''
  const errStdOut = error.toString()
  error.toString = function () {
    return `field "${error.key}": ${errStdOut}`
  }
  error.name = errName
  return error
}

export const enhanceHTTPError = (error, code, message, details) => {
  console.error(error, code, message, details)
  if (code) error.code = code
  if (message) error.message = message
  if (details) error.details = details
  error.name = 'HTTPError'
  error.toString = function () {
    return details?.length > 0 ? details : message
  }
  if (AUTH_ERRORS.includes(details)) {
    Storage.clearStorage()
    browserHistory.push('/login')
  }
  return error
}
