import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { Divider } from 'features/game/field/atoms/divider'
import { InstrumentImg } from 'features/game/field/atoms/instrument-img'
import { NewsContent } from 'features/game/field/atoms/news-content'
import { Price } from 'features/game/field/atoms/price'
import { TabInnerHeading } from 'features/game/field/atoms/tab-inner-heading'
import { TabInnerSection } from 'features/game/field/atoms/tab-inner-section'
import { getSubjectInstrument } from 'features/game/lib/get-subject-instrument'
import { imgSrc } from 'features/game/lib/img-src'
import { selectRelatedInstrument } from 'features/game/lib/select-related-instrument'
import { $isLivingInSelected, $selectedSubTabData } from 'features/game/model'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'
import { TextTable } from 'ui/organisms/text-table'

import { GoodsServicesDescCard } from './card'
import { MatchCard } from './card/match-card'
import { getRowsByInstrumentType } from './lib/get-rows-by-instrument-type'

export const GoodsAndServicesDescription = () => {
  const selectedInstrument = useStore(useStore($selectedSubTabData).selected)
  const isLivingInThis = useStore($isLivingInSelected)
  const relatedGoal =
    selectedInstrument.relatedGoalID || selectedInstrument.relatedGoalID === 0
      ? getSubjectInstrument(selectedInstrument.relatedGoalID, 'goals')
      : null
  const relatedDream =
    selectedInstrument.relatedDreamID || selectedInstrument.relatedDreamID === 0
      ? getSubjectInstrument(selectedInstrument.relatedDreamID, 'dreams')
      : null
  let rows = getRowsByInstrumentType(selectedInstrument)
  if (isLivingInThis) rows = [...rows, ['', 'Current residence']]
  return (
    <>
      <TabInnerSection>
        <TabInnerHeading weight="bold" color={hexToRGBA(theme.color.black, 0.7)} size={theme.font.size.main}>
          {selectedInstrument.name}
        </TabInnerHeading>
        <InstrumentImg src={imgSrc(selectedInstrument.image)} />
        <StyledTextTable
          labelTextAlign="left"
          labelFontSize={theme.font.size.main}
          valueTextAlign="right"
          valueFontSize={theme.font.size.main}
          {...{ rows }}
        />
        {selectedInstrument.description && (
          <>
            <DescHeading>Description</DescHeading>
            <NewsContent html={selectedInstrument.description} />
          </>
        )}
        {selectedInstrument.bonuses?.buying?.otherBonuses?.length > 0 && (
          <StyledGoodsServicesDescCard heading="Bonuses:">
            {selectedInstrument.bonuses.buying.otherBonuses.map((b) => (
              <Typography weight="600" size={15}>
                {b}
              </Typography>
            ))}
          </StyledGoodsServicesDescCard>
        )}
        {!!relatedGoal && (
          <StyledMatchCard
            onClick={() => selectRelatedInstrument(selectedInstrument.relatedGoalID, 'goals')}
            type="achievement"
            match={relatedGoal}
          />
        )}
        {!!relatedDream && (
          <StyledMatchCard
            onClick={() => {
              selectRelatedInstrument(selectedInstrument.relatedDreamID, 'dreams')
            }}
            type="dream"
            match={relatedDream}
          />
        )}
      </TabInnerSection>
      <Divider />
      {selectedInstrument.rentPrice?.current && (
        <>
          <TabInnerSection>
            <PriceSection>
              <Typography color={hexToRGBA(theme.color.black, 0.7)} weight="bold">
                Rent price:
              </Typography>
              <Price weight="bold" {...selectedInstrument.rentPrice} />
            </PriceSection>
          </TabInnerSection>
          <Divider />
        </>
      )}
      {selectedInstrument.price && !['deposits', 'credit'].includes(selectedInstrument.instrumentType) && (
        <TabInnerSection>
          <PriceSection>
            <Typography color={hexToRGBA(theme.color.black, 0.7)} weight="bold">
              Price:
            </Typography>
            <Price weight="bold" {...selectedInstrument.price} />
          </PriceSection>
        </TabInnerSection>
      )}
    </>
  )
}

const StyledGoodsServicesDescCard = styled(GoodsServicesDescCard)`
  margin: 10px 0;
`

const StyledMatchCard = styled(MatchCard)`
  cursor: pointer;
  margin-top: 15px;
  transition: all 0.15s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
  }
`

const PriceSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  &:not(:first-of-type) {
    margin-top: 8px;
  }
  & > *:first-of-type {
    text-align: left;
  }
  & > *:last-of-type {
    text-align: right;
  }
`

const DescHeading = styled.h4`
  color: ${hexToRGBA(theme.color.black, 0.7)};
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
`

const StyledTextTable = styled(TextTable)`
  justify-content: space-between;
  margin: 4px 0;
`
