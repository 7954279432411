import { Logger } from 'features/error'

class HandleFieldError extends Error {
  constructor(value, cases) {
    super(`Can't handle field for value ${value}.`)
    this.name = 'HandleFieldError'
    this.cases = cases
    this.value = value
  }
}

export const createFieldHandler = (value, keys) => {
  const found = keys[value]
  if (found) return found
  if (keys.default) {
    return keys.default
  } else {
    Logger.notify({ error: new HandleFieldError(value, keys) })
    return value
  }
}
