import React from 'react'
import styled from 'styled-components'

import { hexToRGBA } from 'lib/hex-to-rgba'
import closeIcon from 'static/cross-green.svg'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Main } from 'ui/atoms/main'
import { Typography } from 'ui/atoms/typography'

import { clearError } from '../../model'

const openFeedbackDialog = () => {
  window.open('https://vladosabramovich.typeform.com/to/wg3jZyQH', '_blank')
}

export const ErrorBase = ({ error, eventId = null }) => {
  if (error)
    return (
      <StyledMain>
        <Container>
          <Close onClick={clearError}>
            <CloseIcon src={closeIcon} />
          </Close>
          <Heading weight="600" color={theme.color.primary} size={50}>
            We have some problems here.
          </Heading>
          <Heading weight="bold" color={theme.color.black} size={theme.font.size.huge}>
            {error.toString()}
          </Heading>
          {eventId && (
            <>
              <Heading color={theme.color.black} size={theme.font.size.large}>
                We're already working on this error.
                <br />
                However, the more information the better 🤓
              </Heading>
              <StyledButton onClick={openFeedbackDialog}>Help us</StyledButton>
            </>
          )}
        </Container>
      </StyledMain>
    )
  return null
}

const Close = styled.div`
  align-items: center;
  background: ${hexToRGBA(theme.color.green, 0.1)};
  border: 1px solid ${hexToRGBA(theme.color.green, 0.3)};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 14px;
  position: absolute;
  right: 10vw;
  top: 10vh;
  transition: all 0.15s ease-in-out;
  &:hover {
    background: ${hexToRGBA(theme.color.green, 0.2)};
    border: 1px solid ${hexToRGBA(theme.color.green, 0.4)};
  }
`

const CloseIcon = styled.img`
  height: 12px;
  width: 12px;
`

const StyledMain = styled(Main)`
  position: absolute;
  z-index: 998;
`

const StyledButton = styled(Button)`
  margin-top: 20px;
`

const Heading = styled(Typography)`
  margin-bottom: 15px;
`

const Container = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 998;
`
