import { useStore } from 'effector-react'
import React, { useState } from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import { $selectedSubTabData, $selectedSubTabLayout } from 'features/game/model'

import { InstrumentTable } from './instrument-table'

export const SubTabTable = () => {
  const subTabData = useStore($selectedSubTabData)
  const subTabLayout = useStore($selectedSubTabLayout)

  //@TODO: логика сжатия разжатия подтаблиц
  const [minimizedTables, setMinimizedTables] = useState([])
  const onMinimizeToggle = (key) => {
    setMinimizedTables((prevState) => {
      let newState = [...prevState]
      let foundTableIdx = prevState.findIndex((el) => el === key)
      if (foundTableIdx > -1) newState.splice(foundTableIdx, 1)
      if (foundTableIdx === -1) newState.push(key)
      return newState
    })
  }
  if (subTabLayout.views?.length > 0) {
    return (
      <Container>
        {subTabLayout.views.map((el, idx) => {
          const isMinimized = minimizedTables.find((elem) => elem === el.key)
          return (
            <TableContainer key={el.key} flex={isMinimized ? 0 : el.flex}>
              <InstrumentTable
                footer={el.footer}
                header={el.header}
                key={el.key}
                columns={el.columns}
                bgRow={el.bgRow}
                isMinimized={isMinimized}
                isMinimizable={el.isMinimizable}
                groupByKey={el.groupByKey}
                onMinimizeToggle={() => onMinimizeToggle(el.key)}
                instrumentStore={subTabData.views[el.key]}
              />
            </TableContainer>
          )
        })}
      </Container>
    )
  }
  return <StyledInstrumentTable columns={subTabLayout.columns} instrumentStore={subTabData} />
}

const TableContainer = styled.div`
  flex: ${prop('flex', 0)};
`

const StyledInstrumentTable = styled(InstrumentTable)`
  //height: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
