import React from 'react'
import { useStore } from 'effector-react'
import { useForm } from 'react-hook-form'

import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Checkbox } from 'ui/atoms/checkbox'
import { ButtonsGroup, Error, Form, Heading, Horizontal } from 'ui/atoms/form'
import { InputGroup } from 'ui/molecules/input-group'

import {
  $rememberMe,
  loginUser,
  loginUserFetching,
  setRememberMe,
  goToLoginByCode,
  goToRegister,
} from '../../model'

export const LoginForm = () => {
  const { register, handleSubmit, errors } = useForm()
  const formError = useStore(loginUserFetching.error)
  const loading = useStore(loginUserFetching.isLoading)
  const rememberMe = useStore($rememberMe)
  return (
    <Form onSubmit={handleSubmit(loginUser)}>
      <Heading centered weight="600" size={theme.font.size.huge}>
        Authorization
      </Heading>
      <InputGroup
        name="email"
        error={errors.email?.message || formError?.message?.length > 0}
        ref={register({
          required: 'Login is required.',
        })}
        fullWidth
        placeholder="Login"
      />
      <InputGroup
        name="password"
        type="password"
        error={errors.password?.message || formError?.message?.length > 0}
        ref={register({
          required: 'Password is required.',
          minLength: {
            value: 3,
            message: "Password's length must be 3 or more.",
          },
        })}
        fullWidth
        placeholder="Password"
      />
      {formError?.message?.length > 0 && (
        <Error color={theme.color.red} size={theme.font.size.extrasmall}>
          {formError.message}
        </Error>
      )}
      <Button type="submit" {...{ loading }} fullWidth>
        Login
      </Button>
      <Horizontal>
        <Checkbox checked={rememberMe} onChange={({ target: { checked } }) => setRememberMe(checked)}>
          Remember Me
        </Checkbox>
        {/* <Link color={hexToRGBA(theme.color.black, 0.4)}>Forgot your password?</Link> */}
      </Horizontal>
      <ButtonsGroup>
        <Button onClick={goToLoginByCode} variant="secondary" fullWidth>
          Login using code
        </Button>
        <Button onClick={goToRegister} variant="secondary" fullWidth>
          Create new account
        </Button>
      </ButtonsGroup>
    </Form>
  )
}
