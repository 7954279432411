import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { $user } from 'features/auth'
import { isAdmin as checkIsAdmin } from 'features/auth'
import { viewGame, viewGameFetching } from 'features/game/admin/timeline/model'
import { goToStatisticsPage, $isNowPlaying } from 'features/game/navigation/model/common'
import { formatPrice } from 'features/game/lib/prices/format'
import { browserHistory } from 'lib/history'
import { sortByPoints } from 'lib/sort/custom-sorts'
import { ReactComponent as ChartIcon } from 'static/graph.svg'
import { ReactComponent as ViewIcon } from 'static/eye-common.svg'
import { Button } from 'ui/atoms/button'
import { Typography } from 'ui/atoms/typography'
import { LoadingOverflow } from 'ui/molecules/loading-overflow'
import { Tooltip } from 'ui/molecules/tooltip'
import { NewTable, StyledTableParts } from 'ui/organisms/new-table'
import { theme } from 'theme'

import { enhancedLeaderboard, getRoomLeaderboardFetching } from '../model'

const ViewStatisticsContainer = styled.div`
  align-items: center;
  color: ${theme.color.primary};
  &:hover {
    color: ${theme.color.black};
  }
  display: flex;
  height: 100%;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`

const StyledChartIcon = styled(ChartIcon)`
  color: ${theme.color.primary};
  cursor: pointer;
  height: 19px;
  object-fit: contain;
  width: auto;
  will-change: transform;
`

const StyledViewIcon = styled(ViewIcon)`
  color: ${theme.color.primary};
  cursor: pointer;
  height: 19px;
  object-fit: contain;
  width: auto;
  will-change: transform;
`

const LEADERBOARD_COLS = [
  {
    key: 'playerName',
    label: 'Player',
    align: 'center',
    width: '250px',
    isSortable: true,
  },
  {
    key: 'characterName',
    label: 'Character',
    isSortable: true,
    width: '120px',
    align: 'center',
    Aggregated: () => 'Expand to see',
  },
  {
    key: 'currentTurn',
    label: 'Turns (Avg)',
    width: '80px',
    isSortable: true,
    aggregate: 'average',
    Aggregated: ({ value }) => Math.floor(value),
    align: 'center',
  },
  {
    key: 'points',
    label: 'Points (Max)',
    align: 'right',
    width: '180px',
    maxWidth: '210px',
    isSortable: true,
    sortFn: sortByPoints,
    aggregate: 'max',
    Aggregated: ({ value }) => (
      <Typography weight="500" size={theme.font.size.small} fontMono oneLine>
        {formatPrice(value, { disableCurrency: true, forceDecimals: true })}
      </Typography>
    ),
    render: (data) => (
      <Typography weight="500" size={theme.font.size.small} fontMono oneLine>
        {formatPrice(data, { disableCurrency: true, forceDecimals: true })}
      </Typography>
    ),
  },
]

const getColumnsForLeaderboard = ({ user, roomID }) => {
  const isAdmin = checkIsAdmin(user)
  return [
    ...LEADERBOARD_COLS,
    {
      id: 'gameId',
      accessor: (row) => (
        <ViewStatisticsContainer>
          <Tooltip
            content={<Typography size={theme.font.size.small}>View statistics for this game</Typography>}
          >
            <StyledChartIcon
              onClick={() => {
                goToStatisticsPage({
                  gameID: row.gameId,
                  title: `of "${row.playerName}", Game #${row.gameId}`,
                })
              }}
            />
          </Tooltip>
          {isAdmin && (
            <Tooltip
              content={
                <Typography size={theme.font.size.small}>
                  Timeline{' '}
                  <b>
                    <i>(admin)</i>
                  </b>
                </Typography>
              }
            >
              <StyledViewIcon onClick={() => viewGame({ user_id: row.id, game_id: row.gameId })} />
            </Tooltip>
          )}
        </ViewStatisticsContainer>
      ),
      label: '',
      align: 'center',
      isSortable: false,
      width: isAdmin ? '60px' : '40px',
      maxWidth: isAdmin ? '61px' : '41px',
      // render: (data, row) => (
      //     <ViewStatisticsContainer>
      //       <Tooltip content={<Typography size={theme.font.size.small}>View statistics for this game</Typography>}>
      //       <StyledChartIcon onClick={() => {
      //         goToStatisticsPage({ gameID: data, title: `of "${row.playerName}", Game #${data}` })
      //       }} />
      //       </Tooltip>
      //       <Tooltip content={<Typography size={theme.font.size.small}>Timeline <b><i>(admin)</i></b></Typography>}>
      //       <StyledViewIcon onClick={() => viewGame({user_id: , game_id, session_id})} />
      //       </Tooltip>
      //     </ViewStatisticsContainer>
      // ),
      Aggregated: () => null,
    },
  ]
}

const LEADERBOARD_ACTIONS = {
  true: {
    onClick: () => browserHistory.push('/game'),
    children: 'Go back to the game',
  },
  false: {
    onClick: () => browserHistory.push('/rooms'),
    children: 'Go to rooms list',
  },
}

// @TODO
// eslint-disable-next-line no-unused-vars
const flattenLeaderboard = (arr) => {
  let newArr = arr.splice()
  for (let i = 0; i < newArr.length; i++) {
    const row = newArr[i]
    let foundIdx = newArr.findIndex((a) => a.userId === row.userId)
    while (foundIdx > -1) {
      const foundEl = newArr[foundIdx]
      if (foundEl.points > row.points) {
        newArr[i] = foundEl
      }
      newArr.splice(foundIdx, 1)
      foundIdx = newArr.findIndex((a) => a.userId === row.userId)
    }
  }
  return newArr
}

export const RoomLeaderboard = ({ roomIDFromURL }) => {
  //const list = flattenLeaderboard(useStore(enhancedLeaderboard.list))
  const list = useStore(enhancedLeaderboard.list)
  const sort = useStore(enhancedLeaderboard.sort)
  const hasActiveGame = String(useStore($isNowPlaying))
  const user = useStore($user)
  const loadingLeaderboard = useStore(getRoomLeaderboardFetching.isLoading)
  const loadingViewGame = useStore(viewGameFetching.isLoading)
  return (
    <Container>
      <Typography size={theme.font.size.large} weight="600">
        Leaderboard
      </Typography>
      <StyledTable
        {...sort}
        groupByKey="playerName"
        rowIdKey="frontId"
        onChangeOrder={enhancedLeaderboard.setSort}
        columns={getColumnsForLeaderboard({ user, roomID: roomIDFromURL })}
        data={list}
      />
      <LoadingOverflow withLoader absolute loading={loadingLeaderboard || loadingViewGame} />
      <StyledButton {...LEADERBOARD_ACTIONS[hasActiveGame]} />
    </Container>
  )
}

const StyledTable = styled(NewTable)`
  flex: 1;
  margin-bottom: 1vh;
  margin-top: 4vh;
  ${StyledTableParts.tr} ${StyledTableParts.td} {
    padding: 8px 10px;
  }
  ${StyledTableParts.th} {
    padding: 15px 10px;
  }
`

const StyledButton = styled(Button)`
  margin-top: 10vh;
  min-width: 250px;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 900px;
  padding-bottom: 3vh;
  padding-top: 3vh;
  width: 100%;
`
