import React from 'react'
import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools'

import { getSubjectInstrument } from 'features/game/lib/get-subject-instrument'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { Typography } from 'ui/atoms/typography'
import { Scrollbars } from 'ui/molecules/scrollbars'
import { theme } from 'theme'

import { TabInnerHeading } from '../../atoms/tab-inner-heading'
import { NewsContent } from '../../atoms/news-content'

export const NewsList = ({ news, noHeading, heading = 'News', noScrollbars }) => {
  const WrapperForList = noScrollbars ? React.Fragment : Scrollbars
  if (!news) {
    return null
  }
  return (
    <>
      {!noHeading && <TabInnerHeading>{heading}</TabInnerHeading>}
      <WrapperForList>
        <List>
          {news.map((news) => {
            let name
            if (news.subjectID) name = getSubjectInstrument(news.subjectID, news.market)?.name
            return (
              <News key={news.name}>
                <NewsHeading turn={news.turn < 0 ? '-' : news.turn} weight="600" size={15}>
                  {name && (
                    <NewsDescription
                      size={theme.font.size.mediumsmall}
                      weight="bold"
                      color={hexToRGBA(theme.color.black, 0.6)}
                    >
                      {name}
                    </NewsDescription>
                  )}
                  {news.name}
                </NewsHeading>
                <NewsContent html={news.description} />
              </News>
            )
          })}
        </List>
      </WrapperForList>
    </>
  )
}

const List = styled.ul`
  padding-left: 2px;
`

const News = styled.li`
  margin-bottom: 15px;
`

const NewsDescription = styled(Typography)`
  margin-bottom: 3px;
  padding-top: 1.5px;
`

const NewsHeading = styled(Typography)`
  margin-left: 13px;
  position: relative;
  &:before {
    border-radius: 2px;
    display: block;
    position: absolute;
    ${ifProp(
      'turn',
      css`
        color: ${theme.color.green};
        content: '${prop('turn')}';
        font-size: ${theme.font.size.extrasmall}px;
        left: -16px;
        top: 2px;
      `,
      css`
        background: ${theme.color.green};
        content: ' ';
        height: 2px;
        left: -13px;
        top: 9px;
        width: 8px;
      `
    )}
  }
`
