import { createEvent, createStore, merge } from 'effector-root'
import { uniq as uniqueArray } from 'lodash'
import { uuid } from 'uuidv4'

import { roomsAPI } from 'api/rooms'
import { createEntityListModel } from 'features/game/lib/instrument-model-factory'
import { setRoomID } from 'features/game/navigation/model/common'
import { createEffectWithFetching } from 'lib/fetching'
import { ROOM_STATISTICS } from 'mocks/rooms'

import { getRoomStatus } from './lib/get-room-status'

export const resetRoomsList = createEvent()
export const resetRoom = createEvent()
export const resetRooms = createEvent()
export const setRoomStatus = createEvent()
export const resetRoomStatus = createEvent()
export const setRoomStatusInterval = createEvent()
export const resetRoomStatusInterval = createEvent()
export const setSelectedCharacter = createEvent()
export const resetRoomStatistics = createEvent()
export const resetSuggests = createEvent()

export const [getRoom, getRoomFetching] = createEffectWithFetching()
export const [getRooms, getRoomsFetching] = createEffectWithFetching()
export const [enterRoom, enterRoomFetching] = createEffectWithFetching()
export const [getRoomLeaderboard, getRoomLeaderboardFetching] = createEffectWithFetching()
export const [getRoomStatistics, getRoomStatisticsFetching] = createEffectWithFetching()
export const [getTeamSuggest, getTeamSuggestFetching] = createEffectWithFetching()

export const $rooms = createStore([])
export const $room = createStore(null)
export const $roomStatus = createStore(null)
export const $roomStatusInterval = createStore(null)
export const $roomStatistics = createStore(ROOM_STATISTICS)
export const $selectedCharacter = createStore(null)
export const $selectedCharacterPresented = $selectedCharacter.map((state) => state !== null)
export const $roomLeaderboard = createStore([])
export const $leaderboardTeamsList = createStore([])
export const $teamSuggests = createStore([])
export const enhancedLeaderboard = createEntityListModel(
  $roomLeaderboard,
  [],
  'points',
  'leaderboard',
  undefined,
  'desc'
)
export const enhancedRooms = createEntityListModel($rooms, ['roomsType'], 'name')

getRoom.use((id) => roomsAPI.getRoom(id))
getRooms.use((params) => roomsAPI.getRooms(params))
enterRoom.use((id, settings) => roomsAPI.enterRoom(id, settings))
getRoomLeaderboard.use((id) => roomsAPI.getLeaderboard(id))
getRoomStatistics.use((params) => roomsAPI.getStatistics(params))
getTeamSuggest.use((params) => roomsAPI.getTeamSuggest(params))

$teamSuggests
  .on(
    getTeamSuggest.done,
    (
      _,
      {
        result: {
          data: { suggestions },
        },
      }
    ) => suggestions
  )
  .reset(resetSuggests)
$roomStatistics.on(getRoomStatistics.done, (_, { result: { data } }) => data).reset(resetRoomStatistics)
$roomLeaderboard.on(getRoomLeaderboard.done, (_, { result: { data: { leaderboard } } }) =>
  leaderboard.filter((el) => el.points !== 1000 && el.points !== 0).map((el) => ({ ...el, frontId: uuid() }))
)
$leaderboardTeamsList.on(getRoomLeaderboard.done, (_, { result: { data: { leaderboard } } }) =>
  uniqueArray(leaderboard.map((el) => el.team).filter((el) => Boolean(el)))
)
$room.on(getRoom.done, (_, { result: { data } }) => data).reset(resetRoom)
$roomStatusInterval
  .on(
    getRoom.done,
    (
      _,
      {
        result: {
          data: { startDate, finishDate },
        },
      }
    ) => {
      setRoomStatus(getRoomStatus(startDate, finishDate))
      return setInterval(() => {
        setRoomStatus(getRoomStatus(startDate, finishDate))
      }, 1000)
    }
  )
  .reset(resetRoomStatus)
merge([resetRoomStatusInterval, resetRoomStatus]).watch(() => {
  clearInterval($roomStatusInterval.getState())
})
$roomStatus.on(setRoomStatus, (_, data) => data)
$rooms
  .on(getRooms.done, (_, { result: { data } }) => {
    resetRoomStatusInterval()
    return data.map((el) => ({ ...el, frontId: uuid() }))
  })
  .reset(resetRooms)
resetRoomStatus.watch(() => {
  resetRoomStatusInterval()
})
merge([getRoomLeaderboard.done, getRoom.done]).watch(({ params }) => {
  setRoomID(params)
})
$selectedCharacter.on(setSelectedCharacter, (_, data) => data)
$roomLeaderboard.watch((l) => {
  window.log(l, 'roomLeaderboard', 'store')
})
