import React, { Component } from 'react'

import { sendToSentry } from '../lib/send-to-sentry'
import { ErrorBase } from '../organisms/error-base'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { eventId: null, error: null, hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { error: error, hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const eventId = sendToSentry(error, 'fatal', errorInfo)
    this.setState({ eventId })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBase {...this.state} />
    }
    return this.props.children
  }
}
