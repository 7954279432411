import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { formatPrice } from 'features/game/lib/prices/format'
import { handlePropertyType } from 'lib/handle-fields'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { useTableListWithLocalSort } from 'lib/hooks/use-table-list-with-local-sort'
import ImgPlaceholder from 'static/img-placeholder.svg'
import leftArrowImg from 'static/left-white-arrow.svg'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Typography } from 'ui/atoms/typography'
import { HeadingWithStripe } from 'ui/molecules/heading-with-stripe'
import { Scrollbars } from 'ui/molecules/scrollbars'
import { CellText } from 'ui/organisms/table/cell-text'
import { Table } from 'ui/organisms/table'
import { DreamsBlock } from 'features/game/organisms/dreams'

// const FINANCIAL_GOALS_COLS = [
//   {
//     key: 'name',
//     label: 'Goal',
//     wrapLine: true,
//     minWidth: '80%',
//     isSortable: true,
//   },
//   {
//     key: 'endBonus',
//     label: 'Endbonus',
//     align: 'center',
//     isSortable: true,
//   },
// ]

const PROPERTY_COLS = [
  {
    key: 'name',
    label: 'Name',
    isSortable: true,
    minWidth: '320px',
    render: (data, row) => {
      return (
        <VerticalCenter>
          <PropertyImg src={row.image || ImgPlaceholder} />
          {data}
        </VerticalCenter>
      )
    },
  },
  {
    key: 'type',
    label: 'Type',
    minWidth: '190px',
    isSortable: true,
    render: (data, row) => {
      return <CellText>{handlePropertyType(data)}</CellText>
    },
  },
  {
    key: 'qty',
    label: 'Qty',
    isSortable: true,
    align: 'center',
    minWidth: '100px',
  },
  {
    key: 'currentMarketVal',
    label: 'Current market Value',
    isSortable: true,
    align: 'center',
    render: (data, row) => {
      return <CellText>{formatPrice(data)}</CellText>
    },
  },
  {
    key: 'costPerYear',
    label: 'Cost of ownership per year',
    isSortable: true,
    align: 'center',
    render: (data, row) => {
      return <CellText>{formatPrice(data)}</CellText>
    },
  },
]

const ScrollbarsProps = { offset: -8 }

export const CharacterDetails = ({
  character: {
    name,
    image: { big, small },
    age,
    description,
    goals,
    dreams,
    property,
  },
  onChoose,
  onPrev,
  haveControls,
  haveAdditionalInfo,
  onNext,
  className,
}) => {
  const sumQty = property.reduce((acc, cur) => acc + cur.qty, 0)
  const sumMrktVal = property.reduce((acc, cur) => acc + cur.currentMarketVal, 0)
  const sumCostPerYear = property.reduce((acc, cur) => acc + cur.costPerYear, 0)
  const [propertySort, setPropertySort, sortedProperty] = useTableListWithLocalSort(property)
  const ContentTagName = haveControls ? Scrollbars : React.Fragment
  const ContentTagProps = haveControls ? ScrollbarsProps : {}
  return (
    <Columns {...{ className, haveAdditionalInfo }}>
      <ImgColumn>
        <Image src={big || small} />
        <Name weight="bold">{name}</Name>
        {haveControls && (
          <Controls>
            <Control disabled={!onPrev} onClick={onPrev}>
              <ControlImg src={leftArrowImg} />
            </Control>
            <Control disabled={!onNext} right onClick={onNext}>
              <ControlImg src={leftArrowImg} />
            </Control>
          </Controls>
        )}
      </ImgColumn>
      <DetailsColumn>
        <ContentTagName {...ContentTagProps}>
          <InnerDetail>
            <HeadingWithStripe>Biography:</HeadingWithStripe>
            {age > 0 && (
              <Paragraph>
                <Typography>
                  <b>Age:</b> {age} years
                </Typography>
              </Paragraph>
            )}
            <Paragraph>
              <Typography
                size={theme.font.size.small}
                color={hexToRGBA(theme.color.black, 0.8)}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Paragraph>
            {haveAdditionalInfo && (
              <>
                {dreams?.length > 0 && (
                  <>
                    <Paragraph>
                      <HeadingWithStripe>Dreams</HeadingWithStripe>
                    </Paragraph>
                    <Paragraph>
                      <DreamsBlock {...{ dreams }} />
                    </Paragraph>
                  </>
                )}
                <Paragraph>
                  <HeadingWithStripe>Character's ownings:</HeadingWithStripe>
                </Paragraph>
                <Paragraph>
                  <StyledTable
                    onChangeOrder={setPropertySort}
                    {...propertySort}
                    noHeadOverflow
                    footer={
                      <FooterRow>
                        <TotalCell colSpan={2}>
                          <Total size={theme.font.size.small} weight="600">
                            Total:
                          </Total>
                        </TotalCell>
                        <TotalContentCell>
                          <CellText>{sumQty} items</CellText>
                        </TotalContentCell>
                        <TotalContentCell>
                          <CellText>{formatPrice(sumMrktVal)}</CellText>
                        </TotalContentCell>
                        <TotalContentCell>
                          <CellText>{formatPrice(sumCostPerYear)}</CellText>
                        </TotalContentCell>
                      </FooterRow>
                    }
                    columns={PROPERTY_COLS}
                    data={sortedProperty}
                  />
                </Paragraph>
              </>
            )}
            {onChoose && <StyledButton onClick={onChoose}>Choose this character</StyledButton>}
          </InnerDetail>
        </ContentTagName>
      </DetailsColumn>
    </Columns>
  )
}

//@TODO: реализовать парсинг дескрипшна в h1,h2,p,li

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
  margin-top: 40px;
  min-width: 400px;
`

const PropertyImg = styled.img`
  height: 60px;
  margin-right: 20px;
  object-fit: contain;
  width: 68px;
`

const VerticalCenter = styled.div`
  align-items: center;
  display: flex;
`

const Total = styled(Typography)`
  position: relative;
  &::before {
    background: #343840;
    content: ' ';
    height: 100%;
    position: absolute;
    right: -10px;
    top: 0;
    width: 1px;
  }
`

const TotalCell = styled.td`
  text-align: right;
`

const TotalContentCell = styled.td`
  font-weight: 600;
  text-align: center;
`

const FooterRow = styled.tr`
  padding: 10px 15px;
`

const StyledTable = styled(Table)`
  tr td {
    padding: 10px 17px;
    &:first-of-type {
      ${ifProp('hasLeftPadding', 'padding-left: 40px', 'padding-left: 17px')};
      & div {
        position: relative;
      }
    }
    &:not(:last-of-type) {
      position: relative;
      z-index: 1;
    }
  }
  tr th {
    padding: 17px;
    vertical-align: middle;
    position: relative;
    & div {
      bottom: 37%;
      right: 6px;
    }
    &:first-of-type {
      ${ifProp('hasLeftPadding', 'padding-left: 40px', 'padding-left: 17px')};
    }
  }
  tr {
    background: ${theme.color.secondary};
  }
  tr[data-active='true'] td p {
    color: ${theme.color.black};
  }
`

// const GoalsTable = styled(StyledTable)`
//   max-width: 540px;
//   overflow: hidden;
// `

const Paragraph = styled.p`
  margin: 18px 0;
`

const ControlImg = styled.img``

const Control = styled.div`
  align-items: center;
  background: ${hexToRGBA(theme.color.black, 0.1)};
  border: 1px solid ${hexToRGBA(theme.color.black, 0.2)};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: all 0.15s ease-in-out;
  width: 40px;
  &:first-of-type {
    margin-right: 12px;
  }
  ${ifProp('right', 'transform: rotate(-180deg);')}
  ${ifProp(
    'disabled',
    `
  opacity: 0.5;
  cursor: not-allowed;
`,
    `
  &:hover {
    background: ${hexToRGBA(theme.color.black, 0.3)};
    border: 1px solid ${hexToRGBA(theme.color.black, 0.3)};
  }
`
  )}
`

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`

const Image = styled.img`
  border-radius: ${theme.radius.button.medium}px;
  height: 280px;
  object-fit: cover;
  width: 240px;
`
const Name = styled(Typography)`
  font-size: 22px;
  margin-top: 25px;
  text-align: center;
`

const Columns = styled.div`
  align-items: flex-start;
  display: flex;
  ${ifProp('haveAdditionalInfo', 'height: 100%;')}
`
const ImgColumn = styled.div`
  flex: 1;
`
const InnerDetail = styled.div`
  padding-left: 30px;
`
const DetailsColumn = styled.div`
  flex: 5;
  height: 100%;
  margin-left: 40px;
`
