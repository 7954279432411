import { getStatusDelta } from '../../prices/get-status-and-delta'

export const getTickerMarket = (list) => {
  const arr = list
  if (arr.length > 0) {
    const haveFields = arr.some((i) => i.price && i.name)
    if (haveFields) {
      return arr.map(({ name, price: { current, previous } }) => ({
        name,
        price: { current, previous },
        ...getStatusDelta(current, previous),
      }))
      // .filter((el) => el.status !== 'neutral')
    }
  }
  return []
}
