const addTrailedZero = (num) => {
  let formattedNum = String(num)
  if (formattedNum.length === 1) {
    return `0${formattedNum}`
  }
  return formattedNum
}

export const getTimer = (diff, noSecDelete) => {
  const SECOND = 1000
  const MINUTE = SECOND * 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24

  const days = Math.floor(+diff / DAY)
  const hours = Math.floor((+diff - days * DAY) / HOUR)
  const minutes = Math.floor((+diff - days * DAY - hours * HOUR) / MINUTE)
  const seconds = Math.floor((+diff - days * DAY - hours * HOUR - minutes * MINUTE) / SECOND)

  let result = {
    days,
    hours,
    minutes,
    seconds,
  }

  if (noSecDelete) {
    result.hours = addTrailedZero(result.hours)
    result.minutes = addTrailedZero(result.minutes)
    result.seconds = addTrailedZero(result.seconds)
  }

  Object.entries(result).forEach((entry) => {
    const key = entry[0]
    const value = entry[1]
    if (value === 0 && !['minutes', 'seconds'].includes(key) && !noSecDelete) delete result[key]
  })

  if (result.days > 0 && !noSecDelete) delete result.seconds

  return result
}
