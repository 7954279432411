import React from 'react'

import { RegisterForm, registerUser, registerUserFetching } from 'features/auth'
import { FullContainer } from 'ui/atoms/full-centered-container'

export const RegisterPage = () => {
  return (
    <FullContainer>
      <RegisterForm onSubmitForm={registerUser} fetching={registerUserFetching} />
    </FullContainer>
  )
}
