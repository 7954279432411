import React, { useCallback, useEffect, useRef, useState } from 'react'
import useComponentSize from '@rehooks/component-size'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import { Tooltip } from 'ui/molecules/tooltip'

export const OverflowTooltip = React.memo(
  ({
    children,
    bgColor = theme.color.secondary,
    maxWidth,
    disabled,
    className,
    noChildWatch,
    noGradient,
    interactive,
  }) => {
    const containerRef = useRef(null)
    const containerSize = useComponentSize(containerRef)
    const childRef = useRef(null)
    const gradientWidth = Math.max(6, containerSize.width / 8)
    const [showTooltip, setShowTooltip] = useState(false)
    const [showGradient, setShowGradient] = useState(false)
    const calcWidth = useCallback(() => {
      if (childRef.current) {
        const diffLength = childRef.current.scrollWidth - childRef.current.clientWidth
        setShowTooltip(diffLength > 4)
        if (!noGradient) setShowGradient(diffLength > 0)
      }
    }, [noGradient])
    useEffect(() => {
      if (noChildWatch) {
        if (!showTooltip) calcWidth()
      } else {
        calcWidth()
      }
    }, [children, childRef, noChildWatch, showTooltip, calcWidth])
    if (disabled) return children
    return (
      <Container {...{ gradientWidth, bgColor, maxWidth, className, showGradient }} ref={containerRef}>
        {showTooltip ? (
          <Tooltip {...{ interactive }} content={children}>
            <ChildWrapper ref={childRef}>{children}</ChildWrapper>
          </Tooltip>
        ) : (
          <ChildWrapper ref={childRef}>{children}</ChildWrapper>
        )}
      </Container>
    )
  }
)

const ChildWrapper = styled.div`
  height: 100%;
`

const Container = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 100%;
  ${ifProp(
    'maxWidth',
    css`
      max-width: ${prop('maxWidth')}px;
    `
  )}
  &::after {
    background: linear-gradient(
      -90deg,
      ${({ bgColor }) => bgColor} 0%,
      ${({ bgColor }) => hexToRGBA(bgColor, 0)} 100%
    );
    content: ' ';
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: -1px;
    top: 0;
    transition: all 0.2s ease-in-out;
    ${ifProp('showGradient', 'opacity: 1;')}
    width: ${prop('gradientWidth')}px;
    z-index: 1;
  }
`
