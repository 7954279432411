import { createEvent, createStore } from 'effector-root'

const MARKET_INITIAL_STATE = {
  otherMarkets: {
    realEstate: [],
    goods: [],
    services: [],
  },
  financialMarkets: {
    stocks: [],
    bonds: [],
    etf: [],
    commodities: [],
    indicies: [],
    mf: [],
    crypto: [],
    banking: [],
  },
  personal: {
    dreams: [],
    goals: [],
    assetsAndLiabilities: [],
    incomeAndExpenses: [],
  },
}
export const $state = createStore(MARKET_INITIAL_STATE)

export const setSelectedInstrument = createEvent()
export const _setSelectedInstrument = createEvent()
