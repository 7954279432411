export const transformColumns = (cols) =>
  cols.map((col) => {
    let newCol = { ...col }
    if (col.key !== 'name') newCol.sortDescFirst = true
    if (col.key) {
      newCol.accessor = col.key.slice()
      delete newCol.key
    }
    newCol.fontFamily = col.font
    newCol.defaultCanSort = col.isSortable
    if (col.render) {
      newCol.Cell = ({ value, row: { original, values } }) => col.render(value, original || values)
    }
    if (col.minWidth) {
      if (!col.minWidth.split('').includes('%')) {
        newCol.minWidth = Number(col.minWidth.slice(0, -2))
      }
    }
    if (col.maxWidth) {
      if (!col.maxWidth.split('').includes('%')) {
        newCol.maxWidth = Number(col.maxWidth.slice(0, -2))
      }
    }
    if (col.width) {
      if (!col.width.split('').includes('%')) {
        newCol.width = Number(col.width.slice(0, -2))
      }
    }
    if (!col.align) newCol.align = 'left'
    delete newCol.render
    if (col.columns) newCol.columns = transformColumns(col.columns)
    return newCol
  })
