import { useState } from 'react'

const transformValueSlider = (val, normalVal) => {
  let newVal
  const A = 4 - 8 * normalVal // 4
  const B = 10 * normalVal - 4 // -4
  const C = 1 - 2 * normalVal // 1
  if (normalVal === 0) {
    return 0.000001
  }
  newVal = val < normalVal ? val / (2 * normalVal) : (-B + Math.sqrt(B * B - 4 * A * (C - val))) / (2 * A)
  return newVal
}

const transformSliderValue = (val, normalVal) => {
  let newVal = val
  const A = 4 - 8 * normalVal
  const B = 10 * normalVal - 4
  const C = 1 - 2 * normalVal
  if (normalVal === 0) return val
  if (normalVal <= 0.5) {
    newVal = val >= 0.5 ? A * val * val + B * val + C : 2 * normalVal * val
  }
  return newVal
}

export const useNonLinearSliders = ({ initialSettings }) => {
  const getSliderDigitalValue = (val, field) => {
    const normalVal =
      field !== 'savingsLevel' ? initialSettings.spendingsLevel[field] : initialSettings[field]
    const newVal = transformSliderValue(val, normalVal)
    return newVal
  }
  const getLevelBasedOnValue = (val, field) => {
    const normalVal =
      field !== 'savingsLevel' ? initialSettings.spendingsLevel[field] : initialSettings[field]
    const newVal = transformValueSlider(val, normalVal)
    return newVal
  }
  const getLevelsBasedOnValues = (values) =>
    Object.entries(values).reduce((acc, entry) => {
      const key = entry[0]
      const value = entry[1]
      let newVal = { ...acc, [key]: getLevelBasedOnValue(value, key) }
      return newVal
    }, {})
  const getInitialLevels = (levels) => {
    const reduceValues = (vals) => {
      return Object.entries(vals).reduce((acc, entry) => {
        const key = entry[0]
        const value = entry[1]
        if (typeof value === 'number') {
          let newVal = { ...acc, [key]: getLevelBasedOnValue(value, key) }
          return newVal
        } else {
          let newVal = { ...acc, [key]: reduceValues(value) }
          return newVal
        }
      }, {})
    }
    return reduceValues(levels)
  }
  const [slidersValues, setSlidersValues] = useState(initialSettings)
  const [slidersLevels, setSlidersLevels] = useState(getInitialLevels(initialSettings))
  const changeSpendingLevel = (field, value) => {
    let newSpendingsLevels = { ...slidersValues.spendingsLevel }
    newSpendingsLevels[field] = value
    const diff = Object.values(newSpendingsLevels).reduce((acc, cur) => acc + cur, 0) - 1
    if (diff > 0) {
      const minus = diff / 3
      newSpendingsLevels = Object.fromEntries(
        Object.entries(newSpendingsLevels).map((entry) => {
          const key = entry[0]
          let value = key === field ? entry[1] : (entry[1] -= minus)
          value = value < 0 ? 0 : value
          return [key, value]
        })
      )
      let newDiff = Object.values(newSpendingsLevels).reduce((acc, cur) => acc + cur, 0) - 1
      if (newDiff > 0) {
        newSpendingsLevels = Object.fromEntries(
          Object.entries(newSpendingsLevels).map((entry) => {
            const key = entry[0]
            let value = entry[1]
            if (key !== field && newDiff > 0) {
              if (minus > 0) {
                if (value > 0) {
                  let minusing = Math.min(value, newDiff) // ВЫЧИТАЕМОЕ
                  value -= minusing
                  newDiff -= minusing
                }
              }
            }
            return [key, value]
          })
        )
      }
    }
    return newSpendingsLevels
  }
  const changeSliderLevel = (val, field) => {
    if (field === 'savingsLevel') {
      setSlidersLevels((prevLevels) => ({ ...prevLevels, savingsLevel: val }))
      setSlidersValues((prevValues) => ({ ...prevValues, savingsLevel: getSliderDigitalValue(val, field) }))
    } else {
      const spendingsLevel = changeSpendingLevel(field, getSliderDigitalValue(val, field))
      setSlidersValues((prevValues) => ({
        ...prevValues,
        spendingsLevel,
      }))
      setSlidersLevels((prevLevels) => {
        const newSpendingsLevel = getLevelsBasedOnValues(spendingsLevel)
        return {
          ...prevLevels,
          spendingsLevel: newSpendingsLevel,
        }
      })
    }
  }
  return { changeSliderLevel, slidersValues, slidersLevels }
}
