import React, { useState } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { $user } from 'features/auth'
import { isAdmin, isTeamlead } from 'features/auth'
import { viewGameFetching } from 'features/game/admin/timeline/model'
import { ButtonsSection } from 'features/game/field/organisms/buy-sell-tab/molecules'
import { formatTooltipYMoney, formatTooltipYPoints } from 'features/game/lib/chart-format'
import { formatPrice } from 'features/game/lib/prices/format'
import { $isNowPlaying, $statisticsTitle, goToLeaderboardPage } from 'features/game/navigation/model/common'
import { browserHistory } from 'lib/history'
import { useWindowSize } from 'lib/hooks/use-window-size'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { MultiChart } from 'ui/atoms/chart/multi'
import { Typography } from 'ui/atoms/typography'
import { LoadingOverflow } from 'ui/molecules/loading-overflow'
import { Scrollbars } from 'ui/molecules/scrollbars'

import { $roomStatistics, getRoomStatisticsFetching } from '../model'
import { TeamSelect } from './team-select'

const LEADERBOARD_ACTIONS = {
  true: {
    onClick: () => browserHistory.push('/game'),
    children: 'Go back to the game',
  },
  false: {
    onClick: () => browserHistory.push('/rooms'),
    children: 'Go to rooms list',
  },
}

const calcWidthForGraph = (windowWidth, qty) => Math.floor((windowWidth - 85 - 60 * qty) / qty)

const calcHeightForGraph = (windowHeight, qty) => Math.floor((windowHeight - 304 - 50 * qty) / qty)

export const RoomStatistics = ({ roomIDFromURL }) => {
  const statistics = useStore($roomStatistics)
  const title = useStore($statisticsTitle)
  const isPlayingNow = useStore($isNowPlaying)
  const user = useStore($user)
  const ableToChangeTeam = isTeamlead(user) || isAdmin(user)
  const loadingStatistics = useStore(getRoomStatisticsFetching.isLoading)
  const loadingViewGame = useStore(viewGameFetching.isLoading)
  // not implemented by backend yet
  // eslint-disable-next-line no-unused-vars
  const [team, setTeam] = useState(null)
  const windowSize = useWindowSize()
  const canPlaceNewRow = windowSize.height > 960
  const secondRowGraphWidth = calcWidthForGraph(windowSize.width, canPlaceNewRow ? 3 : 5)
  const thirdRowGraphWidth = calcWidthForGraph(windowSize.width, canPlaceNewRow ? 3 : 4)
  const fourthRowGraphWidth = canPlaceNewRow ? calcWidthForGraph(windowSize.width, 3) : 0
  const graphHeight = calcHeightForGraph(windowSize.height, canPlaceNewRow ? 3 : 2)
  window.log(statistics, 'statistics')
  return (
    <Container>
      <Inner>
        <StyledScrollbars>
          <HorizontalContainer>
            <HeaderCell first={!ableToChangeTeam}>
              <Typography centered size={theme.font.size.huge} weight="600">
                Statistics {title}
              </Typography>
            </HeaderCell>

            {ableToChangeTeam && (
              <HeaderCell last>
                <SelectLabel>
                  <b>Current</b> subject:{' '}
                </SelectLabel>
                <TeamSelect roomID={roomIDFromURL} />
              </HeaderCell>
            )}
          </HorizontalContainer>
          <RowSpacer />
          {!loadingStatistics && (
            <>
              <Row>
                <Cell>
                  <GaugeHeading>Min. Turn</GaugeHeading>
                  <BottomRow>
                    <BottomCell>
                      <Value>{statistics.minTurn.current}</Value>
                      <Label>Current</Label>
                    </BottomCell>
                    <BottomCell>
                      <Value>{formatPrice(statistics.minTurn.average, { disableCurrency: true })}</Value>
                      <Label>Average</Label>
                    </BottomCell>
                  </BottomRow>
                </Cell>
                <Cell>
                  <GaugeHeading>Max. Turn</GaugeHeading>
                  <BottomRow>
                    <BottomCell>
                      <Value>{statistics.maxTurn.current}</Value>
                      <Label>Current</Label>
                    </BottomCell>
                    <BottomCell>
                      <Value>{formatPrice(statistics.maxTurn.average, { disableCurrency: true })}</Value>
                      <Label>Average</Label>
                    </BottomCell>
                  </BottomRow>
                </Cell>
                <Cell>
                  <GaugeHeading>Dreams bought</GaugeHeading>
                  <BottomRow>
                    <BottomCell>
                      <Value>{statistics.readyDreamsCount.current}</Value>
                      <Label>Current</Label>
                    </BottomCell>
                    <BottomCell>
                      <Value>
                        {formatPrice(statistics.readyDreamsCount.average, { disableCurrency: true })}
                      </Value>
                      <Label>Average</Label>
                    </BottomCell>
                  </BottomRow>
                </Cell>
                <Cell>
                  <GaugeHeading>Achievements</GaugeHeading>
                  <BottomRow>
                    <BottomCell>
                      <Value>{statistics.readyGoalsCount.current}</Value>
                      <Label>Current</Label>
                    </BottomCell>
                    <BottomCell>
                      <Value>
                        {formatPrice(statistics.readyGoalsCount.average, { disableCurrency: true })}
                      </Value>
                      <Label>Average</Label>
                    </BottomCell>
                  </BottomRow>
                </Cell>
              </Row>
              <Row>
                <Cell>
                  <GaugeHeading>Points</GaugeHeading>
                  <BottomCell>
                    <StyledMultiChart
                      tooltipYFormatter={formatTooltipYPoints}
                      width={secondRowGraphWidth}
                      height={graphHeight}
                      data={statistics.totalPoints}
                    />
                  </BottomCell>
                </Cell>
                <Cell>
                  <GaugeHeading>Flowpoints</GaugeHeading>
                  <BottomCell>
                    <StyledMultiChart
                      tooltipYFormatter={formatTooltipYPoints}
                      width={secondRowGraphWidth}
                      height={graphHeight}
                      data={statistics.flowPoints}
                    />
                  </BottomCell>
                </Cell>
                <Cell>
                  <GaugeHeading>Endpoints</GaugeHeading>
                  <BottomCell>
                    <StyledMultiChart
                      tooltipYFormatter={formatTooltipYPoints}
                      width={secondRowGraphWidth}
                      height={graphHeight}
                      data={statistics.endPoints}
                    />
                  </BottomCell>
                </Cell>
                {!canPlaceNewRow && (
                  <Cell>
                    <GaugeHeading>Income</GaugeHeading>
                    <BottomCell>
                      <StyledMultiChart
                        width={secondRowGraphWidth}
                        height={graphHeight}
                        data={statistics.income}
                      />
                    </BottomCell>
                  </Cell>
                )}
                {!canPlaceNewRow && (
                  <Cell>
                    <GaugeHeading>Expenses</GaugeHeading>
                    <BottomCell>
                      <StyledMultiChart
                        width={secondRowGraphWidth}
                        height={graphHeight}
                        data={statistics.expenses}
                      />
                    </BottomCell>
                  </Cell>
                )}
              </Row>
              <Row>
                <Cell>
                  <GaugeHeading>Capital</GaugeHeading>
                  <BottomCell>
                    <StyledMultiChart
                      width={thirdRowGraphWidth}
                      height={graphHeight}
                      tooltipYFormatter={formatTooltipYMoney}
                      data={statistics.capital}
                    />
                  </BottomCell>
                </Cell>
                <Cell>
                  <GaugeHeading>Cushion</GaugeHeading>
                  <BottomCell>
                    <StyledMultiChart
                      width={thirdRowGraphWidth}
                      height={graphHeight}
                      tooltipYFormatter={formatTooltipYMoney}
                      data={statistics.cushion}
                    />
                  </BottomCell>
                </Cell>
                <Cell>
                  <GaugeHeading>Debts</GaugeHeading>
                  <BottomCell>
                    <StyledMultiChart
                      width={thirdRowGraphWidth}
                      height={graphHeight}
                      tooltipYFormatter={formatTooltipYMoney}
                      data={statistics.debts}
                    />
                  </BottomCell>
                </Cell>
              </Row>
              {canPlaceNewRow && (
                <Row>
                  <Cell>
                    <GaugeHeading>Income</GaugeHeading>
                    <BottomCell>
                      <StyledMultiChart
                        width={fourthRowGraphWidth}
                        height={graphHeight}
                        tooltipYFormatter={formatTooltipYMoney}
                        data={statistics.income}
                      />
                    </BottomCell>
                  </Cell>
                  <Cell>
                    <GaugeHeading>Expenses</GaugeHeading>
                    <BottomCell>
                      <StyledMultiChart
                        width={fourthRowGraphWidth}
                        height={graphHeight}
                        tooltipYFormatter={formatTooltipYMoney}
                        data={statistics.expenses}
                      />
                    </BottomCell>
                  </Cell>
                </Row>
              )}
            </>
          )}
          <LoadingOverflow withLoader absolute loading={loadingStatistics || loadingViewGame} />
        </StyledScrollbars>
      </Inner>
      <StyledButtonsSection space={25}>
        <StyledButton {...LEADERBOARD_ACTIONS[isPlayingNow]} />
        <StyledButton variant="greenOutlined" onClick={() => goToLeaderboardPage({ roomID: roomIDFromURL })}>
          Go to Leaderboard
        </StyledButton>
      </StyledButtonsSection>
    </Container>
  )
}

const HeaderCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 32%;
  ${ifProp(
    'first',
    `
    text-align: center;
    justify-content: center;
  `
  )}
  ${ifProp(
    'last',
    `
    justify-content: flex-end;
  `
  )}
`

const SelectLabel = styled(Typography)`
  margin-left: 25px;
`

const HorizontalContainer = styled.div`
  align-items: center;
  display: flex;
`

const StyledButtonsSection = styled(ButtonsSection)`
  margin: 0 auto;
  max-width: 520px;
`

const StyledMultiChart = styled(MultiChart)`
  margin-top: -25px;
  & .apexcharts-legend-text {
    color: ${theme.color.black} !important;
  }
`

const RowSpacer = styled.div`
  height: 0px;
`

const Value = styled.p`
  color: ${theme.color.black};
  font-size: ${theme.font.size.large + 2}px;
  font-weight: bold;
  margin: 0;
`

const Label = styled.p`
  color: ${theme.color.black};
  font-size: ${theme.font.size.main - 3}px;
  font-weight: bold;
  margin: 0;
  margin-top: 5px;
  opacity: 0.4;
`

const BottomCell = styled.div``

const BottomRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  & > *:last-child {
    margin-left: 7px;
  }
`

const GaugeHeading = styled.h4`
  color: ${theme.color.black};
  font-size: ${theme.font.size.large + 1}px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
`

const Cell = styled.div`
  align-items: center;
  background: ${theme.color.secondary};
  border-radius: ${theme.radius.form.action - 3}px;
  box-shadow: ${theme.shadow.loginForm};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 20px;
  min-width: 180px;
  padding: 10px;
  text-align: center;
`

const Row = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`

const StyledScrollbars = styled(Scrollbars)`
  flex: 1;
`

const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  padding: 0 40px;
`

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
  min-width: 250px;
`

const Container = styled.div`
  flex: 1;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 3vh;
  padding-top: 3vh;
  width: 100%;
`
