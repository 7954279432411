import { formatPrice } from 'features/game/lib/prices/format'
import { handleGoalStatusText, handleGoodStatus, handleRealEstateStatus } from 'lib/handle-fields'

export const getRowsByInstrumentType = (instrument): Array<Array<string>> => {
  const { instrumentType } = instrument
  let status
  switch (instrumentType) {
    case 'events':
      status = handleGoalStatusText(instrument.status)
      break
    case 'realEstate':
      status = handleRealEstateStatus(instrument)
      break
    default:
      status = handleGoodStatus(instrument)
      break
  }
  let rows = [['Status:', status]]
  //debugger
  if (instrument.annualExpense) {
    rows = [...rows, ['Annual expense:', formatPrice(instrument.annualExpense)]]
  }
  if (instrument.annualIncome) {
    rows = [...rows, ['Annual income:', formatPrice(instrument.annualIncome)]]
  }
  switch (instrumentType) {
    case 'services':
      rows = [
        ...rows,
        [
          'Starts in:',
          instrument.startTurn === 0 || !instrument.startTurn ? 'Instantly' : instrument.startTurn + ' turns',
        ],
        [
          'Ends in:',
          instrument.expirationTurn === 0 || !instrument.expirationTurn
            ? 'Never'
            : instrument.expirationTurn + ' turns',
        ],
      ]
      break
    case 'deposits':
    case 'credit':
      rows = [['Interest rate:', `${instrument.rate * 100}%`]]
      break
    default:
      break
  }
  return rows
}
