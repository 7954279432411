import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import { CharacterDetails } from 'features/game/character/details'
import { formatPrice } from 'features/game/lib/prices/format'
import { getStatusDelta } from 'features/game/lib/prices/get-status-and-delta'
import { selectRelatedInstrument } from 'features/game/lib/select-related-instrument'
import { actionsHistoryEnhanced, pointsHistoryEnhanced } from 'features/game/model'
import { DreamsBlock } from 'features/game/organisms/dreams'
import arrowDown from 'static/arrow-down-red.svg'
import arrowUp from 'static/arrow-up-green.svg'
import { Chart } from 'ui/atoms/chart'
import { Container } from 'ui/atoms/container'
import { Typography } from 'ui/atoms/typography'
import { Scrollbars } from 'ui/molecules/scrollbars'
import { theme } from 'theme'

import { InstrumentTable } from '../../subtab-table/instrument-table'
import { COLUMNS } from '../../subtab-table/instrument-table/columns'
import { SpendingsBarChart } from './col-chart'

const Heading = ({ children }) => (
  <StyledHeading size={theme.font.size.huge} weight="bold">
    {children}
  </StyledHeading>
)

const Graph = ({ label, previous, current, data, children, isCushion }) => {
  if (isCushion) {
    return (
      <GraphInner>
        <Chart width="350px" data={data} />
        <GraphHint>
          <Typography weight="600">{label}:</Typography>
          <Price status="neutral">{children}</Price>
        </GraphHint>
      </GraphInner>
    )
  }
  const { status, deltaAbs } = getStatusDelta(current, previous)
  return (
    <GraphInner>
      <Chart width="350px" data={data} />
      <GraphHint>
        <Typography weight="600">Total {label}:</Typography>
        <Price status={status}>
          {children}
          {status !== 'neutral' && <ArrowIcon src={status === 'up' ? arrowUp : arrowDown} />}
          {deltaAbs}
        </Price>
      </GraphHint>
    </GraphInner>
  )
}

export const ProfileMain = ({
  className,
  satisfactionLevels,
  dreams,
  balance,
  bonuses,
  charts,
  character,
  onClose,
  bgImg,
}) => {
  const filteredDreams = dreams.slice(0, 2).map((el) => ({
    ...el,
    key: el.id,
    disabled: balance.cash.current < el.sum,
    onClick: () => {
      selectRelatedInstrument(el.id, 'dreams')
      onClose()
    },
  }))
  const showDreams = filteredDreams.length > 0
  return (
    <Outer {...{ bgImg, className }}>
      <Scrollbars trackOffset={25} trackHeight="calc(100% - 32px)" top="16px">
        <StyledContainer>
          {showDreams && <Heading>Dreams</Heading>}
          {showDreams && <DreamsBlock dreams={filteredDreams} />}
          <Heading>Charts</Heading>
          <GraphsContainer>
            <Graph label="capital" data={charts.capital} {...balance.capital}>
              {formatPrice(balance.capital.current)}
            </Graph>
            <Graph label="points" data={charts.points} {...balance.points}>
              {formatPrice(balance.points.current)}
            </Graph>
            {/* @TODO: график с тремя осями для структуры портфеля */}
            {/* @TODO: график подушки сделать синим */}
            <Graph isCushion label="Safety cushion" data={charts.cushion}>
              {formatPrice(balance.cushion.current.absolute)}
            </Graph>
          </GraphsContainer>
          <Columns>
            <Col>
              <Heading>Character Information</Heading>
            </Col>
            <Col>
              <Heading>Life Condition</Heading>
            </Col>
          </Columns>
          <InfoContainer>
            <StyledCharacterDetails character={character} haveControls={false} />
            <SpendingsBarChart levels={satisfactionLevels} />
          </InfoContainer>
          <Columns>
            <Col>
              <Heading>Actions, that affected on points</Heading>
              <PointsContainer>
                <InstrumentTable
                  noScrollbars
                  groupByKey="turn"
                  columns={COLUMNS.pointsHistory}
                  instrumentStore={pointsHistoryEnhanced}
                />
              </PointsContainer>
            </Col>
            <Col>
              <Heading>Events and theirs consequences</Heading>
              <InstrumentTable
                noScrollbars
                groupByKey="turn"
                columns={COLUMNS.actionsHistory}
                instrumentStore={actionsHistoryEnhanced}
              />
            </Col>
          </Columns>
        </StyledContainer>
      </Scrollbars>
    </Outer>
  )
}

const Col = styled.div`
  flex: 1;
  &:last-of-type {
    margin-left: 30px;
  }
`

const Columns = styled.div`
  display: flex;
`

const PointsContainer = styled.div`
  height: 300px;
  max-width: 700px;
`

const StyledCharacterDetails = styled(CharacterDetails)`
  margin-right: 30px;
`

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  // height: 450px;
  & > *:first-child {
    flex: 1;
  }
  & > *:last-child {
    flex: 1;
  }
  & > *:last-child {
    height: auto;
  }
`

const ArrowIcon = styled.img`
  height: 14px;
  margin-left: 10px;
  margin-right: 7px;
  width: 12px;
`

const GraphHint = styled.div`
  margin-top: 16px;
  padding-left: 45px;
`

const GraphInner = styled.div`
  margin: 25px;
  // &:first-of-type {
  //   margin-left: 0px;
  // }
  // &:last-of-type {
  //   margin-right: 0px;
  // }
  & > *:first-child {
    margin-left: -15px;
    margin-top: -20px;
  }
`

const GraphsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
`

const Price = styled(Typography)`
  color: ${(props) =>
    props.status === 'up'
      ? theme.color.green
      : props.status === 'down'
      ? theme.color.red
      : theme.color.black};
  margin-top: 5px;
`

const StyledHeading = styled(Typography)`
  margin: 20px 0;
`

const StyledContainer = styled(Container)`
  height: 100%;
`

const Outer = styled.div`
  background: url('${prop('bgImg')}') ${theme.color.thirdly};
  background-size: cover;
  height: 100%;
  overflow: hidden;
  width: 100%;
`
