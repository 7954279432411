import React from 'react'
import styled from 'styled-components'
import { Typography } from 'ui/atoms/typography'
import { formatPrice } from 'features/game/lib/prices/format'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import { imgSrc } from 'features/game/lib/img-src'
import { ActionButton } from 'ui/atoms/button/action-button'
import { ifProp, ifNotProp } from 'styled-tools'
import dreamNum1 from 'static/dream-num-1.svg'
import dreamNum2 from 'static/dream-num-2.svg'

export const DreamsBlock = ({ dreams }) => {
  return (
    <DreamsList>
      {dreams.map(({ name, sum, image, onClick, key, disabled }, idx) => (
        <Dream {...{ onClick, key, disabled }}>
          <DreamTitle first={idx === 0}>
            {name}
            {!onClick && (
              <DreamPrice noMl={!onClick} color={hexToRGBA(theme.color.black, 0.7)} weight="600">
                {formatPrice(sum)}
              </DreamPrice>
            )}
          </DreamTitle>
          <DreamImg src={imgSrc(image, 'dream-thumbnail')} />
          {onClick && (
            <DreamFooter>
              <BuyButton disabled={disabled} type="buy">
                Buy
              </BuyButton>
              <DreamPrice color={hexToRGBA(theme.color.black, 0.7)} weight="600">
                {formatPrice(sum)}
              </DreamPrice>
            </DreamFooter>
          )}
        </Dream>
      ))}
    </DreamsList>
  )
}

const DreamPrice = styled(Typography)`
  ${ifNotProp('noMl', 'margin-left: 15px;')}
`
const BuyButton = styled(ActionButton)`
  min-width: 140px;
`
const DreamFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`

const DreamImg = styled.img`
  border-radius: ${theme.radius.button.medium}px;
  height: 220px;
  margin-bottom: 10px;
  object-fit: cover;
  width: 480px;
`

const DreamTitle = styled.p`
  color: ${theme.color.black};
  font-size: 15px;
  font-style: italic;
  font-weight: 600;
  line-height: 1.35;
  margin-bottom: 15px;
  max-width: 60%;
  min-height: 52px;
  padding-left: 12px;
  position: relative;
  &::before {
    background-image: url(${dreamNum1});
    content: ' ';
    display: block;
    height: 52px;
    left: -20px;
    position: absolute;
    top: 0px;
    width: 23px;
  }
  ${ifNotProp(
    'first',
    `
  &::before {
    background-image: url(${dreamNum2});
    width: 30px;
    left: -26px;
  }
  `
  )}
`

const Dream = styled.li`
  flex: 1;
  ${ifProp(
    'disabled',
    `
    //opacity: 0.5;
  `
  )}
  &:not(:first-of-type):last-of-type {
    margin-left: 60px;
  }
`

const DreamsList = styled.ul`
  display: flex;
  margin-bottom: 30px;
`
