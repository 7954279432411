import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

import { resetModal } from '../model'

export const ModalRoot = ({ children, modal }) => {
  const child = React.Children.map(children, (child) =>
    child.props.id === modal?.id ? child : null
  ).filter((el) => Boolean(el))
  const onContainerClick = (ev) => {
    ev.stopPropagation()
    if (!child[0]?.props?.noBackdropClose) modal?.onCancel ? modal.onCancel() : resetModal()
  }
  return (
    <Container onClick={onContainerClick} visible={child.length > 0}>
      {child}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  background: ${hexToRGBA(theme.color.white, 0.55)};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s ease-in-out;
  z-index: 100;
  & > * {
    transition: all 0.25s ease-in-out;
  }
  ${ifProp(
    'visible',
    `
    opacity: 1;
    transform: translateY(0vh);
  `,
    `
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10vh);
  `
  )}
`
