import { useStore } from 'effector-react'
import React, { useEffect } from 'react'

import { $user } from 'features/auth'
import { RoomsList } from 'features/rooms/list'
import { getRooms } from 'features/rooms/model'

export const RoomsListPage = () => {
  const hasUser = useStore($user)
  useEffect(() => {
    if (hasUser) getRooms()
  }, [hasUser])
  return <RoomsList />
}
