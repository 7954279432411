import { useStore } from 'effector-react'
import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { $canUseTimeline, $user } from 'features/auth'
import {
  $autobuyOn,
  $events,
  endTurn,
  endTurnFetching,
  finishGame,
  finishGameFetching,
  toggleAutobuy,
} from 'features/game/model'
import { setSelectedInstrument } from 'features/game/model/state'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { useKeypress } from 'lib/hooks/use-keypress'
import eventIcon from 'static/error.svg'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Highlight } from 'ui/atoms/highlight'
import { Typography } from 'ui/atoms/typography'
import { Tooltip } from 'ui/molecules/tooltip'

import { $selectedSubTab, $selectedTab, setSelectedSubTab, setSelectedTab } from '../model'
import { $gameID, $roomID } from '../model/common'
import { STATIC_TABS_STRUCTURE, ALL_SUBTABS } from '../model/static-structure'

const LAST_SUBTAB_MARGIN = 7

export const FoldersAndTabs = () => {
  const selectedTab = useStore($selectedTab)
  const selectedSubTab = useStore($selectedSubTab)
  const finishGameLoading = useStore(finishGameFetching.isLoading)
  const endTurnLoading = useStore(endTurnFetching.isLoading)
  const events = useStore($events)
  const isAdmin = useStore($canUseTimeline)
  const autobuyOn = useStore($autobuyOn)
  const user = useStore($user)
  const roomID = useStore($roomID)
  const gameID = useStore($gameID)
  const hasEvents = events.length > 0
  const hasNewEvents = events.filter((el) => el.status === 'appointed').length > 0
  const changeNavByKeyboard = (key) => {
    const currentIdx = ALL_SUBTABS.findIndex((el) => el.root === selectedTab && el.key === selectedSubTab)
    window.log(currentIdx, 'currentIdx', 'key')
    if (currentIdx > -1) {
      let futureIdx
      switch (key) {
        case 'left':
          futureIdx = currentIdx - 1
          break
        default:
          futureIdx = currentIdx + 1
          break
      }
      if (futureIdx + 1 > ALL_SUBTABS.length) futureIdx = ALL_SUBTABS.length - 1
      if (futureIdx < 0) futureIdx = 0
      const foundTab = ALL_SUBTABS[futureIdx]
      setSelectedInstrument(null)
      setSelectedTab(foundTab.root)
      setSelectedSubTab(foundTab.key)
    }
  }
  useKeypress('KeyA', () => changeNavByKeyboard('left'))
  useKeypress('KeyD', () => changeNavByKeyboard('right'))
  useKeypress('KeyN', endTurn)
  return (
    <Container>
      <TabsContainer>
        {STATIC_TABS_STRUCTURE.filter(({ key }) => !['turnActions', 'events'].includes(key)).map(
          ({ name, key, subtabs }, idx, arr) => {
            const last = idx === arr.length - 1
            return (
              <Cell key={idx} flex={last}>
                <TopContainer>
                  <FolderItem onClick={() => setSelectedTab(key)} active={selectedTab === key} key={key}>
                    {name}
                    <StyledHighlight type="tabs" containerOffset={-5} width={50} />
                  </FolderItem>
                  {idx === 0 && (
                    <MetaInfoContainer>
                      <p>
                        {user?.name ? 'User' : 'User ID'}: <span>{user?.name || user?.uid}</span>
                      </p>
                      <p>
                        <span>{roomID}</span>, <span>{gameID}</span>,
                        <span>{(process.env.REACT_APP_COMMIT_HASH || '0').slice(-7)}</span>
                      </p>
                    </MetaInfoContainer>
                  )}
                  {last && (
                    <Right>
                      <FolderItem
                        data-test="turn-actions-button"
                        onClick={() => setSelectedTab('turnActions')}
                        active={selectedTab === 'turnActions'}
                        key={'turnActions'}
                        mr={10}
                      >
                        Turn actions
                        <StyledHighlight type="tabs" containerOffset={-5} width={50} />
                      </FolderItem>
                      {hasEvents && (
                        <EventsTab
                          color={theme.color.red}
                          desaturate={!hasNewEvents}
                          active={selectedTab === 'events'}
                          onClick={() => setSelectedTab('events')}
                        >
                          Events
                          {hasNewEvents && <EventsIcon animation={hasNewEvents} src={eventIcon} />}
                        </EventsTab>
                      )}
                      <Tooltip
                        interactive={false}
                        maxWidth={280}
                        content={
                          <Typography weight="500" size={theme.font.size.small}>
                            Automatically buy assets with free cash at the beggining of the turn
                            proportionally to the current portfolio structure.
                          </Typography>
                        }
                      >
                        <ActionsTab
                          color={theme.color.green}
                          data-test="auto-buy-button"
                          active={autobuyOn}
                          onClick={() => toggleAutobuy()}
                        >
                          Auto-buy
                        </ActionsTab>
                      </Tooltip>
                      {isAdmin && (
                        <EndButton
                          loading={finishGameLoading}
                          onClick={finishGame}
                          block
                          variant="outlinedLight"
                        >
                          End game
                        </EndButton>
                      )}
                      <EndButton
                        data-test="end-turn-button"
                        loading={endTurnLoading}
                        onClick={endTurn}
                        block
                        variant="outlinedLight"
                      >
                        End turn
                      </EndButton>
                    </Right>
                  )}
                </TopContainer>
                <SubTabsContainer>
                  {subtabs.map((sbtb) => (
                    <SubTabFolderItem
                      active={selectedSubTab === sbtb.key}
                      onClick={() => setSelectedSubTab(sbtb.key)}
                      key={sbtb.key}
                      data-test={`subtab-${sbtb.key}`}
                    >
                      {sbtb.name}
                      <StyledHighlight type="tabs" containerOffset={-5} width={50} />
                    </SubTabFolderItem>
                  ))}
                </SubTabsContainer>
              </Cell>
            )
          }
        )}
      </TabsContainer>
    </Container>
  )
}

const MetaInfoContainer = styled.div`
  margin-right: 7px;
  max-width: 310px;
  text-align: right;
  user-select: none;
  & > * {
    color: ${hexToRGBA(theme.color.black, 0.3)};
    font-size: ${theme.font.size.extrasmall - 1}px;
    font-weight: 600;
    white-space: nowrap;
    & > span {
      color: ${hexToRGBA(theme.color.black, 0.7)};
      font-size: ${theme.font.size.extrasmall}px;
      font-weight: bold;
    }
  }
`

const EndButton = styled(Button)`
  font-weight: bold;
  line-height: 1.3;
  margin-left: 7px;
  padding: 4px 10px;
  @media (max-width: 1300px) {
    font-size: ${theme.font.size.main - 1}px;
  }
`

const Right = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

const StyledHighlight = styled(Highlight)``

const ActionsTab = styled.li`
  border: 1px solid ${prop('color')};
  border-radius: ${theme.radius.button.small}px;
  color: ${theme.color.black};
  cursor: pointer;
  @media (max-width: 1300px) {
    font-size: ${theme.font.size.main - 1}px;
  }
  font-size: 16px;
  font-weight: bold;
  line-height: 134.75%;
  padding: 4px 10px;
  position: relative;
  transition: all 0.15s ease-in-out;
  user-select: none;
  &:hover {
    background: ${prop('color')};
  }
  ${ifProp(
    'active',
    css`
      background: ${prop('color')};
      &::after {
        border-bottom: 6px solid ${theme.color.black};
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid transparent;
        bottom: -1px;
        content: ' ';
        left: calc(50% - 3px);
        position: absolute;
      }
    `
  )}
`

const EventsIcon = styled.img`
  height: 18px;
  margin-left: 7px;
  ${ifProp(
    'animation',
    `
  @keyframes scale-up-center {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: scale-up-center 0.4s ease-in-out infinite alternate both;
  `
  )}
`

const EventsTab = styled(ActionsTab)`
  align-items: center;
  color: ${theme.color.red};
  display: flex;
  margin-right: 7px;
  transition: all 0.2s ease-in-out;
  user-select: none;
  @media (max-width: 1300px) {
    font-size: ${theme.font.size.main - 1}px;
  }
  ${ifProp('desaturate', 'filter: saturate(0) brightness(1.2);')}
  &::after {
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
    bottom: -1px;
    content: ' ';
    left: calc(50% - 3px);
    position: absolute;
    transition: all 0.15s ease-in-out;
  }
  &:hover {
    background: transparent;
    &::after {
      border-bottom-color: ${theme.color.red};
    }
  }
  ${ifProp(
    'active',
    `
background: transparent;
&::after {
  border-bottom-color: ${theme.color.red};
}
`
  )}
`

const Container = styled.div`
  box-shadow: ${theme.shadow.loginForm};
  font-weight: bold;
  margin-bottom: 15px;
`

const TopContainer = styled.div`
  background: ${theme.color.secondary};
  display: flex;
  justify-content: space-between;
  min-height: 42px;
  overflow: hidden;
  padding: 5px;
`

const TabsContainer = styled.div`
  border-radius: ${theme.radius.button.small}px;
  display: flex;
  overflow: hidden;
`

const SubTabsContainer = styled(TabsContainer)`
  background: linear-gradient(90deg, ${theme.color.darkgray} 61.08%, ${theme.color.secondary} 100%);
  border-radius: 0px;
  display: flex;
  justify-content: flex-start;
  min-height: 35px;
  padding: 5px;
  position: relative;
`

const Cell = styled.div`
  ${ifProp('flex', 'flex: 1;')}
  position: relative;
  &:not(:last-of-type) > * {
    padding-right: 7px;
  }
  &:not(:first-of-type) {
    &::before {
      background: linear-gradient(90deg, transparent, ${theme.color.thirdly});
      bottom: -41px;
      content: ' ';
      left: -15px;
      position: absolute;
      top: -6px;
      width: 15px;
    }
  }
`

const FolderItem = styled.li`
  position: relative;
  cursor: pointer;
  min-height: 30px;
  padding: 0 7px;
  font-size: ${theme.font.size.main - 1}px;
  user-select: none;
  @media (max-width: 1300px) {
    font-size: ${theme.font.size.small}px;
  }
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? theme.color.primary : theme.color.gray400)};
  transition: all 0.15s ease-in-out;
  margin-right: ${prop('mr', '0')}px;
  ${StyledHighlight} {
    width: calc(150%);
    left: -25%;
    height: calc(100% + 10px);
    top: -5px;
    bottom: -5px;
  }
  ${ifProp(
    'active',
    `
    ${StyledHighlight} {
      opacity: 1;
    }
  `
  )}
  &:hover {
    ${StyledHighlight} {
      opacity: 1;
    }
    // color: ${theme.color.black};
  }
  &:not(:last-of-type) {
    position: relative;
    z-index: 1;
  }
`
const SubTabFolderItem = styled(FolderItem)`
  font-size: ${theme.font.size.small}px;
  min-height: 25px;
  ${ifProp(
    'last',
    css`
      &:not(:last-of-type) {
        margin-right: ${LAST_SUBTAB_MARGIN}px;
      }
    `
  )}
`
