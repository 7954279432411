import React, { useMemo } from 'react'
// import HeightTransition from 'react-height-transition'
import HeightTransition from 'react-animate-height'
import { useExpanded, useFlexLayout, useGroupBy, useTable } from 'react-table'
import styled from 'styled-components'
import { ifProp, prop, switchProp } from 'styled-tools'
import downIcon from 'static/down-arrow.svg'
import { theme } from 'theme'
import { Scrollbars } from 'ui/molecules/scrollbars'
import { Typography } from 'ui/atoms/typography'
import { OverflowTooltip } from 'ui/molecules/overflow-tooltip'
import { transformColumns } from 'ui/organisms/new-table/lib/transform-columns'
import { prepareRatioColumns } from './lib/prepare-ratio-columns'

const EXAMPLE_RATIO = [
  { description: 'base value', typeName: 'base value', value: 1 },
  {
    description: 'Good financial cushion (-0.00 annual incomes) on turn 2',
    typeName: 'financial cushion',
    value: 0.01,
  },
  {
    description: 'Good share of mid-risk instruments (0.00 of portfolio) on turn 2',
    typeName: 'portfolio structure',
    value: 0.002,
  },
  {
    description: 'Good share of mid-risk instruments (0.00 of portfolio) on turn 3',
    typeName: 'portfolio structure',
    value: 0.002,
  },
  {
    description: 'Good share of mid-risk instruments (0.00 of portfolio) on turn 4',
    typeName: 'portfolio structure',
    value: 0.002,
  },
  {
    description: 'Good share of mid-risk instruments (0.02 of portfolio) on turn 5',
    typeName: 'portfolio structure',
    value: 0.002,
  },
  {
    description: 'Good share of mid-risk instruments (0.02 of portfolio) on turn 6',
    typeName: 'portfolio structure',
    value: 0.002,
  },
  {
    description: 'Good share of mid-risk instruments (0.03 of portfolio) on turn 7',
    typeName: 'portfolio structure',
    value: 0.002,
  },
  {
    description: 'Good share of mid-risk instruments (0.03 of portfolio) on turn 8',
    typeName: 'portfolio structure',
    value: 0.002,
  },
]

const COLUMNS = [
  {
    key: 'typeName',
    label: () => null,
    render: () => null,
    groupBy: true,
    width: '15px',
  },
  {
    key: 'description',
    minWidth: '120px',
    maxWidth: '600px',
    flex: true,
    render: (data) => (
      <OverflowTooltip>
        <Typography oneLine color={theme.color.gray400} weight="600" size={theme.font.size.small}>
          {data}
        </Typography>
      </OverflowTooltip>
    ),
    Aggregated: ({ row }) => (
      <OverflowTooltip>
        <Typography oneLine color={theme.color.gray400} weight="600" size={theme.font.size.small}>
          {row.values.typeName}
        </Typography>
      </OverflowTooltip>
    ),
  },
  {
    key: 'value',
    width: '50px',
    align: 'right',
    aggregate: 'sum',
    Aggregated: ({ value }) => (
      <Typography oneLine weight="500" size={theme.font.size.small}>
        x {value}
      </Typography>
    ),
    render: (data) => (
      <Typography oneLine weight="500" size={theme.font.size.small}>
        x {data}
      </Typography>
    ),
  },
]

export const RatioTable = ({
  data = EXAMPLE_RATIO,
  columns = COLUMNS,
  className,
  bgRow,
  type,
  marginCols = 4,
  marginRows = 5,
  marginHeader = 10,
}) => {
  const groupByKey = useMemo(() => columns.find((el) => el.groupBy)?.key, [columns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsExpanded,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns: useMemo(() => transformColumns(prepareRatioColumns(columns, data, type)), [
        columns,
        data,
        type,
      ]),
      data: useMemo(() => data, [data]),
      autoResetSelectedRows: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      aggregations: { noop: () => null },
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            groupBy: [groupByKey],
          }),
          [state]
        )
      },
    },
    useGroupBy,
    useFlexLayout,
    useExpanded
  )
  return (
    <UITable {...getTableProps()} {...{ className }}>
      <HeightTransition height="auto" initial={0} duration={600} easing="ease-in-out">
        <Head>
          {headerGroups.map((headerGroup) => (
            <HeadRow
              {...{ bgRow, marginCols, marginRows, marginHeader }}
              {...headerGroup.getHeaderGroupProps()}
            >
              <>
                {headerGroup.headers.map((column, idx) => {
                  const { paddingLeft, align, isGrouped } = column
                  return (
                    <HeadCell
                      {...{ paddingLeft, bgRow, align, isGrouped }}
                      {...column.getHeaderProps()}
                      first={idx === 0}
                      //   onClick={id === 'expander' ? null : () => onHeadCellClick(id, sortFn)}
                    >
                      {column.isGrouped && (
                        <MinimizeIcon
                          src={downIcon}
                          isMaximized={isAllRowsExpanded}
                          onClick={(ev) => {
                            ev.stopPropagation()
                            toggleAllRowsExpanded()
                          }}
                        />
                      )}
                      {column.render('label')}
                    </HeadCell>
                  )
                })}
              </>
            </HeadRow>
          ))}
        </Head>
      </HeightTransition>
      <Scrollbars>
        <HeightTransition height="auto" initial={0} duration={600} easing="ease-in-out">
          {data?.length > 0 && (
            <Body {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <Row
                    {...{ marginCols, marginRows }}
                    onClick={() => {
                      if (row.isGrouped) {
                        row.toggleRowExpanded()
                      }
                    }}
                    isGrouped={row.isGrouped}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell, i) => {
                      const { align, wrapLine, fontFamily, paddingLeft, noTooltip } = cell.column
                      return (
                        <Cell
                          isGrouped={cell.isGrouped}
                          {...{ align, wrapLine, fontFamily, paddingLeft }}
                          first={i === 0}
                          {...cell.getCellProps()}
                        >
                          {cell.isGrouped ? (
                            <>
                              <MinimizeIcon src={downIcon} isMaximized={row.isExpanded} />
                              <OverflowTooltip disabled={noTooltip}>{cell.render('Cell')}</OverflowTooltip>
                            </>
                          ) : cell.isAggregated ? (
                            <OverflowTooltip disabled={noTooltip}>
                              {cell.render('Aggregated')}
                            </OverflowTooltip>
                          ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            <OverflowTooltip disabled={noTooltip}>{cell.render('Cell')}</OverflowTooltip>
                          )}
                        </Cell>
                      )
                    })}
                  </Row>
                )
              })}
            </Body>
          )}
        </HeightTransition>
      </Scrollbars>
    </UITable>
  )
}

const Body = styled.div`
  flex: 1;
`

const UITable = styled.div`
  //height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Cell = styled.div`
  align-items: center;
  ${switchProp('align', {
    left: `
        justify-content: flex-start;
      `,
    center: 'justify-content: center;',
    right: 'justify-content: flex-end;',
  })}
  ${ifProp('isGrouped', 'padding-right: 5px;')}
  display: flex;
  text-align: ${prop('align', 'left')};
`

const Row = styled.div`
  ${ifProp('isGrouped', 'cursor: pointer;')}
  & > ${Cell}:last-of-type {
    padding-left: ${prop('marginCols')}px;
  }
  &:not(:last-of-type) {
    padding-bottom: ${prop('marginRows')}px;
  }
`

const HeadCell = styled(Cell)`
  background: ${prop('bgRow', 'transparent')};
`

const Head = styled(Row)`
  margin-bottom: ${prop('marginHeader')}px;
`

const HeadRow = styled(Row)`
  margin-bottom: ${prop('marginHeader')}px;
`

const MinimizeIcon = styled.img`
  cursor: pointer;
  height: 10px;
  opacity: 0.4;
  transform: rotate(-90deg);
  transition: all 0.2s ease-in-out;
  width: 10px;
  ${ifProp(
    'isMaximized',
    `
    opacity: 0.8;
    transform: rotate(0deg);
  `
  )}
  &:hover {
    opacity: 1;
  }
`
