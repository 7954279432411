import Tippy from '@tippyjs/react'
import React, { useState } from 'react'
import { animateFill } from 'tippy.js'

import 'tippy.js/animations/shift-away.css'
import 'tippy.js/dist/backdrop.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/dist/tippy.css'
import styled from 'styled-components'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

const tippyOpts = {
  plugins: [animateFill],
  animateFill: true,
  interactiveBorder: 5,
  arrow: true,
  maxWidth: 'none',
  // placement: 'top-start',
}

type TooltipProps = {
  children: React.ReactElement
  content: React.ReactElement
  interactive?: boolean
  wrapByDiv?: boolean
  maxWidth?: number
}

export const Tooltip = ({ children, content, interactive, wrapByDiv, maxWidth }: TooltipProps) => {
  const [mounted, setMounted] = useState(false)
  const enhancedChild = wrapByDiv ? <div>{children}</div> : children
  return (
    <StyledTippy
      onShow={() => setMounted(true)}
      onHidden={() => setMounted(false)}
      content={mounted ? content : ''}
      {...tippyOpts}
      {...{ interactive, maxWidth }}
    >
      {enhancedChild}
    </StyledTippy>
  )
}

const StyledTippy = styled(Tippy)`
  & .tippy-backdrop {
    background-color: ${theme.color.thirdly};
  }
  & {
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;
  }
  &[data-state='visible'] {
    border: 1px solid ${hexToRGBA(theme.color.green, 0.5)};
    box-shadow: ${theme.shadow.loginForm};
  }
`
