import { uuid } from 'uuidv4'

export const FINANCIAL_SETTINGS_ID = 'financialSettings'

export const addIncomeExpenses = (state) => {
  let all = []

  Object.entries(state.incomeAndExpenses).forEach((tabEntry) => {
    const subTabKey = tabEntry[0]
    const subTab = tabEntry[1]
    subTab.forEach((instrument) => {
      instrument.balanceType = subTabKey
      instrument.frontId = instrument.id + uuid()
      instrument.instrumentType = 'incomeAndExpenses'
      all.push(instrument)
    })
  })
  return {
    ...state,
    incomeAndExpenses: all,
  }
}
