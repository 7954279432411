import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { $loadingPercent, preloadResources } from 'lib/caching'
import logo from 'static/logo.svg'
import { theme } from 'theme'
import { Container } from 'ui/atoms/container'
import { Typography } from 'ui/atoms/typography'

export const PreloadPage = () => {
  const percent = useStore($loadingPercent)
  useEffect(() => {
    preloadResources()
  }, [])
  return (
    <StyledContainer>
      <Inner>
        <Logo src={logo} />
        <Typography size={theme.font.size.huge * 1.3} weight="500">
          Loading resources...
        </Typography>
        <Percent weight="600" color={theme.color.primary} size={theme.font.size.huge * 2}>
          {Number.isNaN(percent) ? 0 : percent}%
        </Percent>
      </Inner>
    </StyledContainer>
  )
}

const Logo = styled.img`
  margin-bottom: 30px;
  position: absolute;
  top: 40px;
  width: 200px;
`

const Percent = styled(Typography)`
  margin-top: 20px;
`

const Inner = styled.div`
  align-items: center;
  background: ${theme.color.secondary};
  border-radius: ${theme.radius.button.medium}px;
  box-shadow: ${theme.shadow.loginForm};
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
  position: relative;
  width: 70vh;
`
const StyledContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: center;
`
