import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import { theme } from 'theme'

export const HeadingWithStripe = ({
  className,
  fontSize = theme.font.size.large,
  stripeLeft = -30,
  stripeTop = 4,
  stripeHeight = 20,
  children,
}) => {
  return (
    <StyledHeadingWithStripe
      fontSize={fontSize}
      stripeTop={stripeTop}
      stripeHeight={stripeHeight}
      className={className}
      stripeLeft={stripeLeft}
    >
      {children}
    </StyledHeadingWithStripe>
  )
}

export const StyledHeadingWithStripe = styled.h3`
  color: ${theme.color.black};
  font-size: ${prop('fontSize')}px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  position: relative;
  &::after {
    background: ${theme.color.green};
    content: ' ';
    height: ${prop('stripeHeight')}px;
    left: ${prop('stripeLeft')}px;
    opacity: 0.4;
    position: absolute;
    top: ${prop('stripeTop')}px;
    width: 3px;
  }
`
