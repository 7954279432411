import React from 'react'
import ApexCharts from 'react-apexcharts'
import styled from 'styled-components'

import { generateCushionProps } from 'features/game/field/organisms/top-bar/levels/lib/generate-colors'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

const CHART = [34]
export const GaugeChart = ({ data = CHART, height = 210, className, level }) => {
  let series = data
  let chartOpts = {
    chart: { height },
    plotOptions: {
      radialBar: {
        offsetX: -50,
        offsetY: -30,
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 0,
        },
        track: {
          background: '#333',
          startAngle: -90,
          endAngle: 90,
        },
        dataLabels: {
          show: false,
          name: {
            show: false,
          },
          value: {
            show: false,
          },
          total: {
            show: false,
            label: 'Total',
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        gradientToColors: ['#87D4F9'],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'butt',
    },
  }
  if (level) {
    const cushionProps = generateCushionProps(level)
    chartOpts.colors = [cushionProps.startColor]
    chartOpts.fill.gradient.gradientToColors = [cushionProps.endColor]
    series = [cushionProps.level]
  }
  return (
    data &&
    data.length > 0 && (
      <ChartContainer {...{ className }}>
        <ApexCharts options={chartOpts} {...{ height, series }} type="radialBar" />
      </ChartContainer>
    )
  )
}

const ChartContainer = styled.div`
  & .apexcharts-legend-marker {
    margin-right: 8px;
    right: 15px;
    top: 2px;
  }
  & .apexcharts-tooltip-text-value {
    font-weight: normal;
  }
  & .apexcharts-tooltip-text-label {
    font-weight: 600;
  }
  & .apexcharts-tooltip-y-group {
    padding-bottom: 0;
    padding-top: 5px;
  }
  & .apexcharts-tooltip-marker {
    margin-right: 6px;
    top: 2px;
  }
  & .apexcharts-tooltip {
    box-shadow: 0px 0px 7px ${hexToRGBA(theme.color.thirdly, 0.8)};
  }
  & .apexcharts-legend-series {
    align-items: center;
    display: flex;
  }
`
