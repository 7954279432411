import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'
import { uuid } from 'uuidv4'

import { setModal } from 'features/common/organisms/modal/model'
import { Divider } from 'features/game/field/atoms/divider'
import { TabInnerSection } from 'features/game/field/atoms/tab-inner-section'
import { ActionInput } from 'features/game/field/molecules/action-input'
import { Label, Row, SubHeading, Value } from 'features/game/field/molecules/row-elements'
import { useQtyInput } from 'features/game/lib/hooks/use-qty-input'
import { formatPrice } from 'features/game/lib/prices/format'
import { makeTransaction, makeTransactionFetching } from 'features/game/model'
import { InstrumentTypes } from 'features/game/types'
import { theme } from 'theme'
import { ActionButton } from 'ui/atoms/button/action-button'
import { Error } from 'ui/atoms/form'

import { calcAnnualPayment } from '../../lib/calculate-annual-payment.js'

export const BankingActionTab = ({ selectedInstrument }) => {
  const { isPortfolio, name, rate, instrumentType } = selectedInstrument
  const isCredit = ['credit', 'leverage'].includes(instrumentType)
  const actionError = useStore(makeTransactionFetching.error)
  const loading = useStore(makeTransactionFetching.isLoading)
  const transactionIsDone = useStore(makeTransactionFetching.isDone)
  const [onSumInputChange, sumInput, sum, inputRef] = useQtyInput(
    selectedInstrument,
    [transactionIsDone],
    false,
    isPortfolio ? Math.round(selectedInstrument.amount) : 1
  )
  const [onYearsInputChange, yearsInput, years, inputRefYears] = useQtyInput(
    selectedInstrument,
    [transactionIsDone],
    true,
    1
  )
  const newRate = rate || 0
  const interestSubject = isPortfolio ? selectedInstrument.rest : sum
  const payment = isPortfolio ? selectedInstrument.payment : calcAnnualPayment(sum, years, newRate)
  const totalInterestNum = isPortfolio
    ? selectedInstrument.percentSum
    : years * payment - interestSubject || 0
  const futurePayments = formatPrice(interestSubject + totalInterestNum)
  const canWithdraw = sum <= selectedInstrument.amout
  const onOpen = () => {
    if (instrumentType === InstrumentTypes.Credit) {
      setModal({
        id: 'open-credit-confirm',
        onSuccess: () => {
          makeTransaction({
            id: selectedInstrument.id,
            value: sum,
            actionType: 'buy',
            instrumentType: instrumentType,
            uid: uuid(),
            term: years,
            portfolioId: selectedInstrument.portfolio_id,
          })
        },
      })
    } else {
      makeTransaction({
        id: selectedInstrument.id,
        value: sum,
        actionType: 'buy',
        instrumentType: instrumentType,
        uid: uuid(),
        term: years,
        portfolioId: selectedInstrument.portfolio_id,
      })
    }
  }
  const onWithdraw = () => {
    makeTransaction({
      id: selectedInstrument.id,
      value: sum,
      actionType: 'sell',
      instrumentType: instrumentType,
      uid: uuid(),
      portfolioId: selectedInstrument.portfolio_id,
    })
  }
  return (
    <div>
      <TabInnerSection>
        {isPortfolio && !isCredit && (
          <ActionButton {...{ loading }} onClick={onWithdraw} disabled={canWithdraw} fullWidth type="sell">
            Withdraw
          </ActionButton>
        )}
        {!isPortfolio && (
          <ActionButton {...{ loading }} onClick={onOpen} fullWidth type="buy">
            Open
          </ActionButton>
        )}
        {isPortfolio && isCredit && (
          <ActionButton {...{ loading }} onClick={onWithdraw} fullWidth type="sell">
            Close
          </ActionButton>
        )}
        {actionError && <Error>{actionError.toString()}</Error>}
      </TabInnerSection>
      <Divider />
      <TabInnerSection>
        {/* <TabInnerHeading>{isPortfolio ? 'Close' : 'Open'}</TabInnerHeading> */}
        <StyledSubHeading>{isCredit ? 'Loan' : 'Deposit'} Terms</StyledSubHeading>
        {isPortfolio
          ? instrumentType !== InstrumentTypes.Leverage && (
              <Row mt={6}>
                <Label>Turns left:</Label>
                <Value>{selectedInstrument.leftTurns}</Value>
              </Row>
            )
          : selectedInstrument.maxYears && (
              <Row mt={6}>
                <Label>Maximal term:</Label>
                <Value>{selectedInstrument.maxYears}</Value>
              </Row>
            )}
        <Row mt={6}>
          <Label>Interest rate:</Label>
          <Value>{formatPrice(newRate * 100, { disableCurrency: true })}%</Value>
        </Row>
        {isCredit ? (
          <>
            <Row mt={6}>
              <Label>Total interest:</Label>
              <Value>{formatPrice(totalInterestNum)}</Value>
            </Row>
            <Row mt={6}>
              <Label>Total debt:</Label>
              <Value>{formatPrice(interestSubject)}</Value>
            </Row>
            <Row mt={6}>
              <Label>Sum of future payments:</Label>
              <Value>{futurePayments}</Value>
            </Row>
            <Row mt={6}>
              <Label>Annual payment:</Label>
              <Value>{formatPrice(payment)}</Value>
            </Row>
          </>
        ) : null}
      </TabInnerSection>
      <Divider />
      <TabInnerSection>
        <StyledSubHeading>{name}</StyledSubHeading>
        <Row>
          <Label>Amount:</Label>
          <ActionInput
            fullWidth
            type="number"
            step="1"
            min="1"
            maxWidth
            radius={theme.radius.button.small}
            padding={theme.padding.input.action}
            value={sumInput}
            ref={inputRef}
            onChange={onSumInputChange}
          />
        </Row>
        {!isPortfolio && (
          <Row>
            <Label>Years:</Label>
            <ActionInput
              fullWidth
              type="number"
              step="1"
              min="1"
              maxWidth
              radius={theme.radius.button.small}
              padding={theme.padding.input.action}
              value={yearsInput}
              ref={inputRefYears}
              onChange={onYearsInputChange}
            />
          </Row>
        )}
      </TabInnerSection>
      <Divider />
    </div>
  )
}

const StyledSubHeading = styled(SubHeading)`
  margin-bottom: 10px;
`
