import { formatPrice } from '../format'

export const getStatusDelta = (price, prevPrice) => {
  if (price === 0 || prevPrice === 0 || !price || !prevPrice)
    return {
      status: 'neutral',
      delta: '',
      deltaAbs: '',
      deltaNumber: null,
      deltaSign: '',
      deltaAbsNumber: null,
    }
  if (price > prevPrice)
    return {
      status: 'up',
      delta: `+${formatPrice(Math.abs((1 - price / prevPrice) * 100), { disableCurrency: true })}%`,
      deltaAbs: `+${formatPrice(price - prevPrice, { disableCurrency: true })}`,
      deltaNumber: +Math.abs((1 - price / prevPrice) * 100).toFixed(2),
      deltaSign: '+',
      deltaAbsNumber: price - prevPrice,
    }
  if (price < prevPrice)
    return {
      status: 'down',
      delta: `-${formatPrice(Math.abs((1 - price / prevPrice) * 100), { disableCurrency: true })}%`,
      deltaAbs: `-${formatPrice(-price + prevPrice, { disableCurrency: true })}`,
      deltaNumber: +Math.abs((1 - price / prevPrice) * 100).toFixed(2),
      deltaSign: '-',
      deltaAbsNumber: -price + prevPrice,
    }
  if (price === prevPrice)
    return {
      status: 'neutral',
      delta: ``,
      deltaAbs: '',
      deltaSign: '',
      deltaNumber: null,
      deltaAbsNumber: null,
    }
}
