import React from 'react'
import styled from 'styled-components'

import { browserHistory } from 'lib/history'

import { CircleButton } from '..'

export const BackButtonForPage = ({ backURL }) => {
  return (
    <Container>
      <CircleButton
        size={50}
        onClick={() => {
          browserHistory.push(backURL)
        }}
        type="back"
      />
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
  left: -100px;
  position: absolute;
  top: 0;
`
