import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { formatPrice } from 'features/game/lib/prices/format'
import { getTickerMarket } from 'features/game/lib/tickers/get-ticker-market'
import { $selectedSubTabData } from 'features/game/model'
import { hexToRGBA } from 'lib/hex-to-rgba'
import arrowDown from 'static/arrow-down-red.svg'
import arrowUp from 'static/arrow-up-green.svg'
import { theme } from 'theme'
import { Ticker } from 'ui/atoms/ticker'
import { Typography } from 'ui/atoms/typography'

import { Delimeter, TickerInner } from '../molecules'

export const MarketTicker = () => {
  const market = getTickerMarket(useStore(useStore($selectedSubTabData).list))
  window.log('marketticker: ', market)
  return (
    <Ticker bgColor={theme.color.darkgray} speed={1}>
      {market?.length > 0 &&
        market.map(({ status, name, delta, deltaAbs, price: { current } }, idx) => (
          <TickerInner key={idx}>
            <Delimeter />
            <MarketNews>
              <Typography
                size={theme.font.size.extrasmall}
                color={hexToRGBA(theme.color.black, 0.8)}
                weight="600"
              >
                {name}
              </Typography>
              {status !== 'neutral' && <ArrowIcon src={status === 'up' ? arrowUp : arrowDown} />}
              {status === 'neutral' && <Filler />}
              <Typography
                size={theme.font.size.extrasmall}
                color={hexToRGBA(theme.color.black, 0.8)}
                weight="600"
              >
                {formatPrice(current)}
              </Typography>
              {deltaAbs && delta && (
                <Delta
                  size={theme.font.size.extrasmall}
                  color={status === 'up' ? theme.color.green : theme.color.red}
                  weight="600"
                >
                  {deltaAbs} ({delta})
                </Delta>
              )}
            </MarketNews>
          </TickerInner>
        ))}
    </Ticker>
  )
}

const Filler = styled.div`
  margin: 0 2px;
`

const Delta = styled(Typography)`
  margin-left: 5px;
`

const MarketNews = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`

const ArrowIcon = styled.img`
  height: 12px;
  margin: 0 5px;
  width: 10px;
`
