import React from 'react'
import { prop } from 'styled-tools'
import styled from 'styled-components'

import { getStatusDelta } from 'features/game/lib/prices/get-status-and-delta'
import { formatPrice } from 'features/game/lib/prices/format'
import { Typography } from 'ui/atoms/typography'
import arrowDown from 'static/arrow-down-red.svg'
import arrowUp from 'static/arrow-up-green.svg'
import { theme } from 'theme'

export const Price = ({
  current,
  previous,
  className,
  size = theme.font.size.main,
  weight = '500',
  arrowLeftMargin = 0,
  arrowRightMargin = 7,
}) => {
  const { status, delta, deltaAbs } = getStatusDelta(current, previous)
  const color =
    status === 'up' ? theme.color.green : status === 'neutral' ? theme.color.black : theme.color.red
  return (
    <Typography {...{ color, className, size, weight }}>
      {status !== 'neutral' && (
        <ArrowIcon {...{ arrowLeftMargin, arrowRightMargin }} src={status === 'up' ? arrowUp : arrowDown} />
      )}
      {formatPrice(current)}
      {deltaAbs && `  ${deltaAbs}`}
      {delta && ` (${delta})`}
    </Typography>
  )
}
const ArrowIcon = styled.img`
  height: 14px;
  margin-left: ${prop('arrowLeftMargin')}px;
  margin-right: ${prop('arrowRightMargin')}px;
  position: relative;
  top: 1px;
  width: 12px;
`
