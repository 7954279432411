import React from 'react'

import styled from 'styled-components'
import { prop } from 'styled-tools'
import { Typography } from 'ui/atoms/typography'
import { theme } from 'theme'

export const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  &:not(:first-of-type) {
    margin-top: ${prop('mt', 10)}px;
  }
`
export const SubHeading = ({ children, className }) => (
  <Typography {...{ className }} weight="600">
    {children}
  </Typography>
)

export const Label = ({ children, className }) => (
  <Typography {...{ className }} weight="600" size={theme.font.size.small} color={theme.color.gray400}>
    {children}
  </Typography>
)

export const Value = ({ children, className }) => (
  <Typography {...{ className }} weight="600" size={theme.font.size.small}>
    {children}
  </Typography>
)
