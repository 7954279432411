import { endTurnResponse, gameActionsResponse, VisualSettings } from 'api/game/types'
import mainBg from 'static/bg-login.png'
import { theme } from 'theme'

export const TRANSACTION_RESP: gameActionsResponse = { success: true }
export const STEP_RESP: endTurnResponse = { success: true, end_of_game: false }

export const VISUAL_SETTINGS: VisualSettings = {
  images: {
    mainBg,
  },
  theme,
}
