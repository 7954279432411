import React from 'react'
import styled from 'styled-components'

import { handleBalanceBarType } from 'lib/handle-fields'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'

type BalanceBarProps = {
  className?: string
  level: number | null
  type: 'medicine' | 'sport' | 'vacation' | 'entertainment'
}

export const BalanceBar = ({ className, level = 0, type }: BalanceBarProps) => {
  const { icon, label } = handleBalanceBarType(type)
  return (
    <Container className={className}>
      <Horizontal>
        <Cell>
          {/* @ts-ignore */}
          <Typography weight="600" size={theme.font.size.small}>
            {label}
          </Typography>
        </Cell>
        <Cell>
          <Icon src={icon} />
        </Cell>
      </Horizontal>
    </Container>
  )
}
const Cell = styled.div`
  align-items: center;
  display: flex;
  &:nth-of-type(2n) {
    flex: 0;
    justify-content: center;
  }
  &:nth-of-type(3n) {
    justify-content: flex-end;
  }
`

const Icon = styled.img`
  height: 20px;
`

const Horizontal = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  & > *:last-child {
    margin-left: 10px;
  }
`

const Container = styled.div`
  width: 100%;
`
