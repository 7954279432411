import { createEvent, createStore } from 'effector-root'

type ModalType = {
  id: string
  onSuccess?: () => {}
  onCancel?: () => {}
}

export const setModal = createEvent<ModalType>()
export const resetModal = createEvent()

export const $modal = createStore<ModalType | null>(null)
  .on(setModal, (_, s) => s)
  .reset(resetModal)
