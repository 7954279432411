import React from 'react'
import styled from 'styled-components'
import { switchProp } from 'styled-tools'

import { goToLeaderboardPage } from 'features/game/navigation/model/common'
import { formatTime } from 'lib/format-time'
import { ReactComponent as AwardIcon } from 'static/award.svg'
import SessionImgPlaceholder from 'static/img-placeholder.svg'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'
import { Tooltip } from 'ui/molecules/tooltip'
import { NewTable } from 'ui/organisms/new-table'
import { CellText } from 'ui/organisms/table/cell-text'

const ViewStatisticsContainer = styled.div`
  align-items: center;
  color: ${theme.color.primary};
  &:hover {
    color: ${theme.color.black};
  }
  display: flex;
  height: 100%;
  justify-content: center;
  transition: all 0.2s ease-in-out;
`

const StyledViewIcon = styled(AwardIcon)`
  color: ${theme.color.primary};
  cursor: pointer;
  height: 26px;
  object-fit: contain;
  width: auto;
  will-change: transform;
`

const COL_VIEW_LEADERBOARD = {
  key: 'id',
  label: '',
  isSortable: false,
  width: '60px',
  render: (data, row) => (
    <Tooltip content={<Typography>Leaderboard</Typography>}>
      <ViewStatisticsContainer
        onClick={() => {
          goToLeaderboardPage({ roomID: data, title: `of "${row.name}"` })
        }}
      >
        <StyledViewIcon />
      </ViewStatisticsContainer>
    </Tooltip>
  ),
  Aggregated: () => null,
}

const tableViews = {
  session: {
    columns: [
      {
        key: 'name',
        label: 'Game name',
        width: '270px',
        minWidth: '270px',
        maxWidth: '300px',
        isSortable: true,
        render: (data, row) => {
          return (
            <VerticalCenter>
              <SessionImg src={row.image?.length > 0 ? row.image : SessionImgPlaceholder} />
              <CellText>{data}</CellText>
            </VerticalCenter>
          )
        },
      },
      {
        key: 'scenarios[0].turnQty',
        label: 'Turns',
        isSortable: true,
        width: '40px',
        align: 'center',
        render: (data) => (
          <VerticalCenter>
            <CellText>{data}</CellText>
          </VerticalCenter>
        ),
      },
      {
        key: 'startDate',
        label: 'Start date',
        minWidth: '190px',
        isSortable: true,
        render: (data, row) => {
          return (
            <VerticalCenter>
              <CellText>{formatTime(data) + ' UTC'}</CellText>
            </VerticalCenter>
          )
        },
      },
      {
        key: 'finishDate',
        label: 'Finish date',
        minWidth: '190px',
        isSortable: true,
        render: (data, row) => {
          return (
            <VerticalCenter>
              <CellText>{data ? formatTime(data) + ' UTC' : null}</CellText>
            </VerticalCenter>
          )
        },
      },
      COL_VIEW_LEADERBOARD,
    ],
  },
  training: {
    columns: [
      {
        key: 'name',
        label: 'Game name',
        width: '270px',
        minWidth: '270px',
        maxWidth: '300px',
        isSortable: true,
        render: (data, row) => {
          return (
            <VerticalCenter>
              <SessionImg src={row.image?.length > 0 ? row.image : SessionImgPlaceholder} />
              {data}
            </VerticalCenter>
          )
        },
      },
      COL_VIEW_LEADERBOARD,
    ],
  },
  teamlead: {
    columns: [
      {
        key: 'name',
        label: 'Game name',
        width: '270px',
        minWidth: '270px',
        maxWidth: '300px',
        isSortable: true,
        render: (data, row) => {
          return (
            <VerticalCenter>
              <SessionImg src={row.image?.length > 0 ? row.image : SessionImgPlaceholder} />
              {data}
            </VerticalCenter>
          )
        },
      },
    ],
  },
}

export const RoomsTableList = ({ rows, mode = 'session', onSelectActiveRow, activeRowId, ...rest }) => {
  return (
    <StyledTable
      data={rows}
      mode={mode}
      onRowClick={(row) => onSelectActiveRow(row)}
      activeRowId={activeRowId}
      rowIdKey="frontId"
      {...tableViews[mode]}
      {...rest}
    />
  )
}

const StyledTable = styled(NewTable)`
  height: 43vh;
  max-height: 43vh;
  ${switchProp('mode', {
    training: `
      max-width: 360px;
    `,
    teamlead: `
      max-width: 500px;
    `,
  })}
`

const SessionImg = styled.img`
  margin-right: 20px;
  object-fit: contain;
  width: 45px;
`

const VerticalCenter = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`
