import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { $news, $profile } from 'features/game/model'
import { $newsRolletPosition } from 'features/game/navigation/model'
import { sortByTurns } from 'lib/sort/custom-sorts'
import { theme } from 'theme'
import { Tabs } from 'ui/organisms/tabs'

import { NewsList } from '../../news-list'

export const NewsRollet = () => {
  const news = useStore($news)
  const profile = useStore($profile)
  const personalNews = profile.profile.personalNews.sort(sortByTurns)
  window.log(personalNews, 'personalNews')
  const rolletPos = useStore($newsRolletPosition)
  return (
    <GlobalNews rolletDown={rolletPos === 'down'}>
      <Tabs hasFlexBase>
        <NewsList noHeading title="World News" id="global" news={news} />
        <NewsList noHeading title="Personal News" id="personal" news={personalNews} />
      </Tabs>
    </GlobalNews>
  )
}

const GlobalNews = styled.div`
  background: ${theme.color.thirdly};
  border-radius: ${theme.radius.button.medium}px;
  bottom: -40vh;
  box-shadow: ${theme.shadow.loginForm};
  display: flex;
  flex-direction: column;
  height: 40vh;
  left: ${theme.padding.large * 2}px;
  overflow: hidden;
  padding: ${theme.padding.medium / 2}px;
  position: absolute;
  transition: all 0.7s ease-in-out;
  width: calc(50% + 15px - ${theme.padding.large * 2}px);
  z-index: 2;
  ${ifProp(
    'rolletDown',
    `
    bottom: 30px;
  `
  )}
`
