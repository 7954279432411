import { Literal, Union } from 'runtypes'

export const InstrumentTypes = {
  Stock: 'stocks',
  Bond: 'bonds',
  Index: 'indicies',
  Crypto: 'crypto',
  ETF: 'etf',
  MF: 'mf',
  Commodity: 'commodities',
  Goal: 'goals',
  Dream: 'dreams',
  Good: 'goods',
  Service: 'services',
  RealEstate: 'realEstate',
  IncomeAndExpenses: 'incomeAndExpenses',
  Credit: 'credit',
  Deposit: 'deposits',
  Leverage: 'leverage',
  Event: 'events',
} as const

export type InstrumentType = typeof InstrumentTypes[keyof typeof InstrumentTypes]

const BankingTypes = Union(
  Literal(InstrumentTypes.Credit),
  Literal(InstrumentTypes.Leverage),
  Literal(InstrumentTypes.Deposit)
)

const StockMarketTypes = Union(
  Literal(InstrumentTypes.Stock),
  Literal(InstrumentTypes.Bond),
  Literal(InstrumentTypes.Index),
  Literal(InstrumentTypes.Crypto),
  Literal(InstrumentTypes.Commodity),
  Literal(InstrumentTypes.ETF)
)

export const isBankingRelated = (instrumentType: InstrumentType) => BankingTypes.guard(instrumentType)
export const isStockMarketRelated = (instrumentType: InstrumentType) => StockMarketTypes.guard(instrumentType)
