import { parseISO } from 'date-fns'
import { useStore } from 'effector-react'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { OverflowTooltip } from 'ui/molecules/overflow-tooltip'
import { ArrowIcon, Price } from 'features/game/field/molecules/price'
import { imgSrc } from 'features/game/lib/img-src'
import { getStatusDelta } from 'features/game/lib/prices/get-status-and-delta'
import { $dreams, $profile } from 'features/game/model'
import { goToLeaderboardPage, goToStatisticsPage } from 'features/game/navigation/model/common'
import { filterNotReady, filterPortfolio } from 'features/game/lib/filter-instruments'
import { getTimer } from 'features/rooms/lib/get-timer'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { useRollet } from 'lib/hooks/use-rollet'
import { ReactComponent as AwardIcon } from 'static/award.svg'
import { ReactComponent as BugIcon } from 'static/bug.svg'
import { ReactComponent as GraphIcon } from 'static/graph.svg'
import { Container } from 'ui/atoms/container'
import { Typography } from 'ui/atoms/typography'
import { CircleButton } from 'ui/molecules/circle-button'
import { FlippingNumbers } from 'ui/molecules/flipping-numbers'
import { Tooltip } from 'ui/molecules/tooltip'
import { TextTable } from 'ui/organisms/text-table'
import { theme } from 'theme'
import { $bgImgSrc } from 'theme/model'

import { RolletButton } from '../../molecules/rollet-button'
import { TopBarDetails } from './details'
import { TopBarLevels } from './levels'
import { ProfileMain } from './profile-main'

const Bonus = React.memo(
  ({ previous, current, label, children, noDelimeter, notCurrency, isGreen, useDecimals }) => {
    const { status } = getStatusDelta(current, previous)
    return (
      <>
        <Typography oneLine color={theme.color.gray400} size={theme.font.size.small} weight="600">
          {label}
        </Typography>
        <StyledOverflowTooltip maxWidth={70}>
          <TopPrice oneLine status={isGreen ? 'up' : 'down'}>
            <FlippingNumbers noDecimals={!useDecimals} {...{ notCurrency }}>
              {current}
            </FlippingNumbers>
            {status !== 'neutral' && <TopArrowIcon isUp={status === 'up'} />}
          </TopPrice>
        </StyledOverflowTooltip>
        {!noDelimeter && <TopDelimeter />}
      </>
    )
  }
)

const EndTimer = React.memo(({ finishTime }) => {
  const normalizedFinishTime = parseISO(finishTime)
  const [time, setTime] = useState(normalizedFinishTime - new Date())
  const interval = useRef()
  useEffect(() => {
    if (interval.current) clearInterval(interval.current)
    interval.current = setInterval(() => {
      setTime(normalizedFinishTime - new Date())
    }, 1000)
    return () => {
      clearInterval(interval.current)
    }
  }, [normalizedFinishTime])
  const { hours, minutes, seconds } = getTimer(Number(time), true)
  return `${hours}:${minutes}:${seconds}`
})

export const TopBar = React.memo(() => {
  const profile = useStore($profile)
  const bgImgSrc = useStore($bgImgSrc)
  const dreams = filterNotReady(filterPortfolio(useStore($dreams)))
  const character = profile.character
  const rolletState = useRollet()
  const infoRolletState = useRollet()
  const openBugReport = () => {
    window.open('https://vladosabramovich.typeform.com/to/wg3jZyQH', '_blank')
  }
  const endMultiplier = profile?.profile?.bonuses.endBonus.reduce((prev, cur) => prev + cur.value, 0)
  const flowMultiplier = profile?.profile?.bonuses.flowBonus.reduce((prev, cur) => prev + cur.value, 0)
  window.log(profile, 'profile', 'model')
  return (
    <Outer>
      <Bar>
        <StyledContainer>
          <Cell maxWidth={400} fill>
            <Tooltip
              interactive={false}
              content={
                <Typography size={theme.font.size.small} weight="600">
                  Click to see character's breakdown.
                </Typography>
              }
            >
              <ProfileImgContainer onClick={rolletState.toggleRollet}>
                <ProfileImg
                  alt="Character image"
                  isOpened={rolletState.rolletPos === 'down'}
                  src={imgSrc(character.image, 'character-small')}
                />
                <CloseProfileButton type="close" size={30} isOpened={rolletState.rolletPos === 'down'} />
              </ProfileImgContainer>
            </Tooltip>
            <PointsContainer>
              <PointsLabel>Points:</PointsLabel>
              <PointsValue>
                <FlippingNumbers notCurrency noDecimals>
                  {profile.profile.balance.points.current}
                </FlippingNumbers>
              </PointsValue>
            </PointsContainer>
            <StyledTopBarLevels
              wrapByDiv
              aggression={profile.profile.balance.aggression}
              cushion={profile.profile.balance.cushion}
            />
          </Cell>
          <Cell margin>
            <Bonus isGreen label="Free Cash:" {...profile.profile.balance.cash} />
            <Bonus isGreen label="Income:" {...profile.profile.balance.revenue} />
            <Bonus label="Expenses:" {...profile.profile.balance.expenses} />
            <Bonus isGreen label="Capital:" {...profile.profile.balance.capital} />
            <Bonus isGreen label="Flowbonus:" useDecimals notCurrency current={flowMultiplier} />
            <Bonus isGreen noDelimeter label="Endbonus:" useDecimals notCurrency current={endMultiplier} />
          </Cell>
          <Cell>
            <StyledTextTable
              marginRows={0}
              rows={[
                ['Turn:', `${profile.currentTurn} of ${profile.turnQty}`],
                ['Game ends in:', <EndTimer finishTime={profile.finishTime} />],
              ]}
            />
            <IconsContainer>
              <Tooltip
                interactive={false}
                content={<Typography size={theme.font.size.mediumsmall}>Game statistics</Typography>}
              >
                <Icon onClick={() => goToStatisticsPage({ title: 'of current game' })}>
                  <GraphIcon />
                </Icon>
              </Tooltip>
              <Tooltip
                interactive={false}
                content={<Typography size={theme.font.size.mediumsmall}>Leaderboard</Typography>}
              >
                <Icon onClick={goToLeaderboardPage}>
                  <AwardIcon />
                </Icon>
              </Tooltip>
              {/* <Icon src={timerIcon} /> */}
              <Tooltip
                interactive={false}
                content={<Typography size={theme.font.size.mediumsmall}>File us a bug</Typography>}
              >
                <Icon onClick={openBugReport}>
                  <BugIcon />
                </Icon>
              </Tooltip>
            </IconsContainer>
          </Cell>
        </StyledContainer>
        <StyledContainer>
          <TopBarDetails
            balance={profile.profile.balance}
            bonuses={profile.profile.bonuses}
            dreams={dreams}
            character={character}
            charts={profile.profile.charts}
            isDown={infoRolletState.rolletPos === 'down'}
          />
        </StyledContainer>
      </Bar>
      <StyledRolletButton {...infoRolletState} />
      <StyledProfileMain
        balance={profile.profile.balance}
        bonuses={profile.profile.bonuses}
        satisfactionLevels={profile.profile.satisfactionLevels}
        dreams={dreams}
        character={character}
        charts={profile.profile.charts}
        rolletDown={rolletState.rolletPos === 'down'}
        onClose={rolletState.toggleRollet}
        bgImg={bgImgSrc}
      />
    </Outer>
  )
})

const PointsValue = styled.p`
  color: ${hexToRGBA(theme.color.black, 0.85)};
  font-size: ${theme.font.size.large + 1}px;
  font-weight: bold;
  min-width: 51px;
  white-space: nowrap;
`

const PointsLabel = styled.p`
  color: ${hexToRGBA(theme.color.black, 0.65)};
  font-size: ${theme.font.size.small + 1}px;
  font-weight: 600;
  margin-right: 6px;
`

const PointsContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 9px;
  position: relative;
`

const CloseProfileButton = styled(CircleButton)`
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 2;
  ${ifProp('isOpened', 'opacity: 1;', 'opacity: 0;')}
`

const StyledOverflowTooltip = styled(OverflowTooltip)`
  width: auto;
`

const StyledTextTable = styled(TextTable)`
  width: 163px;
`

const StyledTopBarLevels = styled(TopBarLevels)`
  margin-right: 7px;
`

const StyledProfileMain = styled(ProfileMain)`
  height: calc(100vh - 80px);
  left: 0;
  max-height: calc(100vh - 80px);
  overflow: hidden;
  position: absolute;
  right: 0;
  top: calc(100px - 100vh);
  transition: all 0.7s ease-in-out;
  width: 100%;
  z-index: 2;
  ${ifProp(
    'rolletDown',
    `
    top: 50px;
  `
  )}
`

const StyledRolletButton = styled(RolletButton)`
  bottom: -15px;
  left: calc(50% - 14px);
  position: absolute;
`

const Outer = styled.div`
  position: relative;
`

const Icon = styled.div`
  & > svg {
    height: 21px;
    width: auto;
  }
  align-items: center;
  color: ${theme.color.gray400};
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0 5px;
`

const IconsContainer = styled.div`
  align-items: center;
  display: flex;
`

const Cell = styled.div`
  align-items: center;
  display: flex;
  ${ifProp('fill', 'flex: 1;')}
  ${ifProp('margin', 'margin-right: 10px;')}
  ${ifProp(
    'maxWidth',
    css`
      max-width: ${prop('maxWidth')}px;
    `
  )}
  min-width: ${prop('fixedWidth', 'unset')};
`

const TopDelimeter = styled.div`
  background: ${theme.color.black};
  height: 10px;
  margin: 0 7px;
  opacity: 0.2;
  width: 1px;
`

const TopArrowIcon = styled(ArrowIcon)`
  height: 10px;
  margin-left: 7px;
  width: 8px;
`

const TopPrice = styled(Price)`
  display: block;
  font-family: 'Roboto Mono';
  font-size: ${theme.font.size.small}px;
  font-weight: 500;
  letter-spacing: -0.8px;
  margin-left: 7px;
`

const ProfileImg = styled.img`
  border-radius: 50%;
  height: 30px;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  width: 30px;
  ${ifProp('isOpened', 'opacity: 0.1;', 'opacity: 1;')}
`

const ProfileImgContainer = styled.div`
  cursor: pointer;
  height: 30px;
  margin-right: 9px;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 30px;
  &:hover {
    & ${ProfileImg} {
      box-shadow: 0px 0px 10px ${hexToRGBA(theme.color.black, 0.5)};
    }
  }
`

const StyledContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const Bar = styled.header`
  background: ${theme.color.secondary};
  box-shadow: ${theme.shadow.topBar};
  overflow: hidden;
  padding: 6px 0;
  position: relative;
  z-index: 4;
`
