import { filterPortfolio, filterMarket, filterMarketLeverage } from 'features/game/lib/filter-instruments'

export const STATIC_TABS_STRUCTURE = [
  {
    key: 'personal',
    name: 'Personal Status',
    defaultOrderBy: 'name',
    subtabs: [
      {
        name: 'Assets & Liabilities',
        key: 'assetsAndLiabilities',
        actionsHeader: [],
        defaultOrderBy: 'name',
      },
      {
        name: 'Income & Expenses',
        key: 'incomeAndExpenses',
        actionsHeader: [],
      },
      {
        key: 'dreams',
        name: 'Dreams',
        actionsBody: [],
        centerBody: [],
        actionsHeader: [],
        transformFn: filterPortfolio,
      },
      {
        key: 'goals',
        name: 'Achievements',
        actionsBody: [],
        listFilters: ['accomplishedGoals', 'notCompleted'],
      },
    ],
  },
  {
    key: 'otherMarkets',
    name: 'Other Markets',
    subtabs: [
      {
        key: 'realEstate',
        name: 'Real Estate',
        actionsHeader: [],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'goods',
        name: 'Goods',
        transformFn: filterMarket,
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'services',
        name: 'Services',
        transformFn: filterMarket,
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
    ],
  },
  {
    key: 'financialMarkets',
    name: 'Financial Markets',
    subtabs: [
      {
        key: 'stocks',
        name: 'Stocks',
        transformFn: filterMarket,
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'bonds',
        name: 'Bonds',
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'indicies',
        name: 'Indices',
        transformFn: filterMarket,
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'commodities',
        name: 'Commodities',
        transformFn: filterMarket,
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'etf',
        name: 'ETF',
        transformFn: filterMarket,
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'crypto',
        name: 'Crypto',
        transformFn: filterMarket,
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'banking',
        name: 'Banking',
        actionsHeader: [],
        listFilters: [],
        defaultOrderBy: 'name',
        transformFn: filterMarketLeverage,
      },
    ],
  },
  {
    key: 'turnActions',
    defaultOrderBy: 'order',
    listHeader: 'Your actions',
    subtabs: [
      {
        key: 'turnActions',
        name: 'All',
      },
    ],
  },
  {
    key: 'events',
    defaultOrderBy: 'name',
    listHeader: 'Events',
    subtabs: [
      {
        key: 'events',
        name: 'All',
      },
    ],
  },
]

export const ALL_SUBTABS = STATIC_TABS_STRUCTURE.reduce((prev, cur) => {
  const subTabs = cur.subtabs?.map((el) => ({ root: cur.key, key: el.key })) || []
  return [...prev, ...subTabs]
}, [])
