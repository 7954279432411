import { addError, clearErrors } from '../../model'

export class Logger {
  static log(info) {
    addError({ level: 'log', error: info })
  }
  static warn(error) {
    addError({ level: 'warn', ...error })
  }
  static notify(error) {
    addError({ level: 'notify', ...error })
  }
  static error(error) {
    addError({ level: 'error', ...error })
  }
  static fatal(error) {
    addError({ level: 'fatal', ...error })
  }
  static flush() {
    clearErrors()
  }
}
