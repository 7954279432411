import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { FILTERS_LABELS } from 'features/game/lib/instrument-model-factory'
import { Checkbox } from 'ui/atoms/checkbox'

import { Tab } from '../atoms/tab'
import {
  $leverageOn,
  $selectedSubTabData,
  $selectedSubTabLayout,
  $selectedTabLayout,
  toggleLeverage,
} from '../model'
import { GameFieldLayout } from './layout'
import { BuySellTabWrapper } from './organisms/buy-sell-tab/wrapper'
import { SubTabTable } from './organisms/subtab-table'

const StyledCheckbox = styled(Checkbox)`
  &:not(:last-child) {
    margin-right: 20px;
  }
`

export const GameField = () => {
  const tabLayout = useStore($selectedTabLayout)
  const subTabLayout = useStore($selectedSubTabLayout)
  const isLeverageOn = useStore($leverageOn)

  const selectedSubTabStore = useStore($selectedSubTabData)
  const selectedSubTabFilters = useStore(selectedSubTabStore.filters)
  const onFilter = (name, value) => {
    selectedSubTabStore.setFilter({ name, value })
  }
  const renderCheckboxes = (store) => {
    return Object.entries(store).map((entry) => {
      const key = entry[0]
      return (
        <StyledCheckbox
          {...{ key }}
          checked={store[key]}
          onChange={({ target: { checked } }) => onFilter(key, checked)}
        >
          {FILTERS_LABELS[key]}
        </StyledCheckbox>
      )
    })
  }
  return (
    <GameFieldLayout
      listHeader={subTabLayout.listHeader || tabLayout.listHeader || renderCheckboxes(selectedSubTabFilters)}
      listBody={tabLayout.listBody || subTabLayout.listBody || <SubTabTable />}
      centerBody={subTabLayout.centerBody || tabLayout.centerBody || []}
      actionsHeader={
        subTabLayout.actionsHeader?.length > 0 &&
        subTabLayout.actionsHeader.includes('useLeverage') && (
          <StyledCheckbox checked={isLeverageOn} onChange={toggleLeverage}>
            Use leverage
          </StyledCheckbox>
        )
      }
      actionsBody={
        subTabLayout.actionsBody ||
        tabLayout.actionsBody || [
          <Tab key="actions" id="actions" heading="Actions">
            <BuySellTabWrapper />
          </Tab>,
        ]
      }
    />
  )
}
