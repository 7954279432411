import { createEffect } from 'effector-root'

import { setFingerprint, setTokens } from 'features/auth'
import { createFetching } from 'lib/fetching'
import { Storage } from 'lib/storage'
// import { getVisualSettings } from 'theme/model'

export const _initApp = () =>
  new Promise((resolve) => {
    const access = Storage.getAccessToken()
    const refresh = Storage.getRefreshToken()
    setFingerprint()
    if (access && refresh) {
      setTokens({ access, refresh })
    }
    //@ts-ignore
    // getVisualSettings()
    return resolve('ok')
  })

export const initApp = createEffect()
initApp.use(() => _initApp())

export const initAppFetching = createFetching(initApp)
