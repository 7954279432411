import { darken } from 'polished'
import React from 'react'
import { ToastContainer as NaturalToastContainer, Slide } from 'react-toastify'
import styled from 'styled-components'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

import 'react-toastify/dist/ReactToastify.css'

const toastOpts = { position: 'bottom-right', limit: 5, transition: Slide, autoClose: 4 * 1000 }
export const ToastContainer = () => {
  return (
    <StyledToastContainer>
      <NaturalToastContainer {...toastOpts} />
    </StyledToastContainer>
  )
}

const StyledToastContainer = styled.div`
  .Toastify__toast {
    border-radius: ${theme.radius.button.small}px;
    font-family: 'Gilroy', 'Roboto', 'Open Sans', 'Source Sans Pro', Helvetica, -apple-system,
      BlinkMacSystemFont, 'Segoe UI', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    min-height: 42px;
  }
  .Toastify__toast--dark {
    background: ${hexToRGBA(theme.color.white, 0.8)};
  }
  .Toastify__toast--success {
    background: ${darken(0.07, theme.color.green)};
  }
  .Toastify__toast--error {
    background: ${theme.color.primary};
  }
  .Toastify__toast-body {
    margin: auto;
  }
  .Toastify__progress-bar--dark {
    background: ${theme.color.primary};
  }
`
