export class Storage {
  static KEY_PREFIX: string = 'FLG/'
  static ACCESS_TOKEN_KEY: string = `${Storage.KEY_PREFIX}ACCESS_TOKEN`
  static REFRESH_TOKEN_KEY: string = `${Storage.KEY_PREFIX}REFRESH_TOKEN`
  static ERROR_LOGS_KEY: string = `${Storage.KEY_PREFIX}ERROR_LOGS`
  static _storage = localStorage
  static getAccessToken(): string {
    return Storage._storage.getItem(Storage.ACCESS_TOKEN_KEY)
  }
  static getRefreshToken(): string {
    return Storage._storage.getItem(Storage.REFRESH_TOKEN_KEY)
  }
  static setAccessToken(token: string) {
    Storage._storage.setItem(Storage.ACCESS_TOKEN_KEY, token)
  }
  static setRefreshToken(token: string) {
    Storage._storage.setItem(Storage.REFRESH_TOKEN_KEY, token)
  }
  static getErrorLogs(): string[] {
    const logs = Storage._storage.getItem(Storage.ERROR_LOGS_KEY)
    if (!logs) return []
    return JSON.parse(logs)
  }
  static addError(error: any) {
    const logs = Storage.getErrorLogs()
    if (logs.length > 0) {
      Storage._storage.setItem(Storage.ERROR_LOGS_KEY, JSON.stringify([...logs, error]))
    } else {
      Storage._storage.setItem(Storage.ERROR_LOGS_KEY, JSON.stringify([error]))
    }
  }
  static flushErrorLogs(): void {
    Storage._storage.removeItem(Storage.ERROR_LOGS_KEY)
  }
  static clearStorage() {
    Storage._storage.removeItem(Storage.ACCESS_TOKEN_KEY)
    Storage._storage.removeItem(Storage.REFRESH_TOKEN_KEY)
    Storage._storage.removeItem(Storage.ERROR_LOGS_KEY)
  }
}
