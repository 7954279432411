/* eslint-disable no-redeclare */
import { Boolean, Partial, Record as RunRecord, Static, String } from 'runtypes'
import { Theme } from 'theme/types'

const endOfGame = RunRecord({ end_of_game: Boolean })

export const gameActionsResponse = RunRecord({
  success: Boolean,
}).And(Partial({ message: String }))

export type VisualSettings = {
  images: {
    mainBg: string
  }
  theme: Theme
}

export type gameActionsResponse = Static<typeof gameActionsResponse>
export type endTurnReqParams = {
  autobuyOn: Boolean
}

export const endTurnResponse = gameActionsResponse.And(endOfGame)
export type endTurnResponse = Static<typeof endTurnResponse>
