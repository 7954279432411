import styled from 'styled-components'
import { prop } from 'styled-tools'

import defaultBgImg from 'static/bg-login.png'
import { theme } from 'theme'

export const Main = styled.div`
  background: url('${prop('bgImg', defaultBgImg)}') ${theme.color.thirdly};
  background-size: cover;
  display: flex;
  height: 100vh;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  width: 100vw;
`
