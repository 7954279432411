import React, { ReactNode } from 'react'
import Loader from 'react-loading'
import styled from 'styled-components/macro'
import { ifProp, prop, switchProp } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

const noop = () => {}

type buttonProps = {
  fullWidth?: boolean
  onClick?: () => void
  padding?: string | number
  children: ReactNode
  className?: string
  block?: boolean
  fontWeight?: string
  fontSize?: number
  radius?: number
  disabled?: boolean
  variant: 'primary' | 'greenOutlined' | 'noBorderGray' | 'secondary' | 'outlinedLight'
  loading?: boolean
}

export const Button = ({
  fullWidth,
  onClick = noop,
  padding = theme.padding.button.medium,
  children,
  className,
  block,
  fontWeight = '600',
  fontSize = theme.font.size.main,
  radius = theme.radius.button.large,
  disabled = false,
  variant = 'primary',
  loading,
  ...rest
}: buttonProps) => {
  return (
    <UIButton
      onClick={disabled || loading ? noop : onClick}
      //@ts-ignore
      fullWidth={fullWidth}
      fontSize={fontSize}
      variant={variant}
      radius={radius}
      fontWeight={fontWeight}
      className={className}
      padding={padding}
      disabled={disabled}
      block={block}
      {...rest}
    >
      {loading ? (
        <StyledLoader type="spin" color={hexToRGBA(theme.color.black, 0.7)} height={25} width={25} />
      ) : (
        children
      )}
    </UIButton>
  )
}

const StyledLoader = styled(Loader)`
  height: 18px !important;
  position: relative;
  top: -4.5px;
  margin: 0 auto;
`

const UIButton = styled.button`
  cursor: ${ifProp('loading', 'progress', 'pointer')};
  font-size: ${prop('fontSize')}px;
  font-weight: ${prop('fontWeight')};
  border-radius: ${prop('radius')}px;
  padding: ${prop('padding')};
  transition: all 0.15s ease-in-out;
  border: 2px solid transparent;
  ${ifProp('block', 'display: block;')}
  ${switchProp('variant', {
    primary: `
      color: ${theme.color.black};
      background: ${theme.color.primary};
      border-color: ${theme.color.primary};
      &:hover {
        background: transparent;
      }
    `,
    greenOutlined: `
      color: ${theme.color.black};
      background: transparent;
      border-color: ${theme.color.green};
      &:hover {
        background: ${theme.color.green};
      }
    `,
    noBorderGray: `
      color: ${theme.color.gray400};
      &:hover {
        color: ${theme.color.black};
      }
    `,
    secondary: `
      color: ${theme.color.black};
      background: ${theme.color.gray};
      border-color: ${theme.color.gray};
      &:hover {
        background: transparent;
      }
    `,
    outlinedLight: `
      color: ${theme.color.black};
      background: transparent;
      border-color: ${theme.color.gray};
      &:hover {
        background: ${theme.color.gray};
      }
    `,
  })}
  ${ifProp(
    'loading',
    switchProp('variant', {
      primary: `
        &:hover {
          background: ${theme.color.primary};
        }
      `,
      greenOutlined: `
        &:hover {
          background: ${theme.color.green};
        }
      `,
      secondary: `
        &:hover {
          background: ${theme.color.gray};
        }
      `,
      outlinedLight: `
        &:hover {
        background: transparent;
        }
      `,
      noBorderGray: `
      &:hover {
        color: ${theme.color.gray400};
      }
    `,
    })
  )}
  ${ifProp(
    'disabled',
    switchProp('variant', {
      primary: `
      &:hover {
        color: ${theme.color.white};
        background: ${theme.color.primary};
      }
    `,
      greenOutlined: `
      border-color: ${theme.color.gray};
      color: ${theme.color.gray};
      &:hover {
        border-color: ${theme.color.gray};
        color: ${theme.color.gray};
        background: transparent;
      }
    `,
      secondary: `
      &:hover {
          color: ${theme.color.black};
          background: transparent;
      }
    `,
      outlinedLight: `
      &:hover {
      color: ${theme.color.white};
      background: transparent;
      border-color: ${hexToRGBA(theme.color.white, 0.7)};
      }
    `,
      noBorderGray: `
      color: ${theme.color.gray400};
      &:hover {
        color: ${theme.color.gray400};
      }
    `,
    })
  )}
  ${ifProp(
    'disabled',
    `
      cursor: not-allowed;
      color: ${hexToRGBA(theme.color.black, 0.4)};
      background: ${theme.color.gray};
      border-color: ${theme.color.gray};
      &:hover {
        cursor: not-allowed;
        color: ${hexToRGBA(theme.color.black, 0.4)};
        background: ${theme.color.gray};
        border-color: ${theme.color.gray};
      }
    `
  )}
  ${ifProp(
    'fullWidth',
    `
    flex: 1;
    width: 100%;
  `
  )}
`
