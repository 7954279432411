import { lighten } from 'polished'
import React from 'react'
import styled from 'styled-components'

import { theme } from 'theme'
import { FlippingNumbers } from 'ui/molecules/flipping-numbers'

export const HeaderOneCol = styled.div`
  align-items: center;
  background: ${lighten(0.07, theme.color.secondary)};
  border-radius: ${theme.radius.button.small * 2}px ${theme.radius.button.small * 2}px 0px 0px;
  box-shadow: 0px 2px 16px ${theme.color.thirdly};
  color: ${theme.color.black};
  display: flex;
  font-size: ${theme.font.size.small}px;
  font-weight: 600;
  padding: 10px 8px;
`

const StyledHeaderOneCol = styled(HeaderOneCol)`
  justify-content: space-between;
`

const Cell = styled.div`
  align-items: center;
  display: flex;
`

export const HeaderWithTotal = ({ icon, total, children }) => {
  return (
    <StyledHeaderOneCol>
      <Cell>
        {icon}
        {children}
      </Cell>
      <Cell>
        <FlippingNumbers prefix="Total: $ " noDecimals>
          {total}
        </FlippingNumbers>
      </Cell>
    </StyledHeaderOneCol>
  )
}
