import React, { useEffect, useRef, useState } from 'react'
import ApexCharts from 'react-apexcharts'
import styled from 'styled-components'
import { uuid } from 'uuidv4'

import { round } from 'features/game/lib/chart-format'
import { useEffectWithoutInit } from 'lib/hooks/use-effect-without-init'
import { CHART } from 'mocks/state'
import { theme } from 'theme'

import { baseChartOpts } from '../default-options'

export const baseMultiChartOpts = {
  ...baseChartOpts,
  legend: {
    ...baseChartOpts.legend,
    offsetY: 6,
  },
  yaxis: {
    ...baseChartOpts.yaxis,
    labels: {
      ...baseChartOpts.yaxis.labels,
      formatter: round,
    },
  },
}

export const MultiChart = React.memo(
  ({ data = CHART, height = 210, width, yFormatter, tooltipYFormatter, className }) => {
    const ref = useRef(null)
    const { current, average } = data
    const chartColor =
      current[current.length - 2].value > current[current.length - 1].value
        ? theme.color.red
        : theme.color.green
    let chartOpts = {
      ...baseMultiChartOpts,
      colors: [chartColor, theme.color.cyan],
    }
    chartOpts.xaxis.tickAmount = data.length <= 15 ? 'dataPoints' : Math.floor(data.length / 2)
    const [chartOptions, setChartOptions] = useState(chartOpts)
    const normalizedData = [
      {
        name: 'Current',
        data: current.reduce((acc, cur) => [...acc, [cur.turn, cur.value]], []),
      },
      {
        name: 'Average',
        data: average.reduce((acc, cur) => [...acc, [cur.turn, cur.value]], []),
      },
    ]
    // Оно блять не работает без useState, что-то внутри библы неправильно работает с опциями графика и он забирает чужие настройки...
    useEffectWithoutInit(() => {
      setChartOptions((prevState) => {
        let newState = { ...prevState }
        if (yFormatter) {
          if (Array.isArray(newState.yaxis)) {
            newState.yaxis[0].labels.formatter = yFormatter
          } else {
            newState.yaxis.labels.formatter = yFormatter
          }
        }
        if (tooltipYFormatter) {
          newState.tooltip.y = { formatter: tooltipYFormatter }
        }
        return newState
      })
    }, [yFormatter, data, tooltipYFormatter])
    useEffect(() => {
      window.log(chartOptions, 'chartOptions')
      if (typeof ref?.current?.chart?.ctx?.updateOptions === 'function') {
        ref.current.chart.ctx.updateOptions(chartOptions, false, true)
      }
    }, [chartOptions])
    return (
      <Container className={className}>
        <ApexCharts
          key={uuid()}
          options={chartOptions}
          series={normalizedData}
          width={width}
          type="area"
          ref={ref}
          height={height}
        />
      </Container>
    )
  }
)

const Container = styled.div``
