import { http, APIResponse } from 'lib/http'
import { handleAPIError } from 'lib/http/error/handle-error'
import { validateAPIResponse } from 'lib/http/validate-api-response'
import { validateForSuccess } from 'lib/http/validate-for-success'

import { endTurnReqParams, endTurnResponse, gameActionsResponse, VisualSettings } from './types'

export const GAME_HTTP_ROUTES = {
  getState: 'state',
  makeTransaction: 'actions/transaction',
  endTurn: 'actions/step',
  resetTurn: 'actions/reset-step',
  chooseDream: 'actions/choose-dream',
  goBankrupt: 'actions/bankrupt',
  chooseReaction: 'actions/choose-reaction',
  relocate: 'actions/relocation',
  changeFinancialSettings: 'actions/settings',
  getVisualSettings: 'visual-settings',
}

const getState = () => http.get(GAME_HTTP_ROUTES.getState).catch(handleAPIError({}, [403]))
const makeTransaction = (params): Promise<gameActionsResponse> =>
  http
    .post(GAME_HTTP_ROUTES.makeTransaction, params)
    .then(validateAPIResponse(gameActionsResponse))
    .then(validateForSuccess)
    .catch(
      handleAPIError(
        {
          400: 'Cannot apply transaction.',
        },
        [403]
      )
    )

const endTurn = (params: endTurnReqParams): Promise<endTurnResponse> =>
  http
    .post(GAME_HTTP_ROUTES.endTurn, params)
    .then(validateAPIResponse(endTurnResponse))
    .then(validateForSuccess)
    .catch(handleAPIError({}, [403]))

const resetTurn = () => http.post(GAME_HTTP_ROUTES.resetTurn).catch(handleAPIError({}, [403]))

const chooseDream = ({ dreamID, unchoose = false }: { unchoose?: boolean; dreamID: string }) => {
  return http
    .post(GAME_HTTP_ROUTES.chooseDream, { id: dreamID, unchoose })
    .then(validateAPIResponse(gameActionsResponse))
    .then(validateForSuccess)
    .catch(handleAPIError({ default: 'Cannot choose this dream.' }, [403]))
}

const goBankrupt = () =>
  http.post(GAME_HTTP_ROUTES.goBankrupt).catch(handleAPIError({ default: 'Cannot go bankrupt.' }, [403]))

const chooseReaction = (params) =>
  http
    .post(GAME_HTTP_ROUTES.chooseReaction, params)
    .catch(handleAPIError({ default: 'Cannot choose this reaction.' }, [403]))

const relocate = (targetLocationID?: string) =>
  http
    .post(GAME_HTTP_ROUTES.relocate, { relocation: { target_location: targetLocationID } })
    .then(validateForSuccess)
    .catch(handleAPIError({}, [403]))

const changeFinancialSettings = (settings) =>
  http.post(GAME_HTTP_ROUTES.changeFinancialSettings, settings).catch(handleAPIError({}, [403]))

const getVisualSettings = (): APIResponse<VisualSettings> =>
  http.get(GAME_HTTP_ROUTES.getVisualSettings).catch(handleAPIError({}))

export const gameAPI = {
  getState,
  makeTransaction,
  chooseDream,
  endTurn,
  resetTurn,
  chooseReaction,
  relocate,
  changeFinancialSettings,
  goBankrupt,
  getVisualSettings,
}
