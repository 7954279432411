import { enhanceAPIValidationError } from 'lib/http/error'

export const validateAPIResponse = (schema: any) => (resp) => {
  try {
    const { data } = resp
    schema.check(data)
    return resp
  } catch (error) {
    throw enhanceAPIValidationError(error)
  }
}
