import { useStore } from 'effector-react'
import { useCallback, useEffect } from 'react'

import { $error } from 'features/error'

export const useKeypress = (key: string, cb: () => void) => {
  const err = useStore($error)
  const onKeyPress = useCallback(
    (ev) => {
      const { code } = ev
      const path = ev.path || (ev.composedPath && ev.composedPath())
      const isInsideSentryForm =
        path.filter((el) => el?.classList?.contains('sentry-error-embed-wrapper'))?.length > 0
      if (code === key && !err && !isInsideSentryForm) cb()
    },
    [err, cb, key]
  )
  useEffect(() => {
    const root = window
    root.addEventListener('keydown', onKeyPress)
    return () => {
      root.removeEventListener('keydown', onKeyPress)
    }
  }, [cb, err, onKeyPress])
}
