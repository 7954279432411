import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { ReactComponent as arrowNoColor } from 'static/arrow-no-color.svg'
import { theme } from 'theme'
import { FlippingNumbers } from 'ui/molecules/flipping-numbers'

export const PriceWithArrow = ({ status, current, noDecimals }) => {
  return (
    <Price status={status}>
      {status !== 'neutral' && <ArrowIcon isUp={status === 'up'} />}
      <FlippingNumbers noDecimals={noDecimals}>{current}</FlippingNumbers>
    </Price>
  )
}

export const ArrowIcon = styled(arrowNoColor)`
  height: 10px;
  margin-right: 7px;
  transition: all 1s ease-in-out;
  width: 8px;
  ${ifProp(
    'isUp',
    css`
      color: ${theme.color.green};
    `,
    css`
      color: ${theme.color.red};
      transform: rotate(180deg);
    `
  )}
`
export const Price = styled.span`
  color: ${(props) =>
    props.status === 'up' ? theme.color.green : (props.status === 'down' ? theme.color.red : 'inherit')};
  display: block;
  font-size: ${theme.font.size.mediumsmall}px;
  font-weight: 500;
  transition: all 1s ease-in-out;
  ${ifProp('oneLine', 'white-space: nowrap;')}
`
