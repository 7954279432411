import { theme } from 'theme'

export const baseChartOpts = {
  colors: [theme.color.green],
  yaxis: {
    opposite: true,
    decimalsInFloat: 2,
    labels: {
      show: true,
      align: 'right',
      //   formatter: data => {
      //     let newData = String(data)
      //     // if (newData.length > 5) newData = newData.substr(0, 4) + '...'
      //     return newData
      //   },
      style: {
        colors: theme.color.gray400,
        fontSize: '11px',
        fontFamily: 'Gilroy, Helvetica, Arial, sans-serif',
        fontWeight: 600,
      },
      //   offsetX: -5,
    },
    axisBorder: {
      //   show: true,
      show: false,
      color: '#464a52',
      strokeDashArray: 5,
      //   offsetX: -10,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: true,
      width: 2,
      position: 'back',
      opacity: 1,
      stroke: {
        color: theme.color.primary,
        width: 2,
        dashArray: 0,
      },
    },
    tooltip: {
      enabled: true,
      offsetX: 5,
    },
  },
  xaxis: {
    type: 'numeric',
    tickAmount: 'dataPoints',
    labels: {
      show: true,
      trim: true,
      style: {
        colors: theme.color.gray400,
        fontSize: '11px',
        fontFamily: 'Gilroy, Helvetica, Arial, sans-serif',
        fontWeight: 500,
      },
      formatter: function (value) {
        return Math.floor(value)
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'back',
      opacity: 1,
      stroke: {
        color: theme.color.primary,
        width: 2,
        dashArray: 0,
      },
    },
    tooltip: {
      offsetY: 11,
      enabled: true,
      formatter: (data) => data,
    },
  },
  chart: {
    type: 'area',
    selection: {
      enabled: false,
    },
    toolbar: {
      autoSelected: 'pan',
      show: false,
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: false,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
    parentHeightOffset: 0,
    // offsetX: 0,
    // offsetY: 0,
  },
  grid: {
    show: true,
    borderColor: '#464a52',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    // padding: {
    //   top: 0,
    //   right: 0,
    //   bottom: 0,
    //   left: 0,
    // },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 0,
      opacityFrom: 0.75,
      opacityTo: 0.24,
      stops: [0, 90],
    },
  },
  tooltip: {
    followCursor: false,
    style: {
      fontSize: '12px',
      fontFamily: 'Gilroy, Helvetica, Arial, sans-serif',
    },
    x: {
      // format: 'yyyy',
      formatter: (val) => `${val} turn`,
    },
    theme: 'dark',
  },
}
