import React, { Component } from 'react'
import styled from 'styled-components'
import { ifNotProp } from 'styled-tools'

import bg from 'static/error-stripes.svg'

import { sendToSentry } from '../lib/send-to-sentry'

export class ErrorStripes extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null, hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { error: error, hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const eventId = sendToSentry(error, 'error', errorInfo)
    this.setState({ eventId })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Filler noFull={this.props.noFull} {...{ bg }}>
          <Heading>Error</Heading>
          <Subheading>
            <Link
              onClick={() => {
                window.location.reload()
              }}
            >
              Refresh page
            </Link>
            <br />
            Details: {this.state.error?.toString?.() || this.state.error?.message}
          </Subheading>
        </Filler>
      )
    }
    return this.props.children
  }
}

const Link = styled.span`
  color: white;
  font-weight: 800;
  text-decoration: underline;
`

const Subheading = styled.h3`
  color: white;
  font-size: 14px;
  font-weight: 500;
  margin-top: 7px;
  text-align: center;
  z-index: 2;
`

const Heading = styled.h1`
  color: white;
  font-size: 22px;
  font-weight: 800;
  margin: 0;
  z-index: 2;
`

const Filler = styled.div`
    align-items: center;
    display: flex;
    ${ifNotProp(
      'noFull',
      `
      flex: 1;
      width: 100%;
      height: 100%;
    `
    )}
    flex-direction: column;
    justify-content: center;
    position: relative;
    &::before {
      background-image: url('${bg}');
      background-size: 140%;
      bottom: 0;
      content: ' ';
      left: 0;
      opacity: 0.2;
      position: absolute;
      right: 0;
      top: 0;
    }
`
