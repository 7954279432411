import React from 'react'
import ApexCharts from 'react-apexcharts'
import { isFirefox } from 'react-device-detect'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { RiskCircle } from 'features/game/field/molecules/risk-circle'
import { formatPrice } from 'features/game/lib/prices/format'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'

const CHART = [34, 23, 54, 12]
export const PieChart = ({
  data = CHART,
  labels = [],
  height = 210,
  width,
  colors,
  className,
  targetRelativeValues,
}) => {
  const chartOpts = {
    chart: {
      offsetX: -20,
    },
    labels,
    colors,
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -15,
        },
      },
    },
    legend: {
      fontSize: theme.font.size.small,
      fontFamily: 'Gilroy, Helvetica, Arial',
      fontWeight: 500,
      offsetX: 3,
      offsetY: -25,
      labels: {
        colors: [hexToRGBA(theme.color.black, 0.8)],
      },
      itemMargin: {
        horizontal: 0,
      },
    },
    stroke: {
      colors: [theme.color.secondary],
    },
    dataLabels: {
      style: {
        fontSize: '14px',
        fontFamily: 'Gilroy, Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: [theme.color.black],
      },
      dropShadow: {
        enabled: true,
        top: 0,
        left: 0,
        blur: 2,
        color: theme.color.white,
        opacity: 0.6,
      },
      enabled: !isFirefox,
    },
    style: {},
    tooltip: {
      style: {
        fontSize: theme.font.size.extrasmall,
        fontFamily: 'Gilroy, Helvetica',
        color: theme.color.primary,
      },
      marker: {
        show: true,
      },
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          return formatPrice(val)
        },
      },
    },
  }
  return (
    data &&
    data.length > 0 && (
      <ChartContainer {...{ className }}>
        <ApexCharts options={chartOpts} series={data} width={width} type="pie" height={height} />
        <RequiredContainer leftAligned={isFirefox}>
          <Heading weight="600" size={theme.font.size.mediumsmall}>
            Recommended:
          </Heading>
          {colors.map((color, idx) => (
            <Row key={idx}>
              <RiskCircle mr={8} {...{ color }} />
              <Label weight="500" size={theme.font.size.mediumsmall}>
                {formatPrice(targetRelativeValues[idx], { disableCurrency: true })}%
              </Label>
            </Row>
          ))}
        </RequiredContainer>
      </ChartContainer>
    )
  )
}

const Label = styled(Typography)``

const Row = styled.div`
  align-items: center;
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`

const Heading = styled(Typography)`
  margin-bottom: 5px;
`

const RequiredContainer = styled.div`
  bottom: 5px;
  left: calc(50% + 20px);
  position: absolute;
  ${ifProp(
    'leftAligned',
    `
    left: 0;
  `
  )}
`

const ChartContainer = styled.div`
  position: relative;
  & .apexcharts-legend-marker {
    margin-right: 8px;
    right: 15px;
    top: 2px;
  }
  & .apexcharts-tooltip-text-label,
  & .apexcharts-tooltip-text-value {
    font-weight: 600;
  }
  & .apexcharts-tooltip-y-group {
    padding-bottom: 0;
    padding-top: 5px;
  }
  & .apexcharts-tooltip-marker {
    margin-right: 6px;
    top: 2px;
  }
  & .apexcharts-tooltip {
    box-shadow: 0px 0px 7px ${hexToRGBA(theme.color.thirdly, 0.8)};
  }
  & .apexcharts-legend-series {
    align-items: center;
    display: flex;
  }
`
