import React from 'react'
import AutosizeInput from 'react-input-autosize'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

export const Input = React.forwardRef(
  (
    {
      className,
      fontSize = theme.font.size.main,
      padding = theme.padding.button.medium,
      radius = theme.radius.button.medium,
      color = theme.color.black,
      fontColor = theme.color.black,
      maxWidth,
      disabled,
      value,
      onChange,
      placeholder,
      fullWidth,
      pattern,
      hasError,
      autoSize,
      ...rest
    },
    ref
  ) => {
    const cmpProps = {
      value,
      onChange,
      placeholder,
      pattern,
      ref,
      ...rest,
    }
    const tag = autoSize ? (
      <UIInputWrapper
        {...{ fontColor, fontSize, radius, color, disabled, fullWidth, hasError, padding, maxWidth }}
      >
        <AutosizeInput {...cmpProps} />
      </UIInputWrapper>
    ) : (
      <UIInput
        {...cmpProps}
        {...{
          fontColor,
          fontSize,
          radius,
          color,
          disabled,
          fullWidth,
          hasError,
          padding,
          className,
          maxWidth,
        }}
      />
    )
    return tag
  }
)
export const UIInputWrapper = styled.div`
  & input {
    border: 1px solid ${(props) => (props.hasError ? theme.color.red : hexToRGBA(props.color, 0.2))};
    border-radius: ${prop('radius')}px;
    color: ${prop('fontColor')};
    font-size: ${prop('fontSize')}px;
    padding: ${prop('padding')};
    ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth || 110}px;` : '')}
    transition: all 0.15s ease-in-out;
    &::placeholder {
      color: ${(props) => hexToRGBA(props.color, 0.5)};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-internal-autofill-previewed,
    &:-webkit-autofill::first-line {
      -webkit-text-fill-color: ${prop('fontColor')};
      border: 1px solid ${(props) => (props.hasError ? theme.color.red : hexToRGBA(props.color, 0.2))};
      box-shadow: 0 0 0px 1000px transparent inset;
      font-size: ${prop('fontSize')}px;
      transition: background-color 5000s ease-in-out 0s;
    }
    ${ifProp(
      'disabled',
      `
  opacity: 0.7;
  cursor: not-allowed;
  color: ${prop('color')};
  background: ${theme.color.secondary};
  border-color: ${theme.color.secondary};
  box-shadow: none;
  `
    )}
    ${ifProp(
      'fullWidth',
      `
  flex: 1;
  width: 100%;
  `
    )}
  }
`

export const UIInput = styled.input`
  border: 1px solid ${(props) => (props.hasError ? theme.color.red : hexToRGBA(props.color, 0.2))};
  border-radius: ${prop('radius')}px;
  color: ${prop('fontColor')};
  ${ifProp('maxWidth', 'max-width: 110px;')}
  font-size: ${prop('fontSize')}px;
  padding: ${prop('padding')};
  transition: all 0.15s ease-in-out;
  &::placeholder {
    color: ${(props) => hexToRGBA(props.color, 0.5)};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-internal-autofill-previewed,
  &:-webkit-autofill::first-line {
    -webkit-text-fill-color: ${prop('fontColor')};
    //border: 1px solid ${(props) => (props.hasError ? theme.color.red : hexToRGBA(props.color, 0.2))};
    box-shadow: 0 0 0px 1000px transparent inset;
    font-size: ${prop('fontSize')}px;
    transition: background-color 5000s ease-in-out 0s;
  }
  ${ifProp(
    'disabled',
    `
opacity: 0.7;
cursor: not-allowed;
color: ${prop('color')};
background: ${theme.color.secondary};
border-color: ${theme.color.secondary};
box-shadow: none;
`
  )}
  ${ifProp(
    'fullWidth',
    `
flex: 1;
width: 100%;
`
  )}
`
