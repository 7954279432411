import { combine, createEvent, createStore, sample } from 'effector-root'

import { applySort } from 'lib/sort/apply-sort'

const FILTERS = {
  roomsType: {
    roomsType: 'challenge',
  },
  purchased: {
    purchased: false,
  },
  leverageUsed: {
    leverageUsed: false,
  },
  accomplishedGoals: {
    accomplishedGoals: false,
  },
  notCompleted: {
    notCompleted: false,
  },
  typeOfEntities: {
    all: true,
    stockMarket: false,
    realEstate: false,
    goods: false,
    services: false,
  },
}

export const FILTERS_LABELS = {
  roomsType: 'roomsType',
  purchased: 'Purchased',
  leverageUsed: 'Leverage used',
  accomplishedGoals: 'Completed',
  notCompleted: 'Not completed',
}

export const UTILITY_TABS = ['turnActions', 'events']

const createFilters = (filters = []) => {
  let filtersArr = []
  filters
    .filter((name) => Object.keys(FILTERS).includes(name))
    .forEach((name) => {
      let curFilter = FILTERS[name]
      Object.entries(curFilter).forEach((entry) => filtersArr.push(entry))
    })
  filtersArr = Object.fromEntries(filtersArr)
  return filtersArr
}

const applyFiltersAndSort = ({ list, filters, sort }) => {
  let newList = list?.length > 0 ? [...list] : []
  Object.entries(filters).forEach((entry) => {
    const key = entry[0]
    const value = entry[1]
    newList = newList.filter((item) => {
      if (value) {
        switch (key) {
          case 'purchased':
            return item.qty && item.qty > 0
          case 'accomplishedGoals':
            return item.status === 'finished'
          case 'notCompleted':
            return item.status !== 'finished'
          case 'leverageUsed':
            return item.hasOwnProperty('creditDetails')
          case 'roomsType':
            return item.type === value
          default:
            console.error('unknown filter:', key)
            return true
        }
      }
      return true
    })
  })
  return applySort(sort, newList)
}

export const createEntityListModel = (
  listStore,
  filtersList,
  defaultOrderBy = 'name',
  subTabKey,
  rootModel,
  defaultOrder = 'asc'
) => {
  const resetSort = createEvent()
  const setSort = createEvent()
  const resetFilters = createEvent()
  const setFilter = createEvent()
  const resetSelected = rootModel ? rootModel.resetSelected : createEvent()
  const setSelected = rootModel ? rootModel.setSelected : createEvent()
  const setList = createEvent()
  const toggleExpanded = createEvent()
  const resetExpandedRows = createEvent()

  const sort = createStore({
    order: defaultOrder,
    orderBy: defaultOrderBy,
    sortFn: null,
  })
    .on(setSort, (_, val) => val)
    .reset(resetSort)

  const filters = createStore(createFilters(filtersList))
    .on(setFilter, (prevState, { name, value }) => {
      return { ...prevState, [name]: value }
    })
    .reset(resetFilters)

  const list = combine(listStore, filters, sort, (lStore, fStore, sStore) =>
    applyFiltersAndSort({
      list: lStore,
      filters: fStore,
      sort: sStore,
    })
  ).on(setList, (_, l) => l)

  const selected = createStore(null).reset([resetSelected, setFilter])

  sample({
    source: selected,
    clock: list,
    target: selected,
    fn: (selected, list) => {
      const foundRow = list.find((el) => el.frontId === selected?.frontId)
      window.log(foundRow, 'new selected item because of list change')
      const other = list.length > 0 ? list[0] : null
      return foundRow ? foundRow : other
    },
  })

  sample({
    source: list,
    clock: setSelected,
    target: selected,
    fn: (sourceData, clockData) => {
      window.log(clockData, 'setSelected item')
      const foundRow = sourceData.find((el) => el.frontId === clockData?.frontId)
      return foundRow ? foundRow : null
    },
  })

  const expandedRows = createStore({})
    .on(toggleExpanded, (state, { id, value }) => {
      let newState = { ...state }
      let newValue = value
      const foundRow = newState.hasOwnProperty(id)
      if (!foundRow) {
        if (newValue === undefined) newValue = true
        newState[id] = newValue
      } else {
        if (newValue === undefined) {
          newValue = !state[id]
        } else {
          newState[id] = value
          if (!newValue) delete newState[id]
        }
      }
      return newState
    })
    .reset(resetExpandedRows)

  const selectedId = selected.map((el) => (el?.frontId || el?.id) ?? null)
  const selectedIdx = sample({
    source: list,
    clock: selected,
    fn: (sourceData, clockData) => {
      const foundIdx = sourceData.findIndex((item) => item?.frontId === clockData?.frontId)
      return foundIdx >= 0 ? foundIdx : null
    },
  })

  return {
    subTabKey,
    list,
    resetFilters,
    resetSelected,
    resetSort,
    setSelected,
    setFilter,
    setSort,
    filters,
    filtersList,
    sort,
    selected,
    selectedId,
    selectedIdx,
    expandedRows,
    toggleExpanded,
    resetExpandedRows,
  }
}

export const createSubTabModel = (state, filtersList, defaultOrderBy = 'id', views, transformFn, key) => {
  const mappedState = transformFn ? state.map(transformFn) : state
  if (views?.length > 0) {
    const rootModel = createEntityListModel(mappedState, filtersList, defaultOrderBy, key)
    return {
      ...rootModel,
      views: views.reduce((acc, cur) => {
        const mappedState = cur.transformFn ? state.map(cur.transformFn) : state
        return {
          ...acc,
          [cur.key]: createEntityListModel(mappedState, filtersList, defaultOrderBy, key, rootModel),
        }
      }, {}),
    }
  }
  return createEntityListModel(mappedState, filtersList, defaultOrderBy, key)
}

const createTabModel = (tab, states) =>
  tab.subtabs.reduce((acc, cur) => {
    let state, tabKey, subTabKey
    if (UTILITY_TABS.includes(tab.key)) {
      state = states[tab.key]
    } else {
      tabKey = tab.key
      subTabKey = cur.key
      state = states.main.map((s) => s[tabKey][subTabKey])
    }
    return {
      ...acc,
      [cur.key]: createSubTabModel(
        state,
        cur.listFilters || tab.listFilters || [],
        cur.defaultOrderBy || tab.defaultOrderBy,
        cur.views,
        cur.transformFn,
        cur.key
      ),
    }
  }, {})

export const createRootTabsModel = (structure, states) => {
  return structure.reduce(
    (acc, tab) => ({
      ...acc,
      [tab.key]: createTabModel(tab, states),
    }),
    {}
  )
}
