import React from 'react'
import { Typography } from 'ui/atoms/typography'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import styled from 'styled-components'

export const NoData = () => {
  return (
    <Container>
      <Typography weight="600" color={hexToRGBA(theme.color.black, 0.7)}>
        No data
      </Typography>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`
