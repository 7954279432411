import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'
import { uuid } from 'uuidv4'

import { getSubjectInstrument } from 'features/game/lib/get-subject-instrument'
import { imgSrc } from 'features/game/lib/img-src'
import { formatPrice } from 'features/game/lib/prices/format'
import { selectRelatedInstrument } from 'features/game/lib/select-related-instrument'
import { $activeTransactionType, $selectedSubTabData, makeTransaction } from 'features/game/model'
import { handleGoalStatusText } from 'lib/handle-fields'
import { hexToRGBA } from 'lib/hex-to-rgba'
import successIcon from 'static/check-green.svg'
import showIcon from 'static/eye.svg'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { ActionButton } from 'ui/atoms/button/action-button'
import { Row } from 'ui/atoms/row'
import { Typography } from 'ui/atoms/typography'
import { HeadingWithStripe } from 'ui/molecules/heading-with-stripe'
import { NoData } from 'ui/molecules/no-data'

const renderTasks = (task, isLoading) => {
  let value = task.value
  if (typeof task.value === 'object')
    value = getSubjectInstrument(task.value.subjectID, task.value.subjectType).price.current
  const onBuyTask = () => {
    makeTransaction({
      id: task.value?.subjectID,
      value: 1,
      actionType: 'buy',
      instrumentType: task.value?.subjectType,
      uid: uuid(),
    })
  }
  return (
    <Task key={task.id}>
      <LeftTaskContainer>
        <Typography
          overflowHidden
          oneLine
          color={theme.color.gray400}
          weight="600"
          size={theme.font.size.small}
        >
          {task.description}
        </Typography>
        {task.value.subjectID && (
          <ShowIcon
            onClick={() => selectRelatedInstrument(task.value.subjectID, task.value.subjectType)}
            src={showIcon}
          />
        )}
        {task.status !== 'ready' && task.value.subjectID && (
          <Button
            variant="greenOutlined"
            onClick={onBuyTask}
            disabled={isLoading}
            padding={theme.padding.button.task}
            fontSize={theme.font.size.extrasmall}
          >
            Buy
          </Button>
        )}
        {task.status === 'ready' && <BuyIcon src={successIcon} />}
      </LeftTaskContainer>
      <IconContainer>
        {task.value.subjectID && (
          <TaskPrice oneLine overflowHidden fontMono size={theme.font.size.mediumsmall} weight="500">
            {formatPrice(value, { noDecimals: true })}
          </TaskPrice>
        )}
      </IconContainer>
    </Task>
  )
}

export const GoalDetails = () => {
  const selectedInstrument = useStore(useStore($selectedSubTabData).selected)
  const isBuying = useStore($activeTransactionType) === 'buy'
  const onBuyGoal = () => {
    makeTransaction({
      id: selectedInstrument.id,
      value: 1,
      actionType: 'buy',
      instrumentType: selectedInstrument.instrumentType,
      uid: uuid(),
      portfolioId: selectedInstrument.portfolio_id,
    })
  }
  if (!selectedInstrument) return <NoData />
  return (
    <Container>
      <InnerContainer>
        <GoalImg src={imgSrc(selectedInstrument.image, 'goal')} />
        <DetailsContainer>
          <HeadingRow>
            <Typography size={35} weight="bold">
              {selectedInstrument.name}
            </Typography>
            <ActionButton onClick={onBuyGoal} disabled={isBuying} type="buy">
              Buy
            </ActionButton>
          </HeadingRow>
          <StatusContainer>
            <Typography
              size={theme.font.size.main + 2}
              color={hexToRGBA(theme.color.black, 0.7)}
              weight="bold"
            >
              Flowpoints:
            </Typography>
            <Typography weight="600" size={theme.font.size.large}>
              {formatPrice(selectedInstrument.flowPoints, { disableCurrency: true })}
            </Typography>
          </StatusContainer>
          <StatusContainer>
            <Typography color={theme.color.gray400} weight="bold">
              Status:
            </Typography>
            <Typography>{handleGoalStatusText(selectedInstrument.status)}</Typography>
          </StatusContainer>
          <DescriptionContainer>
            <Typography size={15} color={hexToRGBA(theme.color.black, 0.7)} weight="600">
              Description
            </Typography>
            <Typography
              size={theme.font.size.extrasmall}
              color={hexToRGBA(theme.color.black, 0.7)}
              dangerouslySetInnerHTML={{ __html: selectedInstrument.description }}
            />
          </DescriptionContainer>
          {selectedInstrument.tasks?.length > 0 && (
            <TasksContainer>
              <TasksHeader>
                <HeadingWithStripe
                  fontSize={theme.font.size.main}
                  stripeLeft={-12}
                  stripeTop={2}
                  stripeHeight={16}
                >
                  Tasks
                </HeadingWithStripe>
                <Typography size={theme.font.size.small}>
                  {formatPrice(selectedInstrument.sum, { noDecimals: true })}
                </Typography>
              </TasksHeader>
              <TasksBody>{selectedInstrument.tasks.map((task) => renderTasks(task, isBuying))}</TasksBody>
            </TasksContainer>
          )}
        </DetailsContainer>
      </InnerContainer>
    </Container>
  )
}

const LeftTaskContainer = styled.div`
  align-items: center;
  display: flex;
  & > *:not(:first-child) {
    margin-left: 7px;
  }
  margin-right: 10px;
`

const HeadingRow = styled(Row)`
  align-items: center;
  margin-bottom: 10px;
  & > *:last-child {
    margin-left: 10px;
  }
`

const TaskPrice = styled(Typography)``

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  & > * {
    margin-left: 7px;
  }
`

const ShowIcon = styled.img`
  cursor: pointer;
  filter: saturate(0) brightness(4);
  height: 18px;
`

const BuyIcon = styled.img`
  height: 13.3px;
  width: 18px;
`

const Task = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  &:first-of-type {
    margin-top: 14px;
  }
  &:last-of-type {
    margin-bottom: 14px;
  }
  margin: 7px 0;
`

const TasksBody = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`

const TasksHeader = styled(Row)`
  border-bottom: 1px solid ${hexToRGBA(theme.color.black, 0.1)};
  padding: 10px 12px;
`

const TasksContainer = styled.div`
  background: ${theme.color.darksecondary};
  border-radius: ${theme.radius.button.small}px;
  margin-bottom: 20px;
`

const DescriptionContainer = styled.div`
  margin-bottom: 18px;
  margin-top: 8px;
`

const StatusContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const DetailsContainer = styled.div`
  margin: 20px auto;
  max-width: 70%;
`

const GoalImg = styled.img`
  border-radius: ${theme.radius.form.action}px;
  display: block;
  height: 28vh;
  margin: 0 auto;
  width: auto;
`

const InnerContainer = styled.div`
  border-radius: ${theme.radius.form.action}px;
  flex: 1;
  text-align: left;
  width: 100%;
`

const Container = styled.div`
  align-items: center;
  background: ${theme.color.darkgray};
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  text-align: center;
`
