import { createEffect, merge, createStore, attach } from 'effector-root'

import { adminAPI } from 'api/admin'
import { createEffectWithFetching, createFetching } from 'lib/fetching'
import { getGameState } from 'features/game/model'
import { debounce } from 'lib/debounce'
import { getRoom } from 'features/rooms/model'
import { $roomID, setIsNowPlaying } from 'features/game/navigation/model/common'

export const [refreshScenario, refreshScenarioFetching] = createEffectWithFetching()
export const [changeCharacter, changeCharacterFetching] = createEffectWithFetching()
export const _goToStep = createEffect()
export const goToStep = debounce(_goToStep, 1000)
export const getCharactersList = attach({
  effect: getRoom,
  source: $roomID,
  mapParams: (_, roomID) => roomID,
})
export const _viewGame = createEffect()
export const viewGame = attach({
  effect: _viewGame,
  source: $roomID,
  mapParams: (params, roomID) => {
    let newParams = { ...params }
    if (!params.roomID && roomID) newParams.session_id = roomID
    return newParams
  },
})

export const $chars = createStore([])

_goToStep.use((idx) => adminAPI.goToStep(idx))
_viewGame.use(({ user_id, game_id, session_id }) =>
  adminAPI.viewGame({ userID: user_id, gameID: game_id, sessionID: session_id })
)
refreshScenario.use(() => adminAPI.refreshScenario())
changeCharacter.use((id) => adminAPI.changeCharacter(id))

merge([_goToStep.done, refreshScenario.done, changeCharacter.done]).watch(() => {
  getGameState()
})
$chars.on(getCharactersList.done, (_, { result: { data: { characters } } }) =>
  characters.map((ch) => ({ label: ch.name, value: ch.id }))
)
viewGame.done.watch(() => {
  setIsNowPlaying()
})

export const goToStepFetching = createFetching(_goToStep)
export const getCharactersListFetching = createFetching(getCharactersList)
export const viewGameFetching = createFetching(viewGame)
