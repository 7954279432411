export function getMedian(arr) {
  arr = arr.map((el) => Number(el)).filter((el) => !Number.isNaN(el))
  if (arr.length === 0) return 0
  if (arr.every((el) => el === 0)) return 0
  const L = arr.length,
    halfL = L / 2
  return L % 2 === 1 ? quickselect(arr, halfL) : 0.5 * (quickselect(arr, halfL - 1) + quickselect(arr, halfL))
}

function quickselect(arr, k) {
  // Select the kth element in arr
  // arr: List of numerics
  // k: Index
  // return: The kth element (in numerical order) of arr
  if (arr.length === 1) return arr[0]
  else {
    const pivot = arr[0]
    const lows = arr.filter((e) => e < pivot)
    const highs = arr.filter((e) => e > pivot)
    const pivots = arr.filter((e) => e === pivot)
    if (k < lows.length)
      // the pivot is too high
      return quickselect(lows, k)
    else if (k < lows.length + pivots.length)
      // We got lucky and guessed the median
      return pivot
    // the pivot is too low
    else return quickselect(highs, k - lows.length - pivots.length)
  }
}
