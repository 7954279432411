export const normalizeMarket = (market) => {
  let newMarket = {
    ...market,
    etf: [...market.stockMarket.mf, ...market.stockMarket.etf],
    REBusiness: {
      realEstate: market.goodsAndServices.realEstate.map((estate) => {
        let newEstate = estate
        newEstate.rentPrice = { current: estate.rentPrice || null, previous: estate.prevRentPrice || null }
        return newEstate
      }),
      businesses: market.goodsAndServices.businesses,
    },
  }
  return newMarket
}
