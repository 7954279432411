//@ts-nocheck
import { createEvent, createStore } from 'effector-root'
import { createFieldHandler } from 'lib/handle-fields/factory'

import { Storage } from 'lib/storage'

import { sendToSentry } from './lib/send-to-sentry'
import { showNotifications } from './lib/show-notifications'

const mapErrorLevelToSentry = (lvl) =>
  createFieldHandler(lvl, {
    default: 'error',
    warn: 'warning',
    notify: 'info',
    log: 'debug',
    error: 'error',
    fatal: 'fatal',
  })

declare global {
  interface Window {
    FLG_ERRORS: any[]
    ym: any
    log: any
  }
}

export const addError = createEvent<any>()
export const clearErrors = createEvent()
export const setError = createEvent()
export const clearError = createEvent()
export const setErrorEventId = createEvent()

export const $errorEventId = createStore(null)
  .on(setErrorEventId, (_, p) => p)
  .reset(clearError)
export const $errors = createStore([])
  .on(addError, (st, e) => {
    const error = { ...e, date: new Date() }
    window.log(JSON.stringify(error), 'addError', 'error obj')
    Storage.addError(error)
    showNotifications(error)
    if (e.level !== 'notify') {
      sendToSentry(error, mapErrorLevelToSentry(e.level)).then((evId) => {
        window.log(evId, 'eventId', 'addError')
        setErrorEventId(evId)
      })
    }
    return [...st, error]
  })
  .reset(clearErrors)
export const $error = $errors
  .map((arr) => {
    const fatals = arr.filter((er) => er.level === 'fatal')
    if (fatals.length === 0) return null
    return fatals.pop().error
  })
  .on(setError, (_, p) => p)
  .reset(clearError)
$errors.watch((state) => {
  if (!window.FLG_ERRORS) window.FLG_ERRORS = []
  window.FLG_ERRORS = [...state]
})
