export const addAssetsLiabilities = (state) => {
  const INSTRUMENTS_TABS = new Set(['stockMarket'])
  let all = []
  Object.entries(state).forEach((entry) => {
    const key = entry[0]
    //@ts-ignore
    Object.entries(entry[1]).forEach((tabEntry) => {
      const subTabKey = tabEntry[0]
      const subTab = tabEntry[1]
      if (Array.isArray(subTab)) {
        subTab.forEach((instrument) => {
          if (instrument.qty > 0 && instrument.isPortfolio) {
            let newInstrument = {
              ...instrument,
              type: key,
              subType: subTabKey,
              subjectID: instrument.id,
              totalSum: instrument.qty * instrument.price.current,
            }
            if (subTabKey === 'stocks') {
              //newInstrument.annualIncome = instrument.dividends
              newInstrument.isAsset = true
            }
            if (['bonds', 'indicies', 'indexes', 'commodities', 'crypto', 'goods'].includes(subTabKey)) {
              // newInstrument.annualIncome = 0
              newInstrument.isAsset = true
            }
            if (['deposits'].includes(subTabKey)) {
              // newInstrument.annualIncome = 0
              newInstrument.isAsset = true
            }
            if (['realEstate'].includes(subTabKey)) {
              newInstrument.isAsset = true
              newInstrument.type = subTabKey
            }
            if (['credit', 'leverage'].includes(subTabKey)) {
              // newInstrument.annualExpense = 0
              newInstrument.isLiability = true
            }
            if (['etf', 'mf'].includes(subTabKey)) {
              //newInstrument.annualIncome = instrument.dividends
              newInstrument.isAsset = true
            }
            if (['services'].includes(subTabKey)) {
              //newInstrument.annualExpense = 0
              newInstrument.isAsset = true
            }
            if (newInstrument.isLiability || newInstrument.isAsset) all.push(newInstrument)
          }
        })
      } else {
        window.log(subTab, 'is not an Array')
      }
    })
  })
  const instruments = all.filter((instr) => INSTRUMENTS_TABS.has(instr.type))
  const products = all.filter((instr) => instr.subType === 'goods')
  const services = all.filter((instr) => instr.subType === 'services')
  const banking = all.filter((instr) => instr.type === 'banking')
  return {
    ...state,
    assetsAndLiabilities: {
      all,
      instruments,
      products,
      services,
      banking,
    },
  }
}
