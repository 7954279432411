import React from 'react'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp, prop, switchProp } from 'styled-tools'

import { theme } from 'theme'

export const Highlight = ({
  className,
  width = 60,
  containerOffset = -10,
  ovalOffset = -30,
  tabs,
  ovalLeft,
  ovalOpacity = 0.7,
  ovalHeight = 30,
  ovalBlur = 25,
  type = 'bottom',
  needSiblingsRelative = true,
  forceVisibility,
}) => {
  return (
    <OvalContainer {...{ containerOffset, type, className, needSiblingsRelative, forceVisibility }}>
      <Oval {...{ ovalHeight, ovalBlur, ovalOffset, tabs, type, width, ovalOpacity, ovalLeft }} />
    </OvalContainer>
  )
}

const Oval = styled.div`
  background: ${theme.color.primary};
  border-radius: 50%;
  ${ifProp(
    'ovalLeft',
    css`
      left: ${prop('ovalLeft')};
    `,
    css`
      left: calc((100% - ${prop('width')}%) / 2);
    `
  )}
  bottom: -30px;
  filter: blur(${prop('ovalBlur')}px);
  height: ${prop('ovalHeight')}px;
  position: absolute;
  width: ${prop('width')}%;
  ${switchProp('type', {
    bottom: css`
      bottom: ${prop('ovalOffset')}px;
      opacity: ${prop('ovalOpacity')};
    `,
    top: css`
      opacity: ${prop('ovalOpacity')};
      top: ${prop('ovalOffset')}px;
    `,
    tabs: css`
      bottom: -23px;
      filter: blur(15px);
    `,
  })}
`

const OvalContainer = styled.div`
  bottom: ${prop('containerOffset')}px;
  ${ifNotProp('forceVisibility', 'opacity: 0;')}
  height: 250%;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  transition: all 0.2s ease-in-out;
  width: 100%;
  ${ifProp(
    'needSiblingsRelative',
    `& ~ * {
    z-index: 1;
    position: relative;
  }`
  )}
`
