import { createStore } from 'effector-root'
import { useStore } from 'effector-react'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const TRUE_STORE_HACK = createStore(true)

export const PrivateRoute = ({ children, store = TRUE_STORE_HACK, ...rest }) => {
  const storeIsNotNull = useStore(store)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        storeIsNotNull ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
