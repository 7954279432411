import { uuid } from 'uuidv4'

import { addAssetsLiabilities } from '../normalize-market/add-assets-liabilities'
import { addIncomeExpenses } from '../normalize-market/add-income-expenses'

export const transformTabs = (state) => {
  let newState = {
    personal: {
      dreams: state.dreamsAndGoals.dreams,
      goals: state.dreamsAndGoals.goals,
      assetsAndLiabilities: state.assetsAndLiabilities.all,
      incomeAndExpenses: state.incomeAndExpenses,
    },
    otherMarkets: {
      realEstate: state.goodsAndServices.realEstate,
      goods: state.goodsAndServices.goods,
      services: state.goodsAndServices.services,
    },
    financialMarkets: {
      stocks: state.stockMarket.stocks,
      bonds: state.stockMarket.bonds,
      etf: state.stockMarket.etf,
      crypto: state.stockMarket.crypto,
      commodities: state.stockMarket.commodities,
      indicies: state.stockMarket.indicies,
      mf: state.stockMarket.mf,
      banking: [...state.banking.credit, ...state.banking.deposits, ...state.banking.leverage],
    },
  }
  return newState
}

export const mergeMarketPortfolio = ({ market, portfolio }) => {
  let result = market
  let newResult = Object.entries(result).map((entry) => {
    const key = entry[0]
    let marketTab = entry[1]
    let portfolioTab = portfolio[key] //stockMarket obj
    if (portfolioTab) {
      marketTab = Object.fromEntries(
        Object.entries(marketTab).map((marketEntry) => {
          const marketSubTabKey = marketEntry[0] // 'stocks'
          let marketSubTabValue = [...marketEntry[1]] // stocks arr

          let portfolioSubTab = portfolioTab[marketSubTabKey] // stocks arr

          if (portfolioSubTab) {
            if (Array.isArray(marketSubTabValue) && marketSubTabValue.length > 0) {
              marketSubTabValue = marketSubTabValue
                .map((marketInstrument) => {
                  let newMarketInstrument = marketInstrument
                  let portfolioInstrument = portfolioSubTab.find((el) => el.id === marketInstrument.id)
                  if (portfolioInstrument) {
                    // мёрджим рыночный инструмент с портфелем
                    newMarketInstrument = {
                      ...newMarketInstrument,
                      //...portfolioInstrument,
                    }
                    if (marketSubTabKey === 'realEstate' && newMarketInstrument.creditDetails)
                      newMarketInstrument.creditDetails.minDownPayment =
                        marketInstrument.creditDetails.minDownPayment
                  }
                  return {
                    ...newMarketInstrument,
                    instrumentType: marketSubTabKey,
                    frontId: marketInstrument.id,
                  }
                })
                .filter((el) => el.id !== 'mortgage')

              // добавляем к списку отдельно просто портфельные инструменты, помечая их
              marketSubTabValue = [
                ...marketSubTabValue,
                ...portfolioSubTab.map((portInstrument) => {
                  let newPortInstrument = portInstrument
                  let marketInstrument = marketEntry[1].find((el) => el.id === portInstrument.id)
                  if (marketInstrument) {
                    newPortInstrument = {
                      ...marketInstrument,
                      ...portInstrument,
                    }
                  }
                  return {
                    ...newPortInstrument,
                    instrumentType: marketSubTabKey,
                    frontId: portInstrument.id + portInstrument.portfolio_id,
                    isPortfolio: true,
                  }
                }),
              ]
            }
          } else {
            marketSubTabValue = marketSubTabValue.map((el) => ({
              ...el,
              instrumentType: marketSubTabKey,
              frontId: uuid(),
            }))
          }
          marketEntry[1] = marketSubTabValue
          return marketEntry
        })
      )
    }
    entry[1] = marketTab
    return entry
  })
  result = Object.fromEntries(newResult)
  return addIncomeExpenses(addAssetsLiabilities(result))
}
