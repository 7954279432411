export const USER = {
  accessToken: 'MOCKED_ACCESS_TOKEN',
  refreshToken: 'MOCKED_REFRESH_TOKEN',
  user: {
    uid: 777,
    name: 'Mocked user',
    email: 'test@hse.ru',
    sex: 'm', // 'm' || 'f'
    country: 'Russia',
    role: 'player', // 'player' || 'guest' || 'admin'
  },
}

export const USER_WRITER = {
  accessToken: 'MOCKED_ACCESS_TOKEN',
  refreshToken: 'MOCKED_REFRESH_TOKEN',
  user: {
    uid: 777,
    name: 'Mocked user',
    email: 'test@hse.ru',
    sex: 'm', // 'm' || 'f'
    country: 'Russia',
    role: 'writer', // 'player' || 'guest' || 'admin'
  },
}
