import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { imgSrc } from 'features/game/lib/img-src'
import { $dreams, chooseDream, chooseDreamFetching } from 'features/game/model'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'
import { Scrollbars } from 'ui/molecules/scrollbars'
import { FlippingNumbers } from 'ui/molecules/flipping-numbers'
import { Checkbox } from 'ui/atoms/checkbox'
import { Tooltip } from 'ui/molecules/tooltip'
import { LoadingOverflow } from 'ui/molecules/loading-overflow'

export const ChooseDream = React.memo(() => {
  const list = useStore($dreams)
  const chooseLoading = useStore(chooseDreamFetching.isLoading)
  return (
    <Container>
      <LoadingOverflow loading={chooseLoading} bg absolute withLoader />
      <Scrollbars>
        <List>
          {list.map((dream, idx) => {
            return (
              <Tile key={idx}>
                <Tooltip
                  content={
                    <Typography>
                      <b>{dream.isPortfolio ? 'Unchoose' : 'Choose'}</b> a dream "{dream.name}"
                    </Typography>
                  }
                >
                  <CheckboxContainer>
                    <StyledCheckbox
                      onChange={({ target: { checked } }) => {
                        chooseDream({ unchoose: !checked, dreamID: dream.id })
                      }}
                      checked={dream.isPortfolio}
                    />
                    <Typography lineClamp={2} size={theme.font.size.large + 1} weight="bold">
                      {dream.name}
                    </Typography>
                  </CheckboxContainer>
                </Tooltip>
                <Image src={imgSrc(dream.image, 'dream-thumbnail')} />
                <PriceContainer>
                  <Price>
                    <FlippingNumbers>{dream.sum}</FlippingNumbers>
                  </Price>
                </PriceContainer>
              </Tile>
            )
          })}
        </List>
      </Scrollbars>
    </Container>
  )
})

const StyledCheckbox = styled(Checkbox)`
  margin-right: 6px;
  & > * {
    background: transparent;
    height: 21px;
    margin-right: 8px;
    width: 21px;
  }
`

const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-between;
`

const PriceContainer = styled.div`
  background: ${theme.color.primary};
  border-radius: ${theme.radius.button.medium}px;
  bottom: 10px;
  box-shadow: ${theme.shadow.loginForm};
  padding: 5px;
  position: absolute;
  right: 10px;
`

const Price = styled.p`
  color: ${theme.color.black};
  font-size: ${theme.font.size.small}px;
  font-weight: 500;
  white-space: nowrap;
`

const List = styled.ul`
  display: grid;
  grid-gap: 20px 40px;
  grid-template-columns: repeat(auto-fit, 320px);
  justify-content: center;
  padding: 20px 0;
  width: 100%;
`

const Image = styled.img`
  border-radius: ${theme.radius.button.medium}px;
  height: 200px;
  margin-top: 8px;
  width: 100%;
`

const Tile = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: center;
  width: 320px;
`

const Container = styled.div`
  background: ${theme.color.darksecondary};
  border-radius: ${theme.radius.button.medium}px;
  box-shadow: ${theme.shadow.loginForm};
  height: calc(100vh - 170px);
  overflow: hidden;
  padding: 0 ${theme.padding.medium}px;
  position: relative;
  width: calc(100vw - ${2 * theme.padding.large}px);
`
