import { uuid } from 'uuidv4'

import { getSubjectInstrument } from '../get-subject-instrument'

const EVENTS_HISTORY = [
  {
    turn: 1,
    name: 'Event 1',
    reaction: 'Kill that baby',
    consequence: 'Arrest for 3 years',
    reactionType: 'negative',
  },
  {
    turn: 1,
    name: 'Event 2',
    reaction: 'Accept inheritance',
    consequence: '$ 1000 cash',
    reactionType: 'positive',
  },
]
const POINTS_HISTORY = [
  {
    turn: 4,
    name: 'Action 1',
    type: 'neutral',
    flowPoints: 0,
    endPoints: 435200,
  },
  {
    turn: 5,
    name: 'Action 2',
    type: 'positive',
    flowPoints: 900,
    endPoints: 0,
  },
  {
    turn: 4,
    name: 'Action 3',
    type: 'negative',
    flowPoints: 23423,
    endPoints: 400,
  },
]

export const normalizeProfile = (profile, gameState) => {
  let newProfile = profile
  newProfile.profile.events = profile.profile.events.map((event) => {
    event.instrumentType = 'events'
    event.frontId = event.id
    return event
  })
  if (!newProfile.profile.eventsHistory) newProfile.profile.eventsHistory = EVENTS_HISTORY
  if (!newProfile.profile.pointsHistory) newProfile.profile.pointsHistory = POINTS_HISTORY
  newProfile.turnActions = newProfile.turnActions.map((action) => {
    let newAction = { ...action }
    let name = `Can't find subject instrument`
    let qty = 0
    let sum = 0
    if (action.type === 'settings') name = 'Financial settings'
    if (action.type === 'reaction') {
      const foundEv = newProfile.profile.events.find((el) => el.id === action.payload.event_id)
      if (foundEv) {
        name = foundEv.name
      } else {
        throw new ReferenceError(`Can't find event with id ${action.payload.event_id}`)
      }
    }
    if (action.type === 'transaction') {
      qty = Math.abs(action.payload.value)
      newAction.type = action.payload.value > 0 ? 'buy' : 'sell'
      const foundInstr = getSubjectInstrument(
        action.payload.instrumentId,
        action.payload.instrumentType,
        action.payload.portfolio_id,
        gameState
      )
      if (foundInstr) {
        name = foundInstr.name
        sum = qty * foundInstr.price.current
      }
    }
    return {
      ...newAction,
      qty,
      name,
      sum,
      frontId: uuid(),
      isTurnAction: true,
    }
  })
  return newProfile
}
