import React from 'react'
// import HeightTransition from 'react-height-transition'
import HeightTransition from 'react-animate-height'
import { useExpanded, useFlexLayout, useGroupBy, useRowSelect, useSortBy, useTable } from 'react-table'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { ifNotProp } from 'styled-tools'
import { desaturate, lighten } from 'polished'

import { formatTime } from 'lib/format-time'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { SESSIONS_LIST } from 'mocks/rooms'
import minusIcon from 'static/minus.svg'
import plusIcon from 'static/plus-expanding.svg'
import triangle from 'static/triangle.svg'
import { theme } from 'theme'
import { Highlight } from 'ui/atoms/highlight'
import { Typography } from 'ui/atoms/typography'
import { NoData } from 'ui/molecules/no-data'
import { Scrollbars } from 'ui/molecules/scrollbars'
import { Tooltip } from 'ui/molecules/tooltip'
import { OverflowTooltip } from 'ui/molecules/overflow-tooltip'

import { CellText } from '../table/cell-text'
import { getMedian } from './lib/get-median'
import { transformColumns } from './lib/transform-columns'

const HARDCODE_HEAD = [
  {
    key: 'name',
    label: 'Name',
    isSortable: true,
    render: (data, row) => {
      return (
        <VerticalCenter>
          <SessionImg src={row.image} />
          {data}
        </VerticalCenter>
      )
    },
  },
  {
    key: 'turnQty',
    label: 'Available turns qty',
    isSortable: true,
    align: 'center',
  },
  {
    key: 'startDate',
    label: 'Start date',
    isSortable: true,
    render: (data, row) => {
      return formatTime(data)
    },
  },
  {
    key: 'finishDate',
    label: 'Finish date',
    isSortable: true,
    render: (data, row) => {
      return formatTime(data)
    },
  },
]

const highlightOpts = {
  ovalHeight: 20,
  ovalBlur: 15,
  width: 75,
  ovalOffset: -18,
  containerOffset: 0,
  needSiblingsRelative: false,
}

export const NewTable = ({
  columns = HARDCODE_HEAD,
  data = SESSIONS_LIST,
  noItems = NoData,
  orderBy = null,
  order = null,
  className,
  activeRowId,
  rowIdKey = 'id',
  layout = 'fixed',
  footer,
  onChangeOrder = (data) => console.log(data),
  noHeadOverflow,
  onRowClick = (data) => console.log(data),
  header,
  bgRow = desaturate(0.02, lighten(0.02, theme.color.secondary)),
  isMinimized,
  isMinimizable,
  onMinimizeToggle,
  groupByKey,
  noScrollbars,
}) => {
  const getRowId = React.useMemo(
    () => (row, relativeIndex) => (row && row[rowIdKey] ? row[rowIdKey] : relativeIndex),
    [rowIdKey]
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsExpanded,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns: React.useMemo(() => transformColumns(columns), [columns]),
      data: React.useMemo(() => data, [data]),
      disableMultiSort: true,
      disableSortRemove: true,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      manualRowSelectedKey: true,
      manualSortBy: true,
      aggregations: { noop: () => null, median: getMedian },
      // manualGroupBy: true,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            selectedRowIds: {
              [activeRowId]: true,
            },
            sortBy: [
              {
                id: orderBy,
                desc: order === 'desc',
              },
            ],
            groupBy: [groupByKey],
          }),
          //eslint-disable-next-line react-hooks/exhaustive-deps
          [state, activeRowId, order, orderBy, groupByKey]
        )
      },
      getRowId,
    },
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    useFlexLayout
  )
  const onHeadCellClick = (key, sortFn) => {
    let orderObj = { order, orderBy }
    if (key === orderBy) orderObj.order = order === 'asc' ? 'desc' : 'asc'
    if (key !== orderBy) orderObj.orderBy = key
    if (!order || key !== orderBy) orderObj.order = 'desc'
    if (sortFn) orderObj.sortFn = sortFn
    onChangeOrder(orderObj)
  }
  const ScrollBarFiller = noScrollbars ? React.Fragment : Scrollbars
  const ScrollBarFillerOpts = noScrollbars ? {} : { offset: 0 }
  return (
    <>
      <UITable {...getTableProps()} {...{ className, layout }}>
        {header &&
          header(
            isMinimizable ? (
              <MinimizeIcon
                src={isMinimized ? plusIcon : minusIcon}
                isMaximized={!isMinimized}
                onClick={onMinimizeToggle}
              />
            ) : null,
            data
          )}
        <HeightTransition height={isMinimized ? 0 : 'auto'} initial={0} duration={600} easing="ease-in-out">
          <Head>
            {headerGroups.map((headerGroup) => (
              <HeadRow {...{ bgRow }} {...headerGroup.getHeaderGroupProps()}>
                <>
                  {headerGroup.headers.some((el) => el.effectColumn) && (
                    <EffectHeader
                      weight={600}
                      size={theme.font.size.extrasmall}
                      color={hexToRGBA(theme.color.black, 0.5)}
                    >
                      Effects
                    </EffectHeader>
                  )}
                  {headerGroup.headers.map((column, idx) => {
                    const {
                      canSort,
                      isSorted,
                      isSortedDesc,
                      id,
                      align,
                      sortFn,
                      effectColumn,
                      paddingLeft,
                      labelTooltip,
                      isSortable,
                    } = column
                    const LabelWrapper = labelTooltip ? Tooltip : React.Fragment
                    const labelWrapperOpts = labelTooltip
                      ? { content: <Typography size={theme.font.size.small}>{labelTooltip}</Typography> }
                      : {}
                    return (
                      <LabelWrapper {...labelWrapperOpts} key={id}>
                        <HeadCell
                          {...{ align, noHeadOverflow, effectColumn, paddingLeft }}
                          {...column.getHeaderProps(column.getSortByToggleProps({ title: '' }))}
                          first={idx === 0}
                          last={effectColumn && !headerGroup.headers[idx + 1]?.effectColumn}
                          onClick={id === 'expander' ? null : () => onHeadCellClick(id, sortFn)}
                        >
                          {column.isGrouped && (
                            <MinimizeIcon
                              src={!isAllRowsExpanded ? plusIcon : minusIcon}
                              isMaximized={isAllRowsExpanded}
                              onClick={(ev) => {
                                ev.stopPropagation()
                                toggleAllRowsExpanded()
                              }}
                            />
                          )}
                          {column.render('label')}
                          {canSort && isSortable && !effectColumn && (
                            <SortContainer {...{ align, effectColumn }}>
                              <SortAsc src={triangle} active={isSorted && !isSortedDesc} />
                              <SortDesc src={triangle} active={isSorted && isSortedDesc} />
                            </SortContainer>
                          )}
                        </HeadCell>
                      </LabelWrapper>
                    )
                  })}
                </>
              </HeadRow>
            ))}
          </Head>
        </HeightTransition>
        <ScrollBarFiller {...ScrollBarFillerOpts}>
          <HeightTransition height={isMinimized ? 0 : 'auto'} initial={0} duration={600} easing="ease-in-out">
            {data?.length > 0 && (
              <Body {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <Row
                      data-active={row.isSelected}
                      onClick={() => {
                        if (row.isGrouped) {
                          row.toggleRowExpanded()
                        } else {
                          onRowClick(row.original)
                        }
                      }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell, i) => {
                        const {
                          align,
                          wrapLine,
                          fontFamily,
                          effectColumn,
                          paddingLeft,
                          noTooltip,
                        } = cell.column
                        return (
                          <Cell
                            flex={cell.isGrouped}
                            {...{ align, wrapLine, fontFamily, effectColumn, paddingLeft }}
                            first={i === 0}
                            last={!row.cells[i + 1]?.column.effectColumn}
                            {...cell.getCellProps()}
                          >
                            {cell.isGrouped ? (
                              <>
                                <MinimizeIcon
                                  src={!row.isExpanded ? plusIcon : minusIcon}
                                  isMaximized={row.isExpanded}
                                />
                                <OverflowTooltip bgColor={bgRow} disabled={noTooltip}>
                                  {cell.render('Cell')}
                                </OverflowTooltip>
                              </>
                            ) : cell.isAggregated ? (
                              <OverflowTooltip bgColor={bgRow} disabled={noTooltip}>
                                {cell.render('Aggregated')}
                              </OverflowTooltip>
                            ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                              // Otherwise, just render the regular cell
                              <OverflowTooltip bgColor={bgRow} disabled={noTooltip}>
                                {cell.render('Cell')}
                              </OverflowTooltip>
                            )}
                            {i === row.cells.length - 1 && (
                              <>
                                {!row.isSelected && (
                                  <StyledBottomHighlight type="bottom" {...highlightOpts} />
                                )}
                                <StyledTopHighlight active={row.isSelected} type="top" {...highlightOpts} />
                              </>
                            )}
                          </Cell>
                        )
                      })}
                    </Row>
                  )
                })}
              </Body>
            )}
          </HeightTransition>
        </ScrollBarFiller>
        {footer}
      </UITable>
      {!data || (data?.length === 0 && noItems)}
    </>
  )
}

const MinimizeIcon = styled.img`
  cursor: pointer;
  height: 14px;
  margin-right: 7px;
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
  width: 14px;
  ${ifProp('isMaximized', 'opacity: 1;')}
  &:hover {
    opacity: 1;
  }
`

const StyledTopHighlight = styled(Highlight)`
  left: 0;
  top: -1px;
  z-index: 1;
  ${ifProp('active', 'opacity: 1;')}
`

const StyledBottomHighlight = styled(Highlight)`
  bottom: -1px;
  left: 0;
  z-index: 1;
`

const SessionImg = styled.img`
  height: 60px;
  margin-right: 20px;
  object-fit: contain;
  width: 60px;
`

const VerticalCenter = styled.div`
  align-items: center;
  display: flex;
`

// const Container = styled.div`
//   //overflow: auto;
//   height: 100%;
// `

const UITable = styled.div`
  // overflow: hidden;
  width: 100%;
  font-size: ${theme.font.size.small}px;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  & & {
    box-sizing: border-box;
  }
`

const EffectHeader = styled(Typography)`
  left: 365px;
  position: absolute;
  top: 2px;
  z-index: 2;
`

const Head = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`

const Body = styled.div`
  height: 100%;
`

const HeadCell = styled.div`
  color: ${hexToRGBA(theme.color.black, 0.4)};
  box-sizing: border-box;
  font-size: ${theme.font.size.small}px;
  font-weight: 600;
  position: relative;
  // position: sticky;
  // top: 0;
  vertical-align: top;
  padding: 25px 20px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  z-index: 1;
  display: flex;
  align-items: center;
  ${ifProp(
    'paddingLeft',
    css`
      padding-left: ${prop('paddingLeft')}px !important;
    `
  )}
  ${ifProp(
    'effectColumn',
    css`
      padding-bottom: 3px !important;
      padding-left: ${ifProp('first', '12px', '3px')} !important;
      padding-right: ${ifProp('last', '6px', '3px')} !important;
      padding-top: 19px !important;
    `
  )}
  ${({ align, effectColumn }) => {
    let textalign = 'left'
    let content = 'space-between'
    let flexDir = 'row'
    if (align === 'center') {
      textalign = 'center'
      content = 'center'
    }
    if (align === 'right') {
      textalign = 'right'
      content = 'flex-start'
      flexDir = 'row-reverse'
    }
    // if (effectColumn) {
    //   textalign = 'center'
    //   content = 'center'
    // }
    return `
      justify-content: ${content};
      text-align: ${textalign};
      flex-direction: ${flexDir};
    `
  }}
  ${ifNotProp(
    'noHeadOverflow',
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
  )}
  // ${(props) => (props.minWidth ? `width: ${props.minWidth};` : '')}
  // ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
  background: ${desaturate(0.08, lighten(0.05, theme.color.secondary))};
  &:first-of-type {
    border-radius: ${theme.radius.button.small * 2}px 0px 0px 0px;
  }
  &:last-of-type {
    border-radius: 0px ${theme.radius.button.small * 2}px 0px 0px;
  }
  &:first-of-type:last-of-type {
    border-radius: ${theme.radius.button.small * 2}px ${theme.radius.button.small * 2}px 0px 0px;
  }
  ${ifProp(
    'isBalanceTable',
    `
    font-size: ${theme.font.size.extrasmall}px;
    padding: 8px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    
    &:first-of-type {
      border-radius: 0;
    }
    &:last-of-type {
      border-radius: 0;
    }
    &:first-of-type:last-of-type {
      border-radius: 0;
    }
  `
  )}
  ${({ onClick }) =>
    onClick
      ? `
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    background: ${desaturate(0.08, lighten(0.08, theme.color.secondary))};
  }
  `
      : ''}
  ${theme.media.large} {
    ${(props) => (props.largeMinWidth ? `width: ${props.largeMinWidth};` : '')}
    ${(props) => (props.largeMaxWidth ? `max-width: ${props.largeMaxWidth};` : '')}
  }
`
const Row = styled.div`
  background: ${desaturate(0.02, lighten(0.02, theme.color.secondary))};
  color: ${theme.color.black};
  position: relative;
  transform: scale(1);
  transition: all 0.15s ease-in-out;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${hexToRGBA(theme.color.black, 0.1)};
  }
  ${ifProp(
    'onClick',
    `
    cursor: pointer;
    &:hover {
      ${StyledBottomHighlight} {
        opacity: 1;
      }
    }
  `
  )}
  &[data-active='true'] ${CellText} {
    color: ${theme.color.black};
    font-weight: 600;
  }
`
// ${ifProp(
//   'active',
//   `
//   color: ${theme.color.primary};
//   font-weight: 600;
// `
// )}

const HeadRow = styled.div`
  background: ${prop('bgRow', 'transparent')};
  position: relative;
`
const Cell = styled.div`
  ${ifNotProp(
    'wrapLine',
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
  )}
  text-align: ${prop('align', 'left')};
  font-family: ${prop('fontFamily', 'Gilroy, Roboto, Helvetica, sans-serif')};
  ${({ fontFamily }) => (fontFamily === 'Roboto Mono' ? 'letter-spacing: -0.75px;' : '')}
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 10px;
  ${ifProp(
    'paddingLeft',
    css`
      padding-left: ${prop('paddingLeft')}px !important;
    `
  )}
  transition: all .2s ease-in-out;
  ${ifProp(
    'effectColumn',
    css`
      padding-left: ${ifProp('first', '12px', '3px')} !important;
      padding-right: 3px !important;
    `
  )}
  ${ifProp(
    'flex',
    `
    display: flex;
    align-items: center;
  `
  )} // ${(props) => (props.minWidth ? `width: ${props.minWidth};` : '')}
  // ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
`
const SortContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ align }) => {
    if (align === 'right') return 'margin-right: 7px;'
    return 'margin-left: 3px;'
  }}
  ${ifProp}
  ${ifProp(
    'isBalanceTable',
    `
    bottom: 12px;
    right: 10px;
  `
  )}
`
const SortAsc = styled.img`
  height: 4px;
  opacity: 0.3;
  width: 4px;
  ${ifProp('active', 'opacity: 1;')}
`
const SortDesc = styled.img`
  height: 4px;
  margin-top: 1px;
  opacity: 0.3;
  ${ifProp('active', 'opacity: 1;')}
  transform: rotate(180deg);
  width: 4px;
`

export const StyledTableParts = { tr: Row, td: Cell, th: HeadCell }
