import React from 'react'
import Loader from 'react-loading'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

type LoadingOverflowProps = {
  loading?: any
  bg?: any
  absolute?: any
  color?: any
  withLoader?: boolean
}

export const LoadingOverflow = ({
  loading,
  bg,
  absolute,
  withLoader = false,
  color = theme.color.primary,
}: LoadingOverflowProps) => {
  return loading ? (
    <Container {...{ absolute, bg }}>
      {withLoader && <StyledLoader type="spin" {...{ color }} height="80px" width="80px" />}
    </Container>
  ) : null
}

const StyledLoader = styled(Loader)`
  position: relative;
  pointer-events: none;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
  left: 0;
  bottom: 0;
  right: 0;
  ${ifProp('absolute', 'position: absolute;')}
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 499;
  ${ifProp(
    'bg',
    css`
      background: ${hexToRGBA(theme.color.white, 0.4)};
    `
  )}
`
