import React from 'react'
import { useStore } from 'effector-react'
import { useForm } from 'react-hook-form'

import { useControlForReactSelect } from 'lib/hooks/use-control-for-react-select'
import { COUNTRIES } from 'lib/constants/countries'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Error, Form, Heading } from 'ui/atoms/form'
import { Select } from 'ui/atoms/select'
import { BackButtonForPage } from 'ui/molecules/circle-button/wrapper-for-page'
import { InputGroup } from 'ui/molecules/input-group'
import { useEffectWithoutInit } from 'lib/hooks/use-effect-without-init'

export const RegisterForm = ({ onSubmitForm, fetching }) => {
  const { register, handleSubmit, errors, setValue } = useForm()
  const formError = useStore(fetching.error)
  const errorMsg = formError?.details || formError?.message
  const loading = useStore(fetching.isLoading)
  const selectState = useControlForReactSelect({
    register,
    setValue,
    registerProps: {
      required: 'Country is required.',
      name: 'countrySelect',
    },
  })
  const onSubmit = (data) => {
    onSubmitForm({ ...data, country: selectState.value?.value })
  }
  useEffectWithoutInit(() => {
    console.error(formError)
  }, [formError])
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <BackButtonForPage backURL="/login" />
      <Heading centered weight="600" size={theme.font.size.huge}>
        Registration
      </Heading>
      <InputGroup
        name="email"
        error={errors.email?.message || formError?.message?.length > 0}
        ref={register({
          pattern: {
            value: /^[\w!#$%&'*+./=?^`{|}~-]+@[\da-z](?:[\da-z-]{0,61}[\da-z])?(?:\.[\da-z](?:[\da-z-]{0,61}[\da-z])?)*$/gi,
            message: 'Email is not valid.',
          },
          required: 'Email is required.',
        })}
        fullWidth
        placeholder="Email"
      />
      <InputGroup
        name="password"
        type="password"
        error={errors.password?.message || formError?.message?.length > 0}
        ref={register({
          required: 'Password is required.',
          minLength: {
            value: 3,
            message: "Password's length must be 3 or more.",
          },
        })}
        fullWidth
        placeholder="Password"
      />
      <InputGroup
        name="name"
        error={errors.name?.message || formError?.name?.length > 0}
        ref={register}
        fullWidth
        placeholder="Name"
      />
      <InputGroup
        name="country"
        placeholder="Country"
        fullWidth
        value={selectState.value}
        onRawChange={selectState.handleMultiChange}
        error={errors.country?.message || formError?.country?.length > 0}
      >
        <Select padding={theme.padding.button.small} options={COUNTRIES} />
      </InputGroup>
      {errorMsg?.length > 0 && (
        <Error color={theme.color.red} size={theme.font.size.mediumsmall}>
          {errorMsg}
        </Error>
      )}
      <Button type="submit" {...{ loading }} fullWidth>
        Sign Up
      </Button>
    </Form>
  )
}
