/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import { useParams } from 'react-router-dom'

import { getUserFetching } from 'features/auth'
import { RoomDetails } from 'features/rooms/details'
import { getRoom } from 'features/rooms/model'
import { navigate } from 'lib/history'

export const RoomDetailsPage = () => {
  const { id } = useParams()
  const isUserFetched = useStore(getUserFetching.status) !== 'initial'
  useEffect(() => {
    if (id) {
      if (isUserFetched) getRoom(id)
    } else {
      navigate('/rooms')
    }
  }, [isUserFetched])
  return <RoomDetails />
}
