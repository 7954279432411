import { useStore } from 'effector-react'
import React from 'react'

import { $news } from 'features/game/model'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import { Ticker } from 'ui/atoms/ticker'
import { Typography } from 'ui/atoms/typography'

import { Delimeter, TickerInner } from '../molecules'

export const NewsTicker = () => {
  const news = useStore($news)
  return (
    <Ticker bgColor={theme.color.secondary} delay={0} speed={1}>
      {news?.length > 0 &&
        news.slice(0, 4).map((neww, idx) => (
          <TickerInner key={idx}>
            <Delimeter />
            <Typography size={theme.font.size.small} color={hexToRGBA(theme.color.black, 0.8)}>
              {neww.name}
            </Typography>
          </TickerInner>
        ))}
    </Ticker>
  )
}
