import styled from 'styled-components'
import { prop } from 'styled-tools'

export const RiskCircle = styled.div`
  background: ${prop('color')};
  border-radius: 50%;
  height: 12px;
  margin-right: ${prop('mr', 0)}px;
  width: 12px;
`
