import { hexToRGBA } from 'lib/hex-to-rgba'
import assignedIcon from 'static/assigned.svg'
import successIcon from 'static/check-green.svg'
import entertainmentIcon from 'static/entertainmet.svg'
import failedIcon from 'static/failed.svg'
import medicineIcon from 'static/medicine.svg'
import processingIcon from 'static/processing.svg'
import fitnessIcon from 'static/sport.svg'
import vacationIcon from 'static/vacation.svg'
import { theme } from 'theme'
import { createFieldHandler } from './factory'

export const handleRoomStatusHeading = (status) => {
  switch (status) {
    case 'waiting':
      return 'Game session starting in'
    case 'playing':
      return 'Game session will be finished in'
    case 'finished':
      return 'Game has already finished'
    default:
      return status
  }
}

export const handlePropertyType = (type) =>
  createFieldHandler(type, {
    realEstate: 'Real Estate',
    vehicle: 'Transport affinity',
    service: 'Service',
    landPlot: 'Land plot',
  })

export const handleIncomeAndExpensesGroup = (type) =>
  createFieldHandler(type, {
    dividend: 'Dividens',
    interest: 'Interest',
    salary: 'Salary',
    life: 'Life',
    rent: 'Rent',
    buy: 'Purchasing',
    maturation: 'Maturation',
    open: 'Open',
  })

export const handleInstrumentType = (type) =>
  createFieldHandler(type, {
    stocks: 'Stock',
    bonds: 'Bond',
    etf: 'ETF',
    crypto: 'Crypto',
    indicies: 'Index',
    leverage: 'Leverage',
    commodities: 'Commodity',
    credit: 'Credit',
    deposits: 'Deposit',
    realEstate: 'Real Estate',
    services: 'Service',
    goods: 'Product',
  })

export const handleBalanceBarType = (type) =>
  createFieldHandler(type, {
    medicine: {
      label: 'Medicine',
      icon: medicineIcon,
      color: theme.color.red,
    },
    fitness: {
      label: 'Fitness',
      icon: fitnessIcon,
      color: theme.color.green,
    },
    entertainment: {
      label: 'Entertainment',
      icon: entertainmentIcon,
      color: theme.color.cyan,
    },
    vacation: {
      label: 'Vacation',
      icon: vacationIcon,
      color: theme.color.yellow,
    },
  })

export const handleBankingType = (type) =>
  createFieldHandler(type, {
    credit: 'Credit',
    deposits: 'Deposit',
    leverage: 'Leverage',
  })

export const handleIncomeAndExpensesType = (type) =>
  createFieldHandler(type, {
    income: 'Income',
    expenses: 'Expense',
    default: null,
  })

export const handleGoalStatusImg = (status) =>
  createFieldHandler(status, {
    processing: processingIcon,
    notReady: assignedIcon,
    ready: successIcon,
    appointed: assignedIcon,
  })

export const handleGoalStatusText = (status) =>
  createFieldHandler(status, {
    processing: 'In progress',
    notReady: 'Not achieved',
    ready: 'Achieved',
    aborted: 'Aborted',
    finished: 'Finished',
    appointed: 'Appointed',
  })

export const handleEventType = ({ type }) =>
  createFieldHandler(type, {
    income: 'Income',
    expense: 'Expense',
    other: 'Other',
  })

export const handleReactionType = (type) =>
  createFieldHandler(type, {
    default: hexToRGBA(theme.color.black, 0.6),
    positive: theme.color.green,
    negative: theme.color.red,
  })

export const handleActionTypeToVariant = (type) =>
  createFieldHandler(type, {
    default: 'outlinedLight',
    positive: 'greenOutlined',
    negative: 'primary',
  })

export const handleGoodStatus = ({ qty }) => (qty > 0 ? 'Owning' : 'On sale')

export const handleRealEstateStatus = ({ qty, rentStatus }) => {
  let status = handleGoodStatus({ qty })
  if (rentStatus === 'renting') {
    status += `, renting`
  } else if (rentStatus === 'rentingOut') {
    status += `, renting out`
  }
  return status
}

export const handleCenterStyle = (width) =>
  createFieldHandler(width, {
    default: {
      width: 270,
      flex: 26,
      largeFlex: 10,
      largeWidth: 360,
    },
    dreams: {
      width: 755,
      flex: 70,
      largeFlex: 70,
      largeWidth: 755,
    },
    goals: {
      width: 755,
      flex: 70,
      largeFlex: 70,
      largeWidth: 755,
    },
    // goods: {
    //   flex: 55,
    //   largeFlex: 60,
    // },
    // services: {
    //   flex: 55,
    //   largeFlex: 60,
    // },
  })

export const handleTasksStatus = (tasks) => {
  if (tasks.length === 0) return { msg: 'No tasks', icon: null }
  const qty = tasks.filter((t) => t.status === 'ready').length
  if (qty === 0) return { msg: `Not ready (0/${tasks.length})`, icon: failedIcon }
  if (qty > 0 && qty !== tasks.length)
    return { msg: `In progress (${qty}/${tasks.length})`, icon: processingIcon }
  if (qty === tasks.length) return { msg: `Ready (${tasks.length}/${tasks.length})`, icon: successIcon }
}

export const handleTurnActionType = (type) =>
  createFieldHandler(type, {
    buy: 'Purchase',
    sell: 'Sell',
    transaction: 'Transaction',
    settings: 'Settings changes',
    reaction: 'Event reaction',
  })
