import { useStore, useStoreMap } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { Divider } from 'features/game/field/atoms/divider'
import { TabInnerHeading } from 'features/game/field/atoms/tab-inner-heading'
import { TabInnerSection } from 'features/game/field/atoms/tab-inner-section'
import { NewsList } from 'features/game/field/organisms/news-list'
import { formatTooltipYMoney, formatToPercent, round } from 'features/game/lib/chart-format'
import { $profile, $selectedSubTabData } from 'features/game/model'
import { $selectedSubTab } from 'features/game/navigation/model'
import { InstrumentTypes, isBankingRelated } from 'features/game/types'
import { sortByTurns } from 'lib/sort/custom-sorts'
import { Chart } from 'ui/atoms/chart'
import { NoData } from 'ui/molecules/no-data'
import { Tabs } from 'ui/organisms/tabs'

import { StockMarketAbout } from '../about'

export const ChartAndNews = ({ entityName = 'Stock' }) => {
  const selectedInstrument = useStore(useStore($selectedSubTabData).selected)
  const subTabName = useStore($selectedSubTab)
  const marketNews = useStoreMap({
    store: $profile,
    keys: [subTabName],
    fn: (state, [instr]) => {
      return state.profile.marketNews.filter((el) => el?.market === instr).sort(sortByTurns) || []
    },
  })
  return (
    <>
      {selectedInstrument && (
        <>
          <TabInnerSection>
            <TabInnerHeading>{selectedInstrument.name}</TabInnerHeading>
            {selectedInstrument.charts?.price?.length > 1 && (
              <ChartContainer>
                <Chart
                  frontId={selectedInstrument.frontId}
                  align="right"
                  yFormatter={round}
                  tooltipYFormatter={formatTooltipYMoney}
                  data={selectedInstrument?.charts?.price}
                />
              </ChartContainer>
            )}
          </TabInnerSection>
          {selectedInstrument.instrumentType === InstrumentTypes.ETF && (
            <>
              <Divider />
              <TabInnerSection small>
                <TabInnerHeading>Relative price delta</TabInnerHeading>
                {selectedInstrument.charts?.deltaPrice?.length > 1 && (
                  <ChartContainer>
                    <Chart
                      frontId={`${selectedInstrument.frontId}deltaprice`}
                      yFormatter={formatToPercent}
                      tooltipYFormatter={formatToPercent}
                      align="right"
                      label="Delta price"
                      data={selectedInstrument.charts.deltaPrice}
                    />
                  </ChartContainer>
                )}
              </TabInnerSection>
            </>
          )}
          {isBankingRelated(selectedInstrument.instrumentType) && (
            <>
              <Divider />
              <TabInnerSection small>
                <TabInnerHeading>Rate changes</TabInnerHeading>
                {selectedInstrument.charts?.rate?.length > 1 && (
                  <ChartContainer>
                    <Chart
                      frontId={`${selectedInstrument.frontId}rate`}
                      yFormatter={formatToPercent}
                      tooltipYFormatter={formatToPercent}
                      align="right"
                      label="Rate"
                      data={selectedInstrument.charts.rate}
                    />
                  </ChartContainer>
                )}
              </TabInnerSection>
            </>
          )}
          <Divider />
          <StockMarketAbout />
          <TabInnerSection>
            <Tabs alignment="space-between">
              <NewsList
                noScrollbars
                noHeading
                title="Instr. News"
                id="instr"
                news={selectedInstrument.news}
              />
              <NewsList noScrollbars noHeading title="Market News" id="mkrt" news={marketNews} />
            </Tabs>
          </TabInnerSection>
        </>
      )}
      {!selectedInstrument && <NoData />}
    </>
  )
}

const ChartContainer = styled.div`
  margin-top: -20px;
  width: calc(100% + 10px);
`
