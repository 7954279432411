import qs from 'qs'

import { http } from 'lib/http'
import { handleAPIError } from 'lib/http/error/handle-error'

export const ROOM_HTTP_ROUTES = {
  rooms: 'rooms',
  finishRoom: 'rooms/finish',
  startPlaying: 'rooms/start',
  leaderboard: 'rooms/leaderboard',
  statistics: 'rooms/statistics',
  getTeamSuggest: 'suggest-team',
}

const enterRoom = (id, settings) =>
  http.post(`${ROOM_HTTP_ROUTES.rooms}/${id}`, settings).catch(
    handleAPIError({
      404: 'Room does not exist or was deleted.',
    })
  )
const getRoom = (id) => http.get(`${ROOM_HTTP_ROUTES.rooms}/${id}`).catch(handleAPIError({}, [403]))
const getRooms = (params) =>
  http.get(ROOM_HTTP_ROUTES.rooms, qs.stringify(params)).catch(handleAPIError({}, [403]))
const finishRoom = () => http.post(ROOM_HTTP_ROUTES.finishRoom).catch(handleAPIError({}, [403]))
const startPlaying = (params) =>
  http.post(ROOM_HTTP_ROUTES.startPlaying, params).catch(handleAPIError({}, [403]))
const getLeaderboard = (id) =>
  http.get(`${ROOM_HTTP_ROUTES.leaderboard}/${id}`).catch(handleAPIError({}, [403]))
const getStatistics = ({ roomID, gameID, teamID }) => {
  let req = { roomID }
  if (gameID) req = { ...req, gameID }
  if (teamID) req = { ...req, teamID }
  return http
    .get(ROOM_HTTP_ROUTES.statistics, { params: req })
    .catch(handleAPIError({ default: 'Cannot get statistics.' }, [403]))
}
const getTeamSuggest = (query) =>
  http
    .get(ROOM_HTTP_ROUTES.getTeamSuggest, { params: { q: query } })
    .catch(handleAPIError({ default: 'Cannot get suggest for teams list.' }, [403]))

export const roomsAPI = {
  getRooms,
  getRoom,
  enterRoom,
  finishRoom,
  startPlaying,
  getLeaderboard,
  getStatistics,
  getTeamSuggest,
}
