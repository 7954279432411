import { desaturate, lighten } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { ifNotProp } from 'styled-tools'

import { formatTime } from 'lib/format-time'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { SESSIONS_LIST } from 'mocks/rooms'
import triangle from 'static/triangle.svg'
import { theme } from 'theme'
import { Highlight } from 'ui/atoms/highlight'
import { NoData } from 'ui/molecules/no-data'

import { CellText } from './cell-text'

const HARDCODE_HEAD = [
  {
    key: 'name',
    label: 'Name',
    isSortable: true,
    render: (data, row) => {
      return (
        <VerticalCenter>
          <SessionImg src={row.image} />
          {data}
        </VerticalCenter>
      )
    },
  },
  {
    key: 'turnQty',
    label: 'Available turns qty',
    isSortable: true,
    align: 'center',
  },
  {
    key: 'startDate',
    label: 'Start date',
    isSortable: true,
    render: (data, row) => {
      return formatTime(data)
    },
  },
  {
    key: 'finishDate',
    label: 'Finish date',
    isSortable: true,
    render: (data, row) => {
      return formatTime(data)
    },
  },
]

const highlightOpts = {
  ovalHeight: 20,
  ovalBlur: 15,
  width: 75,
  ovalOffset: -18,
  containerOffset: 0,
  needSiblingsRelative: false,
}

export const Table = ({
  columns = HARDCODE_HEAD,
  data = SESSIONS_LIST,
  noItems = NoData,
  orderBy = null,
  order = null,
  className,
  activeRowId,
  rowIdKey = 'id',
  layout = 'fixed',
  footer,
  onChangeOrder = (data) => console.log(data),
  noHeadOverflow,
  onRowClick,
  header,
  isBalanceTable,
}) => {
  const onHeadCellClick = (key, sortFn) => {
    let orderObj = { order, orderBy }
    if (key === orderBy) orderObj.order = order === 'asc' ? 'desc' : 'asc'
    if (key !== orderBy) orderObj.orderBy = key
    if (!order || key !== orderBy) orderObj.order = 'desc'
    if (sortFn) orderObj.sortFn = sortFn
    onChangeOrder(orderObj)
  }
  const getTableHead = () => {
    return columns.map(
      ({ key, isSortable, sortFn, label, align, maxWidth, minWidth, largeMaxWidth, largeMinWidth }) => (
        <HeadCell
          noHeadOverflow={noHeadOverflow}
          align={align}
          maxWidth={maxWidth}
          minWidth={minWidth}
          largeMinWidth={largeMinWidth}
          largeMaxWidth={largeMaxWidth}
          onClick={isSortable ? () => onHeadCellClick(key, sortFn) : null}
          key={key}
          isBalanceTable={isBalanceTable}
        >
          {label}
          {isSortable && (
            <SortContainer {...{ isBalanceTable }}>
              <SortAsc src={triangle} active={orderBy === key && order === 'asc'} />
              <SortDesc src={triangle} active={orderBy === key && order === 'desc'} />
            </SortContainer>
          )}
        </HeadCell>
      )
    )
  }
  const getTableRows = () => {
    return data.length > 0
      ? data.map((row) => {
          const isActive = activeRowId === row[rowIdKey]
          const cells = columns.map((column, i) => {
            let dataToRender = row[column.key] ? row[column.key] : ''
            if (column.render) dataToRender = column.render(row[column.key], row)
            return (
              <Cell
                align={column.align}
                wrapLine={column.wrapLine}
                largeMinWidth={column.largeMinWidth}
                largeMaxWidth={column.largeMaxWidth}
                minWidth={column.minWidth}
                maxWidth={column.maxWidth}
                fontFamily={column.font}
                key={i}
              >
                {dataToRender}

                {i === columns.length - 1 && (
                  <>
                    {!isActive && <StyledBottomHighlight type="bottom" {...highlightOpts} />}
                    <StyledTopHighlight active={isActive} type="top" {...highlightOpts} />
                  </>
                )}
              </Cell>
            )
          })
          let rowOpts = {
            'data-active': isActive,
            key: row[rowIdKey],
          }
          if (onRowClick) rowOpts.onClick = () => onRowClick(row)
          return <Row {...rowOpts}>{cells}</Row>
        })
      : null
  }
  return (
    <Container className={className}>
      {/* <Scrollbars> */}
      <UITable layout={layout}>
        {header}
        <Head>
          <HeadRow>{getTableHead()}</HeadRow>
        </Head>
        {data?.length > 0 && <Body>{getTableRows()}</Body>}
        {footer && <Footer>{footer}</Footer>}
      </UITable>
      {!data || (data?.length === 0 && noItems)}
      {/* </Scrollbars> */}
    </Container>
  )
}

const StyledTopHighlight = styled(Highlight)`
  left: 0;
  top: -1px;
  ${ifProp('active', 'opacity: 1;')}
`

const StyledBottomHighlight = styled(Highlight)`
  bottom: -1px;
  left: 0;
`

const SessionImg = styled.img`
  height: 60px;
  margin-right: 20px;
  object-fit: contain;
  width: 60px;
`

const VerticalCenter = styled.div`
  align-items: center;
  display: flex;
`

const Container = styled.div`
  overflow: auto;
`

const UITable = styled.table`
  border-collapse: collapse;
  box-sizing: border-box;
  font-size: ${theme.font.size.small}px;
  position: relative;
  table-layout: ${prop('layout')};
  width: 100%;
  & & {
    box-sizing: border-box;
  }
`
const Head = styled.thead``

const Body = styled.tbody``
const Footer = styled.tfoot`
  background: ${theme.color.darkgray};
`
const HeadCell = styled.th`
  background: ${desaturate(0.08, lighten(0.05, theme.color.secondary))};
  border-color: initial;
  border-image: initial;
  border-style: none;
  border-width: initial;
  box-sizing: border-box;
  color: ${hexToRGBA(theme.color.black, 0.4)};
  font-size: ${theme.font.size.small}px;
  font-weight: 600;
  padding: 25px 20px;
  position: sticky;
  position: relative;
  text-align: ${prop('align', 'left')};
  top: 0;
  vertical-align: top;
  ${ifNotProp(
    'noHeadOverflow',
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
  )}
  ${(props) => (props.minWidth ? `width: ${props.minWidth};` : '')}
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
  z-index: 1;
  &:first-of-type {
    border-radius: ${theme.radius.button.small * 2}px 0px 0px 0px;
  }
  &:last-of-type {
    border-radius: 0px ${theme.radius.button.small * 2}px 0px 0px;
  }
  &:first-of-type:last-of-type {
    border-radius: ${theme.radius.button.small * 2}px ${theme.radius.button.small * 2}px 0px 0px;
  }
  ${ifProp(
    'isBalanceTable',
    `
    font-size: ${theme.font.size.extrasmall}px;
    padding: 8px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    
    &:first-of-type {
      border-radius: 0;
    }
    &:last-of-type {
      border-radius: 0;
    }
    &:first-of-type:last-of-type {
      border-radius: 0;
    }
  `
  )}
  ${({ onClick }) =>
    onClick
      ? `
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    background: ${desaturate(0.08, lighten(0.08, theme.color.secondary))};
  }
  `
      : ''}
  ${theme.media.large} {
    ${(props) => (props.largeMinWidth ? `width: ${props.largeMinWidth};` : '')}
    ${(props) => (props.largeMaxWidth ? `max-width: ${props.largeMaxWidth};` : '')}
  }
`
const Row = styled.tr`
  background: ${desaturate(0.02, lighten(0.02, theme.color.secondary))};
  border-bottom: 1px solid ${hexToRGBA(theme.color.black, 0.1)};
  color: ${theme.color.black};
  position: relative;
  transform: scale(1);
  transition: all 0.15s ease-in-out;
  ${ifProp(
    'onClick',
    `
    cursor: pointer;
    &:hover {
      ${StyledBottomHighlight} {
        opacity: 1;
      }
    }
  `
  )}
  &[data-active='true'] ${CellText} {
    color: ${theme.color.black};
    font-weight: 600;
  }
`
// ${ifProp(
//   'active',
//   `
//   color: ${theme.color.primary};
//   font-weight: 600;
// `
// )}

const HeadRow = styled.tr``
const Cell = styled.td`
  ${ifNotProp(
    'wrapLine',
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
  )}
  border-color: initial;
  border-image: initial;
  ${({ fontFamily }) => (fontFamily === 'Roboto Mono' ? 'letter-spacing: -0.5px;' : '')}
  border-style: none;
  border-width: initial;
  font-family: ${prop('fontFamily', 'Gilroy, Roboto, Helvetica, sans-serif')};
  padding: 10px;
  text-align: ${prop('align', 'left')};
  transition: all 0.2s ease-in-out;
  ${(props) => (props.minWidth ? `width: ${props.minWidth};` : '')}
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
`
const SortContainer = styled.div`
  align-items: center;
  bottom: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2px;
  position: absolute;
  right: 20px;
  ${ifProp(
    'isBalanceTable',
    `
    bottom: 12px;
    right: 10px;
  `
  )}
`
const SortAsc = styled.img`
  height: 4px;
  opacity: 0.3;
  width: 4px;
  ${ifProp('active', 'opacity: 1;')}
`
const SortDesc = styled.img`
  height: 4px;
  margin-top: 1px;
  opacity: 0.3;
  ${ifProp('active', 'opacity: 1;')}
  transform: rotate(180deg);
  width: 4px;
`
