import { addMinutes, isBefore, parseISO } from 'date-fns'

const offset = new Date().getTimezoneOffset()

export const getRoomStatus = (startDate, finishDate) => {
  const now = addMinutes(new Date(), offset)
  const normalizedStart = parseISO(startDate)
  const normalizedFinish = parseISO(finishDate)
  const isStarted = isBefore(normalizedStart, now)
  const isFinished = isBefore(normalizedFinish, now)
  let difference
  let status = 'waiting'
  if (isStarted && !isFinished) {
    difference = new Date(normalizedFinish) - now
    status = 'playing'
  }
  if (!isStarted) {
    difference = new Date(normalizedStart) - now
  }
  if (isFinished) status = 'finished'
  if (Number.isNaN(difference)) {
    difference = 0
    status = 'finished'
  }
  return { status, difference }
}
