import { combine, createEffect, createEvent, createStore, merge, sample, Store } from 'effector-root'

import { authAPI } from 'api/auth'
import {
  resetIsNowPlaying,
  setGameID,
  setIsNowPlaying,
  setRoomID,
} from 'features/game/navigation/model/common'
import { $rooms } from 'features/rooms/model'
import { createEffectWithFetching } from 'lib/fetching'
import { getFingerprint } from 'lib/get-fingerprint'
import { browserHistory, navigate } from 'lib/history'
import { http } from 'lib/http'
import { Storage } from 'lib/storage'
import { notify } from 'lib/toast'

import { canUseTimeline, isTeamlead } from './types'

export const logoutUser = createEvent()
export const setTokens = createEvent<any>()
export const setRememberMe = createEvent()
export const resetIsGameReadOnly = createEvent()
export const setIsGameReadOnly = createEvent()
export const setUser = createEvent()

export const setFingerprint: any = createEffect()

export const [loginUser, loginUserFetching] = createEffectWithFetching()
export const [loginByCode, loginByCodeFetching] = createEffectWithFetching()
export const [getUser, getUserFetching] = createEffectWithFetching()
export const [registerUser, registerUserFetching] = createEffectWithFetching()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UserRole = 'player' | 'guest' | 'admin' | 'writer'

type User = {
  uid: number
  name: string
  email: string
  sex: string
  country: string
  // role: [UserRole]
  role: string
}

export const $user: Store<User> = createStore(null)
export const $loginError = createStore(null)
export const $fingerprint = createStore(null)
export const $tokens = createStore({ access: null, refresh: null })
export const $tokensPresented = $tokens.map((state) => state.access && state.refresh)
export const $userPresented = $user.map((state) => state !== null)
export const $canUseTimeline = $user.map((user) => canUseTimeline(user))
export const $isTeamlead = $user.map((user) => isTeamlead(user))
export const $rememberMe = createStore(true)
export const $isGameReadOnly = createStore(true)
  .on(setIsGameReadOnly, (_, p) => p)
  .reset(resetIsGameReadOnly)

setFingerprint.use(() => getFingerprint())
//@ts-ignore
loginUser.use((creds) => authAPI.login({ ...creds }))
loginByCode.use((code) => authAPI.loginByCode(code))
getUser.use(() => authAPI.getUser())
registerUser.use((user) => authAPI.registration(user))

merge([getUser.done, loginUser.done, loginByCode.done]).watch(
  ({
    result: {
      //@ts-ignore
      data: { activeRoom, activeGame },
    },
  }) => {
    setRoomID(activeRoom)
    if (activeGame?.id) setGameID(activeGame.id)
    if (activeGame) setIsGameReadOnly(activeGame.isReadonly)
    if (activeGame?.id && activeRoom) {
      setIsNowPlaying()
    }
  }
)

$rememberMe.on(setRememberMe, (_, p) => p)

sample({
  clock: registerUser.done,
  //@ts-ignore
  source: $rememberMe,
  target: $user,
  fn: (
    rememberMe,
    {
      result: {
        //@ts-ignore
        data: { accessToken, refreshToken, user },
      },
    }
  ) => {
    notify('Successfully registered!', 'Redirecting to rooms list...', 'success')
    if (rememberMe) {
      Storage.setAccessToken(accessToken)
      Storage.setRefreshToken(refreshToken)
    }
    setTokens({ access: accessToken, refresh: refreshToken })
    return user
  },
})

sample({
  clock: [loginUser.done, loginByCode.done],
  //@ts-ignore
  source: $rememberMe,
  target: $user,
  fn: (
    rememberMe,
    {
      result: {
        //@ts-ignore
        data: { accessToken, refreshToken, user },
      },
    }
  ) => {
    if (rememberMe) {
      Storage.setAccessToken(accessToken)
      Storage.setRefreshToken(refreshToken)
    }
    setTokens({ access: accessToken, refresh: refreshToken })
    return user
  },
})

$user
  .on(
    getUser.done,
    (
      _,
      {
        result: {
          //@ts-ignore
          data: { user },
        },
      }
    ) => user
  )
  .reset(logoutUser)

merge([registerUser.done, loginByCode.done, loginUser.done]).watch(() => {
  registerUserFetching.resetError()
  loginByCodeFetching.resetError()
  loginUserFetching.resetError()
  navigate('/rooms')
})

$fingerprint.on(setFingerprint.done, (_, { result }) => {
  http.defaults.headers.common['x-fingerprint'] = result
  return result
})
$tokens.on(setTokens, (_, tokens) => {
  http.defaults.headers.common['Authorization'] = tokens.access
  return tokens
})
logoutUser.watch(() => {
  delete http.defaults.headers.common['Authorization']
  Storage.clearStorage()
  browserHistory.replace('/login')
})
getUser.fail.watch(({ error }) => {
  //@ts-ignore
  if (error.code === 403) {
    resetIsNowPlaying()
    navigate('/login')
  }
})

combine($fingerprint, $tokens, (result, { access }) => {
  window.log(result + ' ' + access, '', 'combined fingerprint + access token')
  return result && access
}).watch((allIsSet) => {
  if (allIsSet) {
    //@ts-ignore
    getUser()
  }
})

sample({
  source: $isTeamlead,
  clock: $rooms,
  fn: (source, clock) => ({ isTeamlead: source, rooms: clock }),
}).watch(({ isTeamlead, rooms }) => {
  const forcedRoom = rooms.find((el) => el.forceEnter)
  if (forcedRoom) {
    if (isTeamlead) {
      if (browserHistory.location.pathname !== '/game') navigate(`/rooms/${forcedRoom.id}/statistics`)
    } else {
      if (browserHistory.location.pathname !== '/game') navigate(`/rooms/${forcedRoom.id}`)
    }
  }
})

export const submitCode = async (data) => {
  if (data) {
    loginByCode(data.code)
  }
}
export const goToLoginByCode = () => {
  browserHistory.push('/login-by-code')
}
export const goToRegister = () => {
  browserHistory.push('/register')
}
