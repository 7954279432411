import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import { $selectedSubTabData } from 'features/game/model'
import { $selectedSubTab } from 'features/game/navigation/model'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import { NewTable, StyledTableParts } from 'ui/organisms/new-table'

export const InstrumentTable = ({
  columns,
  instrumentStore = $selectedSubTabData.getState(),
  hasLeftPadding = false,
  notFullHeight,
  useSubTabSelectedId,
  footer,
  header,
  bgRow,
  ...rest
}) => {
  const subTabName = useStore($selectedSubTab)
  const store = instrumentStore
  const rows = useStore(store.list)
  window.log(rows, `${subTabName} instrument table rows`)
  const selectedId = useStore(store.selected)?.frontId
  const sort = useStore(store.sort)
  const onRowClick = (row) => {
    store.setSelected(row)
  }
  return (
    <StyledTable
      {...{ footer, header, bgRow }}
      onRowClick={onRowClick}
      order={sort.order}
      notFullHeight={notFullHeight}
      hasLeftPadding={hasLeftPadding}
      orderBy={sort.orderBy}
      onChangeOrder={store.setSort}
      activeRowId={selectedId}
      rowIdKey="frontId"
      columns={columns}
      data={rows}
      bgRow={theme.color.secondary}
      {...rest}
    />
  )
}

const StyledTable = styled(NewTable)`
  ${StyledTableParts.tr} ${StyledTableParts.td} {
    color: ${hexToRGBA(theme.color.black, 0.6)};
    font-weight: 500;
    padding: 6px 8px;
    &:first-of-type {
      &:not(:last-of-type) div {
        position: relative;
      }
    }
    &:not(:last-of-type) {
      position: relative;
      z-index: 1;
    }
  }
  ${StyledTableParts.th} {
    font-size: 11px;
    padding: 11px 8px;
    vertical-align: middle;
    position: relative;
  }
  ${StyledTableParts.tr} {
    background: transparent;
  }
`
