import axios, { AxiosResponse } from 'axios'

import { handleHTTPError } from './error'

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: (Number(process.env.REACT_APP_HTTP_TIMEOUT) || 30) * 1000,
})

http.interceptors.response.use((resp) => resp, handleHTTPError)

export type APIResponse<T> = Promise<AxiosResponse<T>>
