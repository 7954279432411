import { useStore, useStoreMap } from 'effector-react'
import { darken, desaturate, lighten } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { uuid } from 'uuidv4'

import { setModal } from 'features/common/organisms/modal/model'
import { imgSrc } from 'features/game/lib/img-src'
import { formatPrice } from 'features/game/lib/prices/format'
import {
  $profile,
  $selectedSubTabData,
  chooseDream,
  makeTransaction,
  makeTransactionFetching,
} from 'features/game/model'
import { handleGoalStatusText } from 'lib/handle-fields'
import { sortByReady } from 'lib/sort/custom-sorts'
import plusIcon from 'static/plus.svg'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Typography } from 'ui/atoms/typography'
import { FlippingNumbers } from 'ui/molecules/flipping-numbers'
import { HeadingWithStripe } from 'ui/molecules/heading-with-stripe'
import { Scrollbars } from 'ui/molecules/scrollbars'
import { TextTable } from 'ui/organisms/text-table'
import { hexToRGBA } from 'lib/hex-to-rgba'

export const DreamsList = () => {
  const selectedSubTabData = useStore($selectedSubTabData)
  const dreams = useStore(selectedSubTabData.list).slice(0).sort(sortByReady)
  const selectedDream = useStore(selectedSubTabData.selected)
  const cash = useStoreMap({ store: $profile, keys: [], fn: (state) => state.profile.balance.cash.current })
  const canBuy = selectedDream?.sum <= cash
  const loading = useStore(makeTransactionFetching.isLoading)
  const onBuy = () => {
    makeTransaction({
      id: selectedDream.id,
      value: 1,
      actionType: 'buy',
      instrumentType: 'dream',
      uid: uuid(),
    })
  }
  return (
    <Container>
      <ListColumn>
        <Typography size={theme.font.size.large} weight="600">
          Your chosen dreams:
        </Typography>
        <List>
          <AddDream onClick={() => setModal({ id: 'choose-dream' })}>
            <PlusButton>
              <PlusIcon src={plusIcon} />
            </PlusButton>
            <Typography weight="600" color={hexToRGBA(theme.color.black, 0.7)}>
              Choose a dream
            </Typography>
          </AddDream>
          <StyledScrollbars>
            {dreams.map((dream) => {
              const { name, id, status, sum } = dream
              return (
                <DreamCard
                  ready={status === 'ready'}
                  onClick={() => {
                    selectedSubTabData.setSelected(dream)
                  }}
                  selected={id === selectedDream?.id}
                  key={id}
                >
                  <Typography weight="600">{name}</Typography>
                  <StyledTextTable
                    marginRows={8}
                    labelTextAlign="left"
                    valueTextAlign="right"
                    rows={[
                      ['Status:', handleGoalStatusText(status)],
                      ['Price:', formatPrice(sum)],
                    ]}
                  />
                </DreamCard>
              )
            })}
          </StyledScrollbars>
        </List>
      </ListColumn>
      <DreamColumn>
        {selectedDream && (
          <Scrollbars offset={-8}>
            <SelectedDreamContainer>
              <SelectedImg
                ready={selectedDream.status === 'ready'}
                img={imgSrc(selectedDream.image, 'dream-wide')}
              ></SelectedImg>
              <InfoContainer>
                <TextColumn>
                  <HeadingWithStripe fontSize={theme.font.size.huge * 0.75}>
                    {selectedDream.name}
                  </HeadingWithStripe>
                  <BonusColumn>
                    <Cell>
                      <Button
                        padding={theme.padding.button.chooseDream}
                        variant="outlinedLight"
                        onClick={() => {
                          setModal({
                            id: 'unchoose-dream-confirm',
                            onSuccess: () => chooseDream({ unchoose: true, dreamID: selectedDream.id }),
                          })
                        }}
                        loading={loading}
                      >
                        Reject
                      </Button>
                    </Cell>
                    <Cell>
                      <Typography weight="bold" size={theme.font.size.medium}>
                        Price:
                      </Typography>
                      <Typography weight="500" size={theme.font.size.large - 2}>
                        <FlippingNumbers>{selectedDream.sum}</FlippingNumbers>
                      </Typography>
                    </Cell>
                    <Cell>
                      <Typography size={theme.font.size.medium} weight="bold">
                        Endbonus:
                      </Typography>
                      <Typography weight="500" size={theme.font.size.large - 2}>
                        x {formatPrice(selectedDream.endBonus, { disableCurrency: true })}
                      </Typography>
                    </Cell>
                    <Cell>
                      <BuyButton
                        padding={theme.padding.button.chooseDream}
                        onClick={onBuy}
                        loading={loading}
                        disabled={!canBuy}
                      >
                        Buy
                      </BuyButton>
                    </Cell>
                  </BonusColumn>
                </TextColumn>
                <NumbersColumn>
                  <Descr
                    size={theme.font.size.small}
                    color={hexToRGBA(theme.color.black, 0.8)}
                    dangerouslySetInnerHTML={{ __html: selectedDream.description }}
                  />
                </NumbersColumn>
              </InfoContainer>
            </SelectedDreamContainer>
          </Scrollbars>
        )}
      </DreamColumn>
    </Container>
  )
}

const Cell = styled.div`
  &:not(:first-of-type) {
    margin-left: 15px;
  }
  align-items: center;
  display: flex;
  white-space: nowrap;
  & > *:last-child {
    margin-left: 6px;
  }
`

const BonusColumn = styled.div`
  align-items: center;
  display: flex;
`

const StyledScrollbars = styled(Scrollbars)`
  flex: 1;
`

const BuyButton = styled(Button)``

const Descr = styled(Typography)`
  margin-top: 10px;
`

const TextColumn = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-left: 30px;
`

const NumbersColumn = styled.div`
  flex: 1;
`

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 20px;
  padding-bottom: 15px;
  padding-top: 5px;
  position: relative;
`

const SelectedImg = styled.div`
  background-position: center;
  ${ifProp(
    'ready',
    css`
      background: linear-gradient(
          180deg,
          ${hexToRGBA(theme.color.green, 0.4)} 11.57%,
          ${hexToRGBA(theme.color.darkgray, 0.2)} 100%
        ),
        url(${prop('img')});
    `,
    css`
      background: url(${prop('img')});
    `
  )}
  background-size: cover;
  border-radius: ${theme.radius.button.medium}px;
  height: 48vh;
  min-height: 200px;
  padding: 35px 90px;
  text-align: center;
`

const SelectedDreamContainer = styled.div`
  background: ${theme.color.darkgray};
  border-radius: ${theme.radius.button.medium}px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledTextTable = styled(TextTable)`
  margin-top: 10px;
  & > *:last-child {
    flex: 1;
  }
`

const CardBase = styled.li`
  background: ${theme.color.darkgray};
  border-radius: ${theme.radius.form.action}px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${lighten(0.05, theme.color.darkgray)};
  }
`

const DreamCard = styled(CardBase)`
  ${ifProp(
    'selected',
    `
  background: ${lighten(0.1, theme.color.darkgray)};
`
  )}
  ${ifProp(
    'ready',
    `
  background: ${desaturate(0.3, darken(0.3, theme.color.green))};
`
  )}
`

const PlusIcon = styled.img`
  height: 16px;
  width: 16px;
`

const PlusButton = styled.div`
  align-items: center;
  background: ${theme.color.primary};
  border-radius: 50%;
  box-shadow: ${theme.shadow.addDreamButton};
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  width: 40px;
`

const AddDream = styled(CardBase)`
  align-items: center;
  flex-direction: row;
  justify-content: center;
  min-height: 90px;
`

const List = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 25px;
`

const ListColumn = styled.div`
  display: flex;
  flex-basis: 230px;
  flex-direction: column;
  margin-right: 30px;
  padding-top: 10px;
`

const DreamColumn = styled.div`
  flex: 1;
  height: 100%;
`
