import { createEffect, createEvent, createStore, sample } from 'effector-root'

import { roomsAPI } from 'api/rooms'
import { createFetching } from 'lib/fetching'
import { areWeLocatedHereRegex, browserHistory, navigate } from 'lib/history'
import { notify } from 'lib/toast'

export const setRoomID = createEvent()
export const setGameID = createEvent()
export const setIsNowPlaying = createEvent()
export const resetIsNowPlaying = createEvent()
export const resetRoomID = createEvent()
export const resetGameID = createEvent()
export const resetStartPlayingEffect = createEvent()
export const goToLeaderboardPage = createEvent()
export const goToStatisticsPage = createEvent()
export const setStatisticsTitle = createEvent()
export const resetStatisticsTitle = createEvent()

export const $isNowPlaying = createStore(false)
  .on(setIsNowPlaying, (_, __) => true)
  .reset(resetIsNowPlaying)

export const $roomID = createStore(null)
  .on(setRoomID, (_, p) => p)
  .reset(resetRoomID)

export const $gameID = createStore(null)
  .on(setGameID, (_, p) => p)
  .reset(resetGameID)

export const $statisticsTitle = createStore('')
  .on(setStatisticsTitle, (_, p) => p)
  .reset(resetStatisticsTitle)

export const startPlaying = createEffect()
startPlaying.use((params) => roomsAPI.startPlaying(params))

export const startPlayingFetching = createFetching(startPlaying, { reset: resetStartPlayingEffect })

export const checkActiveGames = (gameID) => Boolean(gameID)

$roomID.on(startPlaying, (_, { sessionId }) => {
  return sessionId
})

sample({
  clock: goToLeaderboardPage,
  source: $roomID,
  fn: (roomID, clockParams) => {
    let normalizedParams = { roomID }
    if (clockParams?.roomID) normalizedParams.roomID = clockParams.roomID
    return normalizedParams
  },
}).watch(({ roomID }) => {
  if (roomID) {
    if (!$isNowPlaying.getState()) setRoomID(roomID)
    browserHistory.push(`/rooms/${roomID}/leaderboard`)
  } else {
    notify('Cannot go to the leaderboard page because of empty roomID', null, 'warn')
  }
})

sample({
  clock: goToStatisticsPage,
  source: [$roomID, $gameID],
  fn: ([roomID, gameID], params) => {
    let normalizedParams = { roomID, gameID }
    if (params?.roomID) normalizedParams.roomID = params.roomID
    if (params?.gameID) normalizedParams.gameID = params.gameID
    if (params?.title) normalizedParams.title = params.title
    return normalizedParams
  },
}).watch(({ roomID, gameID, title }) => {
  if (roomID) {
    if (title) setStatisticsTitle(title)
    browserHistory.push(`/rooms/${roomID}/statistics/${gameID}`)
  } else {
    notify('Cannot go to the leaderboard page because of empty roomID', null, 'warn')
  }
})

$gameID.watch((st) => {
  window.log(st, 'gameID', 'store')
})

$roomID.watch((st) => {
  window.log(st, 'roomID', 'store')
})

startPlaying.done.watch((p) => {
  setIsNowPlaying()
  window.log(p, 'startPlaying.done', 'arg')
})

$isNowPlaying.watch((playing) => {
  window.log(playing, 'isNowPlaying', 'store')
  const notInUtilPages = ['statistics', 'leaderboard'].every((url) => !areWeLocatedHereRegex(url))
  if (playing && notInUtilPages) {
    navigate('/game')
  }
})

window.setIsNowPlaying = setIsNowPlaying
