import { get } from 'lodash'
import { lighten } from 'polished'
import React from 'react'

import { COLUMNS } from 'features/game/field/organisms/subtab-table/instrument-table/columns'
import { filterPortfolio, filterMarket, filterMarketLeverage } from 'features/game/lib/filter-instruments'
import { theme } from 'theme'

import { Tab } from '../../atoms/tab'
import { DreamsList } from '../../field/dreams-goals/dreams/list'
import { GoalDetails } from '../../field/dreams-goals/tabs/goal-details'
import { FinancialSettings } from '../../field/income-expenses/tabs/actions/financial-settings'
import { DescriptionTab } from '../../field/organisms/description-tab'
import { InstrumentTable } from '../../field/organisms/subtab-table/instrument-table'
import {
  HeaderOneCol,
  HeaderWithTotal,
} from '../../field/organisms/subtab-table/instrument-table/organisms/header'
import { ChartAndNews } from '../../field/stock-market/tabs/chart-news'
import { TurnActionsButtons } from '../../field/turn-actions/action-buttons'
import { InstrumentTypes } from '../../types'

const getTotalByPrice = (data) => data.reduce((acc, cur) => acc + get(cur, 'price.current'), 0)
const getTotalBySum = (data) => data.reduce((acc, cur) => acc + cur.sum, 0)
const getTotalByQtySum = (data) => data.reduce((acc, cur) => acc + cur.qty * cur.price.current, 0)

// const TAB_STOCK_ABOUT = (
//   <Tab key="about" id="about" heading="About company">
//     <StockMarketAbout />
//   </Tab>
// )

const TAB_ABOUT = (
  <Tab key="about" id="about" heading="Description">
    <DescriptionTab />
  </Tab>
)

const TAB_CHART_NEWS = (
  <Tab key="chart" id="chart" heading="Chart & News">
    <ChartAndNews />
  </Tab>
)

export const ROOT_TABS_STRUCTURE = [
  {
    key: 'personal',
    name: 'Personal Status',
    centerBody: [TAB_ABOUT, TAB_CHART_NEWS],
    defaultOrderBy: 'name',
    subtabs: [
      {
        name: 'Assets & Liabilities',
        key: 'assetsAndLiabilities',
        centerBody: [
          <Tab key="about" id="about" heading="Description">
            <DescriptionTab />
          </Tab>,
          TAB_CHART_NEWS,
        ],
        actionsHeader: [],
        defaultOrderBy: 'name',
        // listBody: <BalanceTable />,
        views: [
          {
            key: 'assets',
            columns: COLUMNS.assetsAndLiabilities.assets,
            groupByKey: 'subType',
            header: (icon, data) => {
              return (
                <HeaderWithTotal {...{ icon }} total={getTotalByQtySum(data)}>
                  Assets
                </HeaderWithTotal>
              )
            },
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            flex: 6,
            transformFn: (list) => list.filter((elem) => elem.isAsset),
          },
          {
            key: 'liabilities',
            columns: COLUMNS.assetsAndLiabilities.liabilities,
            // groupByKey: 'instrumentType',
            flex: 4,
            header: (icon, data) => {
              return (
                <HeaderWithTotal {...{ icon }} total={getTotalByQtySum(data)}>
                  Liabilities
                </HeaderWithTotal>
              )
            },
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            transformFn: (list) => list.filter((elem) => elem.isLiability),
          },
        ],
      },
      {
        name: 'Income & Expenses',
        key: 'incomeAndExpenses',
        centerBody: [
          <Tab key="about" id="about" heading="Description">
            <DescriptionTab />
          </Tab>,
        ],
        actionsBody: [
          <Tab id="expenses" key="expenses">
            <FinancialSettings />
          </Tab>,
        ],
        actionsHeader: [],
        views: [
          {
            key: 'income',
            columns: COLUMNS.incomeAndExpenses,
            flex: 1,
            groupByKey: 'group',
            header: (icon, data) => {
              return (
                <HeaderWithTotal {...{ icon }} total={getTotalBySum(data)}>
                  Income
                </HeaderWithTotal>
              )
            },
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            transformFn: (list) => list.filter((elem) => elem.balanceType === 'income'),
          },
          {
            key: 'expense',
            columns: COLUMNS.incomeAndExpenses,
            flex: 1,
            header: (icon, data) => {
              return (
                <HeaderWithTotal {...{ icon }} total={Math.abs(getTotalBySum(data))}>
                  Expenses
                </HeaderWithTotal>
              )
            },
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            groupByKey: 'group',
            transformFn: (list) => list.filter((elem) => elem.balanceType === 'expenses'),
          },
        ],
      },
      {
        key: 'dreams',
        name: 'Dreams',
        listBody: <DreamsList />,
        actionsBody: [],
        centerBody: [],
        actionsHeader: [],
        transformFn: filterPortfolio,
      },
      {
        key: 'goals',
        name: 'Achievements',
        centerBody: [
          <Tab key="goals" id="goals" heading="Description">
            <GoalDetails />
          </Tab>,
        ],
        actionsBody: [],
        columns: COLUMNS.dreamsAndGoals.goals,
        listFilters: ['accomplishedGoals', 'notCompleted'],
      },
    ],
  },
  {
    key: 'otherMarkets',
    name: 'Other Markets',
    subtabs: [
      {
        key: 'realEstate',
        name: 'Real Estate',
        centerBody: [TAB_ABOUT, TAB_CHART_NEWS],
        actionsHeader: [],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
        views: [
          {
            key: 'realEstate',
            columns: COLUMNS.REBusiness.realEstate,
            header: (icon) => <HeaderOneCol>{icon}Market</HeaderOneCol>,
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            flex: 5,
            transformFn: filterMarket,
          },
          {
            key: 'onHand',
            columns: COLUMNS.REBusiness.realEstate,
            flex: 2,
            header: (icon, data) => {
              return (
                <HeaderWithTotal
                  {...{ icon }}
                  total={getTotalByPrice(data)}
                  totalPropertyPath="price.current"
                >
                  On hand
                </HeaderWithTotal>
              )
            },
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            transformFn: filterPortfolio,
          },
        ],
      },
      {
        key: 'goods',
        name: 'Goods',
        columns: COLUMNS.goodsAndServices.goods,
        transformFn: filterMarket,
        centerBody: [TAB_ABOUT, TAB_CHART_NEWS],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'services',
        name: 'Services',
        columns: COLUMNS.goodsAndServices.services,
        transformFn: filterMarket,
        centerBody: [TAB_ABOUT, TAB_CHART_NEWS],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
    ],
  },
  {
    key: 'financialMarkets',
    name: 'Financial Markets',
    subtabs: [
      {
        key: 'stocks',
        name: 'Stocks',
        columns: COLUMNS.stockMarket.stocks,
        transformFn: filterMarket,
        centerBody: [TAB_CHART_NEWS],
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'bonds',
        name: 'Bonds',
        centerBody: [TAB_CHART_NEWS],
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
        views: [
          {
            key: 'bonds',
            columns: COLUMNS.stockMarket.bonds,
            header: (icon) => <HeaderOneCol>{icon}Market</HeaderOneCol>,
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            flex: 5,
            transformFn: filterMarket,
          },
          {
            key: 'onHand',
            columns: COLUMNS.stockMarket.bonds,
            flex: 4,
            header: (icon, data) => {
              return (
                <HeaderWithTotal
                  {...{ icon }}
                  total={getTotalByPrice(data)}
                  totalPropertyPath="price.current"
                >
                  On hand
                </HeaderWithTotal>
              )
            },
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            transformFn: filterPortfolio,
          },
        ],
      },
      {
        key: 'indicies',
        name: 'Indices',
        columns: COLUMNS.stockMarket.indicies,
        transformFn: filterMarket,
        centerBody: [TAB_CHART_NEWS],
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'commodities',
        name: 'Commodities',
        columns: COLUMNS.stockMarket.commodities,
        transformFn: filterMarket,
        centerBody: [TAB_CHART_NEWS],
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'etf',
        name: 'ETF',
        columns: COLUMNS.stockMarket.etf,
        transformFn: filterMarket,
        centerBody: [TAB_CHART_NEWS],
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'crypto',
        name: 'Crypto',
        columns: COLUMNS.stockMarket.crypto,
        transformFn: filterMarket,
        centerBody: [TAB_CHART_NEWS],
        actionsHeader: ['useLeverage'],
        listFilters: ['purchased'],
        defaultOrderBy: 'name',
      },
      {
        key: 'banking',
        name: 'Banking',
        centerBody: [TAB_ABOUT, TAB_CHART_NEWS],
        actionsHeader: [],
        listFilters: [],
        defaultOrderBy: 'name',
        transformFn: filterMarketLeverage,
        views: [
          {
            key: 'credit',
            columns: COLUMNS.banking.all,
            header: (icon) => <HeaderOneCol>{icon}Credits</HeaderOneCol>,
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            flex: 3,
            transformFn: (list) =>
              list.filter((elem) => elem.instrumentType === InstrumentTypes.Credit && !elem.isPortfolio),
          },
          {
            key: 'deposits',
            columns: COLUMNS.banking.deposits,
            header: (icon) => <HeaderOneCol>{icon}Deposits</HeaderOneCol>,
            bgRow: lighten(0.07, theme.color.secondary),
            flex: 2,
            isMinimizable: true,
            transformFn: (list) =>
              list.filter((elem) => elem.instrumentType === InstrumentTypes.Deposit && !elem.isPortfolio),
          },
          {
            key: 'onHand',
            header: (icon) => <HeaderOneCol>{icon}On hand</HeaderOneCol>,
            bgRow: lighten(0.07, theme.color.secondary),
            isMinimizable: true,
            flex: 4,
            columns: COLUMNS.assetsAndLiabilities.liabilities,
            transformFn: filterPortfolio,
          },
        ],
      },
    ],
  },
  {
    key: 'turnActions',
    centerBody: [
      <Tab key="about" id="about" heading="Description">
        <DescriptionTab />
      </Tab>,
      TAB_CHART_NEWS,
    ],
    listBody: (
      <>
        <InstrumentTable useSubTabSelectedId notFullHeight columns={COLUMNS.turnActions} />
        <TurnActionsButtons />
      </>
    ),
    defaultOrderBy: 'order',
    listHeader: 'Your actions',
    subtabs: [
      {
        key: 'turnActions',
        name: 'All',
        columns: COLUMNS.turnActions,
      },
    ],
  },
  {
    key: 'events',
    centerBody: [
      <Tab key="about" id="about" heading="Description">
        <DescriptionTab />
      </Tab>,
    ],
    listBody: <InstrumentTable columns={COLUMNS.events} />,
    defaultOrderBy: 'name',
    listHeader: 'Events',
    subtabs: [
      {
        key: 'events',
        name: 'All',
        columns: COLUMNS.events,
      },
    ],
  },
]
