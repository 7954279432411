import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Error, Form, Heading, Subheading } from 'ui/atoms/form'
import { BackButtonForPage } from 'ui/molecules/circle-button/wrapper-for-page'
import { InputGroup } from 'ui/molecules/input-group'

import { $fingerprint, loginByCode, loginByCodeFetching, submitCode } from '../../model'

export const LoginByCodeForm = ({ initialCode }) => {
  const { register, handleSubmit, errors } = useForm()
  const formError = useStore(loginByCodeFetching.error)
  const fingerprint = useStore($fingerprint)
  const loading = useStore(loginByCodeFetching.isLoading)
  useEffect(() => {
    if (initialCode && fingerprint) {
      loginByCode(initialCode)
    }
  }, [initialCode, fingerprint])
  return (
    <Form onSubmit={handleSubmit(submitCode)}>
      <BackButtonForPage backURL="/login" />
      <Heading centered weight="600" size={theme.font.size.huge}>
        One-time game session login
      </Heading>
      <StyledSubheading>
        To enter the game session, enter the "Code" issued by the administrator \ demiurge
      </StyledSubheading>
      <InputGroup
        name="code"
        defaultValue={initialCode}
        error={errors.code?.message || formError?.message?.length > 0}
        ref={register({
          required: 'Code is required.',
        })}
        fullWidth
        placeholder="Enter code here"
      />
      {formError?.message?.length > 0 && <Error>{formError.message}</Error>}
      <EnterButton type="submit" {...{ loading }} fullWidth>
        Enter the game
      </EnterButton>
    </Form>
  )
}

const StyledSubheading = styled(Subheading)`
  margin-bottom: 30px;
  margin-top: -10px;
  text-align: center;
`

const EnterButton = styled(Button)`
  margin-top: 15px;
`
