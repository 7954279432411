import timestamp from 'time-stamp'

window.log =
  process.env.NODE_ENV !== 'development'
    ? () => {}
    : function (info, type, model, emoji = '') {
        const STYLES = {
          red: 'background: #F35531; color: white; font-size: 12px;',
          white: 'background: white; color: black; font-size: 12px;',
          black: 'background: black; color: white; font-size: 12px;',
          blue: 'background: deepblue; color: white; font-size: 12px;',
        }
        const TIME_STYLE = 'font-size: 10px;'
        const TYPES = {
          store: {
            styles: STYLES.red,
            label: '🗄Store',
          },
          event: {
            styles: STYLES.blue,
            label: '🦋Event',
          },
          effect: {
            styles: STYLES.green,
            label: 'Effect',
          },
        }
        const MODELS = {
          game: {
            styles: STYLES.white,
            label: 'Game',
          },
        }
        let logStr = `${emoji}%c [${timestamp('DD.MM HH:mm:ss.ms')}]`
        if (type && model) {
          const foundTypeLabel = TYPES[type]?.label || type
          const foundTypeStyles = TYPES[type]?.styles || STYLES.white
          const foundModelLabel = MODELS[model]?.label || model
          const foundModelStyles = MODELS[model]?.styles || STYLES.black
          console.log(
            `${logStr} %c ${foundTypeLabel} %c ${foundModelLabel} \n`,
            TIME_STYLE,
            foundTypeStyles,
            foundModelStyles,
            info
          )
        } else if (type) {
          const foundTypeLabel = TYPES[type]?.label || type
          const foundTypeStyles = TYPES[type]?.styles || STYLES.white
          console.log(`${logStr} %c ${foundTypeLabel} \n`, TIME_STYLE, foundTypeStyles, info)
        } else if (model) {
          const foundModelLabel = MODELS[model]?.label || model
          const foundModelStyles = MODELS[model]?.styles || STYLES.black
          console.log(`${logStr} %c ${foundModelLabel} \n`, TIME_STYLE, foundModelStyles, info)
        } else {
          console.log(logStr, TIME_STYLE, info)
        }
      }
