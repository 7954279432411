import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { Divider } from 'features/game/field/atoms/divider'
import { NewsContent } from 'features/game/field/atoms/news-content'
import { TabInnerHeading } from 'features/game/field/atoms/tab-inner-heading'
import { TabInnerSection } from 'features/game/field/atoms/tab-inner-section'
import { getSubjectInstrument } from 'features/game/lib/get-subject-instrument'
import { formatPrice } from 'features/game/lib/prices/format'
import { selectRelatedInstrument } from 'features/game/lib/select-related-instrument'
import { $selectedSubTabData } from 'features/game/model'
import { handleIncomeAndExpensesType } from 'lib/handle-fields'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Typography } from 'ui/atoms/typography'
import { TextTable } from 'ui/organisms/text-table'

export const IncomeExpensesDescription = () => {
  const selectedInstrument = useStore(useStore($selectedSubTabData).selected)
  const { name, balanceType, sum } = selectedInstrument
  const subjectInstrument = selectedInstrument.subjectID
    ? getSubjectInstrument(selectedInstrument.subjectID, selectedInstrument.subjectType)
    : null
  const rows = [['Type:', handleIncomeAndExpensesType(balanceType)]]
  return (
    <>
      <TabInnerSection>
        <TabInnerHeading weight="bold" color={hexToRGBA(theme.color.black, 0.7)} size={theme.font.size.main}>
          {name}
        </TabInnerHeading>
        <StyledTextTable
          labelTextAlign="left"
          labelFontSize={theme.font.size.main}
          valueTextAlign="right"
          valueFontSize={theme.font.size.main}
          {...{ rows }}
        />
      </TabInnerSection>
      <Divider />
      {Boolean(selectedInstrument.about?.description) && (
        <TabInnerSection>
          <TabInnerHeading color={hexToRGBA(theme.color.black, 0.7)} size={theme.font.size.main}>
            Description
          </TabInnerHeading>
          <NewsContent html={selectedInstrument?.about?.description} />
        </TabInnerSection>
      )}
      <TabInnerSection noPt>
        <PriceSection>
          <Typography color={hexToRGBA(theme.color.black, 0.7)} weight="bold">
            Sum:
          </Typography>
          <Typography weight="bold">{formatPrice(sum)}</Typography>
        </PriceSection>
      </TabInnerSection>
      {Boolean(subjectInstrument) && (
        <StyledButton
          padding={theme.padding.button.small}
          onClick={() =>
            selectRelatedInstrument(selectedInstrument.subjectID, selectedInstrument.subjectType)
          }
          variant="greenOutlined"
        >
          Go to related instrument
        </StyledButton>
      )}
    </>
  )
}

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
  margin-top: 15px;
`

const PriceSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  &:last-child {
    text-align: right;
  }
`

const StyledTextTable = styled(TextTable)`
  justify-content: space-between;
  margin: 4px 0;
`
