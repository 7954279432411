import { http } from 'lib/http'
import { handleAPIError } from 'lib/http/error/handle-error'
// eslint-disable-next-line no-unused-vars
import { mixinRole, responseMixinFake } from 'lib/http/response-mixin-fake'

const AUTH_HTTP_ROUTES = {
  login: 'login',
  loginByCode: 'login-by-code',
  refreshToken: 'refresh-token',
  registration: 'registration',
  confirmEmail: 'confirm-email',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  logout: 'logout',
  preloadResources: 'preload-resources',
  getUser: 'user',
}

const login = (creds) =>
  http.post(AUTH_HTTP_ROUTES.login, creds).catch(
    handleAPIError(
      {
        403: 'Incorrect login or passsword. \n Please, check your entered data.',
        404: 'User does not exist. \n Please, check your entered data.',
      },
      [403, 404]
    )
  )
const loginByCode = (token) =>
  http.post(`${AUTH_HTTP_ROUTES.loginByCode}/${token}`).catch(
    handleAPIError(
      {
        403: 'Code is not correct.',
      },
      [403, 404]
    )
  )
const refreshToken = (token) => http.post(AUTH_HTTP_ROUTES.refreshToken, { refreshToken: token })
const registration = (user) => http.post(AUTH_HTTP_ROUTES.registration, user)
const confirmEmail = (token) => http.get(`${AUTH_HTTP_ROUTES.confirmEmail}/${token}`)
const forgotPassword = (email) => http.post(AUTH_HTTP_ROUTES.forgotPassword, { email })
const resetPassword = (token, password) =>
  http.post(`${AUTH_HTTP_ROUTES.resetPassword}/${token}`, { password })
const logout = () => http.get(AUTH_HTTP_ROUTES.logout)
const getUser = () => http.get(AUTH_HTTP_ROUTES.getUser)
const preloadResources = (type) =>
  http
    .get(`${AUTH_HTTP_ROUTES.preloadResources}/${type}`)
    .catch(handleAPIError({ default: 'Cannot preload resources.' }, [403]))

export const authAPI = {
  login,
  loginByCode,
  refreshToken,
  registration,
  confirmEmail,
  forgotPassword,
  resetPassword,
  logout,
  getUser,
  preloadResources,
}
