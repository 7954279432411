import { useStore } from 'effector-react'
import React, { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import RCSlider from 'react-input-slider'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { $profile } from 'features/game/model'
import {
  changeCharacter,
  goToStepFetching,
  refreshScenario,
  refreshScenarioFetching,
  $chars,
  getCharactersList,
  getCharactersListFetching,
} from 'features/game/admin/timeline/model'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { useEffectWithoutInit } from 'lib/hooks/use-effect-without-init'
import moveIcon from 'static/move.svg'
import playIcon from 'static/play.svg'
import rewindIcon from 'static/rewind.svg'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Input } from 'ui/atoms/input'
import { Select } from 'ui/atoms/select'
import { Typography } from 'ui/atoms/typography'
import { LoadingOverflow } from 'ui/molecules/loading-overflow'

import { goToStep } from './model'

const sliderStyles = {
  track: {
    background: theme.color.secondary,
  },
  active: {
    background: hexToRGBA(theme.color.black, 0.2),
  },
  thumb: {
    width: 10,
    height: 25,
    backgroundColor: theme.color.primary,
    borderRadius: 0,
  },
}

export const GameAdminTimeline = () => {
  const profile = useStore($profile)
  const currentTurn = profile?.currentTurn
  const totalTurns = profile?.turnQty
  const [x, setX] = useState(currentTurn)
  const [input, setInput] = useState(x)
  //eslint-disable-next-line no-unused-vars
  const [focus, setFocus] = useState(false)
  const inputRef = useRef(null)
  const selectedChar = profile?.character
    ? { value: profile.character.id, label: profile.character.name }
    : { value: null, label: null }
  const loading = useStore(goToStepFetching.isLoading)
  const refreshingLoading = useStore(refreshScenarioFetching.isLoading)
  const chars = useStore($chars)
  const charsLoading = useStore(getCharactersListFetching.isLoading)
  const draggableOpts = {
    defaultPosition: {
      y: window.innerHeight - 150,
      x: window.innerWidth * 0.05,
    },
    // bounds: 'body',
    handle: '.move-knob',
  }
  const onBack = () => {
    if (input !== 0) setInput(input - 1)
  }
  const onForward = () => {
    if (input !== totalTurns) setInput(input + 1)
  }
  const changeStep = () => {
    goToStep(input)
    // .catch((error) => {
    //   setInput(x)
    //   notify('Error changing step to ' + input, error.details || error.message, 'error')
    // })
    //setX(input)
  }
  const sliderOnChange = ({ x }) => {
    window.log(x, 'x', 'onSliderChange')
    if (input !== x) setInput(x)
  }
  useEffectWithoutInit(() => {
    changeStep()
  }, [input])
  useEffectWithoutInit(() => {
    if (x !== input) setInput(x)
    window.log(x, 'x', 'useEffect')
  }, [x])
  useEffect(() => {
    if (x !== currentTurn) setX(currentTurn)
  }, [currentTurn, x])
  useEffect(() => {
    getCharactersList()
  }, [])
  return (
    <Draggable {...draggableOpts}>
      <TimeLineContainer>
        <ActionsContainer>
          <ActionButton onClick={() => setInput(1)}>
            <ActionIcon src={rewindIcon} />
          </ActionButton>
          <ActionButton onClick={onBack}>
            <ActionIcon rotate src={playIcon} />
          </ActionButton>
        </ActionsContainer>
        <HandleContainer>
          <StyledRCSlider
            styles={sliderStyles}
            axis="x"
            x={input}
            disabled={loading}
            onChange={sliderOnChange}
            xmin={1}
            xmax={totalTurns}
            //@ts-ignore
            step={1}
          />
          <BottomContainer>
            <Typography>Turn:</Typography>
            <StyledInput
              //@ts-ignore
              ref={inputRef}
              //@ts-ignore
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              onChange={({ target: { value } }) => {
                setInput(value)
              }}
              value={input}
            />
            <Typography>Character:</Typography>
            <StyledSelect
              placeholder="Select character"
              menuPlacement="top"
              menuListHeight={300}
              disabled={charsLoading}
              onChange={changeCharacter}
              padding={theme.padding.button.extrasmall}
              options={chars}
              value={selectedChar}
            />
            <RefreshButton
              variant="outlinedLight"
              fontSize={theme.font.size.main}
              padding={theme.padding.button.extrasmall}
              loading={refreshingLoading}
              onClick={refreshScenario}
            >
              Refresh scenario
            </RefreshButton>
          </BottomContainer>
        </HandleContainer>
        <ActionsContainer>
          <ActionButton onClick={onForward}>
            <ActionIcon src={playIcon} />
          </ActionButton>
          <ActionButton onClick={() => setInput(totalTurns)}>
            <ActionIcon rotate src={rewindIcon} />
          </ActionButton>
        </ActionsContainer>
        <MoveKnob draggable="false" className="move-knob" src={moveIcon}></MoveKnob>
        <LoadingOverflow withLoader bg absolute {...{ loading }} />
      </TimeLineContainer>
    </Draggable>
  )
}

const StyledSelect = styled(Select)`
  margin-left: 10px;
  margin-right: 40px;
  max-width: 250px;
  width: 100%;
`

const RefreshButton = styled(Button)``

const BottomContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

const StyledInput = styled(Input)`
  display: block;
  font-size: ${theme.font.size.large}px;
  margin-left: 10px;
  margin-right: 20px;
  max-width: 110px;
  padding: 4px;
  width: 42px;
`

const StyledRCSlider = styled(RCSlider)`
  height: 10px !important;
  margin-bottom: 10px;
  width: 100% !important;
`

const MoveKnob = styled.img`
  cursor: move;
  height: 20px;
  opacity: 0.3;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
`

const ActionButton = styled(Button)`
  padding: 5px 9px;
  &:not(:first-of-type) {
    margin-left: 8px;
  }
`

const ActionIcon = styled.img`
  width: 10px;
  height: 10px;
  position: relative;
  // top: 1px;
  // left: -1px;
  ${ifProp('rotate', 'transform: rotate(180deg);')}
`

const Item = styled.div`
  align-items: center;
  display: flex;
  flex: 0;
`

const ActionsContainer = styled(Item)``

const HandleContainer = styled(Item)`
  display: block;
  flex: 1;
  margin: 0 30px;
`

const TimeLineContainer = styled.div`
  align-items: center;
  background: ${hexToRGBA(theme.color.white, 0.8)};
  border-radius: ${theme.radius.form.action}px;
  display: flex;
  padding: 10px;
  padding-top: 20px;
  position: absolute;
  width: 70vw;
  z-index: 100;
`
