import { createEvent, createStore } from 'effector-root'

import { STATIC_TABS_STRUCTURE } from './model/static-structure'

export const setSelectedTab = createEvent()
export const resetSelectedTab = createEvent()
export const setSelectedSubTab = createEvent()
export const setNewsRolletPosition = createEvent()

export const $selectedSubTab = createStore(STATIC_TABS_STRUCTURE[0].subtabs[2].key)
export const $selectedTab = $selectedSubTab.map((subTab) => {
  const foundTab = STATIC_TABS_STRUCTURE.find((tab) => tab.subtabs.some((sbtb) => sbtb.key === subTab))
  return foundTab ? foundTab.key : STATIC_TABS_STRUCTURE[0].key
})
export const $newsRolletPosition = createStore('up').on(setNewsRolletPosition, (_, p) => p)

$selectedTab.on(setSelectedTab, (_, tab) => tab).reset(resetSelectedTab)
$selectedSubTab.on(setSelectedSubTab, (_, tab) => tab).reset(resetSelectedTab)
setSelectedTab.watch((key) => {
  window.log(key, 'selectedTab', 'store')
  setSelectedSubTab(STATIC_TABS_STRUCTURE.find((tab) => tab.key === key).subtabs[0].key)
})
setSelectedSubTab.watch((key) => {
  window.log(key, 'selectedSubTab', 'store')
})
