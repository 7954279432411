import React from 'react'
import styled from 'styled-components'
import { ifNotProp } from 'styled-tools'

import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

export const TabInnerHeading = ({ children, noMb }) => {
  return <Heading {...{ noMb }}>{children}</Heading>
}

const Heading = styled.h3`
  ${ifNotProp('noMb', 'margin-bottom: 7px;')}
  color: ${hexToRGBA(theme.color.black, 0.7)};
  font-size: ${theme.font.size.main}px;
  font-weight: bold;
`
