import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { $user, logoutUser, isTeamlead as checkIsTeamlead } from 'features/auth'
import { goToStatisticsPage } from 'features/game/navigation/model/common'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { browserHistory } from 'lib/history'
import { theme } from 'theme'
import { Button } from 'ui/atoms/button'
import { Container } from 'ui/atoms/container'
import { Typography } from 'ui/atoms/typography'
import { LoadingOverflow } from 'ui/molecules/loading-overflow'
import { Tabs } from 'ui/organisms/tabs'

import { enhancedRooms, getRoomFetching, getRoomsFetching } from '../model'
import { RoomsTableList } from './table'

export const RoomsList = () => {
  const user = useStore($user)
  const isTeamlead = checkIsTeamlead(user)
  const isRoomLoading = useStore(getRoomFetching.isLoading)
  const isRoomsListLoading = useStore(getRoomsFetching.isLoading)
  const rows = useStore(enhancedRooms.list)
  const selectedRoom = useStore(enhancedRooms.selected)
  const sort = useStore(enhancedRooms.sort)
  const viewRoom = () => {
    goToStatisticsPage({ gameID: selectedRoom.currentGameId, roomID: selectedRoom.id })
  }
  const joinRoom = () => {
    browserHistory.push(`/rooms/${selectedRoom.id}`)
  }
  const updateFilter = (tab) => {
    enhancedRooms.setFilter({ name: 'roomsType', value: tab === 'training' ? 'training' : 'challenge' })
  }
  const buttonProps = {
    onClick: isTeamlead ? viewRoom : joinRoom,
    children: isTeamlead ? 'Spectate' : 'Join',
  }
  return (
    <Main>
      <Header>
        <Cell></Cell>
        <Cell>
          <Heading weight="600" size={theme.font.size.huge}>
            Game Session Selection
          </Heading>
        </Cell>
        <Cell>
          <UserEmail weight="600" size={theme.font.size.main + 2} color={hexToRGBA(theme.color.black, 0.8)}>
            {user?.email || user?.name}
          </UserEmail>
          <LogoutButton padding={theme.padding.button.small} variant="outlinedLight" onClick={logoutUser}>
            Log out
          </LogoutButton>
        </Cell>
      </Header>
      <Container small>
        <BG>
          {isTeamlead ? (
            <>
              <RoomsTableList
                {...sort}
                onChangeOrder={enhancedRooms.setSort}
                activeRowId={selectedRoom?.frontId}
                onSelectActiveRow={enhancedRooms.setSelected}
                rows={rows}
                mode="teamlead"
              />
              <LoadingOverflow bg absolute withLoader loading={isRoomsListLoading} />
            </>
          ) : (
            <Tabs defaultActiveId="training" onChange={updateFilter}>
              <Tab id="comp" title="Competitive sessions">
                <RoomsTableList
                  {...sort}
                  onChangeOrder={enhancedRooms.setSort}
                  activeRowId={selectedRoom?.frontId}
                  onSelectActiveRow={enhancedRooms.setSelected}
                  rows={rows}
                />
                <LoadingOverflow bg absolute withLoader loading={isRoomsListLoading} />
              </Tab>
              <Tab id="training" data-test="training" title="Training sessions">
                <RoomsTableList
                  {...sort}
                  onChangeOrder={enhancedRooms.setSort}
                  activeRowId={selectedRoom?.frontId}
                  onSelectActiveRow={enhancedRooms.setSelected}
                  rows={rows}
                  mode="training"
                />
                <LoadingOverflow bg absolute withLoader loading={isRoomsListLoading} />
              </Tab>
            </Tabs>
          )}
        </BG>
      </Container>
      <JoinButton loading={isRoomLoading} disabled={!selectedRoom} {...buttonProps} />
    </Main>
  )
}

const UserEmail = styled(Typography)`
  margin-right: 150px;
  text-align: right;
`

const Cell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  &:nth-of-type(2) {
    justify-content: center;
    text-align: center;
  }
  &:nth-of-type(3) {
    justify-content: flex-end;
    text-align: right;
  }
`

const LogoutButton = styled(Button)`
  position: absolute;
  right: ${theme.padding.medium}px;
`

const Header = styled.header`
  align-items: center;
  display: flex;
  margin-bottom: 60px;
  position: relative;
  text-align: center;
  width: 100%;
`

const Tab = styled.div`
  height: 100%;
  position: relative;
`

const BG = styled.div`
  align-items: center;
  background: ${theme.color.secondary};
  border-radius: ${theme.radius.button.small * 2}px;
  box-shadow: ${theme.shadow.loginForm};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
`

const Main = styled.main`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`
const JoinButton = styled(Button)`
  margin-top: 30px;
  min-width: 400px;
`
const Heading = styled(Typography)``
