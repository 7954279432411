export class Notification extends Error {
  constructor(message) {
    super(message)
    this.name = 'Notification'
  }
}
export class Warning extends Error {
  constructor(message) {
    super(message)
    this.name = 'Warning'
  }
}
export class Log extends Error {
  constructor(message) {
    super(message)
    this.name = 'Log'
  }
}
