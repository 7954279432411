//@ts-nocheck
/* eslint-disable */
import { format } from 'date-fns'
import { useStore } from 'effector-react'
import React from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Normalize } from 'styled-normalize'

import { $fingerprint, $tokensPresented } from 'features/auth'
import { Alert } from 'features/common/organisms/alert'
import { Modal, ModalBackdrop } from 'features/common/organisms/modal'
import { $modal } from 'features/common/organisms/modal/model'
import { ModalRoot } from 'features/common/organisms/modal/root'
import { ErrorBoundary, $error, $errorEventId, ErrorBase } from 'features/error'
import { ChooseDream } from 'features/game/field/dreams-goals/dreams/choose-dream'
import { initApp } from 'lib/init-app'
import { PrivateRoute } from 'lib/private-route'
import { LoginPage } from 'pages/auth/login'
import { LoginByCodePage } from 'pages/auth/login-by-code'
import { RegisterPage } from 'pages/auth/register'
import { GamePage } from 'pages/game'
import { InitPage } from 'pages/init'
import { PreloadPage } from 'pages/preload'
import { RoomDetailsPage } from 'pages/rooms/details'
import { LeaderboardPage } from 'pages/rooms/details/leaderboard'
import { StatisticsPage } from 'pages/rooms/details/statistics'
import { RoomsListPage } from 'pages/rooms/list'
import { theme } from 'theme'
import { Main } from 'ui/atoms/main'
import { Typography } from 'ui/atoms/typography'
import { LoadingOverflow } from 'ui/molecules/loading-overflow'
import { ToastContainer } from 'ui/molecules/toast-container'

import { browserHistory } from './lib/history'

import 'lib/http/mock'
import { LogoutPage } from 'pages/auth/logout'
import { $bgImgSrc } from 'theme/model'

initApp()

const availableDate = process.env.REACT_APP_AVAILABLE
const techIssues = process.env.REACT_APP_TECH_ISSUES

function App() {
  const fingerprint = useStore($fingerprint)
  const error = useStore($error)
  const errorEventId = useStore($errorEventId)
  const modal = useStore($modal)
  const bgImgSrc = useStore($bgImgSrc)
  const isModalOpened = modal !== null || modal?.id === 'choose-dream'
  window.log(isModalOpened, 'isModalOpened')
  return (
    <>
      <Normalize />
      <Main bgImg={bgImgSrc}>
        {availableDate || techIssues ? (
          <CenteringContainer>
            <Typography weight="600" color={theme.color.primary} size={theme.font.size.large * 2}>
              {availableDate &&
                `This game will be available on ${format(new Date(availableDate), 'dd.MM.yyyy')}`}
              {techIssues && `${techIssues}`}
            </Typography>
          </CenteringContainer>
        ) : (
          <>
            <ErrorBase {...{ error }} eventId={errorEventId} />
            <ErrorBoundary>
              {fingerprint ? (
                <ModalBackdrop {...{ isModalOpened }}>
                  <Router history={browserHistory}>
                    <Switch>
                      <Route exact path="/login">
                        <LoginPage />
                      </Route>
                      <Route exact path="/register">
                        <RegisterPage />
                      </Route>
                      <Route path="/login-by-code/:code?">
                        <LoginByCodePage />
                      </Route>
                      <PrivateRoute store={$tokensPresented} exact path="/game">
                        <GamePage />
                      </PrivateRoute>
                      <PrivateRoute store={$tokensPresented} exact path="/rooms">
                        <RoomsListPage />
                      </PrivateRoute>
                      <PrivateRoute store={$tokensPresented} exact path="/rooms/:id">
                        <RoomDetailsPage />
                      </PrivateRoute>
                      <PrivateRoute store={$tokensPresented} exact path="/rooms/:id/leaderboard">
                        <LeaderboardPage />
                      </PrivateRoute>
                      <PrivateRoute
                        store={$tokensPresented}
                        exact
                        path={[
                          '/rooms/:roomID/statistics/:gameID/:teamID',
                          '/rooms/:roomID/statistics/:gameID',
                          '/rooms/:roomID/statistics',
                        ]}
                      >
                        <StatisticsPage />
                      </PrivateRoute>
                      <Route path="/preload" exact>
                        <PreloadPage />
                      </Route>
                      <Route path="/logout" exact>
                        <LogoutPage />
                      </Route>
                      <Route>
                        <InitPage />
                      </Route>
                    </Switch>
                  </Router>
                </ModalBackdrop>
              ) : (
                <LoadingOverflow withLoader loading={true} />
              )}
              <ModalRoot {...{ modal }}>
                <Modal circleRight={0} circleTop={-60} withCloseButton id="choose-dream">
                  <ChooseDream />
                </Modal>
                <Alert withCloseButton noBackdropClose id="end-game-confirm">
                  Do you really want to end the game? This is irreversible action.
                </Alert>
                <Alert withCloseButton noBackdropClose id="go-bankrupt-confirm">
                  Do you really want to go bankrupt? This is irreversible action.
                </Alert>
                <Alert withCloseButton id="open-credit-confirm">
                  Do you really want to open credit?
                </Alert>
                <Alert withCloseButton id="relocate-confirm">
                  Do you really want to relocate?
                </Alert>
                <Alert withCloseButton id="re-sell-confirm">
                  Do you really want to sell real estate?
                </Alert>
                <Alert withCloseButton noBackdropClose id="buy-dream-confirm">
                  Do you really want to buy this dream?
                </Alert>
                <Alert withCloseButton noBackdropClose id="unchoose-dream-confirm">
                  Do you really want to unchoose this dream?
                </Alert>
              </ModalRoot>
              <ToastContainer />
            </ErrorBoundary>
          </>
        )}
      </Main>
    </>
  )
}

const CenteringContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  padding: 5vh 6vw;
`

export default App
