import { desaturate, lighten } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { prop, switchProp } from 'styled-tools'

import { formatPrice } from 'features/game/lib/prices/format'
import { handleBalanceBarType } from 'lib/handle-fields'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'

const handleGradientLevels = (number) => {
  let color = 'red'
  if (number >= 85) {
    color = 'cyan'
  } else if (number > 66) {
    color = 'green'
  } else if (number > 32) {
    color = 'yellow'
  }
  const foundColor = theme.color[color]
  return { second: desaturate(0.1, lighten(0.2, foundColor)), first: foundColor }
}

export const SpendingsBarChart = ({ levels, className }) => {
  const spendings = Object.entries(levels).map((el) => ({ key: el[0], value: el[1] * 100 }))
  return (
    <Container {...{ className }}>
      {spendings.map((level, idx) => {
        const { icon, label } = handleBalanceBarType(level.key)
        const { first } = handleGradientLevels(level.value)
        return (
          <Cell key={idx}>
            <Hint position="top">Good</Hint>
            <Hint position="middle">Ok</Hint>
            <Hint position="bottom">Bad</Hint>
            <Bar
              content={Number(level.value.toFixed(2))}
              height={Number((level.value * 3).toFixed(2))}
              {...handleGradientLevels(level.value)}
            >
              <BarLevel />
            </Bar>
            <Row>
              <Icon src={icon} />
              <Heading>{label}</Heading>
            </Row>
            <SubHeading color={first}>{formatPrice(level.value, { disableCurrency: true })}%</SubHeading>
          </Cell>
        )
      })}
    </Container>
  )
}

const Hint = styled.p`
  color: ${hexToRGBA(theme.color.black, 0.6)};
  font-size: ${theme.font.size.mediumsmall}px;
  font-weight: 600;
  position: absolute;
  ${switchProp('position', {
    top: `
        bottom: 369px;
        left: -17px;
      `,
    middle: `
    bottom: 215px;
        left: 0px;
      `,
    bottom: `
        left: -7px;
        bottom: 70px;
      `,
  })}
  &::after {
    background: ${hexToRGBA(theme.color.black, 0.6)};
    content: ' ';
    display: block;
    height: 1px;
    position: absolute;
    right: -14px;
    top: 9px;
    width: 10px;
  }
`

const Row = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  margin-top: 20px;
`

const Icon = styled.img`
  margin-right: 8px;
  object-fit: contain;
  width: 20px;
`

const Heading = styled.p`
  color: ${hexToRGBA(theme.color.black, 0.7)};
  font-size: ${theme.font.size.main}px;
  font-weight: bold;
`

const SubHeading = styled.p`
  color: ${prop('color')};
  font-size: ${theme.font.size.large}px;
  font-weight: 600;
`

const Cell = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 380px;
  justify-content: flex-end;
  position: relative;
  text-align: center;
  width: 130px;
`

const BarLevel = styled.div`
  border: 1px solid ${hexToRGBA(theme.color.black, 0.3)};
  border-right-color: transparent;
  bottom: 0;
  height: 300px;
  left: -9px;
  position: absolute;
  width: 7px;
`

const Bar = styled.div`
  width: 50px;
  height: 300px;
  max-height: ${prop('height')}px;
  position: relative;
  background: linear-gradient(${prop('first')} 0%, ${prop('second')} 100%);
  //   &::before {
  //       content: ' ';
  //       position: absolute;
  //       width: 10px;
  //       height: 2px;
  //       right: -16px;
  //       top: -1px;
  //       background: ${theme.color.black};
  //   }
  //   &::after {
  //       content: '${prop('content')}%';
  //       position: absolute;
  //       color: ${theme.color.black};
  //       font-size: ${theme.font.size.main}px;
  //       font-weight: 600;
  //       top: -12px;
  //       left: 70px;
  //   }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  & > *:not(:first-child) {
    margin-left: 25px;
  }
`
