/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  $leaderboardTeamsList,
  getRoomLeaderboardFetching,
  getRoomStatisticsFetching,
  getTeamSuggest,
} from 'features/rooms/model'
import { useEffectWithoutInit } from 'lib/hooks/use-effect-without-init'
import { theme } from 'theme'
import { Select } from 'ui/atoms/select'

//eslint-disable-next-line no-unused-vars
const MOCKED_TEAM_SELECT_OPTS = [
  {
    value: 'Russia',
    label: 'Russia',
  },
  { value: 'Germany', label: 'Germany' },
]

export const TeamSelect = ({ roomID }) => {
  const statsLoading = useStore(getRoomStatisticsFetching.isLoading)
  const leaderboardLoading = useStore(getRoomLeaderboardFetching.isLoading)
  const loading = statsLoading || leaderboardLoading
  // cause of its not implemented by backend
  //eslint-disable-next-line no-unused-vars
  const options = useStore($leaderboardTeamsList).map((el) => ({ value: el, label: el }))
  // const options = MOCKED_TEAM_SELECT_OPTS
  const [value, setValue] = useState({ value: null, label: 'This player' })
  useEffectWithoutInit(() => {
    getRoomStatisticsFetching.lazy({ teamID: value.value, roomID })
  }, [value])
  useEffect(() => {
    getRoomLeaderboardFetching.lazy(roomID)
  }, [])
  return (
    <StyledSelect
      disabled={loading}
      onRawChange={setValue}
      defaultOptions={[{ value: null, label: 'This player' }]}
      cacheOptions
      loadOptions={getTeamSuggest}
      {...{ value }}
      padding={theme.padding.button.extrasmall}
    />
  )
}

const StyledSelect = styled(Select)`
  margin-left: 10px;
  max-width: 250px;
  min-width: 150px;
`
