import React from 'react'
import { CircleButton } from 'ui/molecules/circle-button'
import styled from 'styled-components'
import { hexToRGBA } from 'lib/hex-to-rgba'
import { theme } from 'theme'
import { ifProp, prop } from 'styled-tools'
import { resetModal } from './model'

export const Modal = React.memo(
  ({ onCancel, children, withCloseButton, circleRight = -40, circleTop = -40 }) => {
    return (
      <Container onClick={(ev) => ev.stopPropagation()}>
        {withCloseButton && (
          <StyledCircleButton onClick={onCancel ? onCancel : resetModal} {...{ circleRight, circleTop }} />
        )}
        {children}
      </Container>
    )
  }
)

const StyledCircleButton = styled(CircleButton)`
  cursor: pointer;
  position: absolute;
  right: ${prop('circleRight')}px;
  top: ${prop('circleTop')}px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const ModalBackdrop = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  & > * {
    flex: 1;
  }
  ${ifProp(
    'isModalOpened',
    `
  filter: blur(5px);
  background: ${hexToRGBA(theme.color.white, 0.3)};
`
  )}
`
