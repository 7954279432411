import React, { useEffect } from 'react'
import styled from 'styled-components'

import { setNewsRolletPosition } from 'features/game/navigation/model'
import { useRollet } from 'lib/hooks/use-rollet'

import { RolletButton } from 'features/game/field/molecules/rollet-button'

export const NewsRolletButton = () => {
  const rolletHook = useRollet()
  useEffect(() => {
    setNewsRolletPosition(rolletHook.rolletPos)
  }, [rolletHook.rolletPos])
  return <StyledRolletButton size={24} {...rolletHook} />
}

const StyledRolletButton = styled(RolletButton)`
  left: 50%;
  position: absolute;
  top: -16px;
  transform: rotate(180deg);
`
