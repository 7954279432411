import { useStore } from 'effector-react'
import React, { useEffect } from 'react'

import { $canUseTimeline } from 'features/auth'
import { GameAdminTimeline } from 'features/game/admin/timeline'
import { GameField } from 'features/game/field'
import { BottomBar } from 'features/game/field/organisms/bottom-bar'
import { NewsRollet } from 'features/game/field/organisms/bottom-bar/news-rollet'
import { TopBar } from 'features/game/field/organisms/top-bar'
import { getListBySubTab } from 'features/game/lib/get-list-by-subtab'
import {
  $profilePresented,
  $selectedSubTabData,
  $statePresented,
  getGameState,
  getGameStateFetching,
} from 'features/game/model'
import { setSelectedInstrument } from 'features/game/model/state'
import { FoldersAndTabs } from 'features/game/navigation/folders-and-tabs'
import { $selectedSubTab } from 'features/game/navigation/model'
import { useKeypress } from 'lib/hooks/use-keypress'
import { LoadingOverflow } from 'ui/molecules/loading-overflow'
import { ErrorStripes } from 'features/error'

import { S } from './styled'

/**
 * Логика страницы: getGameState сам редиректает на /rooms если у нас внезапно 403, поэтому здесь нет редиректов.
 */

export const GamePage = () => {
  const profileReady = useStore($profilePresented)
  const stateReady = useStore($statePresented)
  const isWriter = useStore($canUseTimeline)
  const subtab = useStore($selectedSubTabData)
  const subTabName = useStore($selectedSubTab)
  const storeArg = getListBySubTab(subTabName, subtab)
  const list = useStore(storeArg)
  const selectedInstrument = useStore(useStore($selectedSubTabData).selected)
  const gameStateLoading = useStore(getGameStateFetching.isLoading)
  const changeNavByKeyboard = (key) => {
    const currentIdx = list.findIndex((el) => el.frontId === selectedInstrument?.frontId)
    let futureIdx
    switch (key) {
      case 'top':
        futureIdx = currentIdx - 1
        break
      default:
        futureIdx = currentIdx + 1
        break
    }
    if (futureIdx + 1 > list.length) futureIdx = 0
    if (futureIdx < 0) futureIdx = list.length - 1
    const foundInstrument = list[futureIdx]
    if (foundInstrument) setSelectedInstrument(foundInstrument)
  }
  useKeypress('KeyW', () => changeNavByKeyboard('top'))
  useKeypress('KeyS', () => changeNavByKeyboard('bottom'))
  useEffect(() => {
    getGameState()
  }, [])
  return (
    <S.Desaturate working={gameStateLoading && stateReady && profileReady}>
      <S.OuterContainer>
        {profileReady && stateReady && (
          <>
            <ErrorStripes noFull>
              <TopBar />
            </ErrorStripes>
            <S.MainContainer>
              <S.InnerContainer>
                <FoldersAndTabs />
                <GameField />
              </S.InnerContainer>
            </S.MainContainer>
            <ErrorStripes noFull>
              <BottomBar />
            </ErrorStripes>
            <NewsRollet />
          </>
        )}

        <LoadingOverflow withLoader loading={!profileReady || !stateReady} absolute />
        {isWriter && (
          <ErrorStripes noFull>
            <GameAdminTimeline />
          </ErrorStripes>
        )}
      </S.OuterContainer>
    </S.Desaturate>
  )
}
