import { useStore } from 'effector-react'
import React from 'react'

import { BankingActionTab } from 'features/game/field/banking/tabs/action-tab'
import { EventsActionsTab } from 'features/game/field/events/actions-tab'
import { ExpensesActionTab } from 'features/game/field/income-expenses/tabs/actions'
import { getSubjectInstrument } from 'features/game/lib/get-subject-instrument'
import { FINANCIAL_SETTINGS_ID } from 'features/game/lib/normalize-market/add-income-expenses'
import { $events, $profile, $selectedSubTabData } from 'features/game/model'
import { InstrumentTypes } from 'features/game/types'
import { $state } from 'features/game/model/state'
import { NoData } from 'ui/molecules/no-data'

import { BuySellTab } from '..'

const getNormalizedSelectedInstrument = (selected, gameState, events) => {
  if (selected?.isTurnAction) {
    let foundInstr = null
    if (['buy', 'sell'].includes(selected.type)) {
      foundInstr = getSubjectInstrument(
        selected.payload.instrumentId,
        selected.payload.instrumentType,
        selected.payload.portfolio_id,
        gameState
      )
    } else if (selected.type === 'reaction') {
      foundInstr = events.find((el) => el.id === selected.payload.event_id)
    } else if (selected.type === 'settings') {
      foundInstr = getSubjectInstrument(FINANCIAL_SETTINGS_ID, 'incomeAndExpenses')
    }
    return foundInstr
  } else {
    return selected
  }
}

export const BuySellTabWrapper = () => {
  const gameState = useStore($state)
  const events = useStore($events)
  const profile = useStore($profile)
  const _selectedInstrument = useStore(useStore($selectedSubTabData).selected)
  const selectedInstrument = getNormalizedSelectedInstrument(_selectedInstrument, gameState, events)
  window.log(selectedInstrument, 'selectedInstrument по мнению buy sell tab')
  if (
    !selectedInstrument ||
    (selectedInstrument.instrumentType === InstrumentTypes.IncomeAndExpenses &&
      selectedInstrument.id !== FINANCIAL_SETTINGS_ID) ||
    (selectedInstrument &&
      [InstrumentTypes.Goal, InstrumentTypes.Dream].includes(selectedInstrument.instrumentType))
  )
    return <NoData />
  window.log(selectedInstrument, 'selectedInstrument')
  const { instrumentType } = selectedInstrument
  if (instrumentType === InstrumentTypes.Event) return <EventsActionsTab {...{ selectedInstrument }} />
  if (selectedInstrument.id === FINANCIAL_SETTINGS_ID)
    return <ExpensesActionTab {...{ selectedInstrument }} />
  if ([InstrumentTypes.Deposit, InstrumentTypes.Credit, InstrumentTypes.Leverage].includes(instrumentType))
    return <BankingActionTab {...{ selectedInstrument }} />
  return <BuySellTab {...{ selectedInstrument, profile }} />
}
