import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { formatPrice } from 'features/game/lib/prices/format'
import { hexToRGBA } from 'lib/hex-to-rgba'
import imgPlaceholder from 'static/character-img-placeholder.svg'
import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'

export const RoomCharacters = ({ onChoose, onDetails, characters }) => {
  return (
    <List>
      {characters.map((char) => {
        const {
          name,
          id,
          balance,
          expensesInYear,
          revenueInYear,
          image: { big, small },
          intro,
        } = char
        return (
          <Card onClick={() => onDetails(char)} key={id}>
            <Img src={small ? small : big || imgPlaceholder} />
            <Inner>
              <Name weight="800">{name}</Name>
              <Section border>
                <Group>
                  <Label>Balance:</Label>
                  <Value>{formatPrice(balance)}</Value>
                </Group>
                <Group>
                  <Label>Annual income:</Label>
                  <Value>{formatPrice(revenueInYear)}</Value>
                </Group>
                <Group>
                  <Label>Annual expenses:</Label>
                  <Value>{formatPrice(expensesInYear)}</Value>
                </Group>
              </Section>
              <Section>
                <Typography size={theme.font.size.small} dangerouslySetInnerHTML={{ __html: intro }} />
              </Section>
            </Inner>
            {/* <OverlayContainer>
              <OverlayButton
                onClick={() => {
                  onChoose(char)
                }}
              >
                Choose
              </OverlayButton>
              <OverlayButton variant="outlinedLight" onClick={() => onDetails(char)}>
                More info
              </OverlayButton>
            </OverlayContainer> */}
          </Card>
        )
      })}
    </List>
  )
}

const OverlayContainer = styled.div`
  background: linear-gradient(
    180deg,
    transparent 15%,
    ${hexToRGBA(theme.color.green, 0.2)} 33.63%,
    ${theme.color.secondary} 65.69%
  );
  border-radius: ${theme.radius.button.small * 2}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  opacity: 0;
  padding: 12px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.15s ease-in-out;
`

const Group = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
`

const Label = styled.p`
  flex: 0;
  font-size: ${theme.font.size.small}px;
  font-weight: 500;
  margin-right: 10px;
  opacity: 0.5;
`

const Value = styled.p`
  font-size: ${theme.font.size.small}px;
  font-weight: 600;
`

const Section = styled.div`
  color: ${theme.color.black};
  ${ifProp('border', `border-bottom: 1px solid ${hexToRGBA(theme.color.black, 0.1)};`)}
  padding: 8px 0;
`

const List = styled.ul`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(5, 204px);
`

const Img = styled.img`
  border-radius: ${theme.radius.button.small * 2}px ${theme.radius.button.small * 2}px 0 0;
  height: 90px;
  object-fit: cover;
  width: 100%;
`

const Inner = styled.div`
  max-height: 350px;
  overflow: hidden;
  padding: 0 15px;
`

const Name = styled(Typography)`
  align-items: center;
  border-bottom: 1px solid ${hexToRGBA(theme.color.black, 0.1)};
  display: flex;
  justify-content: center;
  line-height: 1;
  min-height: 38px;
  padding: 4px 12px;
  padding-top: 0;
  text-align: center;
`

const Card = styled.li`
  background: ${theme.color.secondary};
  border-radius: ${theme.radius.button.small * 2}px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(7px);
    ${OverlayContainer} {
      opacity: 1;
    }
  }
`
