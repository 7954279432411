import { setModal } from 'features/common/organisms/modal/model'
import { setSelectedInstrument } from 'features/game/model/state'
import { setSelectedSubTab } from 'features/game/navigation/model'

import { getSubjectInstrument } from '../get-subject-instrument'

export const selectRelatedInstrument = (id, type) => {
  const foundInstrument = getSubjectInstrument(id, type)
  if (foundInstrument) {
    setSelectedSubTab(type)
    if (type === 'dreams' && !foundInstrument.isPortfolio) {
      setModal({ id: 'choose-dream' })
    } else {
      setSelectedInstrument(foundInstrument)
    }
  }
}
