import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { theme } from 'theme'
import { Input } from 'ui/atoms/input'
import { Typography } from 'ui/atoms/typography'

export const InputGroup = React.forwardRef(({ error, className, children = <Input />, ...rest }, ref) => {
  const child = React.Children.only(children)
  return (
    <Container className={className}>
      <child.type ref={ref} hasError={error} {...child.props} {...rest} />
      {error?.length > 0 && (
        <Error role="alert" color={theme.color.red} size={theme.font.size.extrasmall}>
          {error}
        </Error>
      )}
    </Container>
  )
})

const Error = styled(Typography)`
  margin-top: 5px;
`
const Container = styled.div`
  margin: 10px 0;
  ${ifProp(
    'fullWidth',
    `
    flex: 1;
    width: 100%;
  `
  )}
`
