import React, { useEffect, useRef, useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { uuid } from 'uuidv4'

import { useEffectWithoutInit } from 'lib/hooks/use-effect-without-init'
import { CHART } from 'mocks/state'
import { theme } from 'theme'

import { baseChartOpts } from './default-options'

export const Chart = ({
  data = CHART,
  height = 210,
  width,
  yFormatter,
  tooltipYFormatter,
  tickAmount,
  frontId = uuid(),
  label = 'Price',
}) => {
  const ref = useRef(null)
  const chartColor =
    data[data.length - 2].value > data[data.length - 1].value ? theme.color.red : theme.color.green
  let chartOpts = {
    ...baseChartOpts,
    colors: [chartColor],
  }
  chartOpts.chart.id = frontId
  chartOpts.xaxis.tickAmount = tickAmount
    ? tickAmount
    : data.length <= 15
    ? 'dataPoints'
    : Math.floor(data.length / 2)
  const [chartOptions, setChartOptions] = useState(chartOpts)
  const normalizedData = [
    {
      name: label,
      data: data.reduce((acc, cur) => [...acc, [cur.turn, cur.value]], []),
    },
  ]
  // Оно блять не работает без useState, что-то внутри библы неправильно работает с опциями графика и он забирает чужие настройки...
  useEffectWithoutInit(() => {
    setChartOptions((prevState) => {
      let newState = { ...prevState }
      if (yFormatter) {
        if (Array.isArray(newState.yaxis)) {
          newState.yaxis[0].labels.formatter = yFormatter
        } else {
          newState.yaxis.labels.formatter = yFormatter
        }
      }
      if (tooltipYFormatter) {
        newState.tooltip.y = { formatter: tooltipYFormatter }
      }
      //@TODO: tooltipYFormatter
      newState.colors = [chartColor]
      return newState
    })
  }, [yFormatter, data, tooltipYFormatter])
  useEffect(() => {
    window.log(chartOptions, 'chartOptions')
    if (ref.current) {
      ref.current.chart.ctx.updateOptions(chartOptions, false, true)
    }
  }, [chartOptions])
  return (
    data &&
    data.length > 0 && (
      <ApexCharts
        ref={ref}
        key={frontId}
        id={frontId}
        options={chartOptions}
        series={normalizedData}
        width={width}
        type="area"
        height={height}
      />
    )
  )
}
