import styled from 'styled-components'
import { theme } from 'theme'

export const InstrumentImg = styled.img`
  border-radius: ${theme.radius.button.medium}px;
  height: 21vh;
  min-height: 140px;
  object-fit: cover;
  width: 100%;
`
