import styled, { css } from 'styled-components'
import { ifNotProp, ifProp, prop } from 'styled-tools'

import { theme } from 'theme'
import { Typography } from 'ui/atoms/typography'

export const ButtonsSection = styled.div`
  display: flex;
  justify-content: space-between;
  & > * {
    flex-basis: calc(50% - 6px);
    min-width: calc(50% - 6px);
  }
  ${ifProp(
    'hasThirdButton',
    `
    flex-wrap: wrap;
    & > *:nth-of-type(2n) {
      margin-left: 12px;
    }
    & > *:nth-of-type(3) {
      margin-top: 12px;
    }
    & > *:not(:nth-of-type(1)), & > *:not(:nth-of-type(2)) {
      // margin-top: 12px;
    }`,
    css`
      & > *:not(:first-of-type) {
        margin-left: ${prop('space', '12')}px;
      }
    `
  )}
`

export const Name = styled(Typography)`
  ${ifProp('hasImg', 'margin-left: 27px;')}
`

export const Instrument = styled.div`
  position: relative;
  &:first-of-type {
    margin-top: 10px;
  }
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

export const ValueIcon = styled.img`
  height: 12px;
  margin-right: 7px;
  object-fit: contain;
  position: relative;
  top: 3px;
  width: 14px;
`

export const Icon = styled.img`
  height: ${ifProp('small', '14px', '19px')};
  left: ${ifProp('small', '1.5px', '0px')};
  margin-right: 10px;
  object-fit: contain;
  position: absolute;
  top: ${ifProp('small', '3px', '0px')};
  width: ${ifProp('small', '14px', '19px')};
`

export const Price = styled.p`
  ${ifNotProp('noMl', 'margin-left: 27px;')}
  color: ${(props) =>
    props.status === 'down' || props.status === 'expense' ? theme.color.red : theme.color.green};
  display: block;
  font-size: ${prop('fontSize', theme.font.size.main)}px;
  font-weight: 600;
  & > span {
    font-size: ${theme.font.size.mediumsmall}px;
    margin-left: 9px;
  }
`
